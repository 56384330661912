import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  selectedWidget: string;
  public selectWidgetChange = new BehaviorSubject(null);
  deleteTemplate = new EventEmitter();
  graphType: any;
  widgetDataPoint: any;
  constructor() {}

  set set_selectedWidget(value) {
    this.selectWidgetChange.next(value);
    this.selectedWidget = value;
  }

  get getsetectedWidget() {
    return this.selectedWidget;
  }

  set selectedGraphType(value) {
    this.graphType =
      value && value === 'singleYAxis'
        ? 'Single Y-Axis'
        : value === 'dualYAxis'
        ? 'Dual Y-Axes'
        : undefined;
  }

  get selectedGraphType() {
    return this.graphType;
  }

  getDataforChart(minDate, maxDate, type = 'addwidget') {
    const diff = (maxDate - minDate) / 100;
    const arr = [];
    for (let i = minDate; i <= maxDate; i = i + diff) {
      const x = type === 'addwidget' ? i : i / 1000;
      const y = Math.round(Math.sin(i)) + Math.floor(Math.random() * 5);
      const newY = y <= 0 ? 10 : y + 90;
      arr.push([Math.round(x), newY]);
    }
    return arr;
  }

  getMinMaxDate(defaulttimewindow) {
    if (defaulttimewindow === 'Month' || defaulttimewindow === 'month') {
      const minDate = moment(moment().subtract(30, 'days')).startOf('day').valueOf();
      const maxDate = moment().valueOf();
      return { minDate, maxDate };
    } else if (defaulttimewindow === 'Week' || defaulttimewindow === 'week') {
      const minDate = moment(moment().subtract(7, 'days')).startOf('day').valueOf();
      const maxDate = moment().valueOf();
      return { minDate, maxDate };
    } else if (defaulttimewindow === 'Today' || defaulttimewindow === 'today') {
      const minDate = moment(moment().subtract(24, 'hours')).valueOf();
      const maxDate = moment().valueOf();
      return { minDate, maxDate };
    }
  }
  get dataPoints() {
    return this.widgetDataPoint;
  }
  set dataPoints(data: any) {
    this.widgetDataPoint = data;
  }
}
