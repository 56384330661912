<!-- Only hack to fix the Initial focus -->
<input cdkFocusInitial type="text" style="display: none" />
<!-- above element end -->
<section class="graph-dialog">
  <div
    fxLayout="row"
    [ngClass]="
      deviceType !== 'MobileLandscape'
        ? 'overlay-header'
        : 'overlay-header-mlandscape'
    "
    fxLayoutAlign="space-between start"
  >
    <div class="charttitle" data-test="text_hist-title">
      {{ data?.widget?.title }}
    </div>
    <img
      class="closeBtn"
      src="../../../assets/images/ic-close.svg"
      alt="Close"
      data-test="close_historicalchart"
      (click)="onNoClick()"
    />
  </div>
  <div
    *ngIf="
      deviceType == 'TabletPortrait' ||
        deviceType == 'TabletLandscape' ||
        deviceType == 'MobileLandscape';
      else DesktopMobile
    "
  >
    <div
      fxLayout="row"
      fxLayoutGap="7px"
      [class]="'temporal ' + deviceType?.toLowerCase()"
    >
      <app-date-controller
        [id]="id"
        [device]="deviceType"
        [type]="cardType"
        [isDialog]="true"
      ></app-date-controller>
      <app-datetime-picker
        [startRangeDate]="startRange"
        [endRangeDate]="endRange"
        (selectedRange)="updateChartDate($event)"
      ></app-datetime-picker>
    </div>

    <app-hist-chart-toolbar [id]="id"></app-hist-chart-toolbar>
  </div>

  <ng-template #DesktopMobile>
    <div
      fxLayout="row"
      fxLayoutGap="25px"
      [class]="'temporal ' + deviceType?.toLowerCase()"
    >
      <app-date-controller
        [id]="id"
        [device]="deviceType"
        [type]="cardType"
        [isDialog]="true"
      ></app-date-controller>
      <app-datetime-picker
        [startRangeDate]="startRange"
        [endRangeDate]="endRange"
        (selectedRange)="updateChartDate($event)"
      ></app-datetime-picker>
      <app-hist-chart-toolbar [id]="id"></app-hist-chart-toolbar>
    </div>
  </ng-template>
  <div *ngIf="cardType === 'line-chart'">
    <app-historical-chart
      [deviceId]="data.deviceId"
      [widget]="data.widget"
      [id]="id"
      [chartType]="cardType"
      [device]="deviceType"
      (statusEvent)="toggleLoader($event)"
      [isDialogView]="true"
    ></app-historical-chart>
  </div>
  <div *ngIf="cardType === 'multiseries-chart'">
    <app-multiseries-chart
      [deviceId]="data.deviceId"
      [widget]="data.widget"
      [id]="id"
      [chartType]="cardType"
      [device]="deviceType"
      (statusEvent)="toggleLoader($event)"
      [isDialogView]="true"
    ></app-multiseries-chart>
  </div>

  <mat-progress-bar
    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
    mode="indeterminate"
    [ngStyle]="{ display: showLoader ? 'block' : 'none' }"
  ></mat-progress-bar>
</section>
