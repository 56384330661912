import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/service/template/template.service';
import { GraphType } from './select-linechart-type/select-linechart-type.component';

@Component({
  selector: 'app-addedit-linechart-v2',
  templateUrl: './addedit-linechart-v2.component.html',
  styleUrls: ['./addedit-linechart-v2.component.scss'],
})
export class AddeditLinechartV2Component {
  seletedGraphType: GraphType;
  data: any;
  @Input() set id(id: string) {
    this.data = this._templateService.getEditData(id)[0];
    this.seletedGraphType = this.data?.dualYAxis ? 'dualYAxis' : 'singleYAxis';
  }
  constructor(private _templateService: TemplateService) {}
}
