import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MeshServiceProvidor } from './service/mesh/mesh.service';
import { MainComponent } from './core/main.component';
import { DevicesComponent } from './core/devices/devices.component';
import { DeviceDashboardComponent } from './core/device-dashboard/device-dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { SecureAuthPagesGuard } from './auth/secure-auth-pages.guard';
import { AddeditTemplatesComponent } from './core/controls-engineer/addedit-templates/addedit-templates.component';
import { TemplatesComponent } from './core/controls-engineer/templates/templates.component';
import { AddWidgetComponent } from './core/controls-engineer/add-widget/add-widget.component';
import { CreateTemplateComponent } from './core/controls-engineer/create-template/create-template.component';
import { AddeditImageComponent } from './core/controls-engineer/addedit-image/addedit-image.component';
import { EditWidgetComponent } from './core/controls-engineer/edit-widget/edit-widget.component';
import { ProfileComponent } from './core/user-management/profile/profile.component';
import { UserListingComponent } from './core/user-management/user-listing/user-listing.component';
import { EditUserComponent } from './core/user-management/edit-user/edit-user.component';
import { AddUserComponent } from './core/user-management/add-user/add-user.component';
import { RegisterComponent } from './core/user-management/register/register.component';
import { ChangePasswordComponent } from './core/user-management/change-password/change-password.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { UnavailableServiceComponent } from './unavailable-service/unavailable-service.component';
import { EventLogComponent } from './core/event-log/event-log.component';
import { NotificationSettingsComponent } from './core/notification-settings/notification-settings.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { InvitationExpiredComponent } from './core/invitation-expired/invitation-expired.component';
import { userRoleConfig } from './shared/constants/constants';
import { RoleGuard } from './auth/role.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [SecureAuthPagesGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [SecureAuthPagesGuard],
  },

  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'unsupportedbrowser',
    component: UnsupportedBrowserComponent,
  },
  {
    path: 'unavailableservice',
    component: UnavailableServiceComponent,
  },
  {
    path: 'invitation-expired',
    component: InvitationExpiredComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'notification-settings',
        component: NotificationSettingsComponent,
      },
      {
        path: 'eventlog',
        component: EventLogComponent,
      },
      {
        path: 'users/addUser',
        component: AddUserComponent,
        data: { role: [userRoleConfig.CE, userRoleConfig.SE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'users',
        component: UserListingComponent,
        data: { role: [userRoleConfig.CE, userRoleConfig.SE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'users/edituser',
        component: EditUserComponent,
        data: { role: [userRoleConfig.CE, userRoleConfig.SE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template',
        component: TemplatesComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template/blanktemplate',
        component: AddeditTemplatesComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template/preview',
        component: DeviceDashboardComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template/create-dashboard-template',
        component: CreateTemplateComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'device',
        component: DevicesComponent,
      },
      {
        path: 'device/:id',
        component: DeviceDashboardComponent,
      },
      {
        path: 'template/addwidget',
        component: AddWidgetComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template/editwidget',
        component: EditWidgetComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
      {
        path: 'template/updateInfo',
        component: AddeditImageComponent,
        data: { role: [userRoleConfig.CE] },
        canActivate: [RoleGuard],
      },
    ],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private meshServiceProvidor: MeshServiceProvidor) {}
}
