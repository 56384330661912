import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DateAdapter } from '@angular/material/core';
//import {LocalizationServiceConfig} from './localization-config.service';

/**
 * Class representing the translation service.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private _localeId = 'en-US'; // default
  private _supportedLanguages: string[] = [];

  /**
   * @constructor
   * @param singleton - the localization service
   * @param config - the localization config
   * @param translateService - the translate service
   * @param dateAdapter -  the dat adapter
   */
  constructor(
    @Optional() @SkipSelf() private singleton: LocalizationService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>
  ) {
    if (this.singleton) {
      throw new Error('LocalizationService is already provided by the root module');
    }

    //this._localeId = '';//this.config.locale_id;
    this.dateAdapter.setLocale(this._localeId);
  }

  /**
   * Initialize the service.
   *
   * @returns
   */
  public initService(): Promise<void> {
    // change this implementation when we support multiple languages
    this._localeId = localStorage.getItem('userLanguage') || 'en-US';
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    return this.useLanguage(this._localeId);
  }

  /**
   * change the selected language
   *
   * @returns
   */
  public useLanguage(lang: string): Promise<void> {
    this.translateService.setDefaultLang(lang);
    this.dateAdapter.setLocale(lang);
    return this.translateService
      .use(lang)
      .toPromise()
      .catch(() => {
        this._supportedLanguages = [];
        throw new Error('LocalizationService.init failed');
      });
  }

  /**
   * Gets the instant translated value of a key (or an array of keys).
   *
   * @param key
   * @param interpolateParams
   * @returns
   */
  public translate(key: string | string[], interpolateParams?: Record<string, unknown>): string {
    return this.translateService.instant(key, interpolateParams) as string;
  }

  /**
   * Change language to given language
   *
   * @param lang new language
   * @param onReady callback handler
   * @param onError callback handler
   */
  public changeLanguage(lang: string, onReady: () => void, onError: () => void): void {
    if (this._supportedLanguages.indexOf(lang) < 0) {
      if (onError) {
        onError();
      }
    } else {
      this._localeId = lang;
      this.dateAdapter.setLocale(this._localeId);
      onReady();
    }
  }

  /**
   * Returns a list of supported languages.
   *
   * @return
   */
  public getSupportedLanguages(): string[] {
    return this._supportedLanguages;
  }

  /**
   * sets the supported languages
   *
   * @public
   */
  public set setSupportedLanguage(list: string[]) {
    this._supportedLanguages = list;
  }

  /**
   * Returns the current language.
   *
   * @return
   */
  public get currentLanguage(): string {
    return this._localeId;
  }
}
