/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
/**
 * Angular 2 decorators and services
 */
import { Component, Input, OnChanges, OnInit, ViewChild, DoCheck } from '@angular/core';
import * as GaugeChart from 'gauge-chart';

/**
 * GaugeChart Component
 */
@Component({
  selector: 'app-fv-gauge-chart',
  templateUrl: './demo-gauge.component.html',
  styleUrls: ['./demo-gauge.component.scss'],
})
export class DemoGaugeComponent implements OnInit, OnChanges, DoCheck {
  @ViewChild('gaugeArea', { static: true }) gaugeArea;
  @ViewChild('gaugeContainer', { static: true }) gaugeContainer;
  @Input() canvasWidth: number;
  @Input() needleValue: number;
  @Input() centralLabel: string;
  @Input() options;
  @Input() wrapOptions?;
  @Input() name?: string;
  @Input() nameFont?: string;
  @Input() bottomLabel?: string;
  @Input() bottomLabelFont?: string;

  public nameMargin: string;
  public bottomLabelMargin: string;

  private element;
  private gaugeChart: any;
  private oldOptions;

  ngOnInit() {
    if (this.name) {
      if (!this.nameFont) {
        this.nameFont = '' + Math.round(this.canvasWidth / 15);
      }
      this.nameMargin = '' + Math.round(+this.nameFont / 4);
    }

    if (this.bottomLabel) {
      if (!this.bottomLabelFont) {
        this.bottomLabelFont = '' + Math.round(this.canvasWidth / 10);
      }
      this.bottomLabelMargin = '-' + this.bottomLabelFont;
    }

    if (this.optionsCheck()) {
      this.element = this.gaugeArea.nativeElement;
      this.drawChart();
    }
    this.oldOptions = JSON.stringify(this.options);
  }

  optionsCheck() {
    if (this.canvasWidth == null) {
      return false;
    } else if (this.needleValue == null) {
      return false;
    }
    if (this.centralLabel == null) {
      this.centralLabel = '';
    }
    return true;
  }

  ngDoCheck() {
    if (!this.areEqual(this.options, this.oldOptions)) {
      this.drawChart(true);
      this.oldOptions = { ...this.options };
    }
  }

  areEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  drawChart(redraw = false) {
    if (redraw) {
      this.gaugeChart.removeGauge();
    }
    if (this.options !== undefined) {
      this.options.centralLabel = this.centralLabel;
    }
    this.gaugeChart = GaugeChart.gaugeChart(this.element, this.canvasWidth, this.options);
    this.gaugeChart.updateNeedle(this.needleValue);
  }

  ngOnChanges(changes) {
    let isRedrawRequest = false;
    if (changes.needleValue && !changes.needleValue.firstChange) {
      this.needleValue = changes.needleValue.currentValue;
      this.gaugeChart.updateNeedle(this.needleValue);
    }
    if (changes?.centralLabel && !changes?.centralLabel?.firstChange) {
      this.centralLabel = changes?.centralLabel?.currentValue;
      isRedrawRequest = true;
    }
    if (changes.canvasWidth && !changes.canvasWidth.firstChange) {
      this.canvasWidth = changes.canvasWidth.currentValue;
      isRedrawRequest = true;
    }
    if (isRedrawRequest) {
      this.drawChart(true);
    }
  }
}
