<div fxFlex="100%" fxLayout="row" class="binary-widget">
  <form [formGroup]="registrationForm" class="w-100">
    <div class="inp-container" fxFlex="38%">
      <mat-tab-group
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabClickedChanged($event)"
        [ngClass]="{
          'error-data-tab': dataTabError,
          'error-design-tab': designTabError
        }"
      >
        <mat-tab label="DATA">
          <ng-template mat-tab-label>
            <div>
              <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                <div class="data-align">DATA</div>
                <div class="tab-icon-align" *ngIf="dataTabError">
                  <mat-icon
                    [ngStyle]="{ color: '#e60000' }"
                    class="example-tab-icon"
                    >cancel</mat-icon
                  >
                </div>
              </div>
            </div>
          </ng-template>
          <div formGroupName="data" class="top-margin">
            <div class="left-margin data-top-margin">
              <div fxFlex="100%" fxLayout="column">
                <div class="label-margin">
                  <mat-label class="label-clr">{{
                    "common.data-point" | translate
                  }}</mat-label>
                </div>
                <mat-select
                  class="dropdown-binary"
                  [ngClass]="{
                    'dropdown-binary-error':
                      !registrationForm.valid &&
                      submitted &&
                      registrationForm.controls['data']['controls']['dataPoint']
                        ?.errors?.required
                  }"
                  formControlName="dataPoint"
                  placeholder="{{
                    'placeholder_messages.datapoint' | translate
                  }}"
                >
                  <mat-option *ngFor="let data of dataset" [value]="data.path">
                    {{ data.path }}
                  </mat-option>
                </mat-select>

                <div
                  class="invalid-feedback-text"
                  *ngIf="
                    !registrationForm.valid &&
                    submitted &&
                    registrationForm.controls['data']['controls']['dataPoint']
                      ?.errors?.required
                  "
                >
                  <span>*</span>{{ "message.valid-data-point" | translate }}
                </div>
              </div>
            </div>
            <div></div>

            <div
              fxLayout="row"
              class="btn-placements main-top-margin top-margin left-margin"
            >
              <button
                data-test="cancel-binary"
                class="btn secondary-btn"
                mat-flat-button
                (click)="onCancel()"
                type="button"
              >
                {{ "action.cancel" | translate | uppercase }}
              </button>
              <button
                *ngIf="type == 'edit'"
                class="btn secondary-btn"
                mat-flat-button
                data-test="action_delete"
                (click)="delete()"
                type="button"
              >
                {{ "action.delete" | translate }}
              </button>
              <button
                type="submit"
                (click)="onSubmit()"
                data-test="submit-binary"
                click="onSubmit()"
                class="btn primary-btn"
                mat-flat-button
              >
                {{ "action.save-widget" | translate | uppercase }}
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab class="design-tab-ht" label="DESIGN">
          <div class="tab-scroll" cdkScrollable>
            <ng-template mat-tab-label>
              <div>
                <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                  <div class="data-align">DESIGN</div>
                  <div class="tab-icon-align" *ngIf="designTabError">
                    <mat-icon
                      [ngStyle]="{ color: '#e60000' }"
                      class="example-tab-icon"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="inp-container">
              <div formGroupName="active" class="active-group">
                <div
                  class="textbox-align top-margin left-margin"
                  fxLayout="column"
                >
                  <div class="label-clr-head">
                    {{ "common.widget-active-state" | translate }}
                  </div>
                  <div class="label-margin top-margin">
                    <label class="label-clr">{{
                      "common.widget-active-text" | translate
                    }}</label>
                  </div>
                  <div fxFlex="100%" fxLayout="row">
                    <div>
                      <span
                        ><input
                          class="inp-field-design"
                          [ngClass]="{
                            'inp-field-design-error':
                              (checkingError || checkDesignError) &&
                              textError &&
                              this.registrationForm.controls['active'][
                                'controls'
                              ]['activeText'].value == '' &&
                              this.registrationForm.controls['active'][
                                'controls'
                              ]['activeIcon'].value == ''
                          }"
                          matInput
                          formControlName="activeText"
                          placeholder="{{
                            'common.place-state-text' | translate
                          }}"
                          type="text"
                        />
                        <div
                          class="invalid-feedback-text"
                          *ngIf="
                            (checkingError || checkDesignError) &&
                            textError &&
                            this.registrationForm.controls['active'][
                              'controls'
                            ]['activeText'].value == '' &&
                            this.registrationForm.controls['active'][
                              'controls'
                            ]['activeIcon'].value == ''
                          "
                        >
                          <span>*</span
                          >{{ "message.valid-icon-text-active" | translate }}
                        </div>
                      </span>
                    </div>
                    <div>
                      <span class="top-margin"
                        ><input
                          class="color-field"
                          matInput
                          formControlName="activeTextColor"
                          type="color"
                      /></span>
                    </div>
                  </div>
                </div>
                <span class="left-margin icon-labels-align">
                  <label class="label-clr">{{
                    "common.widget-icon" | translate
                  }}</label></span
                >
                <div class="left-margin top-margin">
                  <div class="margin-top-ten" fxFlex="100%" fxLayout="row">
                    <div class="" style="position: relative">
                      <mat-form-field
                        matAutocompleteOrigin
                        #origin="matAutocompleteOrigin"
                        floatLabel="never"
                        class="example-full-width-binary"
                        [ngClass]="{
                          'example-full-width-binary-error':
                            (checkingError || checkDesignError) &&
                            iconError &&
                            this.registrationForm.controls['active'][
                              'controls'
                            ]['activeText'].value == '' &&
                            this.registrationForm.controls['active'][
                              'controls'
                            ]['activeIcon'].value == ''
                        }"
                      >
                        <div
                          fxFlex="100%"
                          fxLayout="row"
                          fxLayoutAlign="space-around"
                        >
                          <mat-icon
                            *ngIf="
                              getImageStatusActive(
                                registrationForm.controls['active']['controls'][
                                  'activeIcon'
                                ].value
                              ) &&
                              registrationForm.controls['active']['controls'][
                                'activeIcon'
                              ].value !== 'none'
                            "
                            class="status-dropdown autocomplete-icon-margin"
                            [ngStyle]="{
                              color:
                                registrationForm.controls['active']['controls'][
                                  'activeIconColor'
                                ].value
                            }"
                          >
                            {{
                              this.registrationForm.controls["active"][
                                "controls"
                              ]["activeIcon"].value
                            }}
                          </mat-icon>
                          <span class="autocomplete-text-margin ml-0">
                            <input
                              class="auto-input"
                              matInput
                              aria-label="State"
                              id="plain"
                              placeholder="{{
                                'common.place-icon' | translate
                              }}"
                              [matAutocomplete]="auto"
                              [matAutocompleteConnectedTo]="origin"
                              formControlName="activeIcon"
                            />
                          </span>
                          <mat-icon
                            class="status-dropdown margin-icon cursor"
                            [ngStyle]="{ color: '#404040' }"
                            >arrow_drop_down</mat-icon
                          >
                        </div>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option
                            *ngFor="let state of filteredStatesActive | async"
                            [value]="state.name"
                            [matTooltip]="state.view"
                          >
                            <mat-icon
                              class="status-dropdown"
                              [ngStyle]="{ color: '#404040' }"
                              >{{ state.name }}</mat-icon
                            >
                            <span>{{ state.view }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <div
                        class="invalid-feedback-auto"
                        *ngIf="
                          (checkingError || checkDesignError) &&
                          iconError &&
                          this.registrationForm.controls['active']['controls'][
                            'activeText'
                          ].value == '' &&
                          this.registrationForm.controls['active']['controls'][
                            'activeIcon'
                          ].value == ''
                        "
                      >
                        <span>*</span
                        >{{ "message.valid-icon-text-active" | translate }}
                      </div>
                    </div>

                    <div>
                      <span class="top-margin"
                        ><input
                          class="color-field-icon"
                          matInput
                          formControlName="activeIconColor"
                          type="color"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!--PART B-->
              <div formGroupName="inActive" class="inactive-group">
                <div
                  class="textbox-align top-margin left-margin main-top-margin"
                  fxLayout="column"
                >
                  <div class="label-clr-head">
                    {{ "common.widget-inactive-state" | translate }}
                  </div>
                  <div class="label-margin top-margin">
                    <label class="label-clr">{{
                      "common.widget-inactive-text" | translate
                    }}</label>
                  </div>
                  <div fxFlex="100%" fxLayout="row">
                    <div>
                      <span
                        ><input
                          class="inp-field-design"
                          [ngClass]="{
                            'inp-field-design-error':
                              (checkingError || checkDesignError) &&
                              textError &&
                              this.registrationForm.controls['inActive'][
                                'controls'
                              ]['inactiveText'].value == '' &&
                              this.registrationForm.controls['inActive'][
                                'controls'
                              ]['inactiveIcon'].value == ''
                          }"
                          matInput
                          formControlName="inactiveText"
                          placeholder="{{
                            'common.place-state-text' | translate
                          }}"
                          type="text"
                        />
                        <div
                          class="invalid-feedback-text"
                          *ngIf="
                            (checkingError || checkDesignError) &&
                            textError &&
                            this.registrationForm.controls['inActive'][
                              'controls'
                            ]['inactiveText'].value == '' &&
                            this.registrationForm.controls['inActive'][
                              'controls'
                            ]['inactiveIcon'].value == ''
                          "
                        >
                          <span>*</span
                          >{{ "message.valid-icon-text-inactive" | translate }}
                        </div>
                      </span>
                    </div>
                    <div>
                      <span class="top-margin"
                        ><input
                          class="color-field"
                          matInput
                          formControlName="inactiveTextColor"
                          type="color"
                      /></span>
                    </div>
                  </div>
                </div>

                <span class="left-margin icon-labels-align">
                  <label class="label-clr">{{
                    "common.widget-icon" | translate
                  }}</label></span
                >
                <div class="left-margin top-margin">
                  <div class="margin-top-ten" fxFlex="100%" fxLayout="row">
                    <div class="">
                      <mat-form-field
                        floatLabel="never"
                        class="example-full-width-binary"
                        [ngClass]="{
                          'example-full-width-binary-error':
                            (checkingError || checkDesignError) &&
                            iconError &&
                            this.registrationForm.controls['inActive'][
                              'controls'
                            ]['inactiveText'].value == '' &&
                            this.registrationForm.controls['inActive'][
                              'controls'
                            ]['inactiveIcon'].value == ''
                        }"
                      >
                        <div
                          fxFlex="100%"
                          fxLayoutAlign="space-around"
                          fxLayout="row"
                        >
                          <mat-icon
                            *ngIf="
                              getImageStatusInActive(
                                registrationForm.controls['inActive'][
                                  'controls'
                                ]['inactiveIcon'].value
                              ) &&
                              registrationForm.controls['inActive']['controls'][
                                'inactiveIcon'
                              ].value !== 'none'
                            "
                            class="status-dropdown autocomplete-icon-margin"
                            [ngStyle]="{
                              color:
                                registrationForm.controls['inActive'][
                                  'controls'
                                ]['inactiveIconColor'].value
                            }"
                          >
                            {{
                              this.registrationForm.controls["inActive"][
                                "controls"
                              ]["inactiveIcon"].value
                            }}
                          </mat-icon>
                          <span class="autocomplete-text-margi ml-0">
                            <input
                              class="auto-input"
                              matInput
                              placeholder="{{
                                'common.place-icon' | translate
                              }}"
                              aria-label="State"
                              id="auto"
                              [matAutocomplete]="auto1"
                              formControlName="inactiveIcon"
                            />
                          </span>
                          <mat-icon
                            class="status-dropdown margin-icon cursor"
                            [ngStyle]="{ color: '#404040' }"
                            >arrow_drop_down</mat-icon
                          >
                        </div>
                        <mat-autocomplete #auto1="matAutocomplete">
                          <mat-option
                            *ngFor="let state of filteredStatesInActive | async"
                            [value]="state.name"
                            [matTooltip]="state.view"
                          >
                            <mat-icon
                              class="status-dropdown"
                              [ngStyle]="{ color: '#404040' }"
                              >{{ state.name }}</mat-icon
                            >
                            <span>{{ state.view }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <div
                        class="invalid-feedback-auto"
                        *ngIf="
                          (checkingError || checkDesignError) &&
                          iconError &&
                          this.registrationForm.controls['inActive'][
                            'controls'
                          ]['inactiveText'].value == '' &&
                          this.registrationForm.controls['inActive'][
                            'controls'
                          ]['inactiveIcon'].value == ''
                        "
                      >
                        <span>*</span
                        >{{ "message.valid-icon-text-inactive" | translate }}
                      </div>
                    </div>

                    <div>
                      <span class="top-margin"
                        ><input
                          class="color-field-icon"
                          matInput
                          formControlName="inactiveIconColor"
                          type="color"
                      /></span>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  class="btn-placements main-top-margin top-margin left-margin"
                >
                  <button
                    data-test="new-template"
                    class="btn secondary-btn"
                    mat-flat-button
                    (click)="onCancel()"
                    type="button"
                  >
                    {{ "action.cancel" | translate | uppercase }}
                  </button>
                  <button
                    *ngIf="type == 'edit'"
                    class="btn secondary-btn"
                    mat-flat-button
                    data-test="action_delete"
                    (click)="delete()"
                    type="button"
                  >
                    {{ "action.delete" | translate }}
                  </button>
                  <button
                    type="submit"
                    (click)="onSubmit()"
                    data-test="btn primary-btn"
                    class="btn primary-btn"
                    mat-flat-button
                  >
                    {{ "action.save-widget" | translate | uppercase }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div
      fxFlex="62%"
      fxLayoutAlign="center "
      fxLayout="row"
      class="view-container box-margin-top"
    >
      <div class="preview-left-margin" fxLayout="column">
        <div class="preview-head-align">
          <span class="preview-head-label">{{
            "common.widget-active-state" | translate
          }}</span>
        </div>
        <div class="numnerical-container left-align-cont">
          <div
            *ngIf="
              registrationForm.controls['data']['controls']['dataPoint']
                .value !== ''
            "
            class="preview-left-margin preview-notitle truncate"
            matTooltipClass="tooltip-hover"
          >
            {{
              this.registrationForm.controls["data"]["controls"]["dataPoint"]
                .value
            }}
          </div>
          <div
            *ngIf="
              registrationForm.controls['data']['controls']['dataPoint']
                .value == ''
            "
            class="preview-left-margin preview-title truncate"
            matTooltipClass="tooltip-hover"
          >
            {{ "common.widget-title" | translate }}
          </div>
          <div class="preview-left-margin preview-content">
            <div
              class="content-2"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <span
                *ngIf="
                  registrationForm.controls['active']['controls']['activeIcon']
                    .value !== '' &&
                  registrationForm.controls['active']['controls']['activeIcon']
                    .value !== 'none'
                "
              >
                <mat-icon
                  class="prv-icon"
                  [ngStyle]="{
                    color:
                      registrationForm.controls['active']['controls'][
                        'activeIconColor'
                      ].value
                  }"
                  >{{
                    registrationForm.controls["active"]["controls"][
                      "activeIcon"
                    ].value
                  }}</mat-icon
                >
              </span>
              <span
                class="
                  card-main-head
                  truncate-binary
                  preview-left-margin
                  device-imp-status-details device-card-status-align
                "
                matTooltipClass="tooltip-hover"
                [matTooltip]="
                  registrationForm.controls['active']['controls']['activeText']
                    .value
                "
                [ngStyle]="{
                  color:
                    registrationForm.controls['active']['controls'][
                      'activeTextColor'
                    ].value
                }"
                >{{
                  registrationForm.controls["active"]["controls"]["activeText"]
                    .value
                }}</span
              >
            </div>
          </div>
          <div class="preview-left-margin preview-footer">00:00:00</div>
        </div>
      </div>

      <div fxLayout="column">
        <div class="preview-head-align">
          <span class="preview-head-label">{{
            "common.widget-inactive-state" | translate
          }}</span>
        </div>
        <div class="numnerical-container left-align-cont">
          <div
            *ngIf="
              registrationForm.controls['data']['controls']['dataPoint']
                .value !== ''
            "
            class="preview-left-margin preview-notitle truncate"
            matTooltipClass="tooltip-hover"
          >
            {{
              this.registrationForm.controls["data"]["controls"]["dataPoint"]
                .value
            }}
          </div>
          <div
            *ngIf="
              registrationForm.controls['data']['controls']['dataPoint']
                .value == ''
            "
            class="preview-left-margin preview-title truncate"
            matTooltipClass="tooltip-hover"
          >
            {{ "common.widget-title" | translate }}
          </div>
          <div class="preview-left-margin preview-content">
            <div
              class="content-2"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <span
                *ngIf="
                  registrationForm.controls['inActive']['controls'][
                    'inactiveIcon'
                  ].value !== '' &&
                  registrationForm.controls['inActive']['controls'][
                    'inactiveIcon'
                  ].value !== 'none'
                "
              >
                <mat-icon
                  class="prv-icon"
                  [ngStyle]="{
                    color:
                      registrationForm.controls['inActive']['controls'][
                        'inactiveIconColor'
                      ].value
                  }"
                  >{{
                    registrationForm.controls["inActive"]["controls"][
                      "inactiveIcon"
                    ].value
                  }}</mat-icon
                >
              </span>
              <span
                class="
                  card-main-head
                  truncate-binary
                  preview-left-margin
                  device-imp-status-details device-card-status-align
                "
                matTooltipClass="tooltip-hover"
                [matTooltip]="
                  registrationForm.controls['inActive']['controls'][
                    'inactiveText'
                  ].value
                "
                [ngStyle]="{
                  color:
                    registrationForm.controls['inActive']['controls'][
                      'inactiveTextColor'
                    ].value
                }"
                >{{
                  registrationForm.controls["inActive"]["controls"][
                    "inactiveText"
                  ].value
                }}</span
              >
            </div>
          </div>
          <div class="preview-left-margin preview-footer">00:00:00</div>
        </div>
      </div>
    </div>
  </form>
</div>
