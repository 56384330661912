export const pmDeviceTable = ['deviceName', 'siteName', 'profileName', 'status'];
export const smDeviceTable = [
  'company',
  'deviceName',
  'siteName',
  'profileName',
  'macAddress',
  'serialNumber',
  'gatewaySerialNumber',
  'status',
];
export const ceDeviceTable = [
  'company',
  'deviceName',
  'siteName',
  'profileName',
  'macAddress',
  'serialNumber',
  'gatewaySerialNumber',
  'status',
];
export const previewTable = [
  'selection',
  'deviceName',
  'siteName',
  'profileName',
  'version',
  'status',
];
export const userTable = ['firstName', 'lastName', 'company', 'fieldveuRole', 'email', 'edit'];
export const editUserTable = [
  'assigned',
  'company',
  'deviceName',
  'siteName',
  'profileName',
  'macAddress',
  'serialNumber',
  'gatewaySerialNumber',
];

export const eventLogTable = [
  'deviceName',
  'siteName',
  'status',
  'notificationMessage',
  'dateTime',
];
