import { Pipe, PipeTransform } from '@angular/core';
import { isObject, isString } from 'lodash';

@Pipe({
  name: 'arrayIncludes',
})
export class ArrayIncludesPipe implements PipeTransform {
  transform(listItems: Array<any>, value: string, on?: string): boolean {
    const result = listItems.find((item) => {
      if (isObject(item) && item[on]) {
        return item[on] === value;
      } else if (isString(item)) {
        return item === value;
      } else {
        return false;
      }
    });

    return Boolean(result);
  }
}
