import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss'],
})
export class DeviceStatusComponent implements OnInit, OnDestroy {
  @Input() statusCardData;
  @Input() device;
  @Input() type;
  @Output() scrolling: EventEmitter<any> = new EventEmitter();
  @Output() color: EventEmitter<any> = new EventEmitter();
  @Input() widget;
  @ViewChild(MatMenuTrigger) statusMenu: MatMenuTrigger;

  public timeValue: any = moment().format('HH:mm:ss');
  statusDetails: any;
  colorInfo: any;
  cardStatusCount: any;
  subscriptionHandler;
  constructor(private service: MeshServiceProvidor, private router: Router) {}

  ngOnInit(): void {
    if (this.statusCardData) {
      this.statusDetails = this.statusCardData.statusDetails;
      if (this.type !== 'template' && this.type !== 'widget') {
        this.getData();
      } else {
        this.colorInfo = 'Normal';
        this.color.emit(this.colorInfo);
        this.statusDetails = [];
        this.timeValue = moment().format('HH:mm:ss');
        this.cardStatusCount = [];
      }
    }
  }
  getStatusDetails(e) {
    this.scrolling.emit(e);
  }
  update = (data) => {
    if (data !== null) {
      this.statusDetails = data?.offNormalPoints.map((p) => ({
        ...p,
        title: p.dataPath,
      }));

      this.colorInfo = data?.status;
      if (this.statusDetails) {
        const fil = this.statusDetails.filter((fl) => {
          return fl?.status === this.colorInfo;
        });
        this.cardStatusCount = fil;
      }
      this.cardStatusCount = this.statusDetails;
      this.color.emit(this.colorInfo);
    }
    if (moment(data?.timestamp).format('YYYY MM DD') === moment().format('YYYY MM DD')) {
      this.timeValue = moment(data?.timestamp).format('HH:mm:ss');
    } else {
      this.timeValue = moment(data?.timestamp).format('D MMM HH:mm:ss');
    }
  };
  getData() {
    const sub = this.service
      .getGaugeData(this.widget.currentPath, this.update, this.widget.type)
      .then((res) => {
        if (res) {
          this.subscriptionHandler = res.gaugeDataInstance;
          if (res.initialValue !== null) {
            this.update(res.initialValue);
          }
        }
      })
      .catch((error) => {
        this.subscriptionHandler = 1;
        this.colorInfo = 'Offline';
        this.color.emit(this.colorInfo);
        this.statusDetails = [];
        this.timeValue = moment().format('HH:mm:ss');
        this.cardStatusCount = [];
      });
  }

  destroy(e) {
    this.service.destroyGaugeData(e);
  }
  openStatusMenu() {
    this.statusMenu.openMenu();
  }

  edit(type, data) {
    this.router.navigate(['/', 'main', 'template', 'editwidget'], {
      queryParams: {
        type,
        id: data.widgetID,
      },
    });
  }
  ngOnDestroy() {
    this.destroy(this.subscriptionHandler);
  }
}
