import { Component, OnInit } from '@angular/core';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { ToasterData, userRoleConfig } from 'src/app/shared/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { ShareDashboardComponent } from 'src/app/shared/components/share-dashboard/share-dashboard.component';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { DashboardPermissionComponent } from 'src/app/shared/components/dashboard-permission/dashboard-permission.component';
import { Router } from '@angular/router';
import { DashEventService } from '../services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent implements OnInit {
  userRole = localStorage.getItem('role');
  userRoleConfig = userRoleConfig;
  deviceType: any;
  constructor(
    private _dashEventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService,
    public dialog: MatDialog,
    private meshService: MeshServiceProvidor,
    private cs: CommonService,
    public router: Router
  ) {}

  ngOnInit(): void {
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }

  get deviceName() {
    const deviceData = this._dashEventService._deviceDetail;
    if (deviceData) {
      return deviceData.deviceName;
    }
    return '';
  }

  get profileInstanceID() {
    const deviceData = this._dashEventService._deviceDetail;
    if (deviceData) {
      return deviceData.profileInstanceID;
    }
    return '';
  }
  sharePermission() {
    const dialogRef = this.dialog.open(DashboardPermissionComponent, {
      height: 'auto',
      width: '500px',
      panelClass: 'permission-dialog',
      data: {
        id: this.profileInstanceID,
        dashboardId: this._dashEventService?._deviceDetail?.id,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'success') {
        this.cs.openToaster({
          type: 'success',
          message: 'dashboardPermission-success',
        });
      }
    });
  }

  share() {
    const dialog = this.dialog.open(ShareDashboardComponent, {
      height: 'auto',
      width: '508px',
      backdropClass: 'share-dashboard-model',
      disableClose: true,
      data: this._dashEventService._deviceDetail,
    });
    dialog.afterClosed().subscribe(async (emails) => {
      if (emails) {
        try {
          await this.meshService.shareDashboardInstance(
            this._dashEventService?._deviceDetail?.id,
            emails
          );
          this.cs.openToaster({
            type: 'success',
            message: 'inviteSend',
          });
        } catch (error) {
          this.cs.openToaster({
            type: 'error',
            message: 'inviteSend',
          });
        }
      }
    });
  }

  redirectToEventLog() {
    const keyForParam = ['deviceName', 'siteName'];
    const params = {};
    keyForParam.forEach((key) => {
      params[key] = this._dashEventService._deviceDetail[key];
    });
    this.router.navigate(['/main/eventlog'], {
      queryParams: params,
    });
  }

  redirectToNotification() {
    const params = this._dashEventService.notifSettingsInfo;
    this.router.navigate(['main', 'notification-settings'], {
      queryParams: params,
    });
  }

  redirectToSecureTunnel() {
    const deviceId = this._dashEventService._deviceDetail.deviceId;

    this.meshService
      .getTunnelUrl(deviceId)
      .then((res: string) => {
        if (res) {
          window.open(res);
        }
      })
      .catch((err) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: err.message,
        };
        this.cs.openToaster(data);
      });
  }
}
