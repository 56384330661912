import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usernameFormat',
})
export class UsernameFormatPipe implements PipeTransform {
  transform(value: string) {
    return value?.indexOf('@') > -1 ? value?.substring(0, value?.indexOf('@')) : value;
  }
}
