import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IHistoricalGraphState } from 'src/app/shared/interface/_base';

@Injectable({
  providedIn: 'root',
})
export class ChartUtilityService {
  constructor() {}

  setMarkers(showMarker) {
    if (showMarker) {
      return {
        size: 2,
        strokeColors: '#404040',
        showNullDataPoints: false,
        colors: '#404040',
        strokeWidth: 2,
        strokeOpacity: 1,
        strokeDashArray: 1,
        fillOpacity: 1,
        shape: 'circle',
        radius: 2,
        hover: {
          size: 4,
          sizeOffset: 3,
        },
      };
    } else {
      return {
        size: 0,
        strokeColors: '#404040',
        colors: '#404040',
        radius: 1,
        strokeDashArray: 0,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        hover: {
          size: 4,
          sizeOffset: 3,
        },
      };
    }
  }

  setTooltip(units) {
    return {
      shared: false,
      custom({ series, seriesIndex, dataPointIndex, w }) {
        let unit = '';
        if (Array.isArray(units)) {
          if (units.length > 0) {
            unit =
              units[seriesIndex] !== null && units[seriesIndex] !== undefined
                ? units[seriesIndex].includes('-')
                  ? units[seriesIndex].split('-')[0]
                  : units[seriesIndex]
                : '';
          }
        } else {
          unit = units;
        }
        const displayDate =
          '<div class="arrow_box">' +
          '<span>' +
          +series[seriesIndex][dataPointIndex] +
          ' ' +
          unit +
          '</span>' +
          '</div>';

        return displayDate;
      },
    };
  }

  getUpperLowerLimit() {
    const minDate = moment(moment().subtract(30, 'days')).startOf('day').valueOf();

    const maxDate = moment().valueOf();
    return [minDate, maxDate];
  }

  getFormatter(dateRange: IHistoricalGraphState, chartType, deviceType) {
    const daysDiff = Math.floor(
      (dateRange.maxDateTime - dateRange.minDateTime) / (1000 * 60 * 60 * 24)
    );
    let tickFormat;
    let tickInterval;
    switch (true) {
      case daysDiff > 2 && daysDiff <= 30:
        tickInterval = 'days';
        tickFormat = 'MMM D';
        break;
      case daysDiff > 1 && daysDiff <= 2:
        tickInterval = 'hours';
        tickFormat = 'MMM D';
        break;
      case daysDiff < 0.1:
        tickInterval = 'minutes';
        tickFormat = this.getformatBasedOntype(chartType, deviceType);
        break;
      default:
        tickInterval = 'days';
        tickFormat = 'MMM D';
        break;
    }

    return tickFormat;
  }

  getformatBasedOntype(chartType, device) {
    if (
      (!!chartType &&
        chartType === 'combination-chart' &&
        (device === 'TabletPortrait' ||
          device === 'TabletLandscape' ||
          device === 'MobileLandscape')) ||
      device === 'MobilePortrait' ||
      device === 'MobileLandscape'
    ) {
      return 'HH:mm:ss';
    } else {
      return 'MMM D,HH:mm:ss';
    }
  }

  getTickAmount(chartType, device) {
    if (
      !!chartType &&
      chartType === 'combination-chart' &&
      (device === 'TabletPortrait' || device === 'TabletLandscape' || device === 'MobileLandscape')
    ) {
      return 3;
    } else if (device === 'MobilePortrait') {
      return 3;
    } else {
      return 5;
    }
  }

  getEmptyData(minDate, maxDate) {
    const diff = (maxDate - minDate) / 5;
    const emptyData = [];
    while (minDate < maxDate) {
      const x = minDate + diff;
      const obj = {
        time: Math.round(x / 1000),
        value: 0,
      };
      emptyData.push(obj);
      minDate = x;
    }
    return emptyData;
  }

  getPath(widget): string {
    if (!!widget) {
      return widget.historyPath;
    }
  }

  setZoomEnabledDisable(device, type) {
    if (!!device && (device.includes('Tablet') || device.includes('Mobile'))) {
      return false;
    } else {
      return !!type && type === 'template' ? false : true;
    }
  }

  setNoData() {
    return {
      text: 'No data to display',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    };
  }

  chartWidthHeightSetting(device, isDialogView, chartType, dualAxis = false) {
    let height = 350;
    let width = '97%';
    if (
      !!device &&
      device.includes('Web') &&
      isDialogView === true &&
      window.innerHeight > 950 &&
      window.innerHeight < 1360
    ) {
      height = 625;
      width = '97%';
    } else if (
      !!device &&
      device.includes('Web') &&
      isDialogView === true &&
      window.innerHeight > 1361
    ) {
      height = 825;
      width = '97%';
    } else if (!!device && device.includes('Web')) {
      width = '98%';
      height = chartType === 'multiseries-chart' ? 390 : 350;
    } else if (
      !!device &&
      device.includes('Tablet') &&
      window.innerHeight > 1365 &&
      isDialogView === true
    ) {
      width = '98%';
      height = 450;
    } else if (
      !!device &&
      device.includes('Tablet') &&
      window.innerHeight <= 1365 &&
      isDialogView === true
    ) {
      width = '98%';
      height = 350;
    } else if (
      !!device &&
      device.includes('Tablet') &&
      window.innerWidth < 715 &&
      window.innerHeight < 1140 &&
      isDialogView === false
    ) {
      width = '95%';
      height = chartType === 'line-chart' || chartType === 'multiseries-chart' ? 275 : 325;
    } else if (
      !!device &&
      device.includes('Mobile') &&
      window.innerWidth <= 414 &&
      window.innerWidth >= 350 &&
      isDialogView === false
    ) {
      width =
        (chartType === 'line-chart' || chartType === 'multiseries-chart') && dualAxis === true
          ? '500px'
          : '98%';
      height = chartType === 'line-chart' || chartType === 'multiseries-chart' ? 315 : 225;
    } else if (
      !!device &&
      device.includes('Mobile') &&
      window.innerWidth < 350 &&
      window.innerWidth >= 300 &&
      isDialogView === false
    ) {
      width = chartType === 'line-chart' || chartType === 'multiseries-chart' ? '72%' : '96%';
      height = chartType === 'line-chart' || chartType === 'multiseries-chart' ? 225 : 155;
    } else if (
      !!device &&
      window.innerWidth > 400 &&
      device.includes('MobilePortrait') &&
      isDialogView === false
    ) {
      width = '98%';
      height = 300;
    } else if (
      !!device &&
      window.innerWidth > 400 &&
      device.includes('MobileLandscape') &&
      isDialogView === false
    ) {
      width = '98%';
      height = 325;
    } else if (!!device && device.includes('MobileLandscape') && isDialogView === true) {
      width = '98%';
      height = 280;
    } else if (
      !!device &&
      device.includes('Tablet') &&
      (chartType === 'line-chart' || chartType === 'multiseries-chart') &&
      isDialogView === false
    ) {
      width = '98%';
      height = 325;
    } else if (
      !!device &&
      device.includes('TabletLandscape') &&
      chartType === 'combination-chart' &&
      isDialogView === false
    ) {
      width = '98%';
      height = 325;
    } else if (
      !!device &&
      device.includes('TabletPortrait') &&
      chartType === 'combination-chart' &&
      isDialogView === false
    ) {
      width = '97%';
      height = 325;
    } else {
      width = '80%';
      height = 325;
    }
    return [width, height];
  }
}
