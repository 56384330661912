import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { ceData } from '../../../shared/constants/ce-data';
import { TemplateService } from 'src/app/service/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { addClass, removeClass } from 'src/app/shared/class/util';
import * as _ from 'lodash';
export class State {
  constructor(public name: string, public view: string) {}
}
@Component({
  selector: 'app-addedit-binary',
  templateUrl: './addedit-binary.component.html',
  styleUrls: ['./addedit-binary.component.scss'],
})
export class AddeditBinaryComponent implements OnInit {
  @Input() type;
  @Input() id;
  onceCalled = false;
  binaryValue: number;
  checkDesignError = false;
  validForm = false;
  textError = false;
  iconError = false;
  dataTabError = false;
  designTabError = false;
  selectedTab: any;
  dataset: any;
  editedData = [];
  submitted = false;
  filteredStatesActive: Observable<any[]>;
  filteredStatesInActive: Observable<any[]>;
  registrationForm = this.fb.group({
    data: this.fb.group({
      dataPoint: ['', [Validators.required]],
    }),

    active: this.fb.group({
      activeText: ['Active'],
      activeTextColor: ['#404040'],
      activeIcon: ['check'],
      activeIconColor: ['#009534'],
    }),
    inActive: this.fb.group({
      inactiveText: ['Inactive'],
      inactiveTextColor: ['#404040'],
      inactiveIcon: ['warning'],
      inactiveIconColor: ['#e60000'],
    }),
  });
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private ws: WidgetsService,
    private _templateService: TemplateService,
    public dialog: MatDialog
  ) {
    this.filteredStatesActive = this.registrationForm.controls.active
      .get('activeIcon')
      .valueChanges.pipe(
        startWith(''),
        map((state) => (state ? this.filterStates(state) : ceData.activeStates.slice()))
      );
    this.filteredStatesInActive = this.registrationForm.controls.inActive
      .get('inactiveIcon')
      .valueChanges.pipe(
        startWith(''),
        map((state) => (state ? this.filterStatesInactive(state) : ceData.inactiveStates.slice()))
      );
  }

  ngOnInit(): void {
    this.selectedTab = 0;

    if (this.ws.dataPoints === undefined) {
      const dP = JSON.parse(sessionStorage.getItem('dataPoints'));
      this.dataset = dP;
    } else {
      this.dataset = _.cloneDeep(this.ws.dataPoints);
    }
    if (this.type === 'edit') {
      const d = this._templateService.getEditData(this.id);

      if (!!d) {
        this.editedData = d[0];
        this.registrationForm.patchValue({
          data: {
            dataPoint: (this.editedData as any).title,
          },
          active: {
            activeText: (this.editedData as any).activeState.text,
            activeTextColor: (this.editedData as any).activeState.textColor,
            activeIcon: (this.editedData as any).activeState.icon,
            activeIconColor: (this.editedData as any).activeState.iconColor,
          },
          inActive: {
            inactiveText: (this.editedData as any).inactiveState.text,
            inactiveTextColor: (this.editedData as any).inactiveState.textColor,
            inactiveIcon: (this.editedData as any).inactiveState.icon,
            inactiveIconColor: (this.editedData as any).inactiveState.iconColor,
          },
        });
        // this.title = this.editedData["dataPath"];
      }
    }
    this.registrationForm.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        if (this.registrationForm.valid) {
          this.dataTabError = false;
          this.manageTabInkbarClass();
        }
        this.checkingError();
        if (
          ((this.registrationForm as any).controls.active.controls.activeText.value !== '' ||
            (this.registrationForm as any).controls.active.controls.activeIcon.value !== '') &&
          ((this.registrationForm as any).controls.inActive.controls.inactiveText.value !== '' ||
            (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value !== '')
        ) {
          this.designTabError = false;
          this.manageTabInkbarClass();
          this.checkDesignError = false;
          this.iconError = false;
          this.textError = false;
        }
      });
  }

  filterStates(name: string) {
    return ceData.activeStates.filter((state) =>
      state.name.toLowerCase().includes(name.toLowerCase())
    );
  }
  filterStatesInactive(name: string) {
    return ceData.inactiveStates.filter((state) =>
      state.name.toLowerCase().includes(name.toLowerCase())
    );
  }
  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }
  onTabClickedChanged(e) {
    this.selectedTab = e.index;
    this.manageTabInkbarClass();
  }
  onCancel() {
    this.registrationForm.reset();
    this.router.navigate(['/main/template/blanktemplate']);
  }
  getImageStatusActive(dat) {
    if (dat !== null) {
      const result = ceData.activeStates.filter((element) => dat.indexOf(element.name) !== -1);
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  getImageStatusInActive(dat) {
    if (dat !== null) {
      const result = ceData.inactiveStates.filter((element) => dat.indexOf(element.name) !== -1);
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  checkingError() {
    if (this.type === 'add') {
      if (
        this.registrationForm.controls.active.touched &&
        this.registrationForm.controls.inActive.touched
      ) {
        if (
          ((this.registrationForm as any).controls.active.controls.activeText.value === '' ||
            (this.registrationForm as any).controls.inActive.controls.inactiveText.value === '') &&
          ((this.registrationForm as any).controls.active.controls.activeIcon.value === '' ||
            (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value === '')
        ) {
          this.checkDesignError = true;
          this.designTabError = true;
          this.manageTabInkbarClass();
          this.iconError = true;
          this.textError = true;
          return true;
        } else {
          this.checkDesignError = false;
          return false;
        }
      }
    }

    if (this.type === 'edit') {
      if (
        ((this.registrationForm as any).controls.active.controls.activeText.value === '' ||
          (this.registrationForm as any).controls.inActive.controls.inactiveText.value === '') &&
        ((this.registrationForm as any).controls.active.controls.activeIcon.value === '' ||
          (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value === '')
      ) {
        this.checkDesignError = true;
        this.designTabError = true;
        this.manageTabInkbarClass();
        this.iconError = true;
        this.textError = true;
        return true;
      } else {
        this.checkDesignError = false;
        return false;
      }
    }
  }
  onSubmit() {
    if (this.selectedTab === 0 || this.selectedTab === 1) {
      if (!this.registrationForm.controls.data.valid) {
        this.dataTabError = true;
        this.manageTabInkbarClass();
        this.submitted = true;
        this.selectedTab = 0;
      } else {
        this.dataTabError = false;
        this.manageTabInkbarClass();
        this.submitted = false;
      }
      // this.selectedTab = 1;
      if (
        (this.registrationForm as any).controls.active.controls.activeText.value === '' &&
        (this.registrationForm as any).controls.active.controls.activeIcon.value === '' &&
        (this.registrationForm as any).controls.inActive.controls.inactiveText.value === '' &&
        (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value === ''
      ) {
        this.designTabError = true;
        this.manageTabInkbarClass();
        this.iconError = true;
        this.textError = true;
        this.selectedTab = 1;
      } else {
        this.designTabError = false;
        this.manageTabInkbarClass();
        this.iconError = false;
        this.textError = false;
      }
    }

    this.onceCalled = true;
    if (this.onceCalled) {
      if ((this.registrationForm as any).controls.active.controls.activeIcon.value !== '') {
        const result = ceData.activeStates.filter(
          (element) =>
            (this.registrationForm as any).controls.active.controls.activeIcon.value ===
            element.name
        );
        if (result.length === 0) {
          const txt = '';
          (this.registrationForm as any).controls.active.controls.activeIcon.setValue(txt);
        } else {
          (this.registrationForm as any).controls.active.controls.activeIcon.setValue(
            result[0].name
          );
        }
      }
      if ((this.registrationForm as any).controls.inActive.controls.inactiveIcon.value !== '') {
        const result = ceData.inactiveStates.filter(
          (element) =>
            (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value ===
            element.name
        );
        if (result.length === 0) {
          const txt = '';
          (this.registrationForm as any).controls.inActive.controls.inactiveIcon.setValue(txt);
        } else {
          (this.registrationForm as any).controls.inActive.controls.inactiveIcon.setValue(
            result[0].name
          );
        }
      }
      if (
        (this.registrationForm as any).controls.active.controls.activeText.value !== '' &&
        (this.registrationForm as any).controls.active.controls.activeIcon.value !== '' &&
        (this.registrationForm as any).controls.inActive.controls.inactiveText.value !== '' &&
        (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value !== ''
      ) {
        this.binaryValue = 1;
        this.validForm = true;
        this.formSubmit();
      } else if (
        ((this.registrationForm as any).controls.active.controls.activeText.value !== '' ||
          (this.registrationForm as any).controls.active.controls.activeIcon.value !== '') &&
        ((this.registrationForm as any).controls.inActive.controls.inactiveText.value !== '' ||
          (this.registrationForm as any).controls.inActive.controls.inactiveIcon.value !== '')
      ) {
        this.validForm = true;
        this.binaryValue = 1;
        this.formSubmit();
      } else {
        this.textError = true;
        this.iconError = true;
        this.submitted = true;
        this.designTabError = true;
        this.manageTabInkbarClass();
        this.validForm = false;
      }
    }
  }

  formSubmit() {
    if ((this.validForm && this, this.registrationForm.valid)) {
      const fm = this.registrationForm.value;
      const obj = this._templateService.tempData;
      if (this.type === 'add') {
        obj.push({
          widgetID: 'binary' + Math.floor(Math.random() * 1000000),
          currentPath: '/current/malachitevulture.ea.ayj9au/Boiler 1/',
          dataPath: fm.data.dataPoint,
          height: 1,
          activeState: {
            icon: fm.active.activeIcon,
            iconColor: fm.active.activeIconColor,
            text: fm.active.activeText,
            textColor: fm.active.activeTextColor,
          },
          inactiveState: {
            icon: fm.inActive.inactiveIcon,
            iconColor: fm.inActive.inactiveIconColor,
            text: fm.inActive.inactiveText,
            textColor: fm.inActive.inactiveTextColor,
          },
          historyPath: 'malachitevulture_ea-aYj9AU/Boiler 1/',
          pointStatusPath: '/_data/fieldveu/pointStatus/malachitevulture_ea-aYj9AU/Boiler 1/',
          title: fm.data.dataPoint,
          type: 'binary',
          width: 1,
          value: this.binaryValue,
        });

        this.onceCalled = false;
        this._templateService.tempData = obj;
      } else {
        const foundIndex = obj.findIndex((x) => x.widgetID === this.id);

        const newObj = {
          ...this.editedData,
          activeState: {
            icon: fm.active.activeIcon,
            iconColor: fm.active.activeIconColor,
            text: fm.active.activeText,
            textColor: fm.active.activeTextColor,
          },
          inactiveState: {
            icon: fm.inActive.inactiveIcon,
            iconColor: fm.inActive.inactiveIconColor,
            text: fm.inActive.inactiveText,
            textColor: fm.inActive.inactiveTextColor,
          },
          title: fm.data.dataPoint,
        };

        obj[foundIndex] = newObj;

        this._templateService.tempData = obj;
        sessionStorage.setItem('tempData', JSON.stringify(obj));
      }

      this.router.navigate(['/main/template/blanktemplate']);
    } else {
      this.submitted = true;
      return false;
    }
  }

  manageTabInkbarClass() {
    const selector = 'app-addedit-binary .mat-ink-bar';
    const className = 'error';
    if (this.selectedTab === 0) {
      if (this.dataTabError) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    } else {
      if (this.designTabError) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    }
  }
}
