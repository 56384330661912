<div class="container add-edit-text" fxLayout="row" fxFlex="100%">
  <div class="inp-container" fxFlex="38%" fxLayout="column">
    <form [formGroup]="registrationForm">
      <div fxLayout="column">
        <div class="left-margin data-top-margin">
          <!-- <mat-form-field class="dropdown-field data-top-margin"> -->
          <div class="label-margin">
            <mat-label class="label-clr">{{
              "common.data-point" | translate
            }}</mat-label>
          </div>
          <div>
            <mat-select
              class="dropdown-binary"
              [ngClass]="{
                'dropdown-binary-error':
                  !registrationForm.valid &&
                  submitted &&
                  registrationForm.controls['dataValue']?.errors?.required
              }"
              formControlName="dataValue"
              placeholder="{{ 'placeholder_messages.datapoint' | translate }}"
              name="food"
            >
              <mat-option *ngFor="let data of dataset" [value]="data.path">
                {{ data.path }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- </mat-form-field> -->
        <div
          class="invalid-feedback-drop left-margin"
          *ngIf="
            !registrationForm.valid &&
            submitted &&
            registrationForm.controls['dataValue']?.errors?.required
          "
        >
          <span>*</span>{{ "message.valid-data-point" | translate }}
        </div>
      </div>

      <div
        fxLayout="row"
        class="btn-placements pad-btn main-top-margin top-margin left-margin"
      >
        <button
          data-test="new-template"
          (click)="onCancel()"
          class="btn secondary-btn"
          mat-flat-button
          type="button"
        >
          {{ "action.cancel" | translate | uppercase }}
        </button>
        <button
          *ngIf="type == 'edit'"
          class="btn secondary-btn"
          mat-flat-button
          data-test="action_delete"
          (click)="delete()"
          type="button"
        >
          {{ "action.delete" | translate }}
        </button>

        <button
          data-test="new-template"
          type="submit"
          (click)="onSubmit()"
          class="btn primary-btn"
          mat-flat-button
        >
          {{ "action.save-widget" | translate | uppercase }}
        </button>
      </div>
    </form>
  </div>
  <div fxFlex="62%" fxLayoutAlign="center" class="view-container top-margin">
    <div class="numnerical-container">
      <div
        *ngIf="registrationForm.controls['dataValue'].value !== ''"
        class="preview-left-margin preview-notitle truncate"
        matTooltipClass="tooltip-hover"
        [matTooltip]="registrationForm.controls['dataValue'].value"
      >
        {{ registrationForm.controls["dataValue"].value }}
      </div>
      <div
        *ngIf="registrationForm.controls['dataValue'].value == ''"
        class="preview-left-margin preview-title truncate"
        matTooltipClass="tooltip-hover"
        [matTooltip]="registrationForm.controls['dataValue'].value"
      >
        {{ "common.widget-title" | translate }}
      </div>
      <div class="preview-left-margin preview-content-text">
        {{ defaultValue }}
      </div>
      <div class="preview-left-margin preview-footer">{{ defaultTime }}</div>
    </div>
  </div>
</div>
