import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/service/template/template.service';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-addedit-devicestatus',
  templateUrl: './addedit-devicestatus.component.html',
  styleUrls: ['./addedit-devicestatus.component.scss'],
})
export class AddeditDevicestatusComponent implements OnInit {
  @Input() id;
  @Input() type;
  statusCardData = {
    status: 'Normal',
    count: 3,
    time: '12:53:23',
    statusDetails: [
      { type: 'Pressure 1', status: 'Warning' },
      { type: 'Temperature In', status: 'Warning' },
      { type: 'Temperature Out', status: 'Warning' },
    ],
    img: 'Image',
  };
  card = {
    currentPath: '/_data/fieldveu/deviceStatus/malachitevulture_ea-aYj9AU/Boiler 1',
    dataPath: '',
    height: 1,
    pointStatusPath: '/_data/fieldveu/pointStatus/malachitevulture_ea-aYj9AU/Boiler 1/',
    title: 'State',
    type: 'device-status',
    width: 1,
    widgetID: 'device-status' + Math.floor(Math.random() * 1000000),
  };
  constructor(
    public router: Router,
    private _templateService: TemplateService,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {}

  addWidget() {
    if (this.type === 'add') {
      this.card.title = 'Device Status';
      this._templateService.tempData.push(this.card);
    }
    this.router.navigate(['/main/template/blanktemplate']);
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }

  cancel() {
    this.router.navigate(['/main/template/blanktemplate']);
  }
}
