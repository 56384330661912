export const waitFewMoments: Promise<void> = new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, 10);
});

export const addClass = (selector: string, className: string) => {
  if (document.querySelector(selector)) document.querySelector(selector).classList.add(className);
};

export const removeClass = (selector: string, className: string) => {
  if (document.querySelector(selector))
    document.querySelector(selector).classList.remove(className);
};
