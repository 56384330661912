import { Component, OnInit, ViewChild } from '@angular/core';
import { MeshServiceProvidor } from '../service/mesh/mesh.service';
import { LoginDetail } from '../shared/interface/common';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { UrlService } from '../shared/services/url/url.service';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { ToasterData } from '../shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('loginForm', { static: true }) ngForm: NgForm;
  loginForm: FormGroup;

  loginDissabled = false;
  errorMessage = '';
  loginModel = new LoginDetail();
  hidePassword = true;
  showEmailError = false;
  forgotPasswordStatus = false;
  resetPasswordConfirm = false;
  resetPass = false;
  passwordSent = false;
  fetchRes = false;
  previousUrl;
  currentUrl;
  warning = false;
  passStrength = '';
  formChangesSub: Subscription;
  formValueChangeSub: Subscription;
  urlChangeSub: Subscription;
  userSub: Subscription;
  subscriber: Subscription[] = [];
  deviceType;
  encryptUser;
  pattern = new RegExp('/^(?=.*[A-Za-z])(?=.*d)(?=.*[$@$!%*#?&])[A-Za-zd$@$!%*#?&]{8,}$/');
  resetStatus: boolean;

  constructor(
    private meshServiceProvidor: MeshServiceProvidor,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: UrlService,

    private _resolutionResolver: ResolutionResolverService,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {
      this.encryptUser = data.user_name;
    });
  }

  resetPassword() {
    this.resetPass = true;
  }
  goBackLogin() {
    this.router.navigate(['login']);
  }
  checkPassword(e, evt) {
    const val = evt.target.value;
    if (val === '') {
      this.passStrength = '';
    }
  }
  onStrengthChanged(e) {
    setTimeout(() => {
      if (e < 50) {
        this.passStrength = 'weak';
      } else if (e >= 50 && e <= 80) {
        this.passStrength = 'average';
      } else {
        this.passStrength = 'strong';
      }
    }, 10);
  }
  update = (data) => {};

  onSubmit(e): any {
    const vm = this;
    if (this.encryptUser !== undefined) {
      if (this.passStrength === 'strong' || this.passStrength === 'average') {
        this.fetchRes = true;
        this.meshServiceProvidor
          .resetPassword(this.encryptUser, e.form.value.password, this.update)
          .then((res) => {
            if (res) {
              this.resetPass = true;
              this.fetchRes = false;
            }
          })
          .catch((err) => {
            this.fetchRes = false;
            const data: ToasterData = {
              type: 'error-custom',
              message: err.toString(),
            };

            this.cs.openToaster(data);
          });
      }
    }
    setTimeout(() => {
      this.fetchRes = false;
    }, 500);
  }
}
