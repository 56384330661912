import { Renderer2 } from '@angular/core';
import { AfterViewInit, ElementRef } from '@angular/core';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appStatusStyle]',
})
export class StatusStyleDirective implements AfterViewInit {
  @Input() appStatusStyle: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (!this.appStatusStyle) {
      return;
    }
    if (this.appStatusStyle === '') {
      return;
    }
    const style = STYLE_CONFIG[this.appStatusStyle.toLowerCase()];
    this.el.nativeElement.style.borderRadius = '4px';
    this.el.nativeElement.style.padding = style.padding;
    this.el.nativeElement.style.textTransform = 'capitalize';
    this.el.nativeElement.style.border = 'solid 1px';
    this.el.nativeElement.style.position = 'sticky';
    this.el.nativeElement.style.display = 'inline-block';
    this.el.nativeElement.style.borderColor = style.borderColor;
    this.el.nativeElement.style.backgroundColor = style.backgroundColor;
    this.el.nativeElement.style.color = style.color;
    this.el.nativeElement.style['font-weight'] = '500';
    if (style.iconText === 'none') {
      return;
    }
    const createdEl = style.iconText
      ? this.createIconElement(style.iconText, style.className)
      : this.createIconWithImgElement(style.iconUrl, style.className);
    this.el.nativeElement.prepend(createdEl);
  }

  createIconElement(iconText: string, classname: string): ElementRef {
    const iconElement = this.renderer.createElement('i');
    this.renderer.addClass(iconElement, 'material-icons');
    this.renderer.addClass(iconElement, classname);
    iconElement.innerText = iconText;
    return iconElement;
  }

  createIconWithImgElement(iconURL: string, classname: string): ElementRef {
    const iconElement = this.renderer.createElement('img');
    this.renderer.setProperty(iconElement, 'src', iconURL);
    this.renderer.addClass(iconElement, 'custom-icons');
    this.renderer.addClass(iconElement, classname);
    return iconElement;
  }
}

const STYLE_CONFIG = {
  normal: {
    backgroundColor: 'rgba(0, 149, 52, 0.15)',
    borderColor: '#009534',
    color: '#009534',
    iconText: 'done',
    className: 'done',
    padding: '1px 11px 1px 24px',
  },
  alarm: {
    backgroundColor: 'rgba(228, 35, 38, 0.15)',
    borderColor: '#e42326',
    color: '#e42326',
    iconUrl: '/assets/images/alarm.svg',
    className: 'alarm',
    padding: '1px 20px 1px 24px',
  },
  offline: {
    backgroundColor: 'rgba(99, 99, 99, 0.15)',
    borderColor: '#636363',
    color: '#636363',
    iconText: 'close',
    className: 'close',
    padding: '1px 17px 1px 24px',
  },
  trouble: {
    backgroundColor: 'rgba(25, 97, 166, 0.15)',
    borderColor: '#1961a6',
    color: '#1961a6',
    iconText: 'warning',
    className: 'trouble',
    padding: '1px 11px 1px 24px',
  },
  warning: {
    backgroundColor: 'rgba(255, 213, 0, 0.15)',
    borderColor: '#ffd500',
    color: '#404040',
    iconText: 'warning',
    className: 'warning',
    padding: '1px 6px 1px 24px',
  },
  draft: {
    backgroundColor: 'rgba(25, 97, 166, 0.1)',
    borderColor: '#1961a6',
    color: '#1961a6',
    iconText: 'none',
    className: 'trouble',
    padding: '1px 5px 1px 5px',
  },
};
