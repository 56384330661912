<section class="dashboard-template-listing">
  <ng-template [ngTemplateOutlet]="loadUI"></ng-template>
</section>

<!-- ####################### TEMPLATE FOR LISTING ############################## -->
<ng-template #hasData>
  <app-ce-subheader></app-ce-subheader>
  <div class="filter-card" fxLayout="row" fxLayoutGap="20px">
    <div class="label">{{ "common.filter_by" | translate }}</div>
    <div class="filter-by-publish">
      <mat-checkbox
        disableRipple="true"
        class="filter-checkbox"
        [checked]="cardFilterStatus.publish"
        (change)="cardFilterStatus.publish = !cardFilterStatus.publish"
      >
        {{ "common.published_templates" | translate }}
      </mat-checkbox>
    </div>
    <div class="filter-by-publish">
      <mat-checkbox
        disableRipple="true"
        class="filter-checkbox"
        [checked]="cardFilterStatus.draft"
        (change)="cardFilterStatus.draft = !cardFilterStatus.draft"
      >
      {{ "common.draft_templates" | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div class="grid-list" fxLayout="row wrap" fxLayoutGap="28px">
    <ng-container
      *ngFor="
        let dasboardInfo of listDashboardTemplate?.templates
          | commonFilter: 'published':cardFilterStatus.status;
        let i = index
      "
    >
      <app-template-card
        [fxFlex]="fxFlexWidth"
        [imagePath]="dasboardInfo?.dashboardConfig?.deviceImageUrl"
        [templateInfo]="dasboardInfo"
        [buttonConfig]="btnConfig"
        [showBtnOnHover]="true"
        (actionClickBtn)="cardEventManager($event)"
      >
      </app-template-card>
    </ng-container>
  </div>
</ng-template>

<!-- ####################### TEMPLATE FOR LOADING ############################## -->
<ng-template #loading>
  <app-ce-subheader></app-ce-subheader>
  <mat-progress-bar
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
  <div class="loading">
    {{ "message.loading" | translate }}
  </div>
</ng-template>

<!-- ####################### TEMPLATE FOR EMPTY LISTING ############################## -->
<ng-template #noData>
  <app-ce-subheader></app-ce-subheader>
  <div class="empty">
    <img class="info-graphic" src="../../../assets/images/emptyTemplates.svg" />
    <p class="info-message">
      {{ "message.no-dashboard-template" | translate }}
    </p>
  </div>
</ng-template>

<!-- ####################### TEMPLATE FOR MOBILE ALERT ############################## -->
<ng-template #mobileAlert>
  <app-header></app-header>
  <div class="not-supported">
    <img
      [ngClass]="{
        'info-graphic-mobileLandscape': deviceType === 'MobileLandscape',
        'info-graphic': deviceType !== 'MobileLandscape'
      }"
      src="../../../assets/images/emptyTemplates.svg"
    />
    <div
      [ngClass]="{
        'info-message-mobileLandscape': deviceType === 'MobileLandscape',
        'info-message': deviceType !== 'MobileLandscape'
      }"
    >
      {{ "message.not-support-on-mobile" | translate }}
    </div>
  </div>
</ng-template>
