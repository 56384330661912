import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadApiService {
  constructor(private http: HttpClient, public authService: AuthService) {}

  uploadImage(file): Observable<any> {
    const cookie = this.authService.getCookie('FieldPoPDeviceCloud');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
        'Content-Size': file.size,
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookie}`,
      }),
    };
    return this.http.post(
      (window as any).smcUrl + 'happner-files/files/fieldveu-images/' + file.name,
      file,
      httpOptions
    );
  }
}
