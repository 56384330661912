import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
@Component({
  selector: 'app-device-power',
  templateUrl: './device-power.component.html',
  styleUrls: ['./device-power.component.scss'],
})
export class DevicePowerComponent implements OnInit, OnDestroy {
  @Input() powerData;
  @Input() widget;
  @Input() type;
  public timeValue: any = moment().format('HH:mm:ss');
  subscriptionHandler = [];
  value: any;

  stateValue: any;
  currentStatus = '';
  constructor(private service: MeshServiceProvidor, private router: Router) {}

  ngOnInit(): void {
    if (this.type !== 'template') {
      this.getData();
      this.symbolData();
    } else {
      if (
        this.widget.value === 1 ||
        this.widget?.activeState?.text !== '' ||
        this.widget?.activeState?.text !== undefined ||
        this.widget?.activeState?.icon !== ''
      ) {
        this.stateValue = this.widget.activeState;
      } else {
        this.stateValue = this.widget.inactiveState;
      }
    }
  }

  update = (data) => {
    if (data.value !== null) {
      this.value = data.value;
      if (this.value === 0) {
        this.stateValue = this.widget.inactiveState;
      } else {
        this.stateValue = this.widget.activeState;
      }
    }
    if (moment(data.timestamp).format('YYYY MM DD') === moment().format('YYYY MM DD')) {
      this.timeValue = moment(data.timestamp).format('HH:mm:ss');
    } else {
      this.timeValue = moment(data.timestamp).format('D MMM HH:mm:ss');
    }
  };
  edit(type, data) {
    this.router.navigate(['/', 'main', 'template', 'editwidget'], {
      queryParams: {
        type,
        id: data.widgetID,
      },
    });
  }
  getData() {
    this.stateValue = this.widget.activeState;
    const sub = this.service
      .getGaugeData(this.widget.currentPath, this.update)
      .then((res) => {
        if (res) {
          this.subscriptionHandler.push(res.gaugeDataInstance);
          if (res.initialValue !== null) {
            this.update(res.initialValue);
          }
        }
      })
      .catch((error) => {
        this.currentStatus = 'offline';
        this.subscriptionHandler = [undefined];
      });
  }
  border = (data) => {
    if (data.value !== null) {
      this.currentStatus = data.status;
    }
  };

  symbolData() {
    const subst = this.service
      .getGaugeData(this.widget.pointStatusPath, this.border, 'status')
      .then((res) => {
        if (res) {
          this.subscriptionHandler.push(res.gaugeDataInstance);
          if (res.initialValue !== null) {
            this.border(res.initialValue);
          }
        }
      })
      .catch((error) => {
        this.currentStatus = 'offline';
        this.subscriptionHandler = [undefined];
      });
  }

  destroy(e) {
    this.service.destroyGaugeData(e);
  }

  ngOnDestroy() {
    this.subscriptionHandler.forEach((value) => {
      if (value) {
        this.destroy(value);
      }
    });
  }
}
