/*eslint radix: ["error", "as-needed"]*/
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import * as _ from 'lodash';
import { IHistoricalGraphState } from 'src/app/shared/interface/_base';
import { IcolorObject, Ilabels } from 'src/app/shared/interface/device-dashboard.interface';
import { FETCH_GRAPH_POINT } from '../../../shared/constants/chart-fetchpoints';
import {
  ResolutionResolverService,
  TBreakpoint,
} from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ChartComponent } from 'ng-apexcharts';
import { DashEventService } from '../services/dashboard-event/dash-event.service';
import { ChartUtilityService } from '../services/chart-utlity/chart-utility.service';

export interface ISelectedDate {
  [x: string]: {
    minDate: Date;
    maxDate: Date;
  };
}
@Component({
  selector: 'app-multiseries-chart',
  templateUrl: './multiseries-chart.component.html',
  styleUrls: ['./multiseries-chart.component.scss'],
})
export class MultiseriesChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true })
  chart: ChartComponent = new ChartComponent();
  @Input() widget;
  @Input() deviceId;
  @Input() chartType;
  @Input() device;
  @Input() isDialogView;
  @Input() type;
  @Input() id: string;
  @Output() statusEvent = new EventEmitter<IWidgetEvent>();
  series: any[];
  chartt: any;
  xaxis: any;
  emptyData = true;
  colorObj: IcolorObject[];
  tickFormat: string;
  dataLabels: Ilabels;
  showMarker: boolean;
  upperLimit: number;
  lowerLimit: number;
  units: string;
  stroke: any;
  annotations: any;
  dateRange: IHistoricalGraphState;
  deviceType: TBreakpoint;
  yaxis: any;
  noData: {
    text: string;
    align: string;
    verticalAlign: string;
    offsetX: number;
    offsetY: number;
    style: { color: any; fontSize: string; fontFamily: any };
  };
  tooltip: {
    shared: boolean;
    custom: ({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      series: any;
      seriesIndex: any;
      dataPointIndex: any;
      w: any;
    }) => string;
  };
  markers: any;
  colors: any;
  legend: any;
  chartContext: any;
  private subscription: Subscription[] = [];
  constructor(
    private _meshServiceProvidor: MeshServiceProvidor,
    private _dashEventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService,
    private ws: WidgetsService,
    private cs: CommonService,
    private chartUtilityService: ChartUtilityService
  ) {
    const sub = this._resolutionResolver._deviceType.subscribe(
      (value) => (this.deviceType = value)
    );

    const sub1 = this._meshServiceProvidor.cast.subscribe((res) => {
      window.dispatchEvent(new Event('resize'));
    });
    this.subscription.push(sub);
  }

  ngOnInit(): void {
    this._dashEventService.initSeriesSelectedInfo(this.widget.dataPath, this.widget.widgetID);

    this._dashEventService.seriesSelectedInfo$.subscribe((p) => {
      if (this.chartContext) {
        const data = this.setAnnotations(this.chartContext.w.globals.minY);
        this.setupAnnoAndSelectedSeries(data, this.chartContext);
      }
    });
    if (!!this.type && this.type === 'template') {
      this.units = !!this.widget ? this.widget.units : [];
      this.setSolidOrGradient();
      const [min, max] = this.chartUtilityService.getUpperLowerLimit();
      this.lowerLimit = min;
      this.upperLimit = max;
      this.getDummyData();
    } else {
      const sub = this._dashEventService.get_historicalGraphState(this.id).subscribe((data) => {
        const eventObj: IWidgetEvent = {
          name: 'graph',
          status: 'loading',
          id: this.id,
        };
        if (this.chartt) {
          this.statusEvent.emit(eventObj);
        }

        this.dateRange = data;
        this.getHistChartData(data);
      });
      const markerSub = this._dashEventService.getMarker(this.id).subscribe((event) => {
        this.showMarker = event;
        this.markers = this.chartUtilityService.setMarkers(this.showMarker);
      });

      this.units = !!this.widget ? this.widget.units : '';
      this.setSolidOrGradient();
      const [min, max] = this.chartUtilityService.getUpperLowerLimit();
      this.lowerLimit = min;
      this.upperLimit = max;
      this.subscription.push(sub);
    }
  }

  setAnnotations(minY): any {
    if (!!this.colorObj) {
      const newArr = [];
      this.colorObj.map((p, i) => {
        const obj = {
          y: 0,
          borderColor: '',
          strokeDashArray: 0,
        };
        if (p.y > minY) {
          obj.y = p.y;
          obj.borderColor = p.color;
          obj.strokeDashArray = p.strokeDashArray;

          newArr.push(obj);
        }
      });
      return newArr;
    }
  }

  initChartData(data): void {
    const finalData = [];
    data.map((p) => {
      const formattedData = [];
      if (p.length > 0) {
        p.map((x) => {
          if (!!x && x != null) {
            formattedData.push([Math.round(x.time), parseFloat(x.value.toFixed(2))]);
          }
        });
      }
      finalData.push(formattedData);
    });
    this.initChartProperties(finalData);
  }

  initChartProperties(dates) {
    this.setYaxis();
    this.series = [];
    if (this.emptyData) {
      this.series = [...[]];
    } else {
      const series = [];
      this.widget.dataPath.map((seriesName, i) => {
        const seriesObj = {
          name: seriesName,
          data: dates[i],
        };
        series.push(seriesObj);
      });

      this.series = [...series];
    }

    const that = this;

    this.annotations = {
      position: 'back',
    };
    this.chartt = {
      type: 'line',
      height: 350,
      width: '97%',
      animations: {
        enabled: false,
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !!this.type && this.type === 'template' ? false : true,
        type: 'x',
      },

      events: {
        mounted(chartContext, config) {
          const data = that.setAnnotations(chartContext.w.globals.minY);
          that.chartContext = chartContext;
          that.setupAnnoAndSelectedSeries(data, chartContext);
        },
        beforeZoom(chartContext, { xaxis }) {
          const dateRange: IHistoricalGraphState = {
            minDateTime: xaxis.min * 1000,
            maxDateTime: xaxis.max * 1000,
            type: 'custom',
          };

          if (
            dateRange.minDateTime !== dateRange.maxDateTime &&
            moment(dateRange.maxDateTime).diff(moment(dateRange.minDateTime), 'seconds') > 10 &&
            dateRange.minDateTime >= that.lowerLimit &&
            dateRange.maxDateTime <= that.upperLimit
          ) {
            that._dashEventService.setAll_historicalGraphState(dateRange);

            const eventObj: IWidgetEvent = {
              name: 'graph',
              status: 'loading',
              id: that.id,
            };
            if (that.chartt) {
              that.statusEvent.emit(eventObj);
            }
            return {
              xaxis: {},
            };
          } else {
            return {
              xaxis: {
                min: that.dateRange.minDateTime / 1000,
                max: that.dateRange.maxDateTime / 1000,
              },
            };
          }
        },
        legendClick(chartContext, seriesIndex, config) {
          that._dashEventService.setSeries(seriesIndex, that.widget.widgetID);
          const info = that._dashEventService.getSeries(that.widget.widgetID);
          const seriesDisplayed = info.filter((p) => p.show === true);
          if (seriesDisplayed.length !== 0) {
            if (that.type === 'template') {
              const { minDate, maxDate } = that.ws.getMinMaxDate('Month');
              that.setXaxis(minDate, maxDate);
            } else {
              that.setXaxis(
                that.dateRange ? that.dateRange.minDateTime : 0,
                that.dateRange ? that.dateRange.maxDateTime : 0
              );
            }
          } else {
            // that.setXaxis(0, 0, false);  //need to check with Michelle if this option is needed
          }
        },
      },
    };

    //Set chart width and height
    const [width, height] = this.chartUtilityService.chartWidthHeightSetting(
      this.device,
      this.isDialogView,
      this.chartType,
      this.widget.dualYAxis
    );
    this.chartt.height = height;
    this.chartt.width = width;

    //set chart zoom
    this.chartt.zoom.enabled = this.chartUtilityService.setZoomEnabledDisable(
      this.deviceType,
      this.type
    );

    //set chart markers

    this.markers = this.chartUtilityService.setMarkers(this.showMarker);

    //set noData and tooltip
    if (this.emptyData) {
      this.series = [...[]];
    } else {
      this.tooltip = this.chartUtilityService.setTooltip(this.units);
    }

    this.noData = this.chartUtilityService.setNoData();

    //set stroke colors

    this.colors = this.widget.colors;
    this.legend = this.setLegend();
  }

  setYaxis() {
    if (this.widget && this.widget.dualYAxis === false) {
      this.yaxis = [
        {
          title: {
            show: false,
          },
          floating: false,
          decimalsInFloat: 0,
          tickAmount: 5,
          labels: {
            style: {
              colors: '#444444',
              fontSize: '12px',
              fontFamily: 'Roboto, Regular, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            formatter(val, index) {
              const y = !!val ? val.toString().split('.') : '0';
              const z = y && y.length > 1 ? Math.round(val * 100) / 100 : parseInt(y[0]);
              return z;
            },
          },
          min:
            !this.widget.autoscale && this.widget.min.length > 0 ? this.widget.min[0] : undefined,
          max:
            !this.widget.autoscale && this.widget.max.length > 0 ? this.widget.max[0] : undefined,
        },
      ];
      let min;
      let max;

      if (
        !!this.widget.min &&
        this.widget.min.length > 0 &&
        !!this.widget.max &&
        this.widget.max.length > 0
      ) {
        min = this.widget.min[0];
        max = this.widget.max[0];
        this.yaxis[0] = { ...this.yaxis[0], min, max };
      }
    } else if (this.widget && this.widget.dualYAxis === true) {
      this.yaxis = [];
      this.widget.dataPath.forEach((element, i) => {
        let textDisplay;
        if (
          this.widget.units.length !== 0 &&
          this.widget.units[i] !== null &&
          this.widget.units[i] !== undefined
        ) {
          const units = this.widget.units[i].includes('-')
            ? this.widget.units[i].split('-')[0]
            : this.widget.units[i];
          textDisplay = element + '(' + units + ')';
        } else {
          textDisplay = element;
        }
        // if (this.widget.units?.[i] && this.widget.units[i] !== null) {
        //   this.widget.units[i];
        // }
        const yaxisObj = {
          title: {
            show: this.device === 'MobilePortrait' ? false : true,
            text: textDisplay,
          },
          tickAmount: 5,
          opposite: i === 1 ? true : false,
          floating: false,
          decimalsInFloat: 0,
          labels: {
            style: {
              colors: '#444444',
              fontSize: '12px',
              fontFamily: 'Roboto, Regular, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            formatter(val, index) {
              const y = !!val ? val.toString().split('.') : '0';
              const z = y && y.length > 1 ? Math.round(val * 100) / 100 : parseInt(y[0]);
              return z;
            },
          },
          min: this.widget.min.length > 0 ? this.widget.min[i] : undefined,
          max: this.widget.max.length > 0 ? this.widget.max[i] : undefined,
        };

        if (this.device === 'MobilePortrait') delete yaxisObj.title;

        this.yaxis.push(yaxisObj);
      });
    }
  }

  setXaxis(min, max, label = true): void {
    const tickAmount = this.chartUtilityService.getTickAmount(this.chartType, this.deviceType);
    const that = this;
    this.xaxis = {
      type: 'datetime',
      offsetX: this.widget.dualYAxis === true ? 50 : 30,
      tickAmount,

      min: min / 1000,
      max: max / 1000,
      labels: {
        show: label,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
        maxHeight: 120,
        datetimeUTC: true,
        style: {
          colors: '#444444',
          fontSize: '16px',
          fontFamily: 'Roboto, Regular, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },

        formatter(value, timestamp, opts) {
          if (opts.i === tickAmount) {
            return '-';
          } else {
            return moment(value * 1000).format(that.tickFormat);
          }
        },
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
        formatter(value, timestamp, opts) {
          return moment(value * 1000).format('MMM D,HH:mm:ss');
        },
      },
    };
  }

  async getHistChartData(dateRange: IHistoricalGraphState) {
    const path = this.chartUtilityService.getPath(this.widget);
    const count = FETCH_GRAPH_POINT.getPoint(this.deviceType);

    const promises = this.widget.historyPath.map(async (datapoint) => {
      try {
        const histData = await this._meshServiceProvidor.getHistoricalData(
          this.deviceId,
          datapoint,
          dateRange,
          count
        );
        return histData;
      } catch (e) {
        const data: ToasterData = {
          type: 'error-custom',
          message: e.toString(),
        };

        this.cs.openToaster(data);
      }
    });

    const allChartsData = await Promise.all(promises);
    if (allChartsData.length > 0) {
      this.emptyData = false;
      this.initChartData(allChartsData);
    } else {
      const tempData = this.chartUtilityService.getEmptyData(
        dateRange.minDateTime,
        dateRange.maxDateTime
      );
      this.emptyData = true;
      this.initChartData(tempData);
    }

    this.tickFormat = this.chartUtilityService.getFormatter(
      dateRange,
      this.chartType,
      this.deviceType
    );

    this.setXaxis(dateRange.minDateTime, dateRange.maxDateTime);

    const eventObj: IWidgetEvent = {
      name: 'graph',
      status: 'completed',
      id: this.id,
    };

    if (this.chartt) {
      this.statusEvent.emit(eventObj);
    }
  }

  setSolidOrGradient(): void {
    this.stroke = {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      width: 2,
      dashArray: 0,
    };
    this.colorObj = !!this.widget ? this.widget.annotationData : [];
  }

  getDummyData() {
    const histdata = [];
    const { minDate, maxDate } = this.ws.getMinMaxDate('Month');
    this.widget.dataPath.map((dataPoint) => {
      const data = this.ws.getDataforChart(minDate, maxDate, this.type);
      histdata.push(data);
    });
    this.emptyData = false;
    this.tickFormat = 'MMM D';
    this.setXaxis(minDate, maxDate);

    const eventObj: IWidgetEvent = {
      name: 'graph',
      status: 'completed',
      id: this.id,
    };
    if (this.chartt) {
      this.statusEvent.emit(eventObj);
    }

    this.initChartProperties(histdata);
  }

  setLegend() {
    const that = this;
    const classname = this.device === 'MobilePortrait' ? 'legendmobile' : 'legend';
    const classname_primary =
      this.device === 'MobilePortrait' ? 'pimarylegendmobile' : 'pimarylegend';
    const obj = {
      show: true,
      itemMargin: {
        horizontal: 15,
        vertical: 10,
      },
      horizontalAlign: 'left',
      formatter(seriesName, opts) {
        const classLegendMobile = seriesName.length > 25 ? 'longerLegendName' : null;
        let legend;

        if (seriesName === that.widget.primaryDataPoint) {
          legend =
            '<div class=' +
            classname_primary +
            '>' +
            '<span class=' +
            classLegendMobile +
            '>' +
            seriesName +
            '<span class="legend-astix">*</span>' +
            '</span>' +
            '</div>';
        } else {
          legend =
            '<div class=' +
            classname +
            '>' +
            '<span class=' +
            classLegendMobile +
            '>' +
            seriesName +
            '</span>' +
            '</div>';
        }
        return legend;
      },
      showForSingleSeries: this.widget && this.widget.dataPath.length === 1 ? false : true,
      showForNullSeries: true,
      showForZeroSeries: true,
    };

    if (this.device === 'MobilePortrait') delete obj.itemMargin;

    return obj;
  }

  setupAnnoAndSelectedSeries(data, chartContext) {
    const info = this._dashEventService.getSeries(this.widget.widgetID);
    if (!!info && info.length > 0) {
      info.map((p) => {
        if (
          !!chartContext &&
          chartContext !== undefined &&
          chartContext != null &&
          !!chartContext.series &&
          chartContext.series !== null
        ) {
          if (p.show === true) {
            if (p.seriesName === this.widget.primaryDataPoint) {
              if (
                data &&
                data.length > 0 &&
                !!chartContext &&
                chartContext.annotations !== undefined &&
                chartContext.annotations !== null
              ) {
                data.map((q) => {
                  chartContext.addYaxisAnnotation(
                    {
                      y: q.y,
                      borderColor: q.borderColor,
                      strokeDashArray: 0,
                    },
                    true,
                    chartContext
                  );
                });
              }
            }
            const x = chartContext.showSeries(p.seriesName);
          } else {
            if (p.seriesName === 'series2') {
              chartContext.clearAnnotations();
            }
            const y = chartContext.hideSeries(p.seriesName);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.chart || this.chartContext) {
      this.chartContext.destroy();
    }
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}

export interface IWidgetEvent {
  name: string;
  status: 'loading' | 'completed';
  id: string;
}
