<div
  class="logout-container"
  fxLayout="column"
  [ngClass]="{
    'mobile-device':
      deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape',
    'mobile-landscape': deviceType == 'MobileLandscape'
  }"
>
  <div fxLayout="column">
    <mat-card class="logout-card">
      <div class="header-content">
        <!-- <div class="logo"><p>Logo</p></div> -->
        <div class="logo-img">
          <img src="../../assets/images/atoms-brands-grid-green-and-grey.svg" />
        </div>
        <div><p class="field-title">- &nbsp;FieldVEU</p></div>
      </div>
      <mat-card-content>
        <mat-card-title>{{ "common.loggedout" | translate }}</mat-card-title>
        <div>
          <p data-test="text_logout_message" class="message">
            <img src="../../../assets/images/success.svg" alt="sucess" />
            {{ "message.logout" | translate }}
          </p>
          <!-- <p class="message"><img src="../../../assets/images/ic-warning.svg" alt="warning">
                        {{"message.session-expired" | translate}}</p> -->
          <button
            class="btn btn-primary login-btn"
            id="relogin-btn"
            (click)="login()"
            mat-flat-button
            data-test="action_login_again"
          >
            {{ "common.login-as" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="image-container">
    <img
      src="../../../assets/images/atoms-brands-msa-field-server-green-and-black-on-white.svg"
      alt="brand img"
    />
  </div>
</div>
