<div
  *ngIf="
    deviceType == 'WebPortrait' ||
    deviceType == 'WebLandscape' ||
    deviceType == 'TabletLandscape' ||
    deviceType == 'TabletPortrait'
  "
>
  <mat-toolbar class="subheader-toolbar">
    <div
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div class="device-content" fxLayoutAlign="center center" fxLayout="row">
        <div>
          <span
            *ngIf="deviceType == 'WebPortrait' || deviceType == 'WebLandscape'"
          >
            <img
              [routerLink]="['/', 'main', 'device']"
              class="subhead-func-arrow-img"
              src="../../../../assets/images/back.svg"
              data-test="action_back"
              [matTooltip]="'action.back' | translate"
              matTooltipClass="customtooltip"
          /></span>
          <span
            *ngIf="
              deviceType == 'TabletPortrait' || deviceType == 'TabletLandscape'
            "
          >
            <img
              [routerLink]="['/', 'main', 'device']"
              class="subhead-func-arrow-img-tablet"
              src="../../../../assets/images/back.svg"
              data-test="action_back"
          /></span>
        </div>
        <div class="subhead-details-top-align">
          <span class="subhead-details-text" data-test="text_devicename">{{
            deviceName
          }}</span>
        </div>
      </div>
      <div class="device-util subhead-top-margin" fxLayout="row">
        <!-- WEB  -->
        <div
          class="subhead-func"
          fxLayout="row"
          *ngIf="
            (userRole != userRoleConfig?.SE &&
              userRole != userRoleConfig?.CE &&
              deviceType == 'WebPortrait') ||
            (deviceType == 'WebLandscape' &&
              userRole != userRoleConfig?.SE &&
              userRole != userRoleConfig?.CE)
          "
          (click)="share()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/ic-share.svg"
                data-test="action_share"
                [matTooltip]="'action.share' | translate"
                matTooltipClass="customtooltip"
            /></span>
          </div>
          <div class="subhead-align-img">
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
            >
              {{ 'action.share' | translate | uppercase }}
            </span>
          </div>
        </div>

        <!--TABLET-->
        <div
          class="subhead-func-tablet"
          fxLayout="row"
          *ngIf="
            (userRole != userRoleConfig?.SE &&
              userRole != userRoleConfig?.CE &&
              deviceType == 'TabletPortrait') ||
            (deviceType == 'TabletLandscape' &&
              userRole != userRoleConfig?.SE &&
              userRole != userRoleConfig?.CE)
          "
          (click)="share()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../../assets/images/ic-share.svg"
                data-test="action_share"
            /></span>
          </div>
          <div class="subhead-align-img">
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
              data-test="text_share"
            >
              {{ 'action.share' | translate | uppercase }}
            </span>
          </div>
        </div>

        <div
          class="subhead-func"
          fxLayout="row"
          [ngClass]="{
            'subhead-func':
              deviceType == 'WebPortrait' || deviceType == 'WebLandscape',
            'subhead-func-tablet':
              deviceType == 'TabletPortrait' || deviceType == 'TabletLandscape'
          }"
          *ngIf="
            userRole == userRoleConfig?.SE || userRole == userRoleConfig?.CE
          "
          (click)="sharePermission()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/ic-people-black.svg"
                data-test="action_permission"
                [matTooltip]="'action.permissions' | translate"
                matTooltipClass="customtooltip"
            /></span>
          </div>
          <div>
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
              data-test="text_permission"
            >
              {{ 'action.permissions' | translate | uppercase }}
            </span>
          </div>
        </div>

        <!--WEB-->
        <div
          class="subhead-func"
          fxLayout="row"
          *ngIf="deviceType == 'WebPortrait' || deviceType == 'WebLandscape'"
          (click)="redirectToEventLog()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/ic-history-black.svg"
                data-test="action_eventlog"
                [matTooltip]="'common.event-log' | translate"
                matTooltipClass="customtooltip"
            /></span>
          </div>
          <div>
            <span class="subhead-func-text" data-test="text_eventlog">
              {{ 'common.event-log' | translate | uppercase }}
            </span>
          </div>
        </div>

        <!--Tablet-->
        <div
          *ngIf="
            deviceType == 'TabletPortrait' || deviceType == 'TabletLandscape'
          "
          class="subhead-func-tablet"
          fxLayout="row"
          (click)="redirectToEventLog()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/ic-history-black.svg"
            /></span>
          </div>
          <div>
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
            >
              {{ 'common.event-log' | translate | uppercase }}
            </span>
          </div>
        </div>

        <!--WEB-->
        <div
          (click)="redirectToNotification()"
          class="subhead-func"
          fxLayout="row"
          *ngIf="deviceType == 'WebPortrait' || deviceType == 'WebLandscape'"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/ic-notifications.svg"
                data-test="action_setting"
                [matTooltip]="'action.settings' | translate"
                matTooltipClass="customtooltip"
            /></span>
          </div>
          <div>
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
              data-test="text_setting"
            >
              {{ 'action.settings' | translate | uppercase }}
            </span>
          </div>
        </div>

        <!--TABLET-->
        <div
          (click)="redirectToNotification()"
          class="subhead-func-tablet"
          fxLayout="row"
          *ngIf="
            deviceType == 'TabletPortrait' || deviceType == 'TabletLandscape'
          "
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../../assets/images/ic-notifications.svg"
            /></span>
          </div>
          <div>
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
            >
              {{ 'action.settings' | translate | uppercase }}
            </span>
          </div>
        </div>

        <div
          class="subhead-func-tunnel"
          fxLayout="row"
          *ngIf="
            userRole == userRoleConfig?.SE || userRole == userRoleConfig?.CE
          "
          (click)="redirectToSecureTunnel()"
        >
          <div>
            <span
              ><img
                class="subhead-func-img"
                src="../../../assets/images/diagnose.svg"
                data-test="img_securetunnel"
                [matTooltip]="'action.secure-tunnel' | translate"
                matTooltipClass="customtooltip"
            /></span>
          </div>
          <div>
            <span
              class="subhead-func-text"
              *ngIf="
                deviceType == 'WebPortrait' || deviceType == 'WebLandscape'
              "
              data-test="text_securetunnel"
            >
              {{ 'action.secure-tunnel' | translate | uppercase }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div *ngIf="deviceType == 'MobileLandscape' || deviceType == 'MobilePortrait'">
  <mat-toolbar class="menu-toolbar-mobile">
    <div
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div
        class="device-content icon-align-mobile"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <div>
          <span
            ><img
              [routerLink]="['/', 'main', 'device']"
              class="subhead-func-arrow-img-mobile"
              src="../../../../assets/images/ic-chevron-left-white.svg"
          /></span>
        </div>
        <div class="subhead-details-top-align-mobile">
          <div
            class="subhead-details-text-mobile truncate-device-name"
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
            [matTooltip]="deviceName"
            matTooltipClass="tooltip-hover"
          >
            {{ deviceName }}
          </div>
        </div>
      </div>
      <div class="device-util subhead-top-margin" fxLayout="row">
        <div class="icon-align-mobile">
          <span
            ><img
              (click)="redirectToNotification()"
              class="subhead-func-img-mobile"
              src="../../../../assets/images/notifications-white.svg"
          /></span>
        </div>
        <!-- <div
          class="icon-align-mobile"
          *ngIf="userRole != USERROLE?.SE && userRole != USERROLE?.CE"
        >
          <span
            ><img
              class="subhead-func-img-mobile"
              src="../../../../assets/images/ic-share-white.svg"
          /></span>
        </div> -->
        <!-- <div
          class="icon-align-mobile"
          *ngIf="userRole == USERROLE?.SE || userRole == USERROLE?.CE"
        >
          <span
            ><img
              class="subhead-func-img-mobile"
              src="../../../../assets/images/ic-people.svg"
          /></span>
        </div> -->
        <div class="icon-align-mobile">
          <span (click)="redirectToEventLog()"
            ><img
              class="subhead-func-img-mobile"
              src="../../../assets/images/ic-history.svg"
          /></span>
        </div>
        <!-- <div
          class="icon-align-mobile"
          *ngIf="userRole != USERROLE?.SE && userRole != USERROLE?.CE"
        >
          <span
            ><img
              class="subhead-func-img-mobile"
              src="../../../../assets/images/ic-settings-white.svg"
          /></span>
        </div>
        <div
          (click)="redirectToSecureTunnel()"
          class="icon-align-mobile"
          *ngIf="userRole == USERROLE?.SE || userRole == USERROLE?.CE"
        >
          <span
            ><img
              class="subhead-func-img-mobile"
              src="../../../../assets/images/diagnose-white.svg"
          /></span>
        </div> -->
      </div>
    </div>
  </mat-toolbar>
</div>
