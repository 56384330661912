<app-ce-subheader></app-ce-subheader>
<div class="image-container">
  <div class="selection-container">
    <input
      type="radio"
      [(ngModel)]="type"
      id="device-image"
      name="type-selection"
      value="image"
      data-test="input_device-image"
    />
    <label
      class="type-label cursor"
      data-test="label_device-image"
      for="device-image"
      >{{ 'common.upload-image' | translate }}</label
    >
    <input
      type="radio"
      [(ngModel)]="type"
      id="device-icon"
      name="type-selection"
      value="icon"
      data-test="input_device-icon"
    />
    <label
      class="type-label cursor"
      data-test="label_device-icon"
      for="device-icon"
      >{{ 'common.choose-icon' | translate }}</label
    >
  </div>
  <div class="file-uploader-container" *ngIf="type == 'image'">
    <app-file-upload
      (fileUpdated)="fileUpdated($event)"
      (fileValidation)="fileValidation($event)"
    ></app-file-upload>
    <div class="validation-message">
      <p>{{ 'common.file-type' | translate }}: JPG, PNG, SVG</p>
      <p>{{ 'common.max-size' | translate }}: 1MB</p>
      <p class="error" *ngIf="!validation?.value && validation?.type == 'size'">
        * {{ 'message.upload-error' | translate }}
      </p>
      <p
        class="error"
        *ngIf="!validation?.value && validation?.type == 'fileType'"
      >
        * {{ 'message.upload-type-error' | translate }}
      </p>
    </div>
    <div class="preview-section">
      <p>
        {{ fileName }}
        <mat-icon *ngIf="uploadedFile != null" (click)="delete()"
          >delete</mat-icon
        >
      </p>
      <div class="image-preview" *ngIf="uploadedFile != null">
        <img [src]="uploadImageUrl + uploadedFile" />
      </div>
    </div>
  </div>
  <div class="icon-container" *ngIf="type == 'icon'">
    <div class="" *ngFor="let deviceIcon of deviceIcons">
      <p class="icon-title">{{ 'common.' + deviceIcon.name | translate }}</p>
      <div class="icon-list">
        <label class="device-label cursor" *ngFor="let icon of deviceIcon.icon">
          <input
            class="icon-input"
            type="radio"
            [id]="deviceIcon.name"
            name="select-icon"
            [(ngModel)]="selected_icon"
            [value]="icon"
            [attr.data-test]="'input_' + deviceIcon.name"
          />
          <div class="content">
            <img
              [src]="'../../../../assets/images/device-icon/' + icon + '.svg'"
            />
            <mat-icon>check_circle</mat-icon>
          </div>
        </label>
      </div>
    </div>
  </div>
  <div class="button-container">
    <button
      class="btn secondary-btn"
      mat-flat-button
      data-test="action_cancel"
      (click)="cancel()"
      type="button"
    >
      {{ 'action.cancel' | translate }}
    </button>
    <button
      class="btn primary-btn"
      mat-flat-button
      data-test="action_save_widget"
      (click)="addWidget()"
      [disabled]="
        (type == 'icon' && !selected_icon) || (type == 'image' && !uploadedFile)
      "
    >
      {{ 'action.save-widget' | translate }}
    </button>
  </div>
</div>
