import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { emailRegex, ToasterData } from '../../constants/constants';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import * as _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-dashboard-permission',
  templateUrl: './dashboard-permission.component.html',
  styleUrls: ['./dashboard-permission.component.scss'],
})
export class DashboardPermissionComponent implements OnInit {
  public usersList: any[];
  public removeUserlist: any[] = [];
  usersEmail: {
    email: string;
    isValid: boolean;
    isSelected?: boolean;
    success?: boolean;
    reason?: string;
  }[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA, TAB, SPACE] as const;
  public apiTask = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private meshServiceProvidor: MeshServiceProvidor,
    public dialogRef: MatDialogRef<DashboardPermissionComponent>,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList() {
    const options = {
      profileInstanceID: this.data?.id,
    };
    this.meshServiceProvidor
      .permissionUserList(options)
      .then((res: any[]) => {
        this.usersList = _.uniqBy(res, 'username');
      })
      .catch((err) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: err.toString(),
        };

        this.cs.openToaster(data);
      });
  }
  save() {
    const emails = _.uniq(this.usersEmail.map((userEmail) => userEmail.email));

    const calls = [];
    if (emails.length > 0) {
      calls.push(this.shareDashboard(this.data?.dashboardId, emails));
    }

    this.removeUserlist.forEach((item) => {
      const option = {
        username: item,
        profileInstanceIDs: [this.data?.id],
      };
      calls.push(this.revokeAccess(option));
    });
    const self = this;
    Promise.all(calls).then(function (values) {
      self.dialogRef.close('success');
    });
  }
  cancel() {
    this.dialogRef.close();
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (!event.value) {
      return;
    }
    this.usersEmail.push({
      email: event.value,
      isValid: this.validateEmail(event.value),
    });
    event.input.value = '';
  }

  removeKeyword(idx) {
    if (idx > -1) {
      this.usersEmail.splice(idx, 1);
    }
  }

  validateEmail(email) {
    const re = emailRegex;
    return re.test(String(email).toLowerCase());
  }

  pasted(event: ClipboardEvent) {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/\s|;|,|\n/)
      .forEach((value) => {
        if (value.trim()) {
          this.usersEmail.push({
            email: value.trim(),
            isValid: this.validateEmail(value.trim()),
          });
        }
      });
  }

  blured(event: FocusEvent) {
    (event.target as any).value.split(/\s|;|,|\n/).forEach((value) => {
      if (value.trim()) {
        this.usersEmail.push({
          email: value.trim(),
          isValid: this.validateEmail(value.trim()),
        });
      }
    });
    (event.target as any).value = '';
  }

  get isError() {
    return !this.usersEmail.every((useremail) => useremail.isValid);
  }
  updateRemoveList(userName) {
    const index = this.removeUserlist.findIndex((x) => x === userName);
    if (index > -1) {
      this.removeUserlist.splice(index, 1);
    } else {
      this.removeUserlist.push(userName);
    }
  }

  shareDashboard(dashboardID: string, emails: Array<string>) {
    this.meshServiceProvidor
      .shareDashboardInstance(dashboardID, emails)
      .then((res) => {})
      .catch((error) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: error.toString(),
        };
        this.cs.openToaster(data);
      });
  }

  revokeAccess(options) {
    this.meshServiceProvidor
      .revokeProfileInstanceAccess(options)
      .then((res) => {})
      .catch((err) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: err.toString(),
        };
        this.cs.openToaster(data);
      });
  }

  selectChip(e, i) {
    if (e.srcElement.nextElementSibling === null) {
      return;
    }
    if (!this.usersEmail[i].isSelected) {
      this.usersEmail.forEach((userEmail) => (userEmail.isSelected = false));
      this.usersEmail[i].isSelected = true;
    }
  }

  onFocusOutEvent(e) {
    if (e.relatedTarget) {
      if (!e.relatedTarget.classList.contains('email-input')) {
        this.deSelectEmails(this.usersEmail);
      }
    } else {
      this.deSelectEmails(this.usersEmail);
    }
  }

  onMatChipKeyPress(e) {
    e.stopImmediatePropagation();
  }

  onKeyEnter(e, i, value) {
    if (e.code === 'Space' || e.code === 'Enter' || e.code === 'Tab' || e.code === 'Comma') {
      this.usersEmail.forEach((userEmail) => (userEmail.isSelected = false));
      if (value !== '') {
        this.usersEmail[i].isValid = this.validateEmail(value);
      } else {
        this.usersEmail.splice(i, 1);
      }
    }
  }

  deSelectEmails(userEmails) {
    userEmails.forEach((userEmail, index) => {
      if (userEmail.isSelected) {
        if (userEmail.email !== '') {
          userEmail.isValid = this.validateEmail(userEmail.email);
        } else {
          this.usersEmail.splice(index, 1);
        }
      }
      userEmail.isSelected = false;
    });
  }
}
