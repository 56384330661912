import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { gaugeChart } from 'gauge-chart';
import * as moment from 'moment';
import { includes } from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit, OnDestroy {
  @Input() widget;
  @Input() device;
  @Input() id: number;
  @Input() type: string;
  @Input() cardTitle: string;
  @Input() units: string;
  @Output() timeStamp: EventEmitter<any> = new EventEmitter();

  public canvasWidth = 355;
  public needleValue = 50;
  public timeValue: any = moment().format('HH:mm:ss');

  public centralLabel = '';
  public name = 'Gauge chart';
  public bottomLabel = '128';
  public bottomLabelFont = 30;
  public options = {
    hasNeedle: true,
    needleColor: '#404040',
    needleUpdateSpeed: 1000,
    arcColors: ['#009534', '#009534', '#ffd500', '#e60000'],
    arcDelimiters: [25, 50, 75],
    arcOverEffect: false,
    rangeLabel: ['0', '100'],
    arcLabels: ['25', '50', '75'],
    rangeLabelFontSize: 12,
    arcPadding: 100,
    needleStartValue: 50,
  };
  public screenWidth: any;

  subscriptionHandler = [];
  currentStatus = '';
  isDataAvailable: boolean;
  private subscription: Subscription[] = [];
  constructor(private service: MeshServiceProvidor, private router: Router) {}

  ngOnInit() {
    if (this.type === 'template') {
      this.screenWidth = window.innerWidth;

      this.onFirstLoad();

      this.options.arcColors = this.widget.arcColors;
      this.options.arcDelimiters = this.widget.arcDelimiters;
      this.options.arcLabels = this.widget.arcLabels;
      if ('max' in this.widget && 'min' in this.widget) {
        const min = this.widget.min;
        const max = this.widget.max;
        this.options.rangeLabel = [];
        this.options.rangeLabel.push(min.toString());
        this.options.rangeLabel.push(max.toString());
      } else {
        this.options.rangeLabel = this.widget.rangeLabel;
      }

      this.needleVal(this.widget);

      this.cardTitle = this.widget.title;
    } else {
      this.screenWidth = window.innerWidth;

      this.onFirstLoad();
      this.getData();
      this.getGauge();
      this.symbolData();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize', ['$event'])
  onResize($event) {
    if ($event.isTrusted) {
      if ($event.target.innerWidth >= 1200) {
        this.canvasWidth = 355;
        this.bottomLabelFont = 30;
      } else if ($event.target.innerWidth > 1000 && $event.target.innerWidth < 1200) {
        this.canvasWidth = 275;
        this.bottomLabelFont = 30;
      } else if ($event.target.innerWidth > 800 && $event.target.innerWidth < 1000) {
        this.canvasWidth = 255;
        this.bottomLabelFont = 20;
      } else if ($event.target.innerWidth < 800 && $event.target.innerWidth > 500) {
        this.canvasWidth = 225;
      } else if ($event.target.innerWidth < 500) {
        this.canvasWidth = 200;
        this.bottomLabelFont = 20;
      }
    }
  }
  needleVal(dat) {
    //NEEDLE LOGIC
    const diff = parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
    const needleVal =
      Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);

    this.needleValue =
      ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
      (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
    this.bottomLabel = '';
    const unit = this.widget.units;

    if (unit.includes('-')) {
      const un = unit.split('-');

      this.units = un[0];
    } else {
      this.units = this.widget.units;
    }
    if (needleVal?.toString().includes('.')) {
      this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + this.units;
    } else {
      this.bottomLabel = needleVal?.toString() + ' ' + this.units;
    }
    //END NEEDLE LOGIC
  }
  onFirstLoad() {
    if (
      this.device.includes('Web') ||
      this.device.includes('Tablet') ||
      this.device.includes('MobileLandscape')
    ) {
      if (this.screenWidth >= 1200) {
        this.canvasWidth = 355;
      } else if (this.screenWidth > 1000 && this.screenWidth < 1200) {
        this.canvasWidth = 275;
      } else if (this.screenWidth > 800 && this.screenWidth < 1000) {
        this.canvasWidth = 255;
      } else if (this.screenWidth < 800 && this.screenWidth > 500) {
        this.canvasWidth = 225;
      } else if (this.screenWidth < 500) {
        this.canvasWidth = 200;
      }
    }
    if (this.device.includes('MobilePortrait')) {
      if (this.screenWidth <= 500 && this.screenWidth > 400) {
        this.canvasWidth = 400;
      } else if (this.screenWidth >= 350 && this.screenWidth <= 400) {
        this.canvasWidth = 350;
      } else {
        this.canvasWidth = 300;
      }
    }
  }
  update = (data) => {
    let min;
    let max;
    if (data.value !== null) {
      if ('max' in this.widget && 'min' in this.widget) {
        min = this.widget.min;
        max = this.widget.max;
      } else {
        min = this.widget.rangeLabel[0];
        max = this.widget.rangeLabel[1];
      }
      this.needleValue = ((data.value - min) * 100) / (max - min);

      const val = this.needleValue.toFixed(0);
      const unit = this.widget.units;

      if (unit.includes('-')) {
        const un = unit.split('-');

        this.units = un[0];
      } else {
        this.units = this.widget.units;
      }
      this.bottomLabel = data.value + ' ' + this.units;
    }
    if (moment(data.timestamp).format('YYYY MM DD') === moment().format('YYYY MM DD')) {
      this.timeStamp.emit(moment(data.timestamp).format('HH:mm:ss'));
      this.timeValue = moment(data.timestamp).format('HH:mm:ss');
    } else {
      this.timeStamp.emit(moment(data.timestamp).format('D MMM HH:mm:ss'));
      this.timeValue = moment(data.timestamp).format('D MMM HH:mm:ss');
    }
  };

  getData() {
    this.cardTitle = this.widget.title;
    this.units = this.widget.units;
    if (this.widget.type === 'gauge' || this.widget.type === 'combination-chart') {
      this.options.arcColors = this.widget.arcColors;
      this.options.arcDelimiters = this.widget.arcDelimiters;
      this.options.arcLabels = this.widget.arcLabels;
      if ('max' in this.widget && 'min' in this.widget) {
        const min = this.widget.min;
        const max = this.widget.max;
        this.options.rangeLabel = [];
        this.options.rangeLabel.push(min.toString());
        this.options.rangeLabel.push(max.toString());
      } else {
        this.options.rangeLabel = this.widget.rangeLabel;
      }
    }
  }
  getGauge() {
    const sub = this.service
      .getGaugeData(this.widget.currentPath, this.update)
      .then((res) => {
        if (res) {
          this.subscriptionHandler.push(res.gaugeDataInstance);
          if (res.initialValue !== null) {
            this.update(res.initialValue);
          }
          this.isDataAvailable = true;
        } else {
          this.isDataAvailable = false;
        }
      })
      .catch((error) => {
        this.currentStatus = 'offline';
        this.subscriptionHandler = [undefined];
      });
  }
  border = (data) => {
    if (data.value !== null) {
      this.currentStatus = data.status;
    }
  };

  symbolData() {
    const subst = this.service
      .getGaugeData(this.widget.pointStatusPath, this.border, 'status')
      .then((res) => {
        if (res) {
          this.subscriptionHandler.push(res.gaugeDataInstance);
          if (res.initialValue !== null) {
            this.border(res.initialValue);
          }
        }
      })
      .catch((error) => {
        this.currentStatus = 'offline';
        this.subscriptionHandler = [undefined];
      });
  }
  edit(type, data) {
    this.router.navigate(['/', 'main', 'template', 'editwidget'], {
      queryParams: {
        type,
        id: data.widgetID,
      },
    });
  }
  destroy(e) {
    this.service.destroyGaugeData(e);
  }

  ngOnDestroy() {
    this.subscriptionHandler.forEach((value) => {
      if (value) {
        this.destroy(value);
      }
    });
  }
}
