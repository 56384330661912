<section
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  class="multi-select-dropdown"
  id="msd"
>
  <div
    fxLayout="row"
    fxLayoutGap="5px"
    id="action-clickable"
    class="selection"
    [ngClass]="{ opened: isOpen }"
    (click)="toggleDropdown()"
    data-test="action_status_selection"
    *ngIf="selectType == 'select'; else options"
  >
    <ng-container *ngIf="!selectedValue.length; else outvalue">
      <p id="msd-drop-down">{{ "action.select" | translate }}</p>
      <mat-icon>{{ isOpen ? "arrow_drop_up" : "arrow_drop_down" }}</mat-icon>
    </ng-container>
    <ng-template #outvalue>
      <p
        id="msd-drop-down"
        [ngClass]="{ 'has-value': selectedValue?.length > 0 }"
      >
        {{ selectedValue[0] }}{{ selectedValue?.length > 1 ? ", .." : "" }}
      </p>
      <span class="badge"> {{ selectedValue?.length }} </span>
      <mat-icon data-test="action_close" (click)="clearNreset()" class="close-icon">close</mat-icon>
    </ng-template>
  </div>
  <ng-template #options>
    <div
      id="table-filter"
      [ngClass]="{ opened: isOpen }"
      (click)="toggleDropdown()"
      data-test="action_tablefilter_dropdown"
    >
      <img src="../../../../assets/images/options.svg" />
    </div>
  </ng-template>
</section>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayOpen]="isOpen"
  (backdropClick)="toggleDropdown()"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlayPanelClass="msd-panel"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
>
  <span class="caret" *ngIf="selectType == 'dropdown'"></span>
  <mat-card
    class="mat-elevation-z4 msd-drop-down"
    [ngClass]="{ tabledropdown: selectType == 'dropdown' }"
  >
    <mat-card-content class="msd-drop-down-content">
      <div class="msd-dropdown-content">
        <ul class="msd-list">
          <!----Select All Checkbox------------------------------------>
          <li
            class="msd-item-list"
            *ngIf="selectType == 'select'"
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <label
              class="msd-checkbox-wrap cursor"
              fxLayout="row"
              fxLayoutGap="20px"
              data-test="option_selectall"
            >
              <input
                class="msd-input"
                type="checkbox"
                name="select_all"
                id="select_all"
                [indeterminate]="!isEverySelected && isSomeSelected"
                [checked]="isEverySelected"
                (change)="toggleAllChildCheckBox($event)"
                data-test="'input_selectall'"
              />
              <span class="msd-input-mask"></span>
              <span class="msd-input-label">Select All</span>
            </label>
            <span class="msd-select-count">
              {{ countOfSelected }} of
              {{ itemList.length }}
            </span>
          </li>
          <!---- Other Checkbox ----------------------------------------------->
          <li
            class="msd-item-list"
            *ngFor="let status of itemList; let i = index"
          >
            <label
              class="msd-checkbox-wrap cursor"
              fxLayout="row"
              fxLayoutGap="20px"
              [attr.data-test]="'option_' + status.name"
            >
              <input
                class="msd-input"
                type="checkbox"
                [name]="status.name"
                [id]="status.name"
                [checked]="status.state"
                (change)="changeCheckbox(i)"
                [attr.data-test]="'input_' + status.name"
              />
              <span class="msd-input-mask"></span>
              <span
                class="msd-input-label"
                [attr.data-test]="'text_' + status.name"
                *ngIf="selectType == 'select'"
                >{{ status.name }}</span
              >
              <span
                class="msd-input-label"
                [attr.data-test]="'text_' + status.name"
                *ngIf="selectType != 'select'"
                >{{ "common." + status.name | translate }}</span
              >
            </label>
          </li>
        </ul>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <button
        class="msd-btn-action"
        [disabled]="!isSomeSelected && !selectedValue.length"
        mat-flat-button
        (click)="applyChange()"
        *ngIf="selectType == 'select'"
        data-test="action_apply"
      >
        {{ "action.apply" | translate | uppercase }}
      </button>
      <button
        class="msd-btn-action"
        [disabled]="!isSomeSelected"
        mat-flat-button
        (click)="applyChange()"
        *ngIf="selectType == 'dropdown'"
        data-test="action_apply"
      >
        {{ "action.apply" | translate | uppercase }}
      </button>
    </mat-card-footer>
  </mat-card>
</ng-template>
