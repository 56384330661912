import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToasterData } from '../../../shared/constants/constants';
@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public toasterData: ToasterData,
    public snackRef: MatSnackBarRef<ToasterComponent>
  ) {}

  ngOnInit(): void {}
  dismiss(): void {
    this.snackRef.dismiss();
  }
}
