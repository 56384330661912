<mat-card [ngClass]="{'eventlog':renderedFrom === 'eventlog'}" fxLayout="row" fxFlex fxLayoutAlign="flex-start">
  <ng-container *ngIf="renderedFrom === undefined">
  <div class="content1" fxFlexAlign="center" fxFlex="20">
    <img
      *ngIf="deviceData?.iconType == undefined || deviceData?.iconType == null"
      [src]="defaultImage"
      [alt]="deviceData?.deviceImageUrl"
    />
    <img
      *ngIf="deviceData?.iconType == 'icon'"
      [src]="iconUrl + deviceData?.deviceImageUrl"
      [alt]="deviceData?.deviceImageUrl"
    />
    <img
      *ngIf="deviceData?.iconType == 'image'"
      [src]="imageUrl + deviceData?.deviceImageUrl"
      [alt]="deviceData?.deviceImageUrl"
    />
  </div>
  <div
    *ngIf="userRole === userRoleConfig.PM"
    class="content2"
    fxFlexAlign="center"
    fxFlex="60"
  >
    <p class="deviceName">{{ deviceData?.deviceName }}</p>
    <p class="siteName">{{ deviceData?.siteName }}</p>
    <p class="modelNumber">{{ deviceData?.profileName }}</p>
  </div>
  <div
    *ngIf="userRole === userRoleConfig.SE || userRole === userRoleConfig.CE"
    class="content2"
    fxFlexAlign="center"
    fxFlex="60"
  >
    <p class="deviceName">{{ deviceData?.deviceName }}</p>
    <p class="siteName">{{ deviceData?.company }}</p>
    <p class="modelNumber">{{ deviceData?.macAddress }}</p>
  </div>
</ng-container>
  <div class="content3" fxFlexAlign="center" fxFlex="20">
    <img
      src="../../../assets/images/warning-yellow.svg"
      [alt]="deviceData?.status"
      *ngIf="deviceData?.status?.toLowerCase() == 'warning'"
    />
    <img
      src="../../../assets/images/success-alt.svg"
      [alt]="deviceData?.status"
      *ngIf="deviceData?.status?.toLowerCase() == 'normal'"
    />
    <img
      src="../../../assets/images/alarm.svg"
      [alt]="deviceData?.status"
      *ngIf="deviceData?.status?.toLowerCase() == 'alarm'"
    />
    <img
      src="../../../assets/images/close.svg"
      [alt]="deviceData?.status"
      *ngIf="deviceData?.status?.toLowerCase() == 'offline'"
    />
    <img
      src="../../../assets/images/warning.svg"
      [alt]="deviceData?.status"
      *ngIf="deviceData?.status?.toLowerCase() == 'trouble'"
    />
  </div>
  <ng-container *ngIf="renderedFrom === 'eventlog'">
    <div
      class="content2"
      fxFlexAlign="center"
      fxFlex="80"
    >
      <p class="deviceName">{{ deviceData?.deviceName }}</p>
      <p class="siteName">{{ deviceData?.siteName }}</p>
      <p class="message">{{ deviceData?.notificationMessage }}</p>
      <p class="datetime">{{ deviceData?.dateTime | dateformat: dateFormat}}</p>
    </div>
  </ng-container>
</mat-card>
