import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent {
  @Output() selectedOption = new EventEmitter<Array<string>>();
  @Input() selectType: string;
  itemOption: Array<string> = [];
  @Input() set listOfOption(value: Array<string>) {
    this.itemOption = value || [];
    this.recreateSelectionList();
  }
  @Input() set selectedItem(value: Array<string>) {
    if (value) {
      if (value.length === 0) {
        this.selectedValue = [];
        return;
      }
      value.forEach((val) => {
        const idx = this.itemList.findIndex(
          (item) => item.name.toLowerCase() === val.toLowerCase()
        );
        if (idx < 0) {
          return;
        }
        this.itemList[idx].state = true;
        this.selectedValue = value.map(
          (words) => words.charAt(0).toUpperCase() + words.substring(1, words.length)
        );
      });
    }
  }

  itemList: { name: string; state: boolean }[] = [];
  isOpen = false;

  selectedValue = [];
  constructor() {
    this.recreateSelectionList();
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.itemList.forEach((value) => {
        const name = value.name;
        if (
          !this.selectedValue.includes(
            name.charAt(0).toUpperCase() + name.substring(1, name.length)
          )
        ) {
          value.state = false;
        } else {
          value.state = true;
        }
      });
    }
  }

  recreateSelectionList(): void {
    this.itemList = this.itemOption.map((status) => ({
      name: status,
      state: false,
    }));
  }

  toggleAllChildCheckBox(event: Event) {
    const state = (event.target as HTMLInputElement).checked;
    this.itemList.forEach((status) => (status.state = state));
  }

  get isSomeSelected(): boolean {
    return this.itemList.some((status) => status.state === true);
  }

  get isEverySelected(): boolean {
    return this.itemList.every((status) => status.state === true);
  }

  get countOfSelected() {
    let count = 0;
    this.itemList.forEach((status) => {
      count = status.state === true ? count + 1 : count;
    });
    return count;
  }

  changeCheckbox(index: number) {
    this.itemList[index].state = !this.itemList[index].state;
  }

  applyChange() {
    this.selectedValue = [];
    this.itemList.forEach((status) =>
      status.state ? this.selectedValue.push(status.name) : void 0
    );
    this.selectedOption.emit(this.selectedValue);
    this.isOpen = false;
  }

  clearNreset() {
    this.selectedValue = [];
    this.itemList.forEach((status) => (status.state = false));
    this.selectedOption.emit(this.selectedValue);
    setTimeout(() => {
      this.isOpen = false;
    });
  }
}
