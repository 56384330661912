// purpose of the module is to allow the fielpopclient to be loaded in the APP_INITIALIZER phase
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { MeshServiceProvidor } from './mesh.service';

export function init_mesh(meshServiceProvidor: MeshServiceProvidor): any {
  return () => meshServiceProvidor.awaitFieldpopHappnerClient();
}

@NgModule({
  imports: [],
  providers: [
    MeshServiceProvidor,
    {
      provide: APP_INITIALIZER,
      useFactory: init_mesh,
      deps: [MeshServiceProvidor],
      multi: true,
    },
  ],
})
export class MeshModule {}
