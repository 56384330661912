export const fakeTempData = [
  {
    currentPath: '/current/malachitevulture.ea.ayj9au/Boiler 1/',
    dataPath: '',
    height: 2,
    historyPath: 'malachitevulture_ea-aYj9AU/Boiler 1/',
    pointStatusPath: '/_data/fieldveu/pointStatus/malachitevulture_ea-aYj9AU/Boiler 1/',
    title: 'Device Info',
    type: 'device-info',
    url: (window as any).smcUrl + 'happner-files/files/logo/boiler.jpeg',
    width: 1,
    iconType: undefined,
    widgetID: 'device-info' + Math.floor(Math.random() * 1000000),
  },
  {
    currentPath: '/_data/fieldveu/deviceStatus/malachitevulture_ea-aYj9AU/Boiler 1',
    dataPath: '',
    height: 1,
    pointStatusPath: '/_data/fieldveu/pointStatus/malachitevulture_ea-aYj9AU/Boiler 1/',
    title: 'Device Status',
    type: 'device-status',
    width: 1,
    widgetID: 'device-status' + Math.floor(Math.random() * 1000000),
  },
];
