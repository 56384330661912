import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { cloneDeep, isArray, isEqual, isNumber } from 'lodash';
import * as moment from 'moment';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexLegend,
  ApexMarkers,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';
import { TemplateService } from 'src/app/service/template/template.service';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { addClass, removeClass } from 'src/app/shared/class/util';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { ceData } from 'src/app/shared/constants/ce-data';
import { GraphType } from '../select-linechart-type/select-linechart-type.component';
import { convertMultiSeriesModelToFormObject } from '../shared/helper/default.helper';
import { customValidation } from '../shared/helper/validator.helper';
import { MutliSeriesChartModel } from '../shared/model/mutliserieschart.model';

interface MultiSeriesForm {
  datapoints: {
    datapoint: any;
    unitOfMeasure: string;
    color: string;
    min?: number;
    max?: number;
  }[];
  autoScale: boolean;
  min: number;
  max: number;
  timeFilter: string;
  widgetTitle: string;
  gridLines: any;
  gridLinesColors: [];
}

@Component({
  selector: 'app-linechart-editor',
  templateUrl: './linechart-editor.component.html',
  styleUrls: ['./linechart-editor.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('350ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('350ms', style({ transform: 'translateX(-100%)', height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class LinechartEditorComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  @ViewChild('apxChartEl') private apexChart = new ChartComponent();
  readonly seriesColor = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63'];
  currentActiveTab = 0;
  ignoreValueChange = false;
  limitDatapointField = 4;
  tabConfig = {
    dataTab: {
      title: 'DATA',
      iconName: 'cancel',
      iconColor: '#e60000',
      isIconVisible: false,
    },
    designTab: {
      title: 'DESIGN',
      iconName: 'cancel',
      iconColor: '#e60000',
      isIconVisible: false,
    },
  };
  dataset = [];
  public unitOptions = ceData.unitOptions;
  dualYAxis: boolean;
  isEdit: boolean;
  widgetID: string;
  lineChartForm: FormGroup;
  formSubmitted: boolean;
  subscription: Subscription[] = [];
  listOfAnnotaionIdSuffix = [];
  editedData: MutliSeriesChartModel;

  /* --------------- SECTION Default Line Chart Setup ---------------------------- */

  // SECTION

  series: ApexAxisChartSeries | any = [];

  chart: ApexChart = {
    type: 'line',
    height: 350,
    stacked: false,
    width: '100%',
    animations: {
      enabled: false,
    },
    redrawOnParentResize: true,
    toolbar: {
      show: false,
    },
    zoom: {
      type: 'x',
      enabled: false,
    },
  };

  legend: ApexLegend = {
    show: true,
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
    fontSize: '16px',
    fontFamily: 'Roboto, Regular, sans-serif',
    labels: {
      colors: '#404040',
    },
    formatter: this.legendFormatter.bind(this),
  };

  xAxis: ApexXAxis = {
    type: 'datetime',
    offsetX: 30,
    tickAmount: 5,
    labels: {
      show: true,
      rotateAlways: false,
      hideOverlappingLabels: false,
      trim: false,
      maxHeight: 120,
      datetimeUTC: true,
      style: {
        colors: '#444444',
        fontSize: '18px',
        fontFamily: 'Roboto, Regular, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-label',
      },
      formatter(value) {
        return moment(value).format('MMM D');
      },
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: true,
      formatter(value) {
        return moment(value).format('MMM D,HH:mm:ss');
      },
    },
  };

  yAxis: ApexYAxis | ApexYAxis[] = {
    labels: {
      style: this.yAxisLabelStyles,
    },
  };

  tooltip: ApexTooltip = {
    custom: this.tooltipCustomHelper.bind(this),
  };

  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 2,
    dashArray: 0,
  };

  markers: ApexMarkers = {
    size: 0,
  };

  annotations: ApexAnnotations = {
    position: 'back',
  };
  handleChange = false;

  /* ------------------------------------------------------------- */

  @Input() set graphType(type: GraphType) {
    this.dualYAxis = Boolean(type === 'dualYAxis');
    if (this.dualYAxis) {
      this.addDatapointGroupToForm(true);
      this.addDatapointGroupToForm(true);
      this.lineChartForm.get('autoScale').setValue(false);
      this.updateYAxis();
    } else {
      this.addDatapointGroupToForm();
    }
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data') set editData(data: MutliSeriesChartModel) {
    if (data) {
      this.isEdit = true;
      this.widgetID = data.widgetID;
      this.editedData = data;
      setTimeout(() => {
        const clonedDataset = cloneDeep(this.dataset);
        const formData = convertMultiSeriesModelToFormObject(data, clonedDataset);
        if (data.dataPath?.length !== this.datapointsControls.controls?.length) {
          let addDatapointFieldCount = data.dataPath?.length - 1;
          while (addDatapointFieldCount > 0) {
            this.addDatapointGroupToForm();
            addDatapointFieldCount--;
          }
        }
        formData.gridLinesColors.forEach((gridLineColor, index) => {
          this.addGridLineGroupToForm(
            gridLineColor.name,
            gridLineColor.color,
            gridLineColor.checked
          );
          this.addAnnotation(
            index,
            this.getNumberFromString(gridLineColor.name),
            gridLineColor.color
          );
        });
        this.lineChartForm.get('autoScale').setValue(formData.autoScale);
        if (!this.dualYAxis) {
          this.yAxisMinMaxHandler();
        }
        this.invokeListener();
        this.lineChartForm.setValue(formData);
        this.lineChartForm.get('gridLines').patchValue(formData.gridLines);
        this.seriesManager();
      }, 100);
    }
  }
  constructor(
    private ws: WidgetsService,
    private fb: FormBuilder,
    private _templateService: TemplateService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.lineChartForm = this.fb.group(
      {
        datapoints: this.fb.array([]),
        autoScale: [true, null],
        timeFilter: ['month', null],
        widgetTitle: [null, Validators.required],
        gridLines: [null, null],
        gridLinesColors: this.fb.array([]),
      },
      {
        validators: customValidation,
      }
    );
  }

  ngOnInit(): void {
    if (this.ws.dataPoints === undefined) {
      const dP = JSON.parse(sessionStorage.getItem('dataPoints'));
      this.dataset = dP;
    } else {
      this.dataset = cloneDeep(this.ws.dataPoints);
    }
    if (this.isEdit) return;
    this.invokeListener();
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.handleChange = true;
    }, 100);
  }

  invokeListener() {
    this.subscription = [
      this.listenFormChanges(),
      this.listenDatapointChange(),
      this.listenGridLineSelectionChange(),
      this.listenGridLineColorChange(),
    ];
  }

  /* Listener Setup */

  listenFormChanges() {
    return this.lineChartForm.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        delay(500)
      )
      .subscribe((values) => {
        if (!this.dualYAxis) {
          this.yAxisMinMaxHandler();
        }
        if (this.tabConfig.dataTab.isIconVisible || this.tabConfig.designTab.isIconVisible) {
          this.manageTabInkbarClass(false);
          return;
        }
        this.formSubmitted = false;
      });
  }

  listenDatapointChange() {
    return this.lineChartForm
      .get('datapoints')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        delay(500)
      )
      .subscribe((values) => {
        if (this.ignoreValueChange) return;
        if (this.dualYAxis) {
          this.updateYAxis();
        }
        this.seriesManager();
        this.managePrimaryDataPoint();
        this.manageWidgetTitle();
      });
  }

  listenGridLineSelectionChange() {
    return this.lineChartForm
      .get('gridLines')
      .valueChanges.pipe(delay(500))
      .subscribe(() => {
        const rawValue = this.lineChartForm.getRawValue();
        if (rawValue?.gridLines) {
          this.eventRulesHandler(rawValue.datapoints, rawValue?.gridLines);
          this.apexChart.updateSeries(this.apexChart.series);
        }
      });
  }

  listenGridLineColorChange() {
    return this.lineChartForm
      .get('gridLinesColors')
      .valueChanges.pipe(delay(500))
      .subscribe(async (values) => {
        if (values?.length === 0) {
          while (this.listOfAnnotaionIdSuffix.length > 0) {
            this.removeAnnotaion(this.listOfAnnotaionIdSuffix[0]);
          }
        }
        values.forEach((value, index) => {
          this.removeAnnotaion(index);
          if (value.checked) {
            this.addAnnotation(index, this.getNumberFromString(value.name), value.color);
          }
        });
      });
  }

  /* Listener Setup End */

  createDatapointGroup(color: string, unitOfMeasure = null, addMinMax = false): FormGroup {
    const group = this.fb.group({
      datapoint: [null, Validators.required],
      unitOfMeasure: [unitOfMeasure, null],
      color: [color, null],
    });
    if (addMinMax) {
      group.addControl('min', new FormControl(null, [Validators.required]));
      group.addControl('max', new FormControl(null, [Validators.required]));
      group.setValidators(customValidation);
    }
    return group;
  }

  addDatapointGroupToForm(addMinMax = false) {
    const limit = this.limitDatapointField;
    if (this.datapointsControls?.length < limit) {
      const length = this.datapointsControls.length;
      const previewUnitOfMeasure =
        this.datapointsControls.controls[length - 1]?.value?.unitOfMeasure;
      this.datapointsControls.push(
        this.createDatapointGroup(this.seriesColor[length], previewUnitOfMeasure, addMinMax)
      );
    }
  }

  removeDatapointGroupFromForm(index: number) {
    this.datapointsControls.removeAt(index);
    this.deleteSeries(index);
  }

  get datapointsControls() {
    return this.lineChartForm.get(['datapoints']) as FormArray;
  }

  createGridLineGroup(name: string, color: string, checked = true): FormGroup {
    return this.fb.group({
      name: [name, null],
      color: [color, null],
      checked: [checked, Validators.required],
    });
  }

  addGridLineGroupToForm(name: string, color: string, checked = true) {
    const createdGroup = this.createGridLineGroup(name, color, checked);
    this.gridLinesColorsControls.push(createdGroup);
  }

  removeAllGridLineGroupFromFrom() {
    let count = 0;
    while (this.gridLinesColorsControls.length !== 0) {
      this.gridLinesColorsControls.removeAt(0);
      this.removeAnnotaion(count);
      count++;
    }
    return { removedCount: count };
  }

  get gridLinesColorsControls() {
    return this.lineChartForm.get(['gridLinesColors']) as FormArray;
  }

  saveWidget() {
    this.lineChartForm.markAllAsTouched();
    this.formSubmitted = true;
    this.manageTabInkbarClass();
    if (this.lineChartForm.invalid) return;
    const formValues = this.lineChartForm.value as MultiSeriesForm;
    const min = isNumber(formValues.min)
      ? [formValues.min]
      : this.dualYAxis
      ? formValues.datapoints.map((datapoint) => datapoint?.min)
      : [];
    const max = isNumber(formValues.max)
      ? [formValues.max]
      : this.dualYAxis
      ? formValues.datapoints.map((datapoint) => datapoint?.max)
      : [];

    const widgetData: MutliSeriesChartModel = new MutliSeriesChartModel(
      formValues.autoScale,
      formValues.datapoints.length,
      formValues.datapoints.map((datapoint) => datapoint.color),
      formValues.gridLinesColors
        .filter((gridLineColor: any) => gridLineColor.checked)
        .map((gridLineColor: any) => {
          return {
            y: this.getNumberFromString(gridLineColor.name),
            color: gridLineColor.color,
          };
        }),
      formValues.datapoints.map((datapoint) => datapoint.datapoint.path),
      formValues.widgetTitle,
      formValues.datapoints.map((datapoint) => datapoint.unitOfMeasure),
      formValues.gridLines,
      this.dualYAxis,
      min,
      max,
      this.widgetID
    );

    const templateData = this._templateService.tempData;

    if (this.isEdit) {
      const indexForUpdate = templateData.findIndex((data) => data.widgetID === this.widgetID);
      templateData[indexForUpdate] = widgetData;
    } else {
      templateData.push(Object(widgetData));
    }

    sessionStorage.setItem('defaultTimeFilter', formValues.timeFilter);
    this._templateService.tempData = templateData;
    sessionStorage.setItem('tempData', JSON.stringify(templateData));
    this.router.navigate(['/main/template/blanktemplate']);
  }

  delete() {
    this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.widgetID, modalType: 'delete-widget' },
    });
  }

  cancel() {
    this.router.navigate(['/main/template/blanktemplate']);
  }

  /* Manage Chart  */

  createYAxisOject(
    formPath: string | (string | number)[],
    opposite = false,
    formMinPath: string | (string | number)[],
    formMaxPath: string | (string | number)[],
    unitOfMeasure: string | (string | number)[],
    placeholderText: string
  ): ApexYAxis {
    const path = this.lineChartForm.get(formPath)?.value?.path || '';
    const unit = this.splitStringOnHyphenAndGetStringAt0(
      this.lineChartForm.get(unitOfMeasure)?.value
    );
    const text = `${path} (${unit})`.replace('()', '').trim() || placeholderText;
    return {
      opposite,
      title: {
        text,
        style: {
          color: '#444444',
          fontSize: '16px',
          fontFamily: 'Roboto, Regular, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-label',
        },
      },
      min: this.lineChartForm.get(formMinPath)?.value || 0,
      max: this.lineChartForm.get(formMaxPath)?.value || 100,
      labels: {
        style: this.yAxisLabelStyles,
      },
    };
  }

  updateYAxis() {
    this.yAxis = [];
    if (isArray(this.yAxis)) {
      this.datapointsControls?.controls.forEach((datapointControl, index) => {
        const value = this.createYAxisOject(
          ['datapoints', index, 'datapoint'],
          index === 1,
          ['datapoints', index, 'min'],
          ['datapoints', index, 'max'],
          ['datapoints', index, 'unitOfMeasure'],
          `Data point ${index + 1}`
        );
        (this.yAxis as any).push(value);
      });
    }
  }

  managePrimaryDataPoint() {
    let primaryDatapoint = this.lineChartForm.get('gridLines').value;
    if (primaryDatapoint === 'none') {
      return;
    }
    const selectedDatapointNameList = this.listOfSelectDatapoint.map((item) => item.path);
    if (!selectedDatapointNameList.includes(primaryDatapoint)) {
      primaryDatapoint = undefined;
    }
    const firstDatapointSelected =
      primaryDatapoint || this.datapointsControls.controls[0]?.value?.datapoint?.path;

    if (primaryDatapoint === firstDatapointSelected) {
      return;
    }
    setTimeout(() => {
      this.lineChartForm.get('gridLines').patchValue(firstDatapointSelected);
    }, 100);
  }

  /* ################## Series Chart ############################# */

  async seriesManager() {
    if (this.apexChart.series?.length === this.datapointsControls?.length) {
      this.datapointsControls.controls.forEach((datapoint, index) => {
        this.updateSeries(
          index,
          datapoint.value?.datapoint?.path,
          this.series[index].data,
          datapoint.value.color
        );
      });
    } else {
      const removingEntry = this.datapointsControls?.length - this.apexChart.series?.length;
      for (let i = 0; i < removingEntry; i++) {
        await new Promise<void>((next) => {
          const nextSeriesIndex = this.apexChart.series?.length + i;
          const newEntry = this.datapointsControls.controls[nextSeriesIndex];
          const { minDate, maxDate } = this.ws.getMinMaxDate('month');
          const type = this.isEdit ? 'editwidget' : 'addwidget';
          const data = this.ws.getDataforChart(minDate, maxDate, type);
          if (newEntry?.value?.datapoint?.path) {
            this.addSeries(newEntry.value.datapoint.path, data, newEntry.value.color);
          }
          next();
        });
      }
    }
  }

  addSeries(name: string, data: any, color: string) {
    this.series = [
      ...this.series,
      {
        name,
        color,
        data,
      },
    ];
    this.apexChart?.updateSeries(this.series);
  }

  updateSeries(index: number, name: string, data: any, color: string) {
    this.apexChart.series[index] = {
      name,
      data,
      color,
    };
    this.apexChart?.updateSeries(this.apexChart.series);
  }

  deleteSeries(index: number) {
    this.apexChart.series.splice(index, 1);
    this.apexChart?.updateSeries(this.apexChart.series);
  }

  /*---------------------- Series Chart End ---------------- */

  tooltipCustomHelper({ series, seriesIndex, dataPointIndex, w }) {
    const unit = this.datapointsControls.controls[seriesIndex].value.unitOfMeasure;
    const displayDate =
      '<div class="arrow_box">' +
      '<span>' +
      +series[seriesIndex][dataPointIndex] +
      ' ' +
      this.splitStringOnHyphenAndGetStringAt0(unit) +
      '</span>' +
      '</div>';

    return displayDate;
  }

  eventRulesHandler(datapoints: any[], selectedDatapoint: string) {
    if (selectedDatapoint === 'none') {
      this.removeAllGridLineGroupFromFrom();
      return null;
    }
    const datapoint = datapoints.find(
      (datapointVal) => datapointVal?.datapoint?.path === selectedDatapoint
    )?.datapoint;
    if (datapoint) {
      this.removeAllGridLineGroupFromFrom();
    }
    if (!datapoint?.eventRules) return null;

    this.ignoreValueChange = true;
    if (isArray(datapoint.eventRules)) {
      datapoint.eventRules.forEach((eventRule) => {
        this.createGridLineFormGroupByEventRule(eventRule);
      });
    } else {
      const [name, color] = this.createGridLineFormGroupByEventRule(datapoint.eventRules);
      this.addAnnotation(0, name, color);
    }

    setTimeout(() => {
      this.ignoreValueChange = false;
    }, 100);
  }

  createGridLineFormGroupByEventRule(eventRule: any) {
    const name = `${eventRule?.type} (${eventRule?.alarm_setpoint})`;
    const key = Object.keys(ceData.gridLine).includes(eventRule.type) ? eventRule.type : 'other';
    const color = ceData.gridLine[key];
    const selectedPrimaryDatapoint = this.lineChartForm.get('gridLines')?.value;
    if (this.isEdit && this.editedData?.primaryDataPoint === selectedPrimaryDatapoint) {
      const foundAnnotaion = this.editedData?.annotationData.find(
        (annotation) => annotation.y === eventRule?.alarm_setpoint
      );
      if (foundAnnotaion) {
        this.addGridLineGroupToForm(name, foundAnnotaion?.color);
        return [name, foundAnnotaion?.color];
      } else {
        this.addGridLineGroupToForm(name, color, false);
        return [name, color];
      }
    }
    this.addGridLineGroupToForm(name, color);
    return [name, color];
  }

  addAnnotation(index: number, value: number, color: string) {
    const annotation = {
      id: 'my-annotation' + index,
      y: value,
      borderColor: color,
      strokeDashArray: 0,
    };
    this.listOfAnnotaionIdSuffix.push(index);
    this.apexChart.addYaxisAnnotation(annotation);
  }

  removeAnnotaion(index: number) {
    this.apexChart.removeAnnotation('my-annotation' + index);
    const annotationSuffixIndex = this.listOfAnnotaionIdSuffix.findIndex(
      (annotationIdSuffix) => annotationIdSuffix === index
    );
    if (annotationSuffixIndex > -1) {
      this.listOfAnnotaionIdSuffix.splice(annotationSuffixIndex, 1);
    }
  }

  yAxisMinMaxHandler() {
    const isAutoScale = this.lineChartForm.get('autoScale').value;
    if (isArray(this.apexChart.yaxis)) return;
    if (isAutoScale) {
      this.apexChart.updateOptions({
        yaxis: {
          labels: {
            style: this.yAxisLabelStyles,
          },
        },
      });
      this.lineChartForm.removeControl('min');
      this.lineChartForm.removeControl('max');
    } else {
      this.lineChartForm.addControl('min', new FormControl(null, [Validators.required]));
      this.lineChartForm.addControl('max', new FormControl(null, Validators.required));
      const yaxis = {
        min: Number(this.lineChartForm.get('min').value || 0),
        max: Number(this.lineChartForm.get('max').value || 100),
        labels: {
          style: this.yAxisLabelStyles,
        },
      };
      this.apexChart.updateOptions({
        yaxis,
      });
    }
  }

  manageWidgetTitle() {
    const currentWigetTitle = this.widgetTitle;
    const firstDatapointSelected = this.datapointsControls.controls[0].value;
    if (!currentWigetTitle) {
      this.lineChartForm.get('widgetTitle').setValue(firstDatapointSelected?.datapoint?.path);
    } else if (
      this.dataset.find((eachDataset) => eachDataset.path === currentWigetTitle) &&
      currentWigetTitle !== firstDatapointSelected?.datapoint?.path
    ) {
      this.lineChartForm.get('widgetTitle').setValue(firstDatapointSelected?.datapoint?.path);
    }
  }

  /* Util methods */

  splitStringOnHyphenAndGetStringAt0(stringVal: string) {
    if (!stringVal) return '';
    return stringVal.split('-')[0];
  }

  getNumberFromString(str: string) {
    return Number(str.match(/\d/g).join(''));
  }

  get widgetTitle() {
    return this.lineChartForm.get('widgetTitle').value;
  }

  onSelectedTabChange(e) {
    this.currentActiveTab = e.index;
    this.matTabGroup.selectedIndex = e.index;
    this.manageTabInkbarClass(false);
  }

  manageTabInkbarClass(shouldSwitchTab = true) {
    if (this.formSubmitted) {
      this.tabConfig.dataTab.isIconVisible =
        this.lineChartForm.get('datapoints').invalid || this.isMinMaxInvalid();

      this.tabConfig.designTab.isIconVisible =
        this.lineChartForm.get('widgetTitle').invalid ||
        this.lineChartForm.get('gridLines').invalid;

      if (shouldSwitchTab) {
        this.currentActiveTab = this.tabConfig.dataTab.isIconVisible ? 0 : 1;
      }
    }
    const selector = 'app-linechart-editor .mat-ink-bar';
    const className = 'error';
    if (this.currentActiveTab === 0) {
      if (this.tabConfig?.dataTab?.isIconVisible) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    } else {
      if (this.tabConfig?.designTab?.isIconVisible) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    }
  }

  isMinMaxInvalid() {
    const isAutoScale = this.lineChartForm.get('autoScale').value;
    if (!isAutoScale) {
      const isMinInvalid = this.lineChartForm.get('min')?.invalid;
      const isMaxInvalid = this.lineChartForm.get('max')?.invalid;
      return isMinInvalid || isMaxInvalid;
    }
    return false;
  }

  get listOfSelectDatapoint() {
    return this.datapointsControls.controls
      .map((control) => {
        return control.value.datapoint;
      })
      .filter((value) => value != null);
  }

  get yAxisLabelStyles() {
    return {
      colors: '#444444',
      fontSize: '16px',
      fontFamily: 'Roboto, Regular, sans-serif',
      fontWeight: 400,
      cssClass: 'apexcharts-label',
    };
  }

  isObjectsEqual(object1, object2) {
    return isEqual(object1, object2);
  }

  legendFormatter(legendName: string) {
    const primarySelectedDatapoint = this.lineChartForm.get('gridLines')?.value;
    if (primarySelectedDatapoint === legendName) {
      return `<span style="font-weight: bold !important">${legendName} * </span>`;
    } else {
      return `<span>${legendName} </span>`;
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
