import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.scss'],
})
export class EditWidgetComponent implements OnInit {
  type: string;
  id: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.route.queryParams.subscribe((p) => {
      this.type = p.type;
      this.id = p.id;
    });
  }
}
