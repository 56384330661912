import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-user-mgmt-subheader',
  templateUrl: './user-mgmt-subheader.component.html',
  styleUrls: ['./user-mgmt-subheader.component.scss'],
})
export class UserMgmtSubheaderComponent implements OnInit {
  @Input() pageType;
  @Input() unsaved;
  dialogRef: any;
  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {}

  redirect(): any {
    if (this.unsaved === true) {
      this.dialogRef = this.dialog.open(GenericDialogComponent, {
        height: '278px',
        width: '500px',
        data: { id: '', modalType: 'user-unsaved' },
      });
    } else {
      this.router.navigate(['main', 'users']);
    }
  }
}
