import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { MeshServiceProvidor } from '../service/mesh/mesh.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  deviceType;
  constructor(
    private router: Router,
    private _resolutionResolver: ResolutionResolverService,
    private meshServiceProvidor: MeshServiceProvidor
  ) {
    this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }

  ngOnInit(): void {}
  login(): any {
    this.router.navigate(['login']);
  }
}
