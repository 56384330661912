import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @Input() toolTipTitle!: string;
  @Input() customClass = '';
  @Input() positionTip!: { left: number; top: number };
  elToolTip!: HTMLElement;
  holdPopup = false;
  private readonly mainClass = 'tooltip';
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.positionTip = {
      left: 0,
      top: 0,
    };
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.elToolTip) {
      this.showtooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    setTimeout(() => {
      if (this.elToolTip && !this.holdPopup) {
        this.hidetooltip();
      }
    }, 200);
  }

  showtooltip(): void {
    if (!this.toolTipTitle) {
      return;
    }
    /******** create tooltip container *******/
    this.elToolTip = this.renderer.createElement('div');

    if (this.toolTipTitle.match(/<.*>/gi)) {
      this.renderer.setProperty(this.elToolTip, 'innerHTML', this.toolTipTitle);
      this.renderer.listen(this.elToolTip, 'mouseenter', () => {
        this.holdPopup = true;
      });
      this.renderer.listen(this.elToolTip, 'mouseleave', () => {
        this.holdPopup = false;
        this.hidetooltip();
      });
    } else {
      const text = this.renderer.createText(this.toolTipTitle);
      this.renderer.appendChild(this.elToolTip, text);
    }

    /******** append tooltip container to body tag *******/
    this.renderer.appendChild(document.body, this.elToolTip);
    this.renderer.addClass(this.elToolTip, this.mainClass);
    if (this.customClass) this.renderer.addClass(this.elToolTip, this.customClass);

    /******** element position *******/
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const top = hostPos.y + this.positionTip.top;
    const left = hostPos.x + this.positionTip.left;

    this.renderer.setStyle(this.elToolTip, 'top', `${top}px`);
    this.renderer.setStyle(this.elToolTip, 'left', `${left}px`);
    this.renderer.setStyle(this.elToolTip, 'z-index', '5');
  }

  hidetooltip(): void {
    this.renderer.removeClass(this.elToolTip, this.mainClass);
    this.renderer.removeChild(document.body, this.elToolTip);
    (this.elToolTip as unknown) = null;
  }
}
