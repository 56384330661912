import { Component, EventEmitter, Output } from '@angular/core';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';

export type GraphType = 'singleYAxis' | 'dualYAxis';

@Component({
  selector: 'app-select-linechart-type',
  templateUrl: './select-linechart-type.component.html',
  styleUrls: ['./select-linechart-type.component.scss'],
})
export class SelectLinechartTypeComponent {
  @Output() selectedGraphType = new EventEmitter<GraphType>();
  constructor(private ws: WidgetsService) {
    this.ws.selectedGraphType = undefined;
  }

  onGraphTypeSelected(type: GraphType) {
    this.selectedGraphType.emit(type);
    this.ws.selectedGraphType = type;
  }
}
