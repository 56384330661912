<div class="container add-edit-numerical" fxLayout="row" fxFlex="100%">
  <div class="inp-container" fxFlex="38%" fxLayout="column">
    <form [formGroup]="registrationForm">
      <div fxLayout="column">
        <div class="left-margin data-top-margin">
          <!-- <mat-form-field class="dropdown-field "> -->
          <div class="label-margin">
            <mat-label class="label-clr">{{
              "common.data-point" | translate
            }}</mat-label>
          </div>

          <div>
            <mat-select
              [compareWith]="comparedatapointObjects"
              class="dropdown-binary"
              [ngClass]="{
                'dropdown-binary-error':
                  !registrationForm.valid &&
                  submitted &&
                  registrationForm.controls['selectedValue']?.errors?.required
              }"
              formControlName="selectedValue"
              placeholder="{{ 'placeholder_messages.datapoint' | translate }}"
            >
              <mat-option *ngFor="let data of dataset" [value]="data.path">
                {{ data.path }}
              </mat-option>
            </mat-select>
          </div>
          <!-- </mat-form-field> -->
          <div
            class="invalid-feedback-drop"
            *ngIf="
              !registrationForm.valid &&
              submitted &&
              registrationForm.controls['selectedValue']?.errors?.required
            "
          >
            <span>*</span>{{ "message.valid-data-point" | translate }}
          </div>
        </div>
      </div>

      <div class="left-margin top-margin main-top-margin">
        <div class="label-margin">
          <label class="label-clr">{{ "common.units" | translate }}</label>
        </div>
        <div
          class="example-full-width-numerical auto-main"
          [ngClass]="{
            'example-full-width-error':
              !registrationForm.valid &&
              submitted &&
              registrationForm.controls['stateCtrl']?.errors?.required
          }"
        >
          <!-- <mat-form-field class="example-full-width"> -->

          <div fxFlex="100%" fxLayout="row">
            <input
              class="auto-input auto-inp-pos"
              matInput
              placeholder="{{ 'placeholder_messages.unit' | translate }}"
              aria-label="State"
              id="plain"
              [matAutocomplete]="auto"
              formControlName="stateCtrl"
            />
            <mat-icon
              class="status-dropdown drop-align auto-icon-pos-numerical"
              [ngStyle]="{ color: '#404040' }"
              >arrow_drop_down</mat-icon
            >
          </div>
          <mat-autocomplete class="auto-container-pos" #auto="matAutocomplete">
            <mat-option
              *ngFor="let state of filteredStates | async"
              [value]="state.name"
            >
              <span>{{ state.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- </mat-form-field> -->
        <div
          class="invalid-feedback-auto"
          *ngIf="
            !registrationForm.valid &&
            submitted &&
            registrationForm.controls['stateCtrl']?.errors?.required
          "
        >
          <span>*</span>{{ "message.valid-units" | translate }}
        </div>
      </div>
    </form>
    <div class="button-container">
      <button
        class="btn secondary-btn"
        (click)="onCancel()"
        mat-flat-button
        data-test="action_cancel"
        type="button"
      >
        {{ "action.cancel" | translate }}
      </button>
      <button
        *ngIf="type == 'edit'"
        class="btn secondary-btn"
        mat-flat-button
        data-test="action_delete"
        (click)="delete()"
        type="button"
      >
        {{ "action.delete" | translate }}
      </button>
      <button
        class="btn primary-btn"
        mat-flat-button
        data-test="action_save_widget"
        (click)="onSubmit()"
      >
        {{ "action.save-widget" | translate }}
      </button>
    </div>
  </div>
  <div fxFlex="62%" fxLayoutAlign="center" class="view-container top-margin">
    <div class="numnerical-container">
      <div
        *ngIf="title !== ''"
        class="preview-left-margin preview-notitle truncate"
        matTooltipClass="tooltip-hover"
        [matTooltip]="title"
      >
        {{ title }}
      </div>

      <div
        *ngIf="title == ''"
        class="preview-left-margin preview-title truncate"
        matTooltipClass="tooltip-hover"
        [matTooltip]="title"
      >
        {{ "common.widget-title" | translate }}
      </div>

      <div class="preview-left-margin preview-content">{{ defaultValue }}</div>
      <div class="preview-left-margin preview-footer">{{ defaultTime }}</div>
    </div>
  </div>
</div>
