import { Component, OnDestroy, OnInit } from '@angular/core';
import { MeshServiceProvidor } from './service/mesh/mesh.service';
import { LocalizationService } from './shared/services/localization/localization.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UrlService } from './shared/services/url/url.service';
import { ResolutionResolverService } from './shared/services/resolution-resolver/resolution-resolver.service';
import { Subscription } from 'rxjs';
import { ToasterData } from './shared/constants/constants';
import { CommonService } from './shared/services/common/common.service';
import { version } from '../../package.json';
declare let location: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  public version: string = version;
  subscriber: Subscription[] = [];
  title = 'fieldveu';
  previousUrl: string;
  currentUrl: string;
  StorageListener: any;
  cookieCreate = false;
  constructor(
    private meshServiceProvidor: MeshServiceProvidor,
    private localizated: LocalizationService,
    private router: Router,
    private urlService: UrlService,
    private _resolutionResolver: ResolutionResolverService,
    private cs: CommonService
  ) {
    this.localizated.initService();
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {});
    this.subscriber.push(sub0);
    this.StorageListener = this.checkStorage.bind(this);
    window.addEventListener('storage', this.StorageListener);
    window.addEventListener('beforeunload', (event) => {
      localStorage.clear();
    });
  }

  ngOnInit() {
    console.log('Version is', version);
    const sub1 = this.meshServiceProvidor.logOutObserver.subscribe((x) => {
      this.cookieCreate = false;
      this.router.navigate(['logout']);
    });
    this.subscriber.push(sub1);

    const sub2 = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      });
    this.subscriber.push(sub2);

    const sub3 = this.meshServiceProvidor.loginObserver.subscribe((x) => {
      this.cookieCreate = true;
    });

    this.subscriber.push(sub3);
  }

  checkStorage(event): void {
    if (event.key === 'role' && event.newValue != null) {
      if (this.cookieCreate) {
        this.meshServiceProvidor.reloadApp();
        this.cookieCreate = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriber.forEach((sub) => sub.unsubscribe());
    window.removeEventListener('storage', this.StorageListener);
  }
}
