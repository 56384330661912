<app-ce-subheader></app-ce-subheader>
<div class="widget-container">
  <div class="breadcrumb-line">
    <span *ngIf="handleChange && activeWidget === 'line_chart' && !!ws?.selectedGraphType">
      <span class="a-link" (click)="backToSelectPage()">Line Chart</span> /
      {{ ws.selectedGraphType }}
    </span>
  </div>
  <div class="widget-list">
    <div
      class="widget-tab"
      *ngFor="let widget of widgetList"
      [label]="widget.name"
      [ngClass]="{ active: widget.isActive }"
      (click)="selectTab($event, widget)"
      [attr.data-test]="'action_' + widget.name"
    >
      <div class="tab-header">
        <div class="tab-header-img">
          <img
            [src]="'../../../../assets/images/widgets/' + widget.name + '.svg'"
            *ngIf="!widget.isActive"
          />
          <img
            [src]="'../../../../assets/images/widgets/' + widget.name + '_active.svg'"
            *ngIf="widget.isActive"
          />
        </div>
        <p class="tab-header-name">
          {{ 'common.' + widget.name | translate }}
        </p>
        <div class="active-tab-bar" *ngIf="widget.isActive"></div>
      </div>
    </div>
  </div>

  <div
    [id]="widget.name"
    [hidden]="!widget.isActive"
    class="widget-content-container"
    *ngFor="let widget of widgetList"
    [attr.data-test]="'content_' + widget.name"
  >
    <app-addedit-devicestatus
      *ngIf="widget.name == 'device_status'"
      [type]="'add'"
    ></app-addedit-devicestatus>
    <app-addedit-linechart-v2 *ngIf="widget.name == 'line_chart'"> </app-addedit-linechart-v2>
    <app-addedit-binary *ngIf="widget.name == 'binary'" [type]="'add'"></app-addedit-binary>
    <app-addedit-numerical
      *ngIf="widget.name == 'numeric_kpi'"
      [type]="'add'"
    ></app-addedit-numerical>
    <app-addedit-text *ngIf="widget.name == 'text_kpi'" [type]="'add'"></app-addedit-text>
    <app-addedit-blankspace
      *ngIf="widget.name == 'blank_space'"
      [type]="'add'"
    ></app-addedit-blankspace>
    <app-addedit-gauge *ngIf="widget.name == 'gauge'" [type]="'add'"></app-addedit-gauge>
    <app-add-combination-chart
      *ngIf="widget.name == 'combination_chart'"
    ></app-add-combination-chart>
  </div>
  <div class="default-tab" *ngIf="checkIsArrayState(widgetList)">
    <p data-test="text_select_widget">
      {{ 'message.select-widget' | translate }}
    </p>
  </div>
</div>
