import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DetectBrowserService {
  constructor() {}
  checkBrowser() {
    if (navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      return 'IE';
    } else if (
      navigator.userAgent.indexOf('edge') !== -1 ||
      navigator.userAgent.indexOf('edg') !== -1 ||
      navigator.userAgent.indexOf('Edg') !== -1 ||
      navigator.userAgent.indexOf('Edge') !== -1
    ) {
      return 'edge';
    } else if (
      navigator.userAgent.indexOf('Netscape') !== -1 ||
      navigator.userAgent.indexOf('netscape') !== -1
    ) {
      return 'Netscape';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }
}
