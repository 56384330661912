import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { DashEventService } from '../device-dashboard/services/dashboard-event/dash-event.service';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  notificationPoints: any;
  dashboardInfo: any;
  deviceType: any;
  originalObject: unknown;
  unsavedChanges = false;
  userDetails: any;
  notificationMethods: any;
  no_notification_delivery = true;
  sentRequest = false;

  constructor(
    private location: Location,
    private _dasheventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService,
    private _meshService: MeshServiceProvidor,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cs: CommonService
  ) {
    this._meshService.getUserDetails();
  }

  ngOnInit(): void {
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });

    this.getUserInfo();

    this.route.queryParams.subscribe((params) => {
      this.dashboardInfo = !!params
        ? params
        : !!this._dasheventService.notifSettingsInfo
        ? this._dasheventService.notifSettingsInfo
        : {};
      if (this.dashboardInfo) {
        if (
          !!this._dasheventService.notificationSettings &&
          this._dasheventService.notificationSettings !== null
        ) {
          this.originalObject = {
            ...this._dasheventService.notificationSettings.originalPoints,
          };
          this.notificationPoints = {
            ...this._dasheventService.notificationSettings.updatedPoints,
          };
          this.checkforUnsavedChanged();
        } else {
          const options = {
            profileInstanceID: this.dashboardInfo.profileInstanceID,
          };
          this._meshService
            .getNotificationSettings(options)
            .then((res) => {
              this.originalObject = res;
              this.notificationPoints = JSON.parse(JSON.stringify(res));
            })
            .catch((err) => {
              const data: ToasterData = {
                type: 'error-custom',
                message: err.toString(),
              };
              this.cs.openToaster(data);
            });
        }
      }
    });
  }

  async getUserInfo() {
    const that = this;
    this._meshService.userDetails$.subscribe((userDetail) => {
      setTimeout(() => {
        that.userDetails = userDetail;
        if (!!that.userDetails && that.userDetails.notificationMethods) {
          that.checkIfEmailSMS_selected(that.userDetails.notificationMethods);
          that.checkforUnsavedChanged();
        }
      }, 1000);
    });
  }

  checkIfEmailSMS_selected(notificationMethods) {
    this.no_notification_delivery =
      notificationMethods.email === false && notificationMethods.sms === false ? true : false;
  }

  datapointsset(ev, key) {
    this.notificationPoints[key] = ev.checked;
    this.checkforUnsavedChanged();
  }

  checkforUnsavedChanged() {
    this.unsavedChanges =
      !_.isEqual(this.originalObject, this.notificationPoints) && !this.no_notification_delivery
        ? true
        : false;
  }

  cancel() {
    if (this.unsavedChanges) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        height: 'auto',
        width: '500px',
        data: {
          modalType: 'unsaved-changes-notif',
        },
      });
    } else {
      this._dasheventService.notificationSettings = null;
      this.location.back();
    }
  }

  gotoProfile() {
    this._dasheventService.notificationSettings = {
      updatedPoints: this.notificationPoints,
      originalPoints: this.originalObject,
    };
    this.router.navigate(['main', 'profile']);
  }
  save() {
    if (this.unsavedChanges) {
      const options = {
        profileInstanceID: this.dashboardInfo.profileInstanceID,
        dashboardInstanceID: this.dashboardInfo.dashboardInstanceID,
        selected: this.notificationPoints,
      };
      this.sentRequest = true;
      this._meshService
        .setNotificationSettings(options)
        .then((res) => {
          this.unsavedChanges = false;
          const data: ToasterData = {
            type: 'success',
            message: 'notification-saved',
          };
          this.sentRequest = false;
          this._dasheventService.notificationSettings = null;
          this.cs.openToaster(data);
          this.location.back();
        })
        .catch((err) => {
          this.sentRequest = false;

          const data: ToasterData = {
            type: 'error-custom',
            message: err.toString(),
          };
          this.cs.openToaster(data);
        });
    }
  }
}
