<div fxLayout="row" fxLayoutAlign="space-between" class="template-info">
  <div class="textbox-align top-align" fxLayout="column">
    <label class="templateName">{{ "common.template_name" | translate }}</label>
    <span class="top-margin-align-6"
      ><input
        class="templateName-inp"
        type="text"
        (blur)="changeQuery()"
        [(ngModel)]="templateName"
    /></span>
  </div>

  <div fxLayout="row">
    <div class="sub-icon-align-ce" fxLayout="row">
      <div class="icon-align-ce top-align" fxLayout="column">
        <div>
          <label class="modelnumber">{{
            "common.profileName" | translate
          }}</label>
        </div>
        <div class="top-margin-align">
          <label class="modelnumber">{{
            "common.min_profile" | translate
          }}</label>
        </div>
      </div>
      <div class="top-align" fxLayout="column">
        <div>
          <label>{{ model }}</label>
        </div>
        <div class="top-margin-align">
          <span>{{ version }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
