<mat-card data-test="action-hover" class="dashboard-listing-card">
  <div fxLayout="column" fxLayoutGap="20px">
    <div class="dashboard-image">
      <img
        *ngIf="templateInfo?.dashboardConfig?.iconType == undefined"
        [src]="imagePath || fallbackImagePath"
        alt="boiler"
      />

      <img
        *ngIf="templateInfo?.dashboardConfig?.iconType == 'icon'"
        [src]="iconUrl + imagePath"
        alt="boiler"
      />

      <img
        *ngIf="templateInfo?.dashboardConfig?.iconType == 'image'"
        [src]="imageUrl + imagePath"
        alt="boiler"
      />
      <ng-template [ngTemplateOutlet]="actionBtn"></ng-template>
    </div>

    <div class="dashboard-detail">
      <div
        *ngIf="!templateInfo?.published"
        class="detail-draft"
        appStatusStyle="draft"
      >
        {{ "common.draft" | translate }}
      </div>
      <div class="detail-header">
        {{ templateInfo?.name || "Unknown" }}
      </div>
      <div *ngIf="templateInfo?.published" class="detail-pusblish-date">
        {{
          templateInfo?.publishDate
            | dateformat: "MMM DD,  YYYY" || "01 Jan, 1995"
        }}
      </div>
      <div class="detail-list">
        <ng-template
          [ngTemplateOutlet]="detailListItem"
          [ngTemplateOutletContext]="{
            key: 'Model Number',
            value: templateInfo?.deviceProfileName
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="detailListItem"
          [ngTemplateOutletContext]="{
            key: 'Min profile version',
            value: templateInfo?.minimumVersion
          }"
        ></ng-template>
      </div>
    </div>
  </div>
</mat-card>

<!-- #################### ACTION BUTTON #################################### -->
<!-- #################### ONLY VISIBLE ON HOVER ############################ -->
<ng-template #actionBtn>
  <div class="dl-action-btn" [ngClass]="{ onhover: showBtnOnHover }">
    <div fxLayout="row" fxLayoutGap="10px">
      <ng-template
        *ngFor="let button of buttonConfig"
        [ngTemplateOutlet]="actionButtonEl"
        [ngTemplateOutletContext]="{
          value: button.name,
          icon: button.icon,
          customClass: button.type
        }"
      ></ng-template>
    </div>
  </div>
</ng-template>

<!-- ########################## DETAIL LIST ITEM UI ###################################### -->
<ng-template #detailListItem let-key="key" let-value="value">
  <div class="detail-list-item">
    <div fxFlex="50%" class="detail-list-item-key">{{ key }}</div>
    <div fxFlex="50%" class="detail-list-item-value">
      {{ value }}
    </div>
  </div>
</ng-template>

<!-- ############################# Button UI ##################################### -->

<ng-template
  #actionButtonEl
  let-icon="icon"
  let-value="value"
  let-customClass="customClass"
>
  <button
    (click)="userClickEventEmitter(value)"
    [class]="customClass"
    data-test="action_edit"
    mat-flat-button
  >
    <mat-icon>{{ icon }}</mat-icon>
    {{ value }}
  </button>
</ng-template>
