import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fakeTempData } from 'src/app/shared/constants/ce-templateData';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  public templdataUpdated = new BehaviorSubject(false);
  private flowPath: string;

  private templateData = !!JSON.parse(sessionStorage.getItem('tempData'))
    ? JSON.parse(sessionStorage.getItem('tempData'))
    : fakeTempData;
  constructor() {}

  get flow() {
    return this.flowPath;
  }

  set flow(data: any) {
    this.flowPath = data;
  }

  get tempData() {
    return this.templateData;
  }

  set tempData(data: any) {
    this.templateData = [...data];
    this.templdataUpdated.next(true);
  }

  getEditData(id) {
    return this.templateData.filter((p) => id === p.widgetID);
  }
}
