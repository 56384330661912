<div class="empty">
  <img class="info-graphic" src="../../../assets/images/notfound.svg" />
  <p class="info-message1">
    {{ "message.not-found-title" | translate }}
  </p>
  <p class="info-message2">
    {{ "message.not-found-subtitle" | translate }}
  </p>
  <p class="info-message2">
    {{ "message.not-found-subtitle2" | translate }}
  </p>
</div>
