<span
  *ngIf="
    ((device == 'TabletPortrait' ||
      device == 'TabletLandscape' ||
      device === 'MobileLandscape') &&
      (type == 'line-chart' || type == 'multiseries-chart')) ||
    ((device == 'WebLandscape' || device == 'WebPortrait') &&
      (type == 'combination-chart' ||
        type == 'line-chart' ||
        type == 'multiseries-chart')) ||
    isDialog == true
  "
>
  <button
    class="today"
    type="button"
    mat-button
    [ngClass]="
      (get_historicalGraphState | async)?.type !== 'today'
        ? 'inactive'
        : 'active'
    "
    (click)="getdateRange('today')"
  >
    {{ "temporal.today" | translate }}
  </button>
  <button
    type="button"
    class="week"
    mat-button
    [ngClass]="
      (get_historicalGraphState | async)?.type !== 'week'
        ? 'inactive'
        : 'active'
    "
    (click)="getdateRange('week')"
  >
    {{ "temporal.week" | translate }}
  </button>
  <button
    type="button"
    class="month"
    mat-button
    [ngClass]="
      (get_historicalGraphState | async)?.type !== 'month'
        ? 'inactive'
        : 'active'
    "
    (click)="getdateRange('month')"
  >
    {{ "temporal.month" | translate }}
  </button>
</span>

<div
  *ngIf="
    ((device == 'TabletPortrait' ||
      device == 'TabletLandscape' ||
      device == 'MobilePortrait' ||
      device == 'MobileLandscape') &&
      type == 'combination-chart' &&
      isDialog == false) ||
    (device == 'MobilePortrait' &&
      (type == 'line-chart' || type == 'multiseries-chart') &&
      isDialog == false)
  "
>
  <img
    src="../../../../assets/images/options.svg"
    [matMenuTriggerFor]="periodMenu"
  />
  <mat-menu
    class="period-menu"
    #periodMenu="matMenu"
    backdropClass="has-up-arrow-status"
  >
    <div fxLayout="column">
      <button
        class="today"
        type="button"
        mat-button
        [ngClass]="
          (get_historicalGraphState | async)?.type !== 'today'
            ? 'inactive'
            : 'active'
        "
        (click)="getdateRange('today')"
      >
        {{ "temporal.today" | translate }}
      </button>
      <button
        type="button"
        class="week"
        mat-button
        [ngClass]="
          (get_historicalGraphState | async)?.type !== 'week'
            ? 'inactive'
            : 'active'
        "
        (click)="getdateRange('week')"
      >
        {{ "temporal.week" | translate }}
      </button>
      <button
        type="button"
        class="month"
        mat-button
        [ngClass]="
          (get_historicalGraphState | async)?.type !== 'month'
            ? 'inactive'
            : 'active'
        "
        (click)="getdateRange('month')"
      >
        {{ "temporal.month" | translate }}
      </button>
    </div>
  </mat-menu>
</div>
