import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
})
export class UnsupportedBrowserComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
