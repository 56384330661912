<mat-sidenav-container
  *ngIf="
    deviceType == 'TabletPortrait' ||
    deviceType == 'TabletLandscape' ||
    deviceType == 'WebPortrait' ||
    deviceType == 'WebLandscape'
  "
  autosize="true"
  class="sidenav-container"
  (backdropClick)="close()"
>
  <mat-sidenav
    disableClose
    class="sidenav-main"
    opened="true"
    #sidenav
    [mode]="
      (deviceType == 'MobilePortrait' ||
        deviceType == 'MobileLandscape' ||
        deviceType == 'TabletPortrait' ||
        deviceType == 'TabletLandscape') &&
      isExpanded
        ? 'over'
        : 'side'
    "
  >
    <mat-nav-list class="list-item">
      <mat-list-item
        *ngIf="userRole == userRoleConfig?.CE"
        [ngClass]="isExpanded ? 'list-item-openmenu' : 'list-item-closemenu'"
        [routerLink]="['/', 'main', 'template']"
        (click)="navigateTo('other', 'template')"
        routerLinkActive="active"
        data-test="action_devices"
        [matTooltip]="'common.template' | translate"
        matTooltipClass="customtooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/template.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.template' | translate
          }}</a>
        </div>
      </mat-list-item>
      <mat-list-item
        [ngClass]="isExpanded ? 'list-item-openmenu' : 'list-item-closemenu'"
        [routerLink]="['/', 'main', 'device']"
        (click)="navigateTo('other', 'device')"
        routerLinkActive="active"
        data-test="action_devices"
        [matTooltip]="'common.devices' | translate"
        matTooltipClass="customtooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-insert-chart.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.devices' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        [ngClass]="isExpanded ? 'list-item-openmenu' : 'list-item-closemenu'"
        data-test="action_event_log"
        [matTooltip]="'common.event-log' | translate"
        matTooltipClass="customtooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
        [routerLink]="['/', 'main', 'eventlog']"
        (click)="redirectTo(['/', 'main', 'eventlog'])"
        routerLinkActive="active"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-history.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.event-log' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        *ngIf="userRole != userRoleConfig?.PM"
        [ngClass]="isExpanded ? 'list-item-openmenu' : 'list-item-closemenu'"
        data-test="action_user_management"
        routerLinkActive="active"
        [routerLink]="['/', 'main', 'users']"
        (click)="redirectTo(['/', 'main', 'users'])"
        [matTooltip]="'common.user-management' | translate"
        matTooltipClass="customtooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-people.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.user-management' | translate
          }}</a>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content
    cdkScrollable
    [ngClass]="
      isExpanded && sidenav.mode == 'side'
        ? 'sidenav-content'
        : 'sidenav-content-closed'
    "
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav-container
  *ngIf="deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape'"
  autosize="true"
  class="sidenav-container-mobile"
  (backdropClick)="closeMobile()"
>
  <mat-sidenav class="sidenav-main-mobile" #sidenav [mode]="over">
    <mat-nav-list class="list-item">
      <mat-list-item
        class="mobile name-arrow"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        data-test="action_username"
      >
        <div class="mobile-username">
          {{ userDetails?.username | usernameFormat }}
        </div>
        <!-- <img
          class="mobile-username-icon"
          src="../../../assets/images/ic-chevron-right.svg"
          alt="Dropdown icon"
        /> -->
      </mat-list-item>
      <mat-list-item
        *ngIf="userRole == userRoleConfig?.CE"
        class="mobile name-arrow"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        [routerLink]="['/', 'main', 'template']"
        (click)="navigateTo('mobile', 'template')"
        routerLinkActive="active"
        data-test="action_dashboard_template"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/template.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.template' | translate
          }}</a>
        </div>
      </mat-list-item>
      <mat-list-item
        class="mobile"
        [ngClass]="isExpanded ? ' 
        ' : 'list-item-closemenu-mobile'"
        [routerLink]="['/', 'main', 'device']"
        (click)="navigateTo('mobile', 'device')"
        routerLinkActive="active"
        data-test="action_devices_menu"
        matTooltip="devices"
        matTooltipClass="customtooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-insert-chart.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            'common.devices' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        class="mobile"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        [routerLink]="['/', 'main', 'eventlog']"
        (click)="navigateTo('mobile', 'eventlog')"
        data-test="action_event_log_mobile"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-history.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            "common.event-log" | translate
          }}</a>
        </div>
      </mat-list-item>

      <!-- <mat-list-item
        class="mobile"
        *ngIf="userRole == userRoleConfig?.SE || userRole == userRoleConfig?.CE"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        data-test="action_user_management_mobile"
      >
        <div>
          <img
            class="margin-icon-menu"
            src="../../../assets/images/ic-people.svg"
            alt="Dropdown icon"
          />
        </div>
        <div>
          <a class="side-btn" *ngIf="isExpanded" matLine>{{
            "common.user-management" | translate
          }}</a>
        </div>
      </mat-list-item> -->
      <!-- <mat-list-item
        class="mobile noBorder"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        data-test="action_help_menu"
      >
        <div>
          <a class="side-btn-others" *ngIf="isExpanded" matLine>{{
            "x-refer.help" | translate
          }}</a>
        </div>
      </mat-list-item> -->
      <mat-list-item
        (click)="goTo('terms')"
        class="mobile noBorder"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        data-test="action_tc_menu"
      >
        <div>
          <a class="side-btn-others" *ngIf="isExpanded" matLine>{{
            'x-refer.tc' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        (click)="goTo('privacy')"
        class="mobile noBorder"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        data-test="action_privacy_menu"
      >
        <div>
          <a class="side-btn-others" *ngIf="isExpanded" matLine>{{
            'x-refer.privacy' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        class="mobile noBorder"
        [ngClass]="
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile'
        "
        (click)="logout()"
        data-test="action_logout_menu"
      >
        <div class="logout-display-mobile">
          <mat-icon [ngStyle]="{ color: 'white' }" matListIcon>logout</mat-icon>
          <a class="side-btn logout-mobile" *ngIf="isExpanded" matLine>{{
            'action.logout' | translate
          }}</a>
        </div>
      </mat-list-item>

      <mat-list-item
        class="mobile customBorder"
        [ngClass]="[
          isExpanded ? 'list-item-openmenu' : 'list-item-closemenu-mobile',
          deviceType == 'MobileLandscape' ? 'customItemLand' : 'customItem'
        ]"
      >
        <div class="whitebox">
          <ng-container *ngIf="logoData; else noLogo">
            <img
              [src]="logoData.logoPath ? logoSource + logoData.logoPath : ''"
              alt=""
            />
          </ng-container>
          <ng-template #noLogo>
            <div class="logo">OEM logo</div>
          </ng-template>
          <span class="mobile-title">- &nbsp;FieldVEU</span>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  {{ sidenav.mode }}
  <mat-sidenav-content
    class="sidenav-mobile"
    style="width: 100vw; height: 100%"
    (scroll)="onScroll($event)"
    [ngClass]="
      isExpanded && sidenav.mode == 'over'
        ? 'sidenav-content'
        : 'sidenav-content-closed-mobile'
    "
    cdkScrollable
  >
    <div
      *ngIf="(currentUrl === '/main/device' || isEventPage ) && _dashEventService.headerState"
      class="nav"
      [class.hide-nav]="hideNav"
    >
      <app-header></app-header>
    </div>
    <div
      *ngIf="
        currentUrl.includes('/main/device/') ||
        currentUrl.includes('/main/profile')
      "
      class="nav"
      [class.hide-nav]="hideNav"
    >
      <app-subheader></app-subheader>
    </div>
    <div class="mobile-rooter-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
