import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { userRoleConfig, ToasterData } from 'src/app/shared/constants/constants';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { Router } from '@angular/router';
import { DevicesComponent } from '../../devices/devices.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import * as _ from 'lodash';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  @ViewChild(DevicesComponent) childComponent: DevicesComponent;
  userDetails;
  roles = _.toArray(userRoleConfig);
  userRoleList = userRoleConfig;
  userRole = localStorage.getItem('role');
  uncheckDevices: any[] = [];
  selectedUserRole = '';

  unsaved = false;

  constructor(
    public _meshService: MeshServiceProvidor,
    private router: Router,
    private cs: CommonService,
    public dialog: MatDialog
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('selectedUser'));
    if (this.userDetails?.fieldveuRole === undefined) {
      this.selectedUserRole = '';
    } else {
      this.selectedUserRole = this.userDetails?.fieldveuRole;
    }
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    sessionStorage.removeItem('selectedUser');
  }
  uncheckedDevices(devices): any {
    this.uncheckDevices = [...devices];
    this.unsaved =
      this.uncheckDevices.length > 0 ||
      (this.selectedUserRole !== '' && this.selectedUserRole !== this.userDetails?.fieldveuRole)
        ? true
        : false;
  }
  resetTable(): any {
    this.childComponent.reset();
  }
  save(): any {
    if (this.uncheckDevices.length > 0) {
      const options = {
        username: this.userDetails?.email,
        profileInstanceIDs: this.uncheckDevices,
      };
      this._meshService
        .revokeProfileInstanceAccess(options)
        .then((res) => {
          const data: ToasterData = {
            type: 'success',
            message: 'userUpdated',
          };

          if (
            this.selectedUserRole === '' ||
            this.selectedUserRole === this.userDetails?.fieldveuRole
          ) {
            this.cs.openToaster(data);
            this.router.navigate(['main', 'users']);
          }
        })
        .catch((err) => {
          const data: ToasterData = {
            type: 'error',
            message: 'userUpdated',
          };
          this.cs.openToaster(data);
        });
    }
    if (this.selectedUserRole !== '' && this.selectedUserRole !== this.userDetails?.fieldveuRole) {
      this.updateUserRole();
    }
  }

  checkforRole(event): any {
    if (
      (this.selectedUserRole !== '' && this.selectedUserRole !== this.userDetails?.fieldveuRole) ||
      (!!this.uncheckDevices && this.uncheckDevices.length > 0)
    ) {
      this.unsaved = true;
    } else {
      this.unsaved = false;
    }
  }

  updateUserRole(): any {
    const options = {
      username: this.userDetails?.email,
      fieldveuRole: this.selectedUserRole,
    };
    this._meshService
      .updateUserRole(options)
      .then((res) => {
        const data: ToasterData = {
          type: 'success',
          message: 'userUpdated',
        };

        this.cs.openToaster(data);
        this.router.navigate(['main', 'users']);
      })
      .catch((err) => {
        const data: ToasterData = {
          type: 'error',
          message: 'userUpdated',
        };
        this.cs.openToaster(data);
      });
  }

  deleteUser(): any {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.userDetails, modalType: 'delete-user' },
    });
  }

  cancel(): any {
    if (this.unsaved === true) {
      this.dialog.open(GenericDialogComponent, {
        height: '278px',
        width: '500px',
        data: { id: '', modalType: 'user-unsaved' },
      });
    } else {
      this.router.navigate(['main', 'users']);
    }
  }
}
