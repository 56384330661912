export class GaugeModel {
  constructor(
    public canvasWidth: number,
    public needleValue: number,
    public centralLabel: string,
    public name: string,
    public bottomLabel: string,
    public bottomLabelFont: number,
    public options: {
      hasNeedle: boolean;
      needleColor: string;
      needleUpdateSpeed: number;
      arcColors: Array<string>;
      arcDelimiters: Array<number>;
      arcLabels: Array<string>;
      rangeLabel: Array<string>;
      arcOverEffect: boolean;
      rangeLabelFontSize: number;
      arcPadding: number;
      needleStartValue: number;
    }
  ) {}
}

export class CombinationModel {
  readonly name = 'combination-chart';
  public readonly type = this.name;
  public readonly height = 2;
  public readonly width = 3;
  private readonly _widgetID = `${this.name}${Math.floor(Math.random() * 1000000)}`;

  constructor(
    public dataPath: string,
    public title: string,
    public autoscale: boolean,
    public color: string,
    public colors: Array<any>,
    public min: number,
    public max: number,
    public gaugeData: any,
    public arcColors: Array<string>,
    public arcDelimiters: Array<number>,
    public arcLabels: Array<string>,
    public rangeLabel: any,
    public units: string,
    public gaugeConfig: {
      config: any;
      needleValue: number;
      bottomLabel: string;
      units: string;
    },
    public widgetID?: string
  ) {
    this.widgetID = this._widgetID;
  }
}
