<div class="empty">
  <img class="info-graphic" src="../../../assets/images/inviation-expiry.svg" />
  <div class="infomsg-block">
    <p class="info-message1">
      {{ "message.invitation-expiry-title" | translate }}
    </p>
    <p class="info-message2">
      {{ "message.invitation-expiry-subtitle" | translate }}
    </p>
    <p class="info-message2">
      {{ "message.invitation-expiry-subtitle1" | translate }}
    </p>
    <p class="info-message2">
      {{ "message.invitation-expiry-subtitle2" | translate }}
    </p>
  </div>
</div>
