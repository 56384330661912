import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import * as moment from 'moment';
import { ceData } from '../../../shared/constants/ce-data';
import { TemplateService } from 'src/app/service/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import * as _ from 'lodash';
import { addClass, removeClass } from 'src/app/shared/class/util';
import { DashEventService } from '../../device-dashboard/services/dashboard-event/dash-event.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
export class State {
  constructor(public name: string) {}
}
@Component({
  selector: 'app-addedit-gauge',
  templateUrl: './addedit-gauge.component.html',
  styleUrls: ['./addedit-gauge.component.scss'],
})
export class AddeditGaugeComponent implements OnInit {
  @Input() type;
  @Input() id;
  selectedTab: any;
  dataset: any;
  reqMax = false;
  reqMin = false;
  needleVal: any;
  allValues = false;
  dataPointVal = '';
  designDat = [
    {
      from: null,
      to: null,
      range: false,
      color: ceData.gridLine.default,
      arcRanges: '',
      name: '',
    },
  ];
  filteredStates: Observable<any[]>;
  rangeLength = 0;
  units = '';
  submitted = false;

  dataTabError = false;
  designTabError = false;
  rangeError = false;
  maxError = false;
  minError = false;
  tempObj = [];
  registrationForm = this.fb.group({
    data: this.fb.group({
      dataPoint: ['', [Validators.required]],
      stateCtrl: [''],
    }),
    design: this.fb.group({
      from: [''],
      to: [''],
      color: [ceData.gridLine.needleColor],
    }),
  });
  public canvasWidth = 365;
  public needleValue = 50;
  public timeValue: any = moment().format('HH:mm:ss');

  public centralLabel = '';
  public name = 'Gauge chart';
  public bottomLabel = '128';
  public bottomLabelFont = 30;
  public options = {
    hasNeedle: true,
    needleColor: ceData.gridLine.needleColor,
    needleUpdateSpeed: 1000,
    arcColors: [ceData.gridLine.default],
    arcDelimiters: [],
    arcLabels: [''],
    rangeLabel: [],
    arcOverEffect: false,
    rangeLabelFontSize: 12,
    arcPadding: 100,
    needleStartValue: 50,
  };
  editedData = [];
  constructor(
    public fb: FormBuilder,
    private dashEvent: DashEventService,
    private router: Router,
    private ws: WidgetsService,
    private cs: CommonService,
    private _templateService: TemplateService,
    public dialog: MatDialog
  ) {
    this.filteredStates = this.registrationForm.controls.data.get('stateCtrl').valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this.filterStates(state) : ceData.unitOptions.slice()))
    );
  }
  ngOnInit(): void {
    this.selectedTab = 0;
    if (this.ws.dataPoints === undefined) {
      const dP = JSON.parse(sessionStorage.getItem('dataPoints'));
      this.dataset = _.cloneDeep(dP);
    } else {
      this.dataset = _.cloneDeep(this.ws.dataPoints);
    }

    if (this.type === 'edit') {
      let d = this._templateService.getEditData(this.id);

      const rex = _.cloneDeep(this._templateService.getEditData(this.id));
      d = rex;
      if (!!d) {
        this.editedData = d[0];

        this.registrationForm.patchValue({
          data: {
            dataPoint: (this.editedData as any).title,
            stateCtrl: (this.editedData as any).units,
          },
        });
        this.dataPointVal = (this.editedData as any).title;
        this.designDat = (this.editedData as any).gaugeConfig.config;

        if (this.designDat.length > 1) {
          const vm = this;
          this.rangeLength = this.designDat.length;

          const filterData = vm.dataset.filter(function (o) {
            return o.path === (vm.editedData as any).title;
          });

          const gaugeArr = filterData[0].ranges;

          if ('from' in gaugeArr[0] && 'to' in gaugeArr[gaugeArr.length - 1]) {
            this.allValues = true;
          }
        } else {
          this.rangeLength = 0;
          this.allValues = false;
        }
        this.options.arcLabels = (this.editedData as any).arcLabels;
        this.options.arcColors = (this.editedData as any).arcColors;
        this.options.arcDelimiters = (this.editedData as any).arcDelimiters;
        this.options.rangeLabel = (this.editedData as any).rangeLabel;

        let uom;
        if ((this.editedData as any).units.includes('-')) {
          const unit = (this.editedData as any).units.split('-');
          {
            uom = unit[0];
          }
        } else {
          uom = '';
        }
        //NEEDLE LOGIC
        this.units = uom;
        const diff =
          parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
        const needleVal =
          Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);
        this.needleVal = needleVal;
        this.needleValue =
          ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
          (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
        this.bottomLabel = '';

        if (needleVal?.toString().includes('.')) {
          this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + uom;
        } else {
          this.bottomLabel = needleVal?.toString() + ' ' + uom;
        }

        //END NEEDLE LOGIC
      }
    }
    this.registrationForm.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        if (this.registrationForm.valid) {
          this.dataTabError = false;
          this.manageTabInkbarClass();
        }

        if (value.data.dataPoint !== null && value.data.dataPoint !== this.dataPointVal) {
          this.dataPointVal = value.data.dataPoint;
          this.checkRanges(value.data.dataPoint);
        }
      });
  }
  filterStates(name: string) {
    return ceData.unitOptions.filter((state) =>
      state.name.toLowerCase().includes(name.toLowerCase())
    );
  }
  setUnits(val, tp) {
    this.units = '';
    let vale;
    let units;
    if (tp === 'sel') {
      vale = val.option.value;
    } else {
      vale = val.target.value;
    }

    if (vale.includes('-')) {
      units = vale.split('-');
      this.units = units[0];

      if (this.needleVal === undefined) {
        this.bottomLabel = '128';
        this.bottomLabel = this.bottomLabel + ' ' + units[0];
      } else {
        if (this.needleVal?.toString().includes('.')) {
          this.bottomLabel = this.needleVal?.toFixed(2).toString() + ' ' + this.units;
        } else {
          this.bottomLabel = this.needleVal?.toString() + ' ' + this.units;
        }
      }
    }
  }
  checkRanges(val) {
    const data = [];
    let filterData = [];
    this.dataset = [];
    if (this.ws.dataPoints === undefined) {
      const dP = JSON.parse(sessionStorage.getItem('dataPoints'));
      this.dataset = _.cloneDeep(dP);
    } else {
      this.dataset = _.cloneDeep(this.ws.dataPoints);
    }
    filterData = this.dataset.filter(function (o) {
      return o.path === val;
    });
    if (filterData[0] !== undefined) {
      if ('ranges' in filterData[0]) {
        this.maxError = false;
        this.minError = false;
        this.reqMin = false;
        this.reqMax = false;
        this.rangeError = false;
        this.designTabError = false;
        this.dataTabError = false;
        this.manageTabInkbarClass();
        this.options.rangeLabel = [];
        this.options.arcDelimiters = [];
        this.options.arcLabels = [];
        this.options.arcColors.push(ceData.gridLine.default);

        if (this.units !== '') {
          this.bottomLabel = '128' + ' ' + this.units;
        } else {
          this.bottomLabel = '128';
        }

        const temp = filterData[0].ranges.length ? filterData[0].ranges : [filterData[0].ranges];
        const rex = temp;

        this.designDat = [];
        this.designDat = rex;

        const len = this.designDat.length;
        for (let i = 0; i < len; i++) {
          if (this.designDat[i].name === 'Normal') {
            this.designDat[i].color = ceData.gridLine.normal;
          } else if (this.designDat[i].name === 'Warning') {
            this.designDat[i].color = ceData.gridLine.warning;
          } else if (this.designDat[i].name === 'Alarm') {
            this.designDat[i].color = ceData.gridLine.alarm;
          } else {
            this.designDat[i].color = ceData.gridLine.default;
          }
        }
        if ('from' in this.designDat[0]) {
        } else {
          this.designDat[0].from = null;
        }
        if ('to' in this.designDat[len - 1]) {
        } else {
          this.designDat[len - 1].to = null;
        }

        if (this.designDat[0].from !== null && this.designDat[len - 1].to !== null) {
          this.allValues = true;
          this.setGauge(this.designDat);
        } else {
          this.allValues = false;

          this.options.arcColors = [];
          this.options.arcColors.push(ceData.gridLine.default);
        }

        this.designDat.forEach((e) => {
          e.range = true;
        });

        this.rangeLength = this.designDat.length;
      } else {
        this.designTabError = false;
        this.dataTabError = false;
        this.manageTabInkbarClass();
        this.maxError = false;
        this.minError = false;
        this.reqMin = false;
        this.reqMax = false;
        this.rangeError = false;
        this.rangeLength = 0;
        if (this.units !== '') {
          this.bottomLabel = '128' + ' ' + this.units;
        } else {
          this.bottomLabel = '128';
        }

        this.options.arcDelimiters = [];
        this.options.arcLabels = [];
        this.options.rangeLabel = [];
        this.designDat = [];

        const temp = {
          to: null,
          from: null,
          range: false,
          color: ceData.gridLine.default,
          arcRanges: '',
          name: '',
        };

        this.designDat.push(temp);
        this.options.arcColors = [];
        this.options.arcColors = [ceData.gridLine.default];
      }
    }
  }
  onTabClickedChanged(e) {
    this.selectedTab = e.index;
    this.manageTabInkbarClass();
  }

  setFrom(e, i) {
    const len = e.target.value.length;
    let val = e.target.value;
    const arrayLength = this.designDat.length;
    if (this.designDat[i].range === true) {
      if (len > 10) {
        val = e.target.value.slice(0, 10);
      }
      this.designDat[i].from = val;
      if (len === 0) {
        this.designDat[i].from = null;
        this.reqMin = false;
        this.minError = false;
        this.designTabError = false;
        this.selectedTab = 1;
      }
      if (len > 0 && this.designDat[i].from >= this.designDat[i].to) {
        this.minError = true;
      } else {
        this.minError = false;
      }
      if (this.designDat[arrayLength - 1].to == null && this.designDat[i].from !== null) {
        this.reqMin = false;

        this.selectedTab = 1;
      } else if (this.designDat[arrayLength - 1].to !== null && this.designDat[i].from == null) {
        this.reqMax = false;

        this.selectedTab = 1;
      } else {
        this.designTabError = false;
        this.reqMax = false;
        this.reqMin = false;
      }

      if (
        len > 0 &&
        this.designDat[i].to !== null &&
        this.designDat[0].from !== null &&
        !this.minError &&
        !this.maxError &&
        !this.reqMin &&
        !this.reqMax
      ) {
        this.setGauge(this.designDat);
      }
    } else if (this.designDat[i].range === false) {
      if (len > 10) {
        val = e.target.value.slice(0, 10);
      }
      this.designDat[i].from = val.toString();
      if (len === 0) {
        this.designDat[i].from = null;
        this.selectedTab = 1;
      }
      if (len > 0 && this.designDat[i].to !== null && this.designDat[i].from !== null) {
        this.setDecimal(false, i);
        if (len > 0 && parseFloat(this.designDat[i].from) >= parseFloat(this.designDat[i].to)) {
          this.minError = true;
        } else {
          this.minError = false;
          this.maxError = false;
        }
      }
      if (this.designDat[i].to == null && this.designDat[0].from !== null) {
        this.reqMin = false;

        this.selectedTab = 1;
      } else if (this.designDat[i].to !== null && this.designDat[i].from == null) {
        this.reqMax = false;

        this.selectedTab = 1;
      } else {
        this.designTabError = false;
        this.reqMax = false;
        this.reqMin = false;
      }

      if (
        'arcRanges' in this.designDat[i] &&
        this.designDat[0].from !== null &&
        this.designDat[0].to !== null
      ) {
        if (this.designDat[i].arcRanges !== '') {
          if (parseFloat(this.designDat[0].from) !== parseFloat(this.designDat[0].to)) {
            this.setArcRanges(this.designDat[i].arcRanges, i, 'check');
          }
        } else {
          if (this.designDat[0].to !== null) {
            if (
              parseFloat(this.designDat[0].from).toFixed(2) !==
              parseFloat(this.designDat[0].to).toFixed(2)
            ) {
              //NEEDLE LOGIC
              const diff =
                parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
              const needleVal =
                Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);
              this.needleVal = needleVal;
              this.needleValue =
                ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
                (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
              this.bottomLabel = '';

              if (needleVal?.toString().includes('.')) {
                this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + this.units;
              } else {
                this.bottomLabel = needleVal?.toString() + ' ' + this.units;
              }

              //END NEEDLE LOGIC
            } else {
              this.minError = true;
            }
          }
        }
      }
    }
  }
  setTo(e, i) {
    const len = e.target.value.length;
    let val = e.target.value;
    const arrayLength = this.designDat.length;
    const boolRange = [];
    if (this.designDat[i].range === true) {
      if (len > 10) {
        val = e.target.value.slice(0, 10);
      }
      this.designDat[i].to = val;
      if (len === 0) {
        this.designDat[i].to = null;
        this.selectedTab = 1;
        this.maxError = false;
      }
      if (len > 0 && this.designDat[i].to <= this.designDat[i].from) {
        this.maxError = true;
      } else {
        this.maxError = false;
      }
      if (this.designDat[i].to == null && this.designDat[0].from !== null) {
        this.reqMin = false;

        this.selectedTab = 1;
      } else if (this.designDat[i].to !== null && this.designDat[0].from == null) {
        this.reqMax = false;

        this.selectedTab = 1;
      } else {
        this.designTabError = false;
        this.reqMax = false;
        this.reqMin = false;
      }
      if (!this.minError && !this.maxError && !this.reqMin && !this.reqMax) {
        this.setGauge(this.designDat);
      }
    } else {
      if (len > 10) {
        val = e.target.value.slice(0, 10);
      }
      this.designDat[i].to = val;
      if (len === 0) {
        this.designDat[i].to = null;
        this.selectedTab = 1;
      }

      if (len > 0 && this.designDat[i].from !== null && this.designDat[i].to !== null) {
        this.setDecimal(false, i);
        if (len > 0 && parseFloat(this.designDat[i].to) <= parseFloat(this.designDat[i].from)) {
          this.maxError = true;
        } else {
          this.maxError = false;
          this.minError = false;
        }
      } else {
        this.maxError = false;
      }
      if (this.designDat[i].to == null && this.designDat[i].from !== null) {
        this.reqMin = false;

        this.selectedTab = 1;
      } else if (this.designDat[i].to !== null && this.designDat[i].from == null) {
        this.reqMax = false;

        this.selectedTab = 1;
      } else {
        this.designTabError = false;
        this.reqMax = false;
        this.reqMin = false;
      }

      if ('arcRanges' in this.designDat[i]) {
        if (this.designDat[i].from !== null && this.designDat[i].to !== null) {
          if (this.designDat[i].arcRanges !== '') {
            const valArc = this.options.arcLabels;

            valArc.forEach((item) => {
              if (
                parseFloat(item) <= parseFloat(this.designDat[0].from.toString()) ||
                parseFloat(item) >= parseFloat(this.designDat[0].to.toString())
              ) {
                this.rangeError = true;
                boolRange.push(this.rangeError);
              } else {
                this.rangeError = false;
                boolRange.push(this.rangeError);
              }
            });
            if (boolRange.includes(true)) {
              this.rangeError = true;
            } else {
              if (
                parseFloat(this.designDat[i].from).toFixed(2) !==
                parseFloat(this.designDat[i].to).toFixed(2)
              ) {
                this.setArcRanges(this.designDat[i].arcRanges, i, 'check');
              }
            }
          } else {
            if (this.designDat[0].from !== null) {
              if (
                parseFloat(this.designDat[0].from).toFixed(2) !==
                parseFloat(this.designDat[0].to).toFixed(2)
              ) {
                //NEEDLE LOGIC
                const diff =
                  parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
                const needleVal =
                  Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);
                this.needleVal = needleVal;
                this.needleValue =
                  ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
                  (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
                this.bottomLabel = '';
                if (needleVal?.toString().includes('.')) {
                  this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + this.units;
                } else {
                  this.bottomLabel = needleVal?.toString() + ' ' + this.units;
                }

                //END NEEDLE LOGIC
              } else {
                this.maxError = true;
              }
            }
          }
        } else {
          if (this.rangeError) {
            this.rangeError = false;
          }
        }
      }
    }
  }
  setDecimal(range, i?, dat?) {
    let min: any;
    let max: any;
    if (range === true) {
      const len = dat.length;
      min = +dat[0].from;
      max = +dat[len - 1].to;
    } else {
      min = +this.designDat[i].from;
      max = +this.designDat[i].to;
    }
    if (min.toString().includes('.') && max.toString().includes('.')) {
      this.options.rangeLabel = [
        parseFloat(min).toFixed(2).toString(),
        parseFloat(max).toFixed(2).toString(),
      ];
    } else if (min.toString().includes('.')) {
      this.options.rangeLabel = [
        parseFloat(min).toFixed(2).toString(),
        parseInt(max, 10).toString(),
      ];
    } else if (max.toString().includes('.')) {
      this.options.rangeLabel = [
        parseInt(min, 10).toString(),
        parseFloat(max).toFixed(2).toString(),
      ];
    } else {
      this.options.rangeLabel = [parseInt(min, 10).toString(), parseInt(max, 10).toString()];
    }
  }

  setGauge(dat) {
    const len = dat.length;
    if (
      dat[0].from !== '' &&
      dat[len - 1].to !== '' &&
      dat[0].from < dat[0].to &&
      dat[len - 1].to > dat[len - 1].from
    ) {
      this.options.arcDelimiters = [];
      this.options.arcLabels = [];
      this.options.arcColors = [];
      this.options.rangeLabel = [];
      this.setDecimal(true, 0, dat);
      const arr = [];
      const clr = [];
      for (let i = 0; i < dat.length - 1; i++) {
        arr.push(dat[i].to.toString());
      }
      for (let i = 0; i <= dat.length - 1; i++) {
        clr.push(dat[i].color);
      }
      this.options.arcLabels = arr;
      this.options.arcColors = clr;
      this.options.arcDelimiters = [];
      arr.forEach((e) => {
        const minVal = parseFloat(e) - parseFloat(this.options.rangeLabel[0]);
        const maxVal =
          parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
        const limiters = (minVal / maxVal) * 100;

        if (limiters >= 0 && limiters <= 100) {
          this.options.arcDelimiters.push(limiters).toString();
        }
      });
      //NEEDLE LOGIC
      const diff = parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
      const needleVal =
        Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);
      this.needleVal = needleVal.toString();
      this.needleValue =
        ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
        (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
      this.bottomLabel = '';

      if (needleVal?.toString().includes('.')) {
        this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + this.units;
      } else {
        this.bottomLabel = needleVal?.toString() + ' ' + this.units;
      }

      //END NEEDLE LOGIC
    }
  }
  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }
  setColor(e, i) {
    this.designDat[i].color = e.target.value.toString();
    if (this.designDat[i].range === true) {
      this.setGauge(this.designDat);
    } else {
      const clr = e.target.value.toString();
      if ('arcRanges' in this.designDat[i]) {
        const str = this.options.arcLabels;

        this.options.arcColors = [];
        for (let x = 0; x <= str.length; x++) {
          this.options.arcColors.push(clr);
        }
      }
    }

    if (this.bottomLabel !== '') {
      const val = this.bottomLabel.split(' ');
      this.needleVal = val[0];
    }
  }
  checkComma(e) {
    let tmp = _.cloneDeep(e.target.value);
    e.target.value = tmp.replace(/^,/, '');
    tmp = _.cloneDeep(e.target.value);
  }
  setArcRanges(e, i, type) {
    let ascOrder = true;
    let tmp;
    let len;
    if (type === 'input') {
      tmp = e.target.value;
      len = e.target.value.length;
      if (tmp < 0) {
        this.rangeError = false;
      }
    } else {
      tmp = e;
    }
    if (len === 0) {
      this.rangeError = false;
    }
    if (len > 0) {
      if (this.options.arcColors === []) {
        this.options.arcColors = [ceData.gridLine.default];
      } else {
        this.options.arcColors = [this.designDat[i].color];
      }
      this.options.arcDelimiters = [];
      this.options.arcLabels = [''];
      if (this.designDat[i].to !== null && this.designDat[i].from !== null) {
        this.setDecimal(false, i);
        const boolRange1 = [];

        const format1 = /[!@#$%^&*()_+=\[\]{};':"\\|<>\/?]+/;
        const regExp = /[a-zA-Z]/i;
        const str1 = tmp;
        if (format1.test(str1)) {
          this.rangeError = true;
          boolRange1.push(this.rangeError);
        }
        if (/[a-zA-Z]/i.test(str1)) {
          this.rangeError = true;
          boolRange1.push(this.rangeError);
        }

        if (/^[a-zA-Z]+$/.test(str1)) {
          this.rangeError = true;
          boolRange1.push(this.rangeError);
        }
      } else {
        this.options.rangeLabel = [];
        this.options.arcColors = [ceData.gridLine.default];
      }
    }

    if (this.designDat[i].to !== null && this.designDat[i].from !== null) {
      this.setDecimal(false, i);
    } else {
      this.options.arcColors = [ceData.gridLine.default];
    }
    const boolRange = [];
    const format = /[!@#$%^&*()_+=\[\]{};':"\\|<>\/?]+/;

    let str = tmp;
    if (format.test(str)) {
      this.rangeError = true;
      boolRange.push(this.rangeError);
    }
    if (/[a-zA-Z]/i.test(str)) {
      this.rangeError = true;
      boolRange.push(this.rangeError);
    }
    if (/^[a-zA-Z]+$/.test(str)) {
      this.rangeError = true;
      boolRange.push(this.rangeError);
    }
    if (str.includes(',')) {
      str = str.replace(/,\s*$/, '');
      str = str.split(/[ ]+/).join(',');
    }
    if (str.includes('-')) {
      str = str.replace(/-\s*$/, '');
      str = str.split(/[ ]+/).join(',');
    }

    const strRange = str.split(',');
    if (len > 0) {
      strRange.forEach((element) => {
        if (element.includes('-')) {
          const el = element.split('-');
          if (el[0] !== '') {
            this.rangeError = true;
            boolRange.push(this.rangeError);
          }
        }
        if (element === '') {
          this.rangeError = true;
          boolRange.push(this.rangeError);
        }
      });
    }
    if (this.designDat[0].from !== null && this.designDat[0].to !== null) {
      const nonvalid = [];
      let max = parseFloat(strRange[0]);

      for (let j = 0; j < strRange.length; j++) {
        const test =
          parseFloat(strRange[j + 1]) <= max
            ? nonvalid.push(parseFloat(strRange[j + 1]))
            : (max = parseFloat(strRange[j]));
      }
      if (nonvalid.length > 0) {
        ascOrder = false;
        this.rangeError = true;
        boolRange.push(this.rangeError);
      }

      strRange.forEach((item) => {
        if (
          parseFloat(item) <= parseFloat(this.designDat[0].from.toString()) ||
          parseFloat(item) >= parseFloat(this.designDat[0].to.toString())
        ) {
          this.rangeError = true;
          boolRange.push(this.rangeError);
        } else {
          this.rangeError = false;
          boolRange.push(this.rangeError);
        }
      });
    }

    if (boolRange.includes(true)) {
      this.rangeError = true;
    } else {
      this.rangeError = false;
    }
    if (ascOrder) {
      this.designDat[0].arcRanges = str;
    }
    if (this.rangeError === false) {
      const asc = [...new Set(strRange)];

      this.options.arcDelimiters = [];
      this.options.arcLabels = [];
      this.options.arcColors = [ceData.gridLine.default];
      let clr = '';
      if (this.designDat[0].color !== '') {
        clr = this.designDat[0].color;
        this.options.arcColors = [];
      } else {
        clr = ceData.gridLine.default;
      }
      this.options.arcDelimiters = [];
      if (this.designDat[0].from !== null && this.designDat[0].to !== null && ascOrder) {
        strRange.forEach((val) => {
          this.options.arcColors.push(clr);
          if (val.includes('.')) {
            this.options.arcLabels.push(parseFloat(val).toFixed(2).toString());
          } else {
            this.options.arcLabels.push(val);
          }
          const minVal = parseFloat(val) - parseFloat(this.options.rangeLabel[0]);
          const maxVal =
            parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);

          const limiters = (minVal / maxVal) * 100;

          if (limiters >= 0 && limiters <= 100) {
            this.options.arcDelimiters.push(limiters).toString();
          }
        });

        this.designDat[0].arcRanges = _.cloneDeep(this.options.arcLabels.toString());

        //NEEDLE LOGIC
        const diff =
          parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]);
        const needleVal =
          Math.floor(Math.random() * (diff + 1)) + parseFloat(this.options.rangeLabel[0]);
        this.needleVal = needleVal.toString();
        this.needleValue =
          ((needleVal - parseFloat(this.options.rangeLabel[0])) * 100) /
          (parseFloat(this.options.rangeLabel[1]) - parseFloat(this.options.rangeLabel[0]));
        this.bottomLabel = '';

        if (needleVal?.toString().includes('.')) {
          this.bottomLabel = needleVal?.toFixed(2).toString() + ' ' + this.units;
        } else {
          this.bottomLabel = needleVal?.toString() + ' ' + this.units;
        }

        this.options.arcColors.push(clr);
      } else {
        this.options.arcColors.push(ceData.gridLine.default);
      }
      if (boolRange.includes(true)) {
        this.rangeError = true;
      } else {
        this.rangeError = false;
      }
      const finalVal = asc.toString();
      this.designDat[0].arcRanges = _.cloneDeep(finalVal);

      this.designDat = _.cloneDeep(this.designDat);
    }
  }
  onCancel() {
    this.designDat = [];
    this.editedData = [];
    this.registrationForm.reset();
    this.router.navigate(['/main/template/blanktemplate']);
  }
  onSubmit() {
    const arrLen = this.designDat.length;
    if (this.designDat[0].range === false) {
      if (this.designDat[0].from == null && this.designDat[0].to == null) {
        this.designTabError = true;
        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMax = true;
        this.reqMin = true;
      } else if (this.designDat[0].from == null && this.designDat[0].to !== null) {
        this.designTabError = true;
        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMin = true;
      } else if (this.designDat[0].from !== null && this.designDat[0].to == null) {
        this.designTabError = true;
        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMax = true;
      } else {
        this.designTabError = false;
        this.reqMin = false;
        this.reqMax = false;
      }
    } else {
      if (this.designDat[0].from == null && this.designDat[arrLen - 1].to == null) {
        this.designTabError = true;

        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMax = true;
        this.reqMin = true;
      } else if (this.designDat[0].from == null && this.designDat[arrLen - 1].to !== null) {
        this.designTabError = true;

        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMin = true;
      } else if (this.designDat[0].from !== null && this.designDat[arrLen - 1].to == null) {
        this.designTabError = true;

        this.selectedTab = 1;
        this.manageTabInkbarClass();
        this.reqMax = true;
      } else {
        this.designTabError = false;
        this.reqMin = false;
        this.reqMax = false;
      }
    }

    if (!this.registrationForm.valid) {
      this.submitted = true;
      this.dataTabError = true;
      this.manageTabInkbarClass();
      this.selectedTab = 0;
    }
    if (
      this.rangeError ||
      this.maxError ||
      this.minError ||
      this.designDat[0].from == null ||
      this.designDat[this.designDat.length - 1].to == null
    ) {
      this.designTabError = true;

      this.selectedTab = 1;
      this.manageTabInkbarClass();
    }
    if (this.dataTabError && this.designTabError) {
      this.selectedTab = 0;
      this.manageTabInkbarClass();
    } else if (!this.dataTabError && !this.designTabError) {
      const fm = this.registrationForm.value;
      const obj = this._templateService.tempData;
      if (this.type === 'add') {
        obj.push({
          widgetID: 'gauge' + Math.floor(Math.random() * 1000000),
          dataPath: fm.data.dataPoint,
          height: 2,
          title: fm.data.dataPoint,
          type: 'gauge',
          width: 1,
          gaugeData: this.options,
          arcColors: this.options.arcColors,
          arcDelimiters: this.options.arcDelimiters,
          arcLabels: this.options.arcLabels,
          rangeLabel: this.options.rangeLabel,
          units: fm.data.stateCtrl,
          gaugeConfig: {
            config: this.designDat,
            needleValue: this.needleValue,
            bottomLabel: this.bottomLabel,
            units: fm.data.stateCtrl,
          },
        });

        this._templateService.tempData = obj;
      } else {
        const foundIndex = obj.findIndex((x) => x.widgetID === this.id);
        const newObj = {
          ...this.editedData,
          title: fm.data.dataPoint,
          dataPath: fm.data.dataPoint,
          gaugeData: this.options,
          arcColors: this.options.arcColors,
          arcDelimiters: this.options.arcDelimiters,
          arcLabels: this.options.arcLabels,
          rangeLabel: this.options.rangeLabel,
          units: fm.data.stateCtrl,
          gaugeConfig: {
            config: this.designDat,
            needleValue: this.needleValue,
            bottomLabel: this.bottomLabel,
            units: fm.data.stateCtrl,
          },
        };

        obj[foundIndex] = newObj;

        this._templateService.tempData = obj;
        sessionStorage.setItem('tempData', JSON.stringify(obj));
      }
      this.router.navigate(['/main/template/blanktemplate']);
    }
  }

  manageTabInkbarClass() {
    const selector = 'app-addedit-gauge .mat-ink-bar';
    const className = 'error';
    if (this.selectedTab === 0) {
      if (this.dataTabError) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    } else {
      if (this.designTabError) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    }
  }
}
