<div class="dashboard-permission-modal">
  <div class="header">
    <p>{{ "common.permission-title" | translate }}</p>
    <span (click)="cancel()"
      ><img src="../../../../assets/images/ic-close.svg"
    /></span>
  </div>
  <div class="content">
    <div>
      <p>{{ "common.permission-msg" | translate }}</p>
      <div class="editor-tag">
        <mat-form-field class="email-input" appearance="legacy">
          <mat-chip-list #chipList multiple>
            <div class="single-chip">
              <mat-chip
                class="email-tag"
                *ngFor="let useremail of usersEmail; let i = index"
                [value]="useremail"
                [ngClass]="{ invalid: !useremail.isValid, selected: useremail.isSelected }"
                (click)="selectChip($event,i)"
                (focusout)="onFocusOutEvent($event)"
                >
                <span class="cursor">{{ useremail.email }}</span>
                <input [id]="'chip_'+ i" autocomplete="off" class="email-input" data-test="input_editemail" [(ngModel)]="useremail.email" (keydown.backspace)="onMatChipKeyPress($event)" (keydown)="onKeyEnter($event,i,useremail.email)" focus/>
                <mat-icon matChipRemove *ngIf="!useremail.isSelected" (click)="removeKeyword(i)"
                  >close</mat-icon
                >
              </mat-chip>
            </div>

            <input
              class="email-input"
              data-test="input_email_input"
              placeholder="Add email address"
              autocomplete="off"
              [matChipInputFor]="chipList"
              (matChipInputTokenEnd)="addKeywordFromInput($event)"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (paste)="pasted($event)"
              (blur)="blured($event)"
              (focus)="deSelectEmails(usersEmail)"
            />
          </mat-chip-list>
        </mat-form-field>
        <p *ngIf="isError" class="error">
          {{ "message.invites-invalid-email" | translate }}
        </p>
      </div>
    </div>
    <div class="userlist">
      <div class="single-user" *ngFor="let singleUser of usersList">
        <div class="user-details">
          <p class="name">
            {{ singleUser?.firstName }} {{ singleUser?.lastName }}
          </p>
          <p class="email">{{ singleUser?.username }}</p>
        </div>
        <div class="action-btn">
          <button
            mat-flat-button
            class="modal-action-btn"
            (click)="updateRemoveList(singleUser?.username)"
          >
            <span *ngIf="!removeUserlist?.includes(singleUser?.username)">{{
              "action.remove" | translate
            }}</span>
            <span *ngIf="removeUserlist?.includes(singleUser?.username)">{{
              "action.add-back" | translate
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="button-container">
      <button
        class="btn secondary-btn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ "action.cancel" | translate }}
      </button>
      <button
        class="btn primary-btn"
        mat-flat-button
        data-test="action_save_permission"
        (click)="save()"
        [disabled]="
          isError || (removeUserlist.length == 0 && usersEmail?.length == 0)
        "
      >
        {{ "action.save" | translate }}
      </button>
    </div>
  </div>
</div>
