<div
  class="reset-container"
  [ngClass]="{
    'mobile-device':
      deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape',
    'mobile-landscape': deviceType == 'MobileLandscape'
  }"
  *ngIf="resetPasswordConfirm == false"
>
  <mat-progress-bar
    class="progress-bar p-absolute-top"
    mode="indeterminate"
    *ngIf="
      loginDissabled &&
      (deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape')
    "
  ></mat-progress-bar>
  <div class="loginform-container" [ngClass]="{ formDisabled: loginDissabled }">
    <div>
      <mat-card class="login-card">
        <mat-progress-bar
          class="progress-bar p-absolute-top"
          mode="indeterminate"
          *ngIf="
            loginDissabled &&
            deviceType != 'MobilePortrait' &&
            deviceType != 'MobileLandscape'
          "
        ></mat-progress-bar>
        <div class="header-content">
          <!-- <div class="logo"><p>Logo</p></div> -->
          <div class="logo-img">
            <img
              src="../../assets/images/atoms-brands-grid-green-and-grey.svg"
            />
          </div>
          <div><p class="field-title">- &nbsp;FieldVEU</p></div>
        </div>
        <mat-card-content>
          <mat-card-title>{{
            "common.reset-password" | translate
          }}</mat-card-title>
          <form
            *ngIf="!resetPass"
            #loginForm="ngForm"
            class="text-center"
            (ngSubmit)="onSubmit(loginForm)"
          >
            <div *ngIf="!resetPass" class="row">
              <label
                for="inputPassword3"
                data-test="text_password"
                class="loginfield-label"
              >
                {{ "common.password" | translate }}
                <span
                  data-test="text_show_hide"
                  (click)="
                    !loginDissabled ? (hidePassword = !hidePassword) : ''
                  "
                >
                  {{
                    hidePassword
                      ? ("common.show" | translate)
                      : ("common.hide" | translate)
                  }}
                  <img
                    *ngIf="hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility.svg"
                    alt="Visible"
                    data-test="media_visibility"
                  />
                  <img
                    *ngIf="hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-disabled.svg"
                    alt="visibility-disabled"
                    data-test="media_visibility_disabled"
                  />
                  <img
                    *ngIf="!hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off.svg"
                    alt="visibility-off"
                    data-test="media-visibility_off"
                  />
                  <img
                    *ngIf="!hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off-disabled.svg"
                    alt="visibility-off-disabled"
                    data-test="media_visibility_off_disabled"
                  />
                </span>
              </label>
              <div class="field-container">
                <div class="inp-div">
                  <input
                    placeholder="Enter your password"
                    [type]="hidePassword ? 'password' : 'text'"
                    required
                    [(ngModel)]="loginModel.password"
                    #password="ngModel"
                    (keyup)="checkPassword(password, $event)"
                    (focus)="warning = true"
                    class="form-control"
                    id="inputPassword"
                    [disabled]="loginDissabled"
                    name="password"
                    data-test="input_password"
                    [ngClass]="{
                      error: loginForm.submitted && password?.errors?.required,
                      inputError: passStrength == 'weak'
                    }"
                  />
                  <div class="weak-password" *ngIf="passStrength == 'weak'">
                    <span class="pass-strength-padding">{{
                      "message.weak-password" | translate
                    }}</span>
                  </div>
                  <div class="avg-password" *ngIf="passStrength == 'average'">
                    <span class="pass-strength-padding">
                      {{ "message.avg-password" | translate }}</span
                    >
                  </div>
                  <div class="strong-password" *ngIf="passStrength == 'strong'">
                    <span class="pass-strength-padding">
                      {{ "message.strong-password" | translate }}</span
                    >
                  </div>
                </div>
                <div
                  class="error-message"
                  *ngIf="loginForm.submitted && !loginForm.valid"
                >
                  <p
                    data-test="required_password"
                    *ngIf="password?.errors?.required"
                  >
                    {{ "message.password-required" | translate }}
                  </p>
                </div>
                <p
                  *ngIf="warning"
                  class="warning-txt"
                  [ngClass]="{
                    warningTxtError: passStrength === 'weak'
                  }"
                  data-test="required_password"
                >
                  {{ "message.password-message" | translate }}
                </p>

                <mat-password-strength
                  #passwordComponent
                  (onStrengthChanged)="onStrengthChanged($event)"
                  [password]="password.value"
                >
                </mat-password-strength>
              </div>
            </div>
            <div
              class="failed-message alert alert-warning"
              role="alert"
              *ngIf="errorMessage.length && !loginDissabled && loginForm.valid"
              data-test="text_error"
            >
              <img
                class="margin-icon-menu"
                src="../../../assets/images/failed.svg"
                alt="Failed"
              />
              {{ errorMessage }}
            </div>
            <div class="reset-btn">
              <button
                [disabled]="fetchRes"
                [ngClass]="{ resetDisable: fetchRes }"
                class="btn btn-primary login-btn"
                id="signin-btn"
                mat-flat-button
                [disabled]="loginDissabled"
                data-test="action_signin"
              >
                {{ "common.reset" | translate }}
              </button>
            </div>
          </form>

          <div>
            <div *ngIf="resetPass" class="text-center">
              <div fxLayout="row" style="width: 300px">
                <span>
                  <img src="../../assets/images/success.svg" />
                </span>
                <p class="forgot-text margin-lft">
                  {{ "message.password-success" | translate }}
                </p>
              </div>
              <div class="reset-btn">
                <button
                  class="btn btn-primary login-btn"
                  id="signin-btn"
                  mat-flat-button
                  (click)="goBackLogin()"
                  data-test="action_signin"
                >
                  {{ "action.back-login" | translate | uppercase }}
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="image-container">
      <img
        src="../../../assets/images/atoms-brands-msa-field-server-green-and-black-on-white.svg"
        alt="brand img"
      />
    </div>
  </div>
</div>
