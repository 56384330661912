<app-user-mgmt-subheader
  [unsaved]="unsaved"
  [pageType]="'addUser'"
></app-user-mgmt-subheader>
<div class="addUsercontainer">
  <form [formGroup]="addUserForm">
    <div class="formele">
      <div class="adduser-formfield-title">
        <mat-label>{{ "common.email-address" | translate }}</mat-label>
      </div>
      <input
        [ngClass]="{
          'email-error':
            !addUserForm.valid &&
            submitted &&
            addUserForm.controls['email']?.errors?.required
        }"
        (focusout)="showEmailError()"
        class="emailinp"
        matInput
        placeholder="{{ 'placeholder_messages.add-email' | translate }}"
        formControlName="email"
      />

      <div class="errormsg" *ngIf="!addUserForm.valid && showEmailErrorMsg">
        {{ "message.valid-email" | translate }}
      </div>

      <div
        class="errormsg"
        *ngIf="
          !addUserForm.valid &&
          submitted &&
          addUserForm.controls['email']?.errors?.required
        "
      >
        {{ "message.email-required" | translate }}
      </div>
    </div>

    <div class="formele">
      <div class="adduser-formfield-title">
        <mat-label>{{ "common.user-role" | translate }}</mat-label>
      </div>
      <div>
        <mat-select
          class="emailinp roledropdn"
          [ngClass]="{
            'role-error':
              !addUserForm.valid &&
              submitted &&
              addUserForm.controls['fieldveuRole']?.errors?.required
          }"
          formControlName="fieldveuRole"
          placeholder="{{ 'placeholder_messages.select' | translate }}"
        >
          <mat-option *ngFor="let role of roles" [value]="role.name">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </div>
      <div
        class="errormsg"
        *ngIf="
          !addUserForm.valid &&
          submitted &&
          addUserForm.controls['fieldveuRole']?.errors?.required
        "
      >
        {{ "message.role-required" | translate }}
      </div>
    </div>

    <div class="btn-container">
      <button
        [ngClass]="sentrequest === true ? 'disable-click' : 'enable-click'"
        class="btn secondary-btn cancel"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ "action.cancel" | translate | uppercase }}
      </button>

      <button
        [ngClass]="sentrequest === true ? 'disable-click' : 'enable-click'"
        type="submit"
        class="btn primary-btn save"
        mat-flat-button
        data-test="save-profile"
        (click)="save()"
      >
        {{ "action.save" | translate | uppercase }}
      </button>
    </div>
  </form>
</div>
