import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';

export function customValidation(c: AbstractControl): ValidationErrors | null {
  const min = c.get('min')?.value;
  const max = c.get('max')?.value;
  const gridLines = c.get('gridLines')?.value;
  const gridLinesColor = c.get('gridLinesColors') as FormArray;

  /* START : Grid Line Validation  */

  if (gridLines !== 'none' && gridLinesColor?.length === 0) {
    c.get('gridLines')?.setErrors({ noGridLine: true });
  } else {
    c.get('gridLines')?.setErrors(null);
  }

  /* END : Grid Line Validation   */

  if ((!min && min !== 0) || (!max && max !== 0)) return null;

  if (isRangeValidation(min, max)) {
    c.get('min').setErrors(null);
    c.get('max').setErrors(null);
    return null;
  }
  c.get('min').setErrors({ rangeInvalid: true });
  c.get('max').setErrors({ rangeInvalid: true });
  return null;
}

function isRangeValidation(start: number, end: number) {
  return start < end;
}
