import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadApiService } from '../../../shared/services/api/upload-api.service';
import { v4 as uuidv4 } from 'uuid';
import { TemplateService } from 'src/app/service/template/template.service';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { LocalizationService } from 'src/app/shared/services/localization/localization.service';
@Component({
  selector: 'app-addedit-image',
  templateUrl: './addedit-image.component.html',
  styleUrls: ['./addedit-image.component.scss'],
})
export class AddeditImageComponent implements OnInit {
  type = 'image';
  selected_icon = '';
  deviceIcons = [
    { name: 'boiler', icon: ['boiler-1', 'boiler-2', 'boiler-3', 'boiler-4'] },
    { name: 'metering', icon: ['metering-1', 'metering-2', 'metering-3'] },
    {
      name: 'air_handling',
      icon: [
        'air-handling-1',
        'air-handling-2',
        'air-handling-3',
        'air-handling-4',
        'air-handling-5',
        'air-handling-6',
      ],
    },
    {
      name: 'industrial_automation',
      icon: ['life-safety-1', 'life-safety-2', 'life-safety-3', 'life-safety-4'],
    },
  ];
  fileName: string;
  uploadedFile: string = null;
  validation;
  deviceImageUrl: string;
  readonly uploadImageUrl = (window as any).smcUrl + 'fieldveu-images/';
  constructor(
    private router: Router,
    private apiService: UploadApiService,
    private _templateService: TemplateService,
    private cs: CommonService,
    private localizationService: LocalizationService
  ) {
    let data = JSON.parse(sessionStorage.getItem('tempData'));
    if (data) {
      data = data.find((item) => item.type === 'device-info');
    }

    if (data?.iconType !== undefined) this.type = data?.iconType;
    if (data?.iconType !== undefined && data?.iconType === 'icon') {
      this.selected_icon = data?.url.split('.')[0];
    } else {
      if (data?.iconType !== undefined) this.fileName = this.uploadedFile = data?.url;
    }
  }

  ngOnInit(): void {}
  fileUpdated(files) {
    this.fileName = files[0].name;
    let extn = files[0].name.split('.')[1];
    if (extn === 'svg') {
      extn = 'image/svg+xml';
    } else {
      extn = '';
    }
    const uniqueFileName = uuidv4() + '.' + files[0].name.split('.')[1];
    const file = new File([files[0]], uniqueFileName, {
      type: extn,
    });
    this.apiService.uploadImage(file).subscribe(
      (response) => {
        this.uploadedFile = file.name;
      },
      (err) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: this.localizationService.translate('message.imageUpload-err'),
        };
        this.cs.openToaster(data);
      }
    );
  }
  delete() {
    this.uploadedFile = null;
    this.fileName = '';
  }
  fileValidation(value) {
    this.validation = value;
  }
  addWidget() {
    if (this.type === 'icon') {
      this.deviceImageUrl = this.selected_icon + '.svg';
    } else {
      this.deviceImageUrl = this.uploadedFile;
    }
    const i = this._templateService.tempData.findIndex((item) => item.type === 'device-info');
    this._templateService.tempData[i].url = this.deviceImageUrl;
    this._templateService.tempData[i].iconType = this.type;
    this._templateService.tempData[i].widgetID =
      this._templateService.tempData[i].type + Math.floor(Math.random() * 1000000);
    this._templateService.tempData[i].widgetID = sessionStorage.setItem(
      'tempData',
      JSON.stringify(this._templateService.tempData)
    );
    this.router.navigate(['/main/template/blanktemplate']);
  }
  cancel() {
    this.router.navigate(['/main/template/blanktemplate']);
  }
}
