import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { IDeviceListState, IFilterObject, ISortingObject } from 'src/app/shared/interface/common';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-mobile-filter-sorting',
  templateUrl: './mobile-filter-sorting.component.html',
  styleUrls: ['./mobile-filter-sorting.component.scss'],
})
export class MobileFilterSortingComponent implements OnInit {
  @Input() sortbyList: Array<string>;
  @Input() deviceListingState: IDeviceListState;
  @Input() statuses: Array<string>;
  @Input() renderedFrom;
  @Output() outputState = new EventEmitter<IDeviceListState>();
  readonly dropdownInput = ['status', 'companyName'];
  sortby = {
    by: undefined,
    order: undefined,
  };
  filter: { [key: string]: string | Array<string> | { start: number; end: number } } = {};
  readonly defaultTime = {
    start: '00:00',
    end: '23:59',
  };
  readonly dateFormat = 'MM/DD/YYYY HH:mm:ss';
  constructor() {}

  ngOnInit(): void {
    this.sortby = {
      by: this._sortActiveValues.by,
      order: this._sortActiveValues.order,
    };
  }

  sortByKeyName(keyName: string, order: 'asc' | 'desc'): any {
    const temp = this.deviceListingState.sorting[keyName];
    if (this.deviceListingState.sorting) {
      for (const key in this.deviceListingState.sorting) {
        if (this.deviceListingState.sorting[key]) {
          const element = this.deviceListingState.sorting[key];
          if (key !== keyName) {
            element.isActive = false;
          }
        }
      }
    }

    temp.isActive = true;
    temp.order = order;
    temp.icon = temp.order === 'asc' ? 'arrow_upward' : 'arrow_downward';
  }

  changeOrderSelection(event: any): any {
    this.sortby.by = event.value;
  }

  changeOrderToggle(event: MatButtonToggleChange): any {
    this.sortby.order = event.value;
  }

  searchText(event: InputEvent): any {
    const searchTerm = (event.target as HTMLInputElement).value;
    const columnName = (event.target as HTMLInputElement).id;
    this.filter[columnName] = searchTerm;
  }

  selectedOption(key: string, event: string | Array<string>): any {
    this.filter[key] = event;
  }

  updateFilter(key: string, value: string | Array<string> | { start: number; end: number }): any {
    this.filter[key] = value;
    this.deviceListingState.filter[key] = {
      name: key,
      value,
    };
  }

  apply(): any {
    this.sortByKeyName(this.sortby.by, this.sortby.order);
    for (const key in this.filter) {
      if (this.filter[key]) {
        const value = this.filter[key];
        this.updateFilter(key, value);
      }
    }
    this.deviceListingState.pageNumber = 1;
    this.outputState.emit(this.deviceListingState);
  }

  close(): any {
    this.outputState.emit();
  }

  reset(): any {
    for (const key in this.deviceListingState.sorting) {
      if (this.deviceListingState.sorting[key]) {
        const element = this.deviceListingState.sorting[key];
        if (key !== 'siteName') {
          element.isActive = false;
          element.order = 'desc';
          element.icon = 'arrow_downward';
        } else {
          element.isActive = true;
          element.order = 'asc';
          element.icon = 'arrow_upward';
        }
      }
    }
    this.deviceListingState.filter = {};
    this.deviceListingState.pageNumber = 1;
    this.outputState.emit(this.deviceListingState);
  }

  dateChange(event) {
    const convertedUnix = {
      start: moment(event.start).valueOf(),
      end: moment(event.end).valueOf(),
    };
    this.updateFilter('dateTime', convertedUnix);
  }

  get _sortActiveValues(): ISortingObject {
    const temp = this.deviceListingState.sorting;
    for (const key in temp) {
      if (temp[key]) {
        const element = temp[key];
        if (element.isActive) {
          return element;
        }
      }
    }
  }

  get _filterValues(): { [name: string]: IFilterObject } {
    return this.deviceListingState.filter || {};
  }
}
