import { TBreakpoint } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';

export const FETCH_GRAPH_POINT = {
  MobilePortrait: 100,
  TabletPortrait: 100,
  WebPortrait: 1000,
  MobileLandscape: 100,
  TabletLandscape: 100,
  WebLandscape: 1000,
  getPoint(value: TBreakpoint): number {
    return this[value];
  },
};
