import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/service/template/template.service';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { Location } from '@angular/common';
import { ToasterData } from '../../constants/constants';
import { CommonService } from '../../services/common/common.service';
import { DashEventService } from 'src/app/core/device-dashboard/services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent implements OnInit {
  modalType: any;
  id: any;
  disablebtn = false;
  userName: string;
  missingLoop = [];
  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    private _templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data,
    public router: Router,
    private meshServiceProvidor: MeshServiceProvidor,
    private _dasheventService: DashEventService,
    private location: Location,
    private cs: CommonService
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.modalType = this.data.modalType;
    if (this.modalType === 'delete-widget') {
      this.id = this.data.id;
    } else if (this.modalType === 'delete-user') {
      this.userName = this.getUserName();
    }
    if (this.modalType === 'datapoint-missing') {
      this.missingLoop = this.data?.data;
    }
  }

  getUserName(): string {
    if (
      !!this.data?.id?.firstName &&
      this.data?.id?.firstName !== '' &&
      !!this.data?.id?.lastName &&
      this.data?.id?.lastName !== ''
    ) {
      return this.data?.id?.firstName + ' ' + this.data?.id?.lastName;
    } else {
      return this.data?.id?.email;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  delete(): void {
    const data = this._templateService.tempData;
    const newTempData = data.filter((p) => p.widgetID !== this.id);
    this._templateService.tempData = [...newTempData];

    sessionStorage.setItem('tempData', JSON.stringify(this._templateService.tempData));
    this.dialogRef.close();
    this.router.navigate(['/main/template/blanktemplate']);
  }
  deleteTemplate(): void {
    this.disablebtn = true;
    const options = {
      id: this.data.templateId,
    };

    this.meshServiceProvidor
      .deleteTemplate(options)
      .then((res) => {
        this.disablebtn = false;
        this.dialogRef.close('delete');
        this.router.navigate(['/main/template']);
      })
      .catch((error) => {
        this.dialogRef.close('delete-error');
        const data: ToasterData = {
          type: 'error-custom',
          message: error.toString(),
        };

        this.cs.openToaster(data);
      });
  }
  unPublish(): void {
    const options = {
      id: this.data.templateId,
    };
    this.meshServiceProvidor
      .unPublishTemplate(options)
      .then((res) => {
        this.dialogRef.close('unpublish-success');
      })
      .catch((error) => {
        this.dialogRef.close('unpublish-error');
      });
  }
  continueChanges(): void {
    this.dialogRef.close('continue');
  }
  discardChanges(): void {
    if (this.modalType === 'unsaved-changes') {
      this.dialogRef.close();
      sessionStorage.removeItem('initTempData');
      this.router.navigate(['/', 'main', 'template']);
    } else if (this.modalType === 'user-unsaved') {
      this.dialogRef.close();
      sessionStorage.removeItem('selectedUser');
      this.router.navigate(['/', 'main', 'users']);
    } else if (this.modalType === 'unsaved-changes-notif') {
      this.dialogRef.close();
      this._dasheventService.notificationSettings = null;
      this.location.back();
    }
  }

  deleteUser(): void {
    this.disablebtn = true;

    const options = this.data.id.email;
    this.meshServiceProvidor
      .deleteUser(options)
      .then((res) => {
        this.dialogRef.close();
        this.router.navigate(['/', 'main', 'users']);
        this.disablebtn = false;
      })
      .catch((err) => {
        this.disablebtn = false;
        this.dialogRef.close();
        const data: ToasterData = {
          type: 'error-custom',
          message: err.toString(),
        };
        this.cs.openToaster(data);
        if (err.message.includes('User not registered')) {
          this.router.navigate(['/', 'main', 'users']);
        }
      });
  }
}
