import { IHistoricalGraphState } from './_base';

export interface IHistoricalGraphStateGrouped {
  [id: string]: IHistoricalGraphState;
}

export class LoginDetail {
  username: string;
  password: string;
}
export class ChangePasswordDetail {
  oldpassword: string;
  newpassword: string;
}

export interface IDeviceListState {
  pageNumber: number;
  pageSize: number;
  sorting: {
    [by: string]: ISortingObject;
  };
  filter?: {
    [name: string]: IFilterObject;
  };
  columnDisplayed: string[];
}

export interface ISortingObject {
  by: string;
  order: 'asc' | 'desc';
  isActive: boolean;
  icon: 'arrow_upward' | 'arrow_downward';
}

export interface IFilterObject {
  name: string;
  value: string | Array<string> | { start: number; end: number };
}

export interface IPagination {
  pagination: {
    itemCount: number;
    offset: number;
  };
}
