import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private previousUrl$: Observable<string> = this.previousUrl.asObservable();
  constructor() {}

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
}
