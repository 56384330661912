import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import * as moment from 'moment';
import { IHistoricalGraphState, ITemporal } from 'src/app/shared/interface/_base';
import { Observable } from 'rxjs/internal/Observable';
import { DashEventService } from '../services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-date-controller',
  templateUrl: './date-controller.component.html',
  styleUrls: ['./date-controller.component.scss'],
})
export class DateControllerComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() device: string;
  @Input() type: string;
  @Input() isDialog: any;
  public get_historicalGraphState: Observable<IHistoricalGraphState>;
  private subscription: Subscription[] = [];

  constructor(private _dashEventService: DashEventService) {}

  async ngOnInit(): Promise<void> {
    this.get_historicalGraphState = this._dashEventService.get_historicalGraphState(this.id);
    const isInitialized = this._dashEventService.init_historicalGraphState(this.id);
    if (isInitialized) {
      this.getdateRange(this._dashEventService.defaultTimeWindow || 'month', true);
    }
  }

  getdateRange(type, isFirst = false) {
    if (type === 'month' || type === 'Month') {
      const minDate = moment(moment().subtract(30, 'days')).startOf('day').valueOf();
      const maxDate = moment().valueOf();
      const temp = this._dashEventService.createHistoricalGraphState(minDate, maxDate, 'month');
      this._dashEventService.setAll_historicalGraphState(temp, isFirst);
    }

    if (type === 'week' || type === 'Week') {
      const minDate = moment(moment().subtract(7, 'days')).startOf('day').valueOf();
      const maxDate = moment().valueOf();
      const temp = this._dashEventService.createHistoricalGraphState(minDate, maxDate, 'week');
      this._dashEventService.setAll_historicalGraphState(temp, isFirst);
    }

    if (type === 'today' || type === 'Today') {
      const minDate = moment(moment().subtract(24, 'hours')).valueOf();
      const maxDate = moment().valueOf();
      const temp = this._dashEventService.createHistoricalGraphState(minDate, maxDate, 'today');
      this._dashEventService.setAll_historicalGraphState(temp, isFirst);
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
