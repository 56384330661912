/*eslint radix: ["error", "as-needed"]*/
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import * as _ from 'lodash';
import { IHistoricalGraphState } from 'src/app/shared/interface/_base';
import {
  IcolorObject,
  Ilabels,
  Istroke,
} from 'src/app/shared/interface/device-dashboard.interface';
import { FETCH_GRAPH_POINT } from '../../../shared/constants/chart-fetchpoints';
import {
  ResolutionResolverService,
  TBreakpoint,
} from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ChartComponent } from 'ng-apexcharts';
import { DashEventService } from '../services/dashboard-event/dash-event.service';
import { ChartUtilityService } from '../services/chart-utlity/chart-utility.service';

export interface ISelectedDate {
  [x: string]: {
    minDate: Date;
    maxDate: Date;
  };
}
@Component({
  selector: 'app-historical-chart',
  templateUrl: './historical-chart.component.html',
  styleUrls: ['./historical-chart.component.scss'],
})
export class HistoricalChartComponent implements OnInit, OnDestroy {
  @Input() widget;
  @Input() deviceId;
  @Input() chartType;
  @Input() device;
  @Input() isDialogView;
  @Input() type;
  @Input() id: string;
  @Output() statusEvent: EventEmitter<any> = new EventEmitter<IWidgetEvent>();
  @ViewChild('chart', { static: true })
  chart: ChartComponent = new ChartComponent();
  series: any[];
  chartt: any;

  xaxis: any;
  emptyData = true;
  colorObj: IcolorObject[];

  tickFormat: string;
  dataLabels: Ilabels;
  showMarker: boolean;
  upperLimit: number;
  lowerLimit: number;
  units: string;
  stroke: Istroke;
  annotations: any;
  dateRange: IHistoricalGraphState;
  deviceType: TBreakpoint;
  yaxis: any;
  noData: {
    text: string;
    align: string;
    verticalAlign: string;
    offsetX: number;
    offsetY: number;
    style: { color: any; fontSize: string; fontFamily: any };
  };
  tooltip: {
    shared: boolean;
    custom: ({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      series: any;
      seriesIndex: any;
      dataPointIndex: any;
      w: any;
    }) => string;
  };
  markers: any;
  subscription: Subscription[] = [];
  constructor(
    private _meshServiceProvidor: MeshServiceProvidor,
    private _dashEventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService,
    private ws: WidgetsService,
    private cs: CommonService,
    private chartUtilityService: ChartUtilityService
  ) {
    const sub = this._resolutionResolver._deviceType.subscribe(
      (value) => (this.deviceType = value)
    );
    this.subscription.push(sub);
  }

  ngOnInit(): void {
    if (!!this.type && this.type === 'template') {
      this.units = !!this.widget ? this.widget.units : '';
      this.setSolidOrGradient();
      const [min, max] = this.chartUtilityService.getUpperLowerLimit();
      this.lowerLimit = min;
      this.upperLimit = max;
      this.getDummyData();
    } else {
      const sub = this._dashEventService.get_historicalGraphState(this.id).subscribe((data) => {
        const eventObj: IWidgetEvent = {
          name: 'graph',
          status: 'loading',
          id: this.id,
        };
        if (this.chartt) {
          this.statusEvent.emit(eventObj);
        }

        this.dateRange = data;
        this.getHistChartData(data);
      });
      const markerSub = this._dashEventService.getMarker(this.id).subscribe((event) => {
        this.showMarker = event;
        this.markers = this.chartUtilityService.setMarkers(this.showMarker);
      });

      this.units = !!this.widget ? this.widget.units : '';
      this.setSolidOrGradient();
      const [min, max] = this.chartUtilityService.getUpperLowerLimit();
      this.lowerLimit = min;
      this.upperLimit = max;
      this.subscription.push(sub);
    }
  }

  setAnnotations(minY): any {
    if (!!this.colorObj) {
      const newArr = [];
      this.colorObj.map((p, i) => {
        const obj = {};
        if (p.y > minY) {
          (obj as any).y = p.y;
          (obj as any).borderColor = p.color;
          (obj as any).strokeDashArray = p.strokeDashArray;

          newArr.push(obj);
        }
      });
      return newArr;
    }
  }

  initChartData(data): void {
    const dates = [];
    data.map((p) => {
      if (!!p && p != null) {
        dates.push([Math.round(p.time), parseFloat(p.value.toFixed(2))]);
      }
    });
    this.initChartProperties(dates);
  }

  initChartProperties(dates) {
    this.setYaxis();

    this.series = [];
    if (this.emptyData) {
      this.series = [...[]];
    } else {
      const series = [
        {
          name: 'XYZ Name',
          data: dates,
        },
      ];
      this.series = [...series];
    }

    const that = this;
    this.chartt = {
      type: 'line',
      height: 350,
      width: '97%',
      animations: {
        enabled: false,
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !!this.type && this.type === 'template' ? false : true,
        type: 'x',
      },

      events: {
        mounted(chartContext, config) {
          const data = that.setAnnotations(chartContext.w.globals.minY);

          if (
            data.length > 0 &&
            !!chartContext &&
            chartContext.annotations !== undefined &&
            chartContext.annotations != null
          ) {
            data.map((p) => {
              chartContext.addYaxisAnnotation(
                {
                  y: p.y,
                  borderColor: p.borderColor,
                  strokeDashArray: 0,
                },
                true,
                chartContext
              );
            });
          }
        },
        beforeZoom(chartContext, { xaxis }) {
          const dateRange: IHistoricalGraphState = {
            minDateTime: xaxis.min * 1000,
            maxDateTime: xaxis.max * 1000,
            type: 'custom',
          };

          if (
            dateRange.minDateTime !== dateRange.maxDateTime &&
            moment(dateRange.maxDateTime).diff(moment(dateRange.minDateTime), 'seconds') > 10 &&
            dateRange.minDateTime >= that.lowerLimit &&
            dateRange.maxDateTime <= that.upperLimit
          ) {
            that._dashEventService.setAll_historicalGraphState(dateRange);

            const eventObj: IWidgetEvent = {
              name: 'graph',
              status: 'loading',
              id: that.id,
            };
            if (that.chartt) {
              that.statusEvent.emit(eventObj);
            }
            // that.getHistChartData(dateRange);
            return {
              xaxis: {},
            };
          } else {
            return {
              xaxis: {
                min: that.dateRange.minDateTime / 1000,
                max: that.dateRange.maxDateTime / 1000,
              },
            };
          }
        },
      },
    };

    this.annotations = {
      position: 'back',
    };

    const [width, height] = this.chartUtilityService.chartWidthHeightSetting(
      this.device,
      this.isDialogView,
      this.chartType
    );
    this.chartt.height = height;
    this.chartt.width = width;

    //set chart zoom
    this.chartt.zoom.enabled = this.chartUtilityService.setZoomEnabledDisable(
      this.deviceType,
      this.type
    );

    //set chart markers

    this.markers = this.chartUtilityService.setMarkers(this.showMarker);

    //set noData and tooltip
    if (this.emptyData) {
      this.series = [...[]];
      this.noData = this.chartUtilityService.setNoData();
    } else {
      this.tooltip = this.chartUtilityService.setTooltip(this.units);
    }
  }

  setYaxis() {
    if (!!this.widget && this.widget.autoscale === true) {
      this.yaxis = {
        floating: false,
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: '#444444',
            fontSize: '16px',
            fontFamily: 'Roboto, Regular, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
          formatter(val, index) {
            const y = !!val ? val.toString().split('.') : '0';
            return y && y.length > 1 ? val : parseInt(y[0]);
          },
        },
      };
    } else if (
      !!this.widget &&
      this.widget.autoscale === false &&
      this.widget.min !== null &&
      this.widget.min !== '' &&
      this.widget.min !== undefined &&
      this.widget.max !== null &&
      this.widget.max !== '' &&
      this.widget.max !== undefined
    ) {
      this.yaxis = {
        floating: false,
        decimalsInFloat: 0,
        min: this.widget.min,
        max: this.widget.max,
        labels: {
          style: {
            colors: '#444444',
            fontSize: '16px',
            fontFamily: 'Roboto, Regular, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
          formatter(val, index) {
            const y = !!val ? val.toString().split('.') : '0';
            return y && y.length > 1 ? val : parseInt(y[0]);
          },
        },
      };
    } else {
      this.yaxis = {
        floating: false,
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: '#444444',
            fontSize: '16px',
            fontFamily: 'Roboto, Regular, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
          formatter(val, index) {
            const y = !!val ? val.toString().split('.') : '0';
            return y && y.length > 1 ? val : parseInt(y[0]);
          },
        },
      };
    }
  }

  setXaxis(min, max): void {
    const tickAmount = this.chartUtilityService.getTickAmount(this.chartType, this.deviceType);
    const that = this;
    this.xaxis = {
      type: 'datetime',
      offsetX: 30,
      tickAmount,

      min: min / 1000,
      max: max / 1000,
      labels: {
        show: true,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
        maxHeight: 120,
        datetimeUTC: true,
        style: {
          colors: '#444444',
          fontSize: '16px',
          fontFamily: 'Roboto, Regular, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },

        formatter(value, timestamp, opts) {
          if (opts.i === tickAmount) {
            return '-';
          } else {
            return moment(value * 1000).format(that.tickFormat);
          }
        },
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
        formatter(value, timestamp, opts) {
          return moment(value * 1000).format('MMM D,HH:mm:ss');
        },
      },
    };
  }

  getHistChartData(dateRange: IHistoricalGraphState) {
    const path = this.chartUtilityService.getPath(this.widget);
    const count = FETCH_GRAPH_POINT.getPoint(this.deviceType);
    this._meshServiceProvidor
      .getHistoricalData(this.deviceId, path, dateRange, count)
      .then((histdata) => {
        if (!!histdata && histdata !== undefined && histdata.length !== 0) {
          this.emptyData = false;

          this.initChartData(histdata);
        } else if (histdata.length === 0) {
          const tempData = this.chartUtilityService.getEmptyData(
            dateRange.minDateTime,
            dateRange.maxDateTime
          );
          this.emptyData = true;
          this.initChartData(tempData);
        }
        this.tickFormat = this.chartUtilityService.getFormatter(
          dateRange,
          this.chartType,
          this.deviceType
        );
        this.setXaxis(dateRange.minDateTime, dateRange.maxDateTime);

        const eventObj: IWidgetEvent = {
          name: 'graph',
          status: 'completed',
          id: this.id,
        };
        if (this.chartt) {
          this.statusEvent.emit(eventObj);
        }
      })
      .catch((error) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: error.toString(),
        };

        this.cs.openToaster(data);
      });
  }

  /**
   *
   * Color Functions
   */

  setSolidOrGradient(): void {
    this.stroke = {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: !!this.widget ? this.widget.color : '#4da6ff',
      width: 2,
      dashArray: 0,
    };

    this.colorObj = !!this.widget ? this.widget.colors : [];
  }

  getDummyData() {
    const { minDate, maxDate } = this.ws.getMinMaxDate('Month');
    const histdata = this.ws.getDataforChart(minDate, maxDate, this.type);
    this.emptyData = false;
    this.tickFormat = 'MMM D';
    this.setXaxis(minDate, maxDate);

    const eventObj: IWidgetEvent = {
      name: 'graph',
      status: 'completed',
      id: this.id,
    };
    if (this.chartt) {
      this.statusEvent.emit(eventObj);
    }

    this.initChartProperties(histdata);
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}

export interface IWidgetEvent {
  name: string;
  status: 'loading' | 'completed';
  id: string;
}
