import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private meshServiceProvidor: MeshServiceProvidor) {}

  getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (const cookie of ca) {
      let c = cookie;
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  isLoggedInUpdatedRole(cookie, role): Observable<any> {
    return new Observable((observer) => {
      if (cookie == null) {
        observer.next(false);
      } else {
        if (role == null) {
          this.meshServiceProvidor
            .getUserDetailsPromise()
            .then((userDetail) => {
              if (!!userDetail) {
                if ((userDetail as any).fieldveuRole) {
                  localStorage.setItem('role', (userDetail as any).fieldveuRole);
                }
                if ((userDetail as any).username) {
                  const lastLoggedIn = localStorage.getItem('lastLoggedIn');
                  if (!lastLoggedIn) {
                    localStorage.setItem('lastLoggedIn', (userDetail as any).username);
                  }
                }
                observer.next(true);
              }
            })
            .catch((error) => {
              observer.next(false);
            });
        } else {
          observer.next(true);
        }
      }
    });
  }
}
