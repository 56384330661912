<mat-progress-bar
  *ngIf="!subscriptionHandler && type !== 'template'"
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="card-content"
  fxFlex
  fxLayout="column"
  [ngClass]="{
    'cardtop-trouble': currentStatus === 'Trouble',
    'cardtop-warning': currentStatus === 'Warning',
    'cardtop-alarm': currentStatus === 'Alarm',
    'cardtop-offline': currentStatus === 'Offline',
    'opacity-1': !value && type !== 'template'
  }"
>
  <div class="main-content" fxFlex fxLayout="column">
    <div class="content1" fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <span class="card-main-head truncate" data-test="text_textkpi-title">{{
        widget.title | translate
      }}</span>
      <span
        data-test="edit_textkpi"
        (click)="edit('text-kpi', widget)"
        *ngIf="type == 'template'"
      >
        <mat-icon
          class="status-dropdown cursor"
          [ngStyle]="{ color: '#404040' }"
          >edit</mat-icon
        >
      </span>
      <img
        *ngIf="currentStatus == 'Warning'"
        class="card-status-image"
        src="../../../../assets/images/status/warning-status.svg"
        data-test="img_textapiwarning"
      />
      <img
        *ngIf="currentStatus == 'Alarm'"
        class="card-status-image"
        src="../../../../assets/images/status/alarm-status.svg"
        data-test="img_textapiwarning"
      />
      <img
        *ngIf="currentStatus == 'Trouble'"
        class="card-status-image"
        src="../../../../assets/images/status/trouble-status.svg"
        data-test="img_textapitrouble"
      />
      <img
        *ngIf="currentStatus == 'Offline'"
        class="card-status-image"
        src="../../../../assets/images/status/offline-status.svg"
        data-test="img_textapioffline"
      />
    </div>
    <div></div>
    <div
      [ngClass]="{
        'card-blur': currentStatus == 'Offline'
      }"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      <span class="device-imp-status-details normal-line-height" data-test="text_textkpi-status">{{
        value
      }}</span>
    </div>
    <div
      class="content-2"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between end"
    >
      <span class="time-stamp-font" data-test="text_textkpi-timevalue">{{
        timeValue
      }}</span>
    </div>
  </div>
</div>
