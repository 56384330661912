import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-expired',
  templateUrl: './invitation-expired.component.html',
  styleUrls: ['./invitation-expired.component.scss'],
})
export class InvitationExpiredComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
