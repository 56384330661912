<mat-toolbar class="mobile-filter menu-toolbar-mobile">
  <div
    class="subhead-container"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between"
  >
    <div
      class="device-content icon-align-mobile"
      fxLayoutAlign="center center"
      fxLayout="row"
    >
      <div>
        <span
          ><img
            data-test="action_close"
            (click)="close()"
            class="subhead-func-arrow-img-mobile"
            src="../../../../assets/images/ic-chevron-left-white.svg"
        /></span>
      </div>
      <div class="subhead-details-top-align-mobile">
        <span class="subhead-details-text-mobile" *ngIf="renderedFrom === undefined">
          {{ "common.filter_devices" | translate }}
        </span>
        <span class="subhead-details-text-mobile" *ngIf="renderedFrom === 'eventlog'">
          {{ "common.filter_evenlog" | translate }}
        </span>
      </div>
    </div>
  </div>
</mat-toolbar>
<section id="mobile-settings">
  <div class="content" fxLayout="column" fxLayoutGap="30px">
    <div class="sorting" fxLayout="column">
      <h4 class="header">{{ "common.sort_by" | translate }}</h4>
      <mat-form-field appearance="legacy">
        <mat-select
          data-test="input_change_order"
          (selectionChange)="changeOrderSelection($event)"
          #selectSortBy
          name="sortby"
          id="sortby"
          [value]="_sortActiveValues.by"
        >
          <mat-option *ngFor="let item of sortbyList" [value]="item">
            {{ "common." + item | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group
        data-test="input_change_order_toggle"
        (change)="changeOrderToggle($event)"
        name="order"
        aria-label="order"
        [value]="_sortActiveValues.order"
        appearance="legacy"
      >
        <mat-button-toggle fxFlex="row" class="order-button" value="asc">
          <mat-icon>arrow_upward</mat-icon>
          <span style="padding-left: 5px">{{
            "action.asc" | translate | uppercase
          }}</span>
        </mat-button-toggle>
        <mat-button-toggle fxFlex="row" class="order-button" value="desc">
          <mat-icon>arrow_downward</mat-icon>
          <span style="padding-left: 5px">{{
            "action.desc" | translate | uppercase
          }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="filtering" fxLayout="column" fxLayoutGap="25px">
      <h4 class="header">{{ "common.filter_by" | translate }}</h4>
      <ng-container *ngFor="let item of sortbyList">
        <div fxLayout="column">
          <label class="label-text" for="item" *ngIf="item !== 'notificationMessage'">
            {{ "common." + item | translate }}
          </label>
          <label class="label-text" for="item" *ngIf="item === 'notificationMessage'">
            {{ "common.event" + item | translate }}
          </label>
          <ng-container *ngIf="dropdownInput.includes(item); else searchInput">
            <div class="search-bar">
              <app-multi-select-dropdown
                [listOfOption]="statuses"
                [selectedItem]="_filterValues[item]?.value || []"
                (selectedOption)="selectedOption(item, $event)"
                [selectType]="'select'"
              ></app-multi-select-dropdown>
            </div>
          </ng-container>
          <ng-template #searchInput>
            <div class="search-bar" *ngIf="item !== 'dateTime'">
              <input
                #searchBar
                placeholder="Search..."
                type="text"
                class="search"
                [name]="item"
                [id]="item"
                autocomplete="off"
                [value]="_filterValues[item]?.value"
                (keyup)="searchText($event)"
              />             
              <mat-icon
                class="right-icon"
                (click)="updateFilter(searchBar.id, '')"
              >
                {{ searchBar.value ? "clear" : "search" }}
              </mat-icon>          
            </div>         
            <app-datetime-picker
            *ngIf="item === 'dateTime'"
            [startRangeDate]="_filterValues[item]?.value?.start || ''"
            [endRangeDate]="_filterValues[item]?.value?.end || ''"
            [placeholder]="'Select a date'"
            [positionUpdateBlockOnError]="true"
            [tooltip]="true"
            [displayDateFormat]="dateFormat"
            [clearBtn]="true"
            [defaultTime]="defaultTime"
            (selectedRange)="dateChange($event)"
            (clearAction)="updateFilter(item, '')"
          ></app-datetime-picker>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div class="action" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
      <button data-test="action_reset" class="msa-btn bg-grey" mat-flat-button (click)="reset()">
        {{ "action.reset" | translate | uppercase }}
      </button>
      <button
        data-test="action_apply"
        class="msa-btn msa-btn-primary"
        mat-flat-button
        [disabled]="!sortby.by"
        (click)="apply()"
      >
        {{ "action.apply" | translate | uppercase }}
      </button>
    </div>
  </div>
</section>
