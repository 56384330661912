<section
  class="combination-chart"
  [ngStyle]="{ width: type == 'edit' ? '99%' : 'auto' }"
  *ngIf="openedType === 'combination_chart' || type == 'edit'"
>
  <div class="main-section" fxLayout="row">
    <form #createForm="ngForm">
      <div fxFlex="38%" class="left-section">
        <mat-tab-group
          [selectedIndex]="currentActiveTab"
          (selectedTabChange)="onSelectedTabChange($event)"
          [ngClass]="{
            'error-data-tab': tabConfig?.dataTab?.isIconVisible,
            'error-design-tab': tabConfig?.designTab?.isIconVisible
          }"
        >
          <mat-tab label="DATA">
            <ng-template mat-tab-label>
              <!-- ###################### TAB TITLE TEMPLATE ######################## -->
              <ng-template
                [ngTemplateOutlet]="tabLabelTemplate"
                [ngTemplateOutletContext]="tabConfig.dataTab"
              ></ng-template>
            </ng-template>
            <!-- ###################### TAB BODY ######################## -->
            <div class="gauge-data-form" fxLayout="column">
              <div class="data-point">
                <h5 class="field-label">
                  {{ 'common.datapoint' | translate }}
                </h5>
                <mat-form-field
                  appearance="legacy"
                  [ngClass]="{ error: alertHandler.dataPointError }"
                >
                  <mat-select
                    data-test="data_point_selector"
                    #dataPointSelector
                    [placeholder]="'placeholder_messages.datapoint' | translate"
                    (selectionChange)="datapointChange($event)"
                  >
                    <mat-option class="custom-dropdown" *ngFor="let data of dataset" [value]="data">
                      {{ data.path }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <p class="error" *ngIf="alertHandler.dataPointError">
                  {{ alertHandler.dataPointError | translate }}
                </p>
              </div>

              <div class="unit-field">
                <h5 class="field-label">
                  {{ 'common.unit' | translate }}
                </h5>
                <mat-form-field appearance="legacy">
                  <input
                    data-test="unit_optional"
                    matInput
                    type="text"
                    [placeholder]="'placeholder_messages.unit' | translate"
                    [matAutocomplete]="unit"
                    name="unit"
                    autocomplete="off"
                    [(ngModel)]="unitValue"
                    #unitInput="matInput"
                    (keyup)="keyUpUnitOptions($event)"
                    (focus)="setUnitOptions()"
                    (change)="unitChange($event, 'inp')"
                  />
                  <label for="unit">
                    <mat-icon> arrow_drop_down </mat-icon>
                  </label>
                  <mat-autocomplete
                    #unit="matAutocomplete"
                    (optionSelected)="unitChange($event, 'sel')"
                  >
                    <mat-option
                      *ngFor="let unit of unitOptions"
                      [value]="unit.name"
                      [title]="unit.name"
                      class="custom-dropdown"
                    >
                      {{ unit.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <h3>{{ 'common.line-chart-only' | translate }}</h3>
              <mat-radio-group
                fxLayout="column"
                fxLayoutGap="20px"
                aria-label="Select an option"
                (change)="toggleScale($event)"
              >
                <mat-radio-button value="1" disableRipple="true" [checked]="matRadioValue == 1">
                  {{ 'common.autoscale' | translate }}
                </mat-radio-button>
                <mat-radio-button value="2" disableRipple="true" [checked]="matRadioValue == 2">
                  {{ 'common.gaugescale' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="default-time">
              <h4 class="normal">{{ 'common.defaulttime-1' | translate }}</h4>
              <h5>
                {{ 'message.datefilter' | translate }}
              </h5>

              <mat-button-toggle-group
                [value]="dataObject['defaultTimeWindow']?.toLowerCase()"
                (change)="dataObject['defaultTimeWindow'] = $event.value"
              >
                <mat-button-toggle class="temporal-btn" value="today">
                  {{ 'temporal.today' | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="temporal-btn" value="week">
                  {{ 'temporal.week' | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="temporal-btn" value="month">
                  {{ 'temporal.month' | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </mat-tab>
          <mat-tab label="DESIGN">
            <ng-template mat-tab-label>
              <!-- ###################### TAB TITLE TEMPLATE ######################## -->
              <ng-template
                [ngTemplateOutlet]="tabLabelTemplate"
                [ngTemplateOutletContext]="tabConfig.designTab"
              ></ng-template>
            </ng-template>
            <!-- ###################### TAB BODY ######################## -->

            <div class="design-tab" cdkScrollable>
              <h3>{{ 'common.gauge-only' | translate }}</h3>
              <ng-container *ngFor="let range of designMutlipeValue">
                <ng-template
                  [ngTemplateOutlet]="inputRangeWithColor"
                  [ngTemplateOutletContext]="range"
                ></ng-template>
                <p
                  class="error"
                  *ngIf="
                    (range.fieldOne.isDirty ||
                      designMutlipeValue[designMutlipeValue.length - 1].fieldTwo.isDirty ||
                      alertHandler.isFormSubmitted) &&
                    range.fieldOne.error
                  "
                >
                  {{ range.fieldOne.error | translate }}
                </p>
                <p
                  class="error"
                  *ngIf="
                    (designMutlipeValue[designMutlipeValue.length - 1].fieldOne.isDirty ||
                      range.fieldTwo.isDirty ||
                      alertHandler.isFormSubmitted) &&
                    range.fieldTwo.error
                  "
                >
                  {{ range.fieldTwo.error | translate }}
                </p>
              </ng-container>
              <ng-container *ngIf="!designMutlipeValue.length">
                <ng-template
                  [ngTemplateOutlet]="inputRangeWithColor"
                  [ngTemplateOutletContext]="defaultDesign"
                ></ng-template>
                <p
                  class="error"
                  *ngIf="
                    (defaultDesign.fieldOne.isDirty ||
                      defaultDesign.fieldTwo.isDirty ||
                      alertHandler.isFormSubmitted) &&
                    defaultDesign.error
                  "
                >
                  {{ defaultDesign.error | translate }}
                </p>
                <div class="label-field">
                  <h5 class="field-label">{{ 'common.arc-labels' | translate }} (optional)</h5>
                  <mat-form-field appearance="legacy" [ngClass]="{ error: arcLabelError }">
                    <input
                      matInput
                      type="text"
                      name="arclabel"
                      placeholder="e.g 25,50,75"
                      [(ngModel)]="arcLabelInput"
                      (blur)="arcLabelTyped($event)"
                      pattern="^[0-9.,]*$"
                    />
                  </mat-form-field>
                  <p class="error" *ngIf="arcLabelError">
                    {{ 'message.valid-range' | translate }}
                  </p>
                </div>
              </ng-container>

              <hr />

              <h3>{{ 'common.line-chart-only' | translate }}</h3>

              <ng-template
                [ngTemplateOutlet]="colorPicker"
                [ngTemplateOutletContext]="{
                  title: 'Graph line colour',
                  color: dataObject['color'],
                  showCheckbox: false
                }"
              ></ng-template>
              <div>
                <h4>{{ 'message.custromgrid_title' | translate }}</h4>
                <h5>
                  {{ 'message.customgrid' | translate }}
                </h5>
                <br />
                <h5 *ngIf="!colorgridOptions?.length">
                  {{ 'message.noGrid' | translate }}
                </h5>
              </div>
              <div fxLayout="column" fxLayoutGap="40px">
                <ng-container *ngFor="let item of colorgridOptions">
                  <ng-container>
                    <ng-template
                      [ngTemplateOutlet]="colorPicker"
                      [ngTemplateOutletContext]="{
                        title: item.type + ' ( ' + item.val + ' )',
                        color: item.color,
                        showCheckbox: true,
                        isChecked: item.checked
                      }"
                    ></ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <!-- ##################################### -->
          </mat-tab>
        </mat-tab-group>
        <div fxLayout="row" class="action-btn" fxLayoutGap="10px">
          <button mat-flat-button class="secondary" (click)="cancel()" type="button">
            {{ 'action.cancel' | translate | uppercase }}
          </button>
          <button
            *ngIf="editedData"
            mat-flat-button
            class="secondary"
            (click)="delete()"
            type="button"
          >
            {{ 'action.delete' | translate | uppercase }}
          </button>
          <button mat-flat-button class="primary" (click)="saveWidget()">
            {{ 'action.save-widget' | translate | uppercase }}
          </button>
        </div>
      </div>
      <div fxFlex="62%" class="right-section">
        <div class="preview">
          <div fxLayout="row">
            <mat-card fxFlex="45%" #gaugeContainer class="gauge-container">
              <div [ngClass]="titleUpdated == true ? 'newTtle' : 'titlePlaceholder'">
                {{ dataObject.title }}
              </div>
              <app-fv-gauge-chart
                id="gauge-area"
                [canvasWidth]="gaugeData.canvasWidth"
                [needleValue]="gaugeData.needleValue"
                [centralLabel]="gaugeData.centralLabel"
                [options]="gaugeData.options"
                [bottomLabel]="gaugeData.bottomLabel || defaultBottomLabel"
                [bottomLabelFont]="gaugeData.bottomLabelFont"
              ></app-fv-gauge-chart>
              <div class="date-time">00:00:00</div>
            </mat-card>
            <mat-card fxFlex="55%" class="preview-line">
              <div [ngClass]="titleUpdated == true ? 'newTtle' : 'titlePlaceholder'">
                {{ dataObject.title }}
              </div>
              <div fxLayout="row wrap" class="datecontrol-linechart">
                <div class="datecontrol-linechart">
                  <button class="today" type="button" value="today" mat-button>
                    {{ 'temporal.today' | translate }}
                  </button>
                  <button type="button" class="week" value="week" mat-button>
                    {{ 'temporal.week' | translate }}
                  </button>
                  <button type="button" class="month" value="month" mat-button>
                    {{ 'temporal.month' | translate }}
                  </button>
                </div>

                <div class="customdateimg">
                  <button type="button" class="" value="week" mat-button>
                    mm/dd/yy hh:mm - mm/dd/yy hh:mm <mat-icon> event </mat-icon>
                  </button>
                </div>
              </div>
              <div class="zoompanmarker-control">
                <mat-checkbox class="showMarkerCheck"
                  >{{ 'action.show-markers' | translate }}
                </mat-checkbox>

                <img
                  class="histchart-toolbar"
                  src="../../../assets/images/ic-keyboard-arrow-left.svg"
                />
                <img
                  class="histchart-toolbar"
                  src="../../../assets/images/ic-keyboard-arrow-right.svg"
                />

                <img class="histchart-toolbar" src="../../../assets/images/ic-zoom-out.svg" />

                <img class="histchart-toolbar" src="../../../assets/images/ic-zoom-in.svg" />
              </div>
              <div *ngIf="chart != undefined">
                <div id="chartcontainerapexpreview">
                  <apx-chart
                    #charttt
                    [series]="series"
                    [chart]="chart"
                    [xaxis]="xaxis"
                    [tooltip]="tooltip"
                    [stroke]="stroke"
                    [markers]="markers"
                    [annotations]="annotations"
                    [yaxis]="yaxis"
                  ></apx-chart>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<!-- ########################## TAB TITLE UI ###################################### -->
<ng-template
  #tabLabelTemplate
  let-title="title"
  let-iconName="iconName"
  let-iconColor="iconColor"
  let-isIconVisible="isIconVisible"
>
  <div class="label-template" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <div class="tab-title">
      <h1>{{ title }}</h1>
    </div>
    <div *ngIf="isIconVisible">
      <mat-icon class="material-icons-round" [ngStyle]="{ color: iconColor }">
        {{ iconName }}
      </mat-icon>
    </div>
  </div>
</ng-template>

<!-- ################## TEMPLATE 2 INPUT TEXT FIELD WITH 1 COLOR FIELD ################## -->

<ng-template
  #inputRangeWithColor
  let-title="title"
  let-fieldOne="fieldOne"
  let-fieldTwo="fieldTwo"
  let-fieldColor="fieldColor"
  let-error="error"
>
  <div class="range-input-field">
    <h4>{{ title }}</h4>
    <div fxLayout="row" fxLayoutGap="25px">
      <div class="input-field" fxLayout="column">
        <h5>{{ fieldOne.name }}</h5>
        <input
          matInput
          #fieldOneInput="ngModel"
          [ngClass]="{
            error: (fieldOne.isDirty || alertHandler.isFormSubmitted) && (fieldOne.error || error)
          }"
          type="number"
          [name]="fieldOne.name"
          [(ngModel)]="fieldOne.value"
          [disabled]="fieldOne.isDisabled"
          (keyup)="fieldOne.isDirty = fieldOneInput.dirty"
          (change)="valueChanged($event, fieldOne.name); fieldOne.isDirty = fieldOneInput.dirty"
        />
      </div>
      <div class="input-field" fxLayout="column">
        <h5>{{ fieldTwo.name }}</h5>
        <input
          matInput
          #fieldTwoInput="ngModel"
          type="number"
          [ngClass]="{
            error: (fieldTwo.isDirty || alertHandler.isFormSubmitted) && (fieldTwo.error || error)
          }"
          [name]="fieldTwo.name"
          [(ngModel)]="fieldTwo.value"
          [disabled]="fieldTwo.isDisabled"
          (keyup)="fieldTwo.isDirty = fieldTwoInput.dirty"
          (change)="valueChanged($event, fieldTwo.name); fieldTwo.isDirty = fieldTwoInput.dirty"
        />
      </div>
      <div class="input-field" fxLayout="column">
        <h5>&nbsp;</h5>
        <input
          matInput
          type="color"
          [name]="fieldColor.name"
          [(ngModel)]="fieldColor.value"
          (change)="valueChanged($event)"
        />
      </div>
    </div>
  </div>
</ng-template>

<!-- ################## TEMPLATE - DESIGN TAB UI ################## -->

<ng-template
  #colorPicker
  let-title="title"
  let-color="color"
  let-showCheckbox="showCheckbox"
  let-isChecked="isChecked"
>
  <div class="color-selection">
    <div fxLayout="row" class="input-field" fxLayoutAlign="start baseline">
      <div *ngIf="showCheckbox" style="margin: 15px">
        <mat-checkbox
          [checked]="isChecked"
          (change)="checkboxStatusChange($event, title)"
        ></mat-checkbox>
      </div>
      <!-- [fxFlex]="showCheckbox ? '49.9%' : '59%'" -->
      <div
        [ngStyle]="{
          'margin-right': showCheckbox ? '60px' : '104px',
          width: showCheckbox ? '100px' : 'auto'
        }"
      >
        <p style="text-transform: capitalize">
          {{ title }}
        </p>
      </div>
      <div>
        <input
          matInput
          [attr.data-test]="'input_' + title"
          type="color"
          [value]="color"
          (change)="showCheckbox ? gridlineColorChange($event, title) : lineColorChange($event)"
        />
      </div>
    </div>
  </div>
</ng-template>
