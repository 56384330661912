<div [ngClass]="{ 'opacity-after': !chartt }">
  <mat-progress-bar
    class="progress-bar p-absolute-top"
    mode="indeterminate"
    *ngIf="!chartt"
  ></mat-progress-bar>

  <div *ngIf="chartt != undefined">
    <div id="chartcontainerapex">
      <apx-chart
        #chart
        [series]="series"
        [chart]="chartt"
        [xaxis]="xaxis"
        [tooltip]="tooltip"
        [stroke]="stroke"
        [markers]="markers"
        [noData]="noData"
        [annotations]="annotations"
        [yaxis]="yaxis"
      ></apx-chart>
    </div>
  </div>
</div>
