import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-log-search',
  templateUrl: './event-log-search.component.html',
  styleUrls: ['./event-log-search.component.scss'],
})
export class EventLogSearchComponent implements OnInit {
  @Input() value = '';
  @Output() searchingText = new EventEmitter<InputEvent>();
  @Output() openFilter = new EventEmitter<null>();
  @Output() updateFilter = new EventEmitter<{ id: string; value: string }>();
  constructor() {}

  ngOnInit(): void {}

  searchText(event: InputEvent): any {
    this.searchingText.emit(event);
  }

  _openFilter(): any {
    this.openFilter.emit();
  }

  _updateFilter(id: string, value: string): any {
    this.updateFilter.emit({ id, value });
  }
}
