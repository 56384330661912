import { FormGroup, FormControl, Validators, FormControlName, FormBuilder } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexMarkers,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
} from 'ng-apexcharts';
import { distinctUntilChanged, ignoreElements, map, startWith } from 'rxjs/operators';
import { ceData } from '../../../../shared/constants/ce-data';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import * as moment from 'moment';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/service/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { Subscription } from 'rxjs';
import { addClass, removeClass } from 'src/app/shared/class/util';

@Component({
  selector: 'app-addedit-linechart',
  templateUrl: './addedit-linechart.component.html',
  styleUrls: ['./addedit-linechart.component.scss'],
})
export class AddeditLinechartComponent implements OnInit, OnDestroy {
  @ViewChild('charttt', { static: true })
  charttt: ChartComponent = new ChartComponent();
  @Input() id;
  @Input() type;
  public series: ApexAxisChartSeries;
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: any;
  public tooltip: ApexTooltip;
  public disabled = false;
  stroke: any;
  chart: any;
  chartContext: any;
  autoScale: boolean;
  annotations: {
    position: 'back';
  };
  dataObject = { ...ceData.linechartDefaultData };
  defaultData: any;
  dataTabError = false;
  selectedTab: any = 0;
  submitted = false;
  dataset: any;

  colorgridOptions = [];

  units: FormControl;

  linechartForm = this.fb.group({
    data: this.fb.group({
      dataPath: ['', [Validators.required]],
      units: [''],
      autoScale: [true, [Validators.required]],
    }),
    design: this.fb.group({
      color: [ceData.linechartDefaultData.color, [Validators.required]],
    }),
  });
  filteredUnits: any;
  sub: Subscription[] = [];

  titleUpdated = false;
  gridLines = false;
  minmaxeror = false;
  editedData: any;
  widgetID: any;
  defaultTimeWindow: string;
  constructor(
    public fb: FormBuilder,
    private _meshServiceProvidor: MeshServiceProvidor,
    private ws: WidgetsService,
    private _templateService: TemplateService,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.filteredUnits = this.linechartForm.controls.data.get('units').valueChanges.pipe(
      startWith(''),
      map((unit) => (unit ? this.filterUnits(unit) : ceData.unitOptions.slice()))
    );

    this.dataset =
      this.ws.dataPoints === undefined
        ? JSON.parse(sessionStorage.getItem('dataPoints'))
        : this.ws.dataPoints;
  }

  filterUnits(name: string) {
    return ceData.unitOptions.filter((unit) =>
      unit.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  ngOnInit(): void {
    this.selectedTab = 0;
    this.defaultData = { ...ceData.linechartDefaultData };

    const sub1 = this._meshServiceProvidor.cast.subscribe((res) => {
      window.dispatchEvent(new Event('resize'));
    });

    this.dataObject.defaultTimeWindow = sessionStorage.getItem('defaultTimeFilter')
      ? sessionStorage.getItem('defaultTimeFilter')
      : 'Month';
    this.defaultData.defaultTimeWindow = this.dataObject.defaultTimeWindow;
    this.defaultTimeWindow = this.dataObject.defaultTimeWindow;

    if (this.type === 'add') {
      this.ws.selectWidgetChange.subscribe((p) => {
        if (p === 'line_chart') {
          this.setApexchart();
        }
      });
    } else if (this.type === 'edit') {
      this.setApexchart();
      const d = this._templateService.getEditData(this.id);
      if (!!d) {
        this.editedData = d[0];
        this.setData(d[0]);
      }
    }

    const sub2 = this.linechartForm.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        const units = value.data.units !== null ? value.data.units : this.defaultData.units;
        this.dataObject.autoscale =
          value.data.autoScale !== null ? value.data.autoScale : this.defaultData.autoscale;
        this.dataObject.min = value.data.min !== null ? value.data.min : '';
        this.dataObject.max = value.data.max !== null ? value.data.max : '';
        this.dataObject.color =
          value.design.color != null ? value.design.color : this.defaultData.color;
        this.checkforUnits(units);
        this.checkfordataPoint(value);
        this.addRemoveMinMax();
        this.checkforMinMax();
        this.checkforStroke();

        if (this.linechartForm.valid) {
          this.dataTabError = false;
          this.manageTabInkbarClass();
        }
      });

    this.sub = [sub1, sub2];
  }

  checkforUnits(units) {
    this.dataObject.units = units !== '' ? (units.includes('-') ? units.split('-')[0] : units) : '';
    this.setUnits();
  }
  checkfordataPoint(value) {
    if (
      !!value &&
      !!value.data &&
      value.data.dataPath !== null &&
      value.data.dataPath !== this.dataObject.dataPath
    ) {
      this.dataObject.dataPath = value.data.dataPath;
      this.dataObject.title = this.dataObject.dataPath;
      this.checkforTitle();

      this.checkforAnno(this.dataObject.dataPath);
    } else if (typeof value === 'string') {
      this.dataObject.dataPath = value;
      this.dataObject.title = this.dataObject.dataPath;
      this.checkforTitle();

      this.checkforAnno(this.dataObject.dataPath);
    }
  }
  addRemoveMinMax() {
    if (this.dataObject.autoscale === false) {
      (this.linechartForm.get('data') as FormGroup).addControl(
        'min',
        this.fb.control('', Validators.required)
      );
      (this.linechartForm.get('data') as FormGroup).addControl(
        'max',
        this.fb.control('', Validators.required)
      );

      if (this.type === 'edit' && this.dataObject.autoscale === false) {
        this.linechartForm.controls.data.patchValue({
          min: this.dataObject.min,
          max: this.dataObject.max,
        });
      }
    } else if (this.dataObject.autoscale === true) {
      (this.linechartForm.get('data') as FormGroup).removeControl('min');
      (this.linechartForm.get('data') as FormGroup).removeControl('max');
      this.removeMinandMax();
    }
  }
  checkforMinMax() {
    if (
      this.dataObject.min !== '' &&
      this.dataObject.min !== undefined &&
      this.dataObject.min !== null &&
      this.dataObject.max !== '' &&
      this.dataObject.max !== undefined &&
      this.dataObject.max !== null
    ) {
      if (this.dataObject.min >= this.dataObject.max) {
        this.minmaxeror = true;
      } else {
        this.minmaxeror = false;
        if (this.dataObject.min !== this.dataObject.max) {
          this.setMinandMax(this.dataObject.min, this.dataObject.max);
        }
      }
    }
  }
  checkforStroke() {
    if (!!this.stroke && this.dataObject.color !== this.stroke.colors) {
      this.setStroke();
    }
  }
  checkforTitle() {
    if (this.dataObject.title !== this.defaultData.title) {
      this.titleUpdated = true;
    } else {
      this.titleUpdated = false;
    }
  }

  comparedatapointObjects(object1: any, object2: any) {
    return object1 && object2 && object1.trim() === object2.trim();
  }

  setData(editData) {
    let unitTextArr;
    let unitText;
    if (!!editData.units) {
      unitTextArr = ceData.unitOptions.filter(function (p) {
        return p.name.split('-')[0] === editData.units;
      });
      unitText = unitTextArr.length > 0 ? unitTextArr[0].name : editData.units;
    } else {
      unitText = '';
    }

    this.setcolorgridOptions(editData.colors, editData.dataPath);

    this.linechartForm.controls.data.patchValue({
      dataPath: editData.dataPath,
      units: unitText,
      autoScale: 'autoscale' in editData ? editData.autoscale : true,
    });
    this.linechartForm.controls.design.patchValue({
      color: editData.color,
    });

    this.widgetID = editData.widgetID;

    this.dataObject = {
      autoscale: editData.autoscale,
      color: editData.color,
      colors: editData.colors,
      dataPath: editData.dataPath,
      defaultTimeWindow: this.defaultTimeWindow,
      max: editData.max,
      min: editData.min,
      title: editData.title,
      type: 'line-chart',
      units: editData.units,
      colorgridOptions: this.colorgridOptions,
    };

    this.checkforTitle();
    this.checkforUnits(unitText);
    this.checkfordataPoint(this.dataObject.dataPath);
    this.addRemoveMinMax();
    this.checkforMinMax();
    this.checkforStroke();
  }

  setcolorgridOptions(colors, datapath) {
    const colorgridarr = this.dataset.filter(function (o) {
      return o.path.trim() === datapath.trim();
    });
    if (!!colorgridarr) {
      const obj = colorgridarr[0];
      if (!!obj && 'eventRules' in obj) {
        this.create_colorgridOptions(obj.eventRules);
        if (colors.length === this.colorgridOptions.length) {
          this.colorgridOptions.map((p) => {
            const color = colors.filter((x) => x.y === p.val);
            p.color = color[0].color;
          });
        } else {
          this.colorgridOptions.map((p) => {
            const color = colors.filter((x) => x.y === p.val);
            if (color.length > 0) {
              p.color = color[0].color;
            } else {
              p.checked = false;
            }
          });
        }
      } else {
        this.gridLines = false;
      }
    }
  }

  checkforAnno(datapoint) {
    if (
      this.type === 'add' ||
      (this.type === 'edit' && this.dataObject.dataPath !== this.editedData.dataPath)
    ) {
      const colorgridarr = this.dataset.filter(function (o) {
        return o.path.trim() === datapoint.trim();
      });
      if (!!colorgridarr) {
        const obj = colorgridarr[0];
        if (!!obj && 'eventRules' in obj) {
          this.create_colorgridOptions(obj.eventRules);
          this.gridLines = true;
        } else {
          this.colorgridOptions = [];
          this.gridLines = false;
          if (!!this.chartContext) {
            this.chartContext.clearAnnotations();
          }
        }
      }
    } else if (this.type === 'edit' && this.dataObject.dataPath === this.editedData.dataPath) {
      if (!!this.dataObject.colors && this.dataObject.colors.length > 0) {
        this.gridLines = true;
        this.addAnnotation();
        this.colorgridOptions = [...this.dataObject.colorgridOptions];
      } else if (
        this.dataObject.colors.length === 0 &&
        this.dataObject.colorgridOptions.length > 0
      ) {
        this.gridLines = true;
        this.dataObject.colorgridOptions.map((p) => {
          if (p.type.includes('warning')) {
            p.color = ceData.gridLine.warning;
          } else if (p.type.includes('alarm')) {
            p.color = ceData.gridLine.alarm;
          } else {
            p.color = ceData.gridLine.other;
          }
        });

        this.colorgridOptions = [...this.dataObject.colorgridOptions];
      } else {
        this.gridLines = false;
        this.colorgridOptions = [];
        if (!!this.chartContext) {
          this.chartContext.clearAnnotations();
        }
      }
    }
  }

  create_colorgridOptions(eventRules) {
    let data = [];
    if (!Array.isArray(eventRules)) {
      data.push(eventRules);
    } else {
      data = eventRules;
    }

    this.colorgridOptions = [];
    data.map((p, i) => {
      const obj = {
        checked: true,
        val: p.alarm_setpoint,
        type: p.type,
        id: i,
        color: '',
        message: `${p.type} ( ${p.alarm_setpoint} )`,
      };
      if (p.type.includes('warning')) {
        obj.color = ceData.gridLine.warning;
      } else if (p.type.includes('alarm')) {
        obj.color = ceData.gridLine.alarm;
      } else {
        obj.color = ceData.gridLine.other;
      }
      this.colorgridOptions.push(obj);
    });
    this.addAnnotation();
  }

  setApexchart() {
    const { minDate, maxDate } = this.ws.getMinMaxDate('Month');

    const chartData = this.ws.getDataforChart(minDate, maxDate);
    this.initChartData(chartData);
    this.setStroke();
    this.setUnits();
  }

  checkboxGridline(event, i) {
    this.colorgridOptions[i].checked = event.checked;
    if (!event.checked) {
      this.removeAnno(i);
    } else {
      this.addOneAnno(i);
    }
  }

  setgridLineColor(event, i) {
    this.colorgridOptions[i].color = event.target.value.toString();
    this.addAnnotation();
  }

  createFinalObject() {
    const colors = [];

    this.colorgridOptions.map((p) => {
      if (p.checked === true) {
        const colorobj = {
          y: p.val,
          color: p.color,
        };
        colors.push(colorobj);
      }
    });
    const templateData = this._templateService.tempData;

    if (this.type === 'add') {
      templateData.push({
        widgetID: 'line-chart' + Math.floor(Math.random() * 1000000),
        color: this.dataObject.color,
        dataPath: this.dataObject.dataPath,
        title: this.dataObject.title,
        type: 'line-chart',
        units: this.dataObject.units,
        autoscale: this.dataObject.autoscale,
        colors,
        min: this.dataObject.min,
        max: this.dataObject.max,
        height: 2,
        width: 3,
      });

      this._templateService.tempData = templateData;
    } else if (this.type === 'edit') {
      const foundIndex = templateData.findIndex((x) => x.widgetID === this.widgetID);

      const newObj = {
        ...this.editedData,
        color: this.dataObject.color,
        dataPath: this.dataObject.dataPath,
        title: this.dataObject.title,
        units: this.dataObject.units,
        autoscale: this.dataObject.autoscale,
        colors,
        min: this.dataObject.min,
        max: this.dataObject.max,
      };

      templateData[foundIndex] = newObj;

      templateData[foundIndex] = newObj;

      this._templateService.tempData = templateData;
      sessionStorage.setItem('tempData', JSON.stringify(templateData));
    }

    sessionStorage.setItem('defaultTimeFilter', this.dataObject.defaultTimeWindow);

    this.router.navigate(['/main/template/blanktemplate']);
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.widgetID, modalType: 'delete-widget' },
    });
  }

  removeMinandMax() {
    this.yaxis = {};
  }
  setMinandMax(min, max) {
    this.yaxis = {
      min,
      max,
    };
  }

  saveWidget() {
    this.submitted = true;
    if (this.linechartForm.valid && this.minmaxeror === false) {
      this.dataTabError = false;
      this.manageTabInkbarClass();
      this.createFinalObject();
    } else {
      this.selectedTab = 0;
      this.dataTabError = true;
      this.manageTabInkbarClass();
    }
  }

  onTabClickedChanged(e) {
    this.selectedTab = e.index;
    this.manageTabInkbarClass();
  }

  public initChartData(chartData): void {
    const series = [
      {
        name: 'Historical Chart',
        data: chartData,
      },
    ];
    this.series = [...series];
    const that = this;
    this.chart = {
      type: 'line',
      height: 350,
      stacked: false,
      width: '97%',
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,

      toolbar: {
        show: false,
      },
      zoom: {
        type: 'x',
        enabled: false,
      },
      events: {
        mounted(chartContext, config) {
          that.chartContext = chartContext;
          if (
            !!chartContext &&
            chartContext.annotations !== undefined &&
            chartContext.annotations != null
          ) {
            that.addAnnotation();
          }
          if (this.type === 'edit') {
            this.checkfordataPoint(that.dataObject.dataPath);
          }
        },
      },
    };

    this.dataLabels = {
      enabled: false,
    };
    this.markers = {
      size: 0,
    };

    this.yaxis = {};

    this.tooltip = {
      shared: false,
      y: {
        formatter(val) {
          return (val / 1000000).toFixed(0);
        },
      },
    };

    this.xaxis = {
      type: 'datetime',
      offsetX: 30,
      tickAmount: 5,
      labels: {
        show: true,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
        maxHeight: 120,
        datetimeUTC: true,

        formatter(value) {
          return moment(value).format('MMM D');
        },
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
        formatter(value) {
          return moment(value).format('MMM D,HH:mm:ss');
        },
      },
    };
  }

  addOneAnno(id) {
    if (!!this.chartContext) {
      this.chartContext.addYaxisAnnotation(
        {
          id: 'my-annotation' + id,
          y: parseInt(this.colorgridOptions[id].val, 10),
          borderColor: this.colorgridOptions[id].color,
          strokeDashArray: 0,
        },
        true,
        this.chartContext
      );
    }
  }

  cancel() {
    this.dataTabError = false;
    this.manageTabInkbarClass();
    this.submitted = false;
    this.minmaxeror = false;
    this.router.navigate(['/main/template/blanktemplate']);
  }

  addAnnotation() {
    this.colorgridOptions.map((p) => {
      if (!!this.chartContext && p.checked === true) {
        const idname = p.id.toString();

        this.chartContext.addYaxisAnnotation(
          {
            id: 'my-annotation' + p.id,
            y: parseInt(p.val, 10),
            borderColor: p.color,
            strokeDashArray: 0,
          },
          true,
          this.chartContext
        );
      }
    });
  }

  removeAnno(id) {
    this.chartContext.removeAnnotation('my-annotation' + id);
  }

  setDefaultTime(type) {
    this.dataObject.defaultTimeWindow = type;
  }

  setStroke() {
    this.stroke = {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: this.dataObject.color,
      width: 2,
      dashArray: 0,
    };
  }

  setUnits() {
    const units = this.dataObject.units;
    this.tooltip = {
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const displayDate =
          '<div class="arrow_box">' +
          '<span>' +
          +series[seriesIndex][dataPointIndex] +
          ' ' +
          units +
          '</span>' +
          '</div>';
        return displayDate;
      },
    };
  }

  manageTabInkbarClass() {
    const selector = 'app-addedit-linechart .mat-ink-bar';
    const className = 'error';
    if (this.selectedTab === 0) {
      if (this.dataTabError) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    } else {
      removeClass(selector, className);
    }
  }

  ngOnDestroy() {
    this.ws.set_selectedWidget = null;
    this.sub.forEach((sub) => sub.unsubscribe());
  }
}
