import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreLoginRouteManagerService {
  private readonly redirectKey = 'redirectTo';
  constructor(private router: Router) {}

  set redirectTo(path: string) {
    localStorage.setItem(this.redirectKey, path);
  }

  get redirectTo() {
    return localStorage.getItem(this.redirectKey);
  }

  clearRedirect() {
    localStorage.removeItem(this.redirectKey);
  }

  goRedirect() {
    const path = this.redirectTo;
    if (path) {
      this.router.navigate([this.redirectTo]);
      this.clearRedirect();
    }
  }
}
