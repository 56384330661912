<div class="profile-container">
  <div class="register-profile-title">
    {{ "action.my-profile" | translate }}
  </div>

  <div
    [ngClass]="{ hidewithopacity: dataLoaded === false }"
    class="container-profile"
  >
    <form [formGroup]="profileForm">
      <div class="profile-field">
        <div class="profile-header">
          {{ "common.email-address" | translate }}
        </div>
        <div class="profile-value">{{ email }}</div>
      </div>
      <div class="profile-field">
        <div class="profile-header">{{ "common.firstName" | translate }}</div>
        <input
          maxlength="100"
          class="nameinput"
          formControlName="firstname"
          [ngClass]="{
            'err-border':
              !profileForm.valid &&
              submitted &&
              profileForm.controls['firstname']?.errors?.required
          }"
        />
        <div
          class="errorMsg"
          *ngIf="
            !profileForm.valid &&
            submitted &&
            profileForm.controls['firstname']?.errors?.required
          "
        >
          {{ "message.valid-firstname" | translate }}
        </div>
      </div>
      <div class="profile-field">
        <div class="profile-header">{{ "common.lastName" | translate }}</div>
        <input
          maxlength="100"
          class="nameinput"
          formControlName="lastname"
          [ngClass]="{
            'err-border':
              !profileForm.valid &&
              submitted &&
              profileForm.controls['lastname']?.errors?.required
          }"
        />
        <div
          class="errorMsg"
          *ngIf="
            !profileForm.valid &&
            submitted &&
            profileForm.controls['lastname']?.errors?.required
          "
        >
          {{ "message.valid-lastname" | translate }}
        </div>
      </div>

      <div class="profile-field">
        <div class="profile-header">
          {{ "common.mobile" | translate }}
          <img
            class="help"
            [matTooltipPosition]="'after'"
            [matTooltip]="'message.register-tooltip' | translate"
            matTooltipClass="customtooltip-reg"
            src="../../../../assets/images/ic-help.svg"
          />
        </div>
        <div class="mobileNo">
          <input
            id="phone"
            [ngClass]="{
              errorBorder: !validPhone
            }"
            [placeholder]="'placeholder_messages.mobile' | translate"
            formControlName="phone"
            type="tel"
            (change)="checkifValid($event)"
            (keyup)="checkifValid($event)"
            (blur)="checkifValid($event)"
          />
        </div>
        <div class="errorMsg" *ngIf="!validPhone">
          {{ "message.valid-mobile" | translate }}
        </div>
      </div>

      <div class="profile-field">
        <div class="profile-header">
          {{ "common.timezone" | translate }}
        </div>
        <mat-select
          [typeaheadDebounceInterval]="500"
          [compareWith]="comparedatapointObjects"
          formControlName="timezone"
          class="timezoneselect"
          [(value)]="selectedTimezone"
        >
          <mat-option *ngFor="let time of timezone" [value]="time">
            {{ time.displayText }}
          </mat-option>
        </mat-select>
      </div>

      <div class="profile-field">
        <div class="profile-header">{{ "common.user-role" | translate }}</div>
        <div class="profile-value">
          {{ userDetails?.fieldveuRole }}
        </div>
      </div>

      <div class="NotificationBlock">
        <div class="notification-title">
          {{ "common.notification-settings" | translate }}
        </div>
        <div class="notification-subtitle">
          {{ "message.notication-message" | translate }}
        </div>
        <div class="email-sms">
          <mat-checkbox
            (click)="checkforemail()"
            formControlName="notification_email"
            class="example-margin"
            >{{ "common.email" | translate }}</mat-checkbox
          >
        </div>
        <div class="email-sms">
          <mat-checkbox
            formControlName="notification_sms"
            class="example-margin"
            >{{ "common.sms" | translate }}</mat-checkbox
          >
        </div>
      </div>
      <div class="btn-container">
        <button
          [ngClass]="sentrequest === true ? 'disable-click' : 'enable-click'"
          class="btn secondary-btn cancel"
          mat-flat-button
          data-test="action_cancel"
          (click)="cancel()"
          type="button"
        >
          {{ "action.cancel" | translate | uppercase }}
        </button>

        <button
          [ngClass]="sentrequest === true ? 'disable-click' : 'enable-click'"
          type="submit"
          class="btn primary-btn save"
          mat-flat-button
          data-test="save-profile"
          (click)="save()"
        >
          {{ "action.save" | translate | uppercase }}
        </button>
      </div>
    </form>
  </div>
</div>
