<section class="line-chart-editor">
  <div class="main-section" fxLayout="row">
    <div fxFlex="38%" class="left-section">
      <mat-tab-group
        [selectedIndex]="currentActiveTab"
        (selectedTabChange)="onSelectedTabChange($event)"
        [ngClass]="{
          'error-data-tab': tabConfig?.dataTab?.isIconVisible,
          'error-design-tab': tabConfig?.designTab?.isIconVisible
        }"
      >
        <mat-tab label="DATA">
          <ng-template mat-tab-label>
            <!-- ###################### TAB TITLE TEMPLATE ######################## -->
            <ng-template
              [ngTemplateOutlet]="tabLabelTemplate"
              [ngTemplateOutletContext]="tabConfig.dataTab"
            ></ng-template>
          </ng-template>

          <!-- ###################### TAB BODY ######################## -->

          <form [formGroup]="lineChartForm">
            <div class="gauge-data-form" fxLayout="column">
              <!-- ############################## DATA POINT SELECTION SECTION ##################################################### -->
              <div formArrayName="datapoints">
                <ng-container
                  *ngFor="
                    let datapointsControl of datapointsControls.controls;
                    let rowIndex = index
                  "
                >
                  <div [@enterAnimation] class="datapoint-section">
                    <!-- ###################### DATA POINT SELECTION HEADER ########################### -->
                    <div class="datapoint-header" fxLayout="row" fxLayoutAlign="start center">
                      <h4 style="width: 100%; text-transform: capitalize">
                        {{ 'common.data-point' | translate }} {{ rowIndex + 1 }}
                      </h4>

                      <!-- ###################### DATA POINT SELECTION DELETE ACTION ########################### -->
                      <span *ngIf="rowIndex > 0 && !dualYAxis">
                        <mat-icon class="cursor" (click)="removeDatapointGroupFromForm(rowIndex)"
                          >delete</mat-icon
                        >
                      </span>
                      <!-- ======================= DATA POINT SELECTION DELETE ACTION ============================ -->
                    </div>
                    <!-- ======================= DATA POINT SELECTION HEADER ============================ -->

                    <!-- ###################### DATA POINT SELECTION CONTENT ########################### -->
                    <div class="datapoint-content">
                      <!-- ###################### DATA POINT SELECTION INPUT ########################### -->
                      <div class="data-point">
                        <h5 class="field-label">
                          {{ 'common.datapoint' | translate }}
                        </h5>
                        <mat-form-field
                          appearance="legacy"
                          [formGroupName]="rowIndex"
                          [ngClass]="{
                            error:
                              datapointsControl.get('datapoint')?.invalid &&
                              datapointsControl.get('datapoint').touched
                          }"
                        >
                          <mat-select
                            formControlName="datapoint"
                            [placeholder]="'placeholder_messages.datapoint' | translate"
                            [compareWith]="isObjectsEqual"
                          >
                            <mat-option
                              *ngFor="let data of dataset"
                              [value]="data"
                              [disabled]="listOfSelectDatapoint | arrayIncludes: data.path:'path'"
                              class="custom-dropdown"
                            >
                              {{ data.path }}
                            </mat-option>
                          </mat-select>
                          <mat-error>
                            <p
                              class="error"
                              *ngIf="
                                datapointsControl.get('datapoint')?.invalid &&
                                datapointsControl.get('datapoint').touched
                              "
                            >
                              {{ 'message.valid-data-point' | translate }}
                            </p>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- ======================= DATA POINT SELECTION INPUT ============================ -->
                      <div class="spacing"></div>
                      <!-- ###################### DATA POINT UNIT INPUT ########################### -->
                      <div class="unit-field">
                        <h5 class="field-label">
                          {{ 'common.unit' | translate }}
                        </h5>
                        <mat-form-field appearance="legacy" [formGroupName]="rowIndex">
                          <input
                            matInput
                            formControlName="unitOfMeasure"
                            type="text"
                            [placeholder]="'placeholder_messages.unit' | translate"
                            name="unit"
                            autocomplete="off"
                            [matAutocomplete]="unit"
                            #unitInput="matInput"
                          />
                          <label for="unit">
                            <mat-icon> arrow_drop_down </mat-icon>
                          </label>
                          <mat-autocomplete #unit="matAutocomplete">
                            <mat-option
                              *ngFor="let unit of unitOptions"
                              [value]="unit.name"
                              [title]="unit.name"
                              class="custom-dropdown"
                            >
                              {{ unit.name }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <!-- ======================= DATA POINT UNIT INPUT ============================ -->
                      <div class="spacing"></div>
                      <!-- ###################### DATA POINT MIN AND MAX FOR DUAL AXIS ########################### -->
                      <div class="min-max-section" *ngIf="dualYAxis">
                        <div *ngIf="datapointsControl.get('min')">
                          <h5 class="field-label">
                            {{ 'common.minvalue' | translate }}
                          </h5>

                          <mat-form-field
                            appearance="legacy"
                            [formGroupName]="rowIndex"
                            [ngClass]="{
                              error:
                                datapointsControl.get('min')?.invalid &&
                                datapointsControl.get('min')?.touched
                            }"
                          >
                            <input
                              matInput
                              type="number"
                              formControlName="min"
                              [placeholder]="'placeholder_messages.minvalue' | translate"
                            />
                            <mat-error
                              *ngIf="
                                datapointsControl.get('min')?.invalid &&
                                datapointsControl.get('min')?.touched
                              "
                            >
                              <p
                                class="error"
                                *ngIf="datapointsControl.get('min')?.errors?.required"
                              >
                                {{ 'message.valid-min' | translate }}
                              </p>

                              <p
                                class="error"
                                *ngIf="datapointsControl.get('min')?.errors?.rangeInvalid"
                              >
                                {{ 'message.minmaxerror' | translate }}
                              </p>
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="spacing"></div>

                        <div *ngIf="datapointsControl.get('max')">
                          <h5 class="field-label">
                            {{ 'common.maxvalue' | translate }}
                          </h5>

                          <mat-form-field
                            appearance="legacy"
                            [formGroupName]="rowIndex"
                            [ngClass]="{
                              error:
                                datapointsControl.get('max')?.invalid &&
                                datapointsControl.get('max')?.touched
                            }"
                          >
                            <input
                              matInput
                              type="number"
                              formControlName="max"
                              [placeholder]="'placeholder_messages.maxvalue' | translate"
                            />

                            <mat-error
                              *ngIf="
                                datapointsControl.get('max')?.invalid &&
                                datapointsControl.get('max')?.touched
                              "
                            >
                              <p
                                class="error"
                                *ngIf="datapointsControl.get('max')?.errors?.required"
                              >
                                {{ 'message.valid-max' | translate }}
                              </p>

                              <p
                                class="error"
                                *ngIf="datapointsControl.get('max')?.errors?.rangeInvalid"
                              >
                                {{ 'message.minmaxerror' | translate }}
                              </p>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <!-- ======================= DATA POINT MIN AND MAX FOR DUAL AXIS ============================ -->
                    </div>
                    <!-- ======================= DATA POINT SELECTION CONTENT ============================ -->
                  </div>
                </ng-container>
              </div>
              <!-- ================================= DATA POINT SELECTION SECTION ================================================== -->
              <button
                *ngIf="!dualYAxis"
                class="btn no-background"
                (click)="addDatapointGroupToForm()"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="20px"
                [ngClass]="{
                  disabled: datapointsControls.length === limitDatapointField
                }"
                appTooltip
                [toolTipTitle]="
                  datapointsControls.length === limitDatapointField
                    ? ('message.add-datapoint-tooltip' | translate)
                    : ''
                "
                [positionTip]="{ top: -1, left: 190 }"
              >
                <mat-icon>add</mat-icon>
                {{ 'action.add-another-point' | translate }}
              </button>

              <div class="scale-section">
                <mat-checkbox *ngIf="!dualYAxis" #autoScale formControlName="autoScale">
                  {{ 'common.autoscale' | translate }}
                </mat-checkbox>

                <div class="min-max-section" *ngIf="!lineChartForm.get('autoScale').value">
                  <div *ngIf="lineChartForm.get('min')">
                    <h5 class="field-label">
                      {{ 'common.minvalue' | translate }}
                    </h5>

                    <mat-form-field
                      appearance="legacy"
                      [ngClass]="{
                        error:
                          lineChartForm.get('min')?.invalid && lineChartForm.get('min')?.touched
                      }"
                    >
                      <input
                        matInput
                        type="number"
                        formControlName="min"
                        [placeholder]="'placeholder_messages.minvalue' | translate"
                      />
                      <mat-error
                        *ngIf="
                          lineChartForm.get('min')?.invalid && lineChartForm.get('min')?.touched
                        "
                      >
                        <p class="error" *ngIf="lineChartForm.get('min')?.errors?.required">
                          {{ 'message.valid-min' | translate }}
                        </p>

                        <p class="error" *ngIf="lineChartForm.get('min')?.errors?.rangeInvalid">
                          {{ 'message.minmaxerror' | translate }}
                        </p>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="spacing"></div>
                  <div *ngIf="lineChartForm.get('max')">
                    <h5 class="field-label">
                      {{ 'common.maxvalue' | translate }}
                    </h5>

                    <mat-form-field
                      appearance="legacy"
                      [ngClass]="{
                        error:
                          lineChartForm.get('max')?.invalid && lineChartForm.get('max')?.touched
                      }"
                    >
                      <input
                        matInput
                        type="number"
                        formControlName="max"
                        [placeholder]="'placeholder_messages.maxvalue' | translate"
                      />

                      <mat-error
                        *ngIf="
                          lineChartForm.get('max')?.invalid && lineChartForm.get('max')?.touched
                        "
                      >
                        <p class="error" *ngIf="lineChartForm.get('max')?.errors?.required">
                          {{ 'message.valid-max' | translate }}
                        </p>

                        <p class="error" *ngIf="lineChartForm.get('max')?.errors?.rangeInvalid">
                          {{ 'message.minmaxerror' | translate }}
                        </p>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="default-time">
              <h4 class="normal">{{ 'common.defaulttime-1' | translate }}</h4>
              <h5>
                {{ 'message.datefilter' | translate }}
              </h5>

              <mat-button-toggle-group formControlName="timeFilter">
                <mat-button-toggle class="temporal-btn" value="today">
                  {{ 'temporal.today' | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="temporal-btn" value="week">
                  {{ 'temporal.week' | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="temporal-btn" value="month">
                  {{ 'temporal.month' | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="DESIGN">
          <ng-template mat-tab-label>
            <!-- ###################### TAB TITLE TEMPLATE ######################## -->
            <ng-template
              [ngTemplateOutlet]="tabLabelTemplate"
              [ngTemplateOutletContext]="tabConfig.designTab"
            ></ng-template>
          </ng-template>

          <!-- ###################### TAB BODY ######################## -->
          <div class="design-tab">
            <form [formGroup]="lineChartForm">
              <div class="label-field">
                <h5 class="field-label">Widget Title</h5>

                <mat-form-field
                  appearance="legacy"
                  [ngClass]="{
                    error:
                      lineChartForm.get('widgetTitle')?.invalid &&
                      lineChartForm.get('widgetTitle').touched
                  }"
                >
                  <input matInput type="text" formControlName="widgetTitle" />
                  <mat-error>
                    <p
                      class="error"
                      *ngIf="
                        lineChartForm.get('widgetTitle')?.invalid &&
                        lineChartForm.get('widgetTitle').touched
                      "
                    >
                      {{ 'message.widget-required' | translate }}
                    </p>
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-container
                *ngFor="let datapointsControl of datapointsControls.controls; let rowIndex = index"
              >
                <ng-container *ngIf="datapointsControl?.value?.datapoint?.path">
                  <div class="graph-color-selection" formArrayName="datapoints" fxLayout="row">
                    <div fxLayout="column" style="flex-grow: 1">
                      <div class="header">
                        {{ datapointsControl?.value?.datapoint?.path }}
                      </div>
                      <div class="description">
                        {{ 'common.graph-line-color' | translate }}
                      </div>
                    </div>
                    <div [formGroupName]="rowIndex">
                      <input class="selector" matInput type="color" formControlName="color" />
                    </div>
                  </div>
                  <div class="spacing"></div>
                  <div class="spacing"></div>
                </ng-container>
              </ng-container>

              <h4 class="grid-header">{{ 'message.custromgrid_title' | translate }}</h4>
              <h5 class="grid-description">
                {{ 'message.customgrid' | translate }}
              </h5>

              <div>
                <h5 class="field-label">
                  {{ 'common.select-grid-line' | translate }}
                </h5>
                <mat-form-field
                  appearance="legacy"
                  [ngClass]="{
                    error:
                      lineChartForm.get('gridLines')?.invalid &&
                      lineChartForm.get('gridLines').touched
                  }"
                >
                  <mat-select formControlName="gridLines">
                    <mat-option value="none">{{ 'common.none' | translate }}</mat-option>
                    <mat-option *ngFor="let data of listOfSelectDatapoint" [value]="data?.path">
                      {{ data?.path }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <p
                      class="error"
                      *ngIf="
                        lineChartForm.get('gridLines')?.invalid &&
                        lineChartForm.get('gridLines').touched
                      "
                    >
                      {{ 'message.no-grid-rules' | translate }}
                    </p>
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-container
                formArrayName="gridLinesColors"
                *ngFor="
                  let datapointsControl of gridLinesColorsControls.controls;
                  let gRowIndex = index
                "
              >
                <div class="color-selection" [formGroupName]="gRowIndex">
                  <div fxLayout="row" class="input-field" fxLayoutAlign="start baseline">
                    <div style="margin: 15px">
                      <mat-checkbox formControlName="checked"></mat-checkbox>
                    </div>

                    <div style="flex-grow: 1">
                      <p style="text-transform: capitalize">
                        {{ lineChartForm.get(['gridLinesColors', gRowIndex, 'name']).value }}
                      </p>
                    </div>
                    <div>
                      <input formControlName="color" matInput type="color" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </form>
          </div>
        </mat-tab>
      </mat-tab-group>

      <form [formGroup]="lineChartForm" (ngSubmit)="saveWidget()">
        <div fxLayout="row" class="action-btn" fxLayoutGap="10px">
          <button (click)="cancel()" mat-flat-button class="secondary" type="button">
            {{ 'action.cancel' | translate | uppercase }}
          </button>

          <button *ngIf="isEdit" (click)="delete()" mat-flat-button class="secondary" type="button">
            {{ 'action.delete' | translate | uppercase }}
          </button>
          <button type="submit" mat-flat-button class="primary">
            {{ 'action.save-widget' | translate | uppercase }}
          </button>
        </div>
      </form>
    </div>

    <div fxFlex="62%" class="right-section">
      <div class="preview">
        <div fxLayout="row">
          <mat-card class="preview-line">
            <div
              #cardTitle
              class="widget-title truncate"
              appTooltip
              [toolTipTitle]="
                handleChange && cardTitle.offsetWidth < cardTitle.scrollWidth ? widgetTitle : ''
              "
              [positionTip]="{ top: 25, left: 0 }"
              customClass="nowrap"
              [ngClass]="widgetTitle ? 'newTtle' : 'titlePlaceholder'"
            >
              {{ widgetTitle || 'Widget Title' }}
            </div>
            <div fxLayout="row wrap" class="datecontrol-linechart">
              <div class="datecontrol-linechart">
                <button class="today" type="button" value="today" mat-button>
                  {{ 'temporal.today' | translate }}
                </button>
                <button type="button" class="week" value="week" mat-button>
                  {{ 'temporal.week' | translate }}
                </button>
                <button type="button" class="month" value="month" mat-button>
                  {{ 'temporal.month' | translate }}
                </button>
              </div>

              <div class="customdateimg">
                <button type="button" class="" value="week" mat-button>
                  mm/dd/yy hh:mm - mm/dd/yy hh:mm <mat-icon> event </mat-icon>
                </button>
              </div>
            </div>
            <div class="zoompanmarker-control">
              <mat-checkbox class="showMarkerCheck"
                >{{ 'action.show-markers' | translate }}
              </mat-checkbox>

              <img
                class="histchart-toolbar"
                src="../../../assets/images/ic-keyboard-arrow-left.svg"
              />
              <img
                class="histchart-toolbar"
                src="../../../assets/images/ic-keyboard-arrow-right.svg"
              />

              <img class="histchart-toolbar" src="../../../assets/images/ic-zoom-out.svg" />

              <img class="histchart-toolbar" src="../../../assets/images/ic-zoom-in.svg" />
            </div>
            <div>
              <div id="chartcontainerapexpreview">
                <apx-chart
                  #apxChartEl
                  [series]="series"
                  [chart]="chart"
                  [xaxis]="xAxis"
                  [tooltip]="tooltip"
                  [stroke]="stroke"
                  [markers]="markers"
                  [annotations]="annotations"
                  [yaxis]="yAxis"
                  [legend]="legend"
                ></apx-chart>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ########################## TAB TITLE UI ###################################### -->
<ng-template
  #tabLabelTemplate
  let-title="title"
  let-iconName="iconName"
  let-iconColor="iconColor"
  let-isIconVisible="isIconVisible"
>
  <div class="label-template" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <div class="tab-title">
      <h1>{{ title }}</h1>
    </div>
    <div *ngIf="isIconVisible">
      <mat-icon class="material-icons-round" [ngStyle]="{ color: iconColor }">
        {{ iconName }}
      </mat-icon>
    </div>
  </div>
</ng-template>
