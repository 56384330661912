import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResolutionResolverService {
  private deviceType = new ReplaySubject<TBreakpoint>(1);
  private rd2: Renderer2;
  constructor(private deviceService: DeviceDetectorService, rendererFactory: RendererFactory2) {
    this.rd2 = rendererFactory.createRenderer(null, null);
    this.detectDeviceType();
    this.activeLister();
  }

  activeLister() {
    this.rd2.listen('window', 'resize', () => {
      setTimeout(() => {
        this.detectDeviceType();
      }, 0);
    });
  }

  detectDeviceType() {
    this.deviceService.userAgent = navigator.userAgent;
    const filterByDeviceType = config.filter((device) => device.type === this.whichDevice);
    const filterByOrientation = filterByDeviceType.filter(
      (device) => device.orientation === this.orientation
    );
    if (filterByOrientation.length > 0) {
      const result = filterByOrientation[0].return as TBreakpoint;
      this.deviceType.next(result);
    }
  }

  get _deviceType(): Observable<TBreakpoint> {
    return this.deviceType.asObservable().pipe(distinctUntilChanged());
  }

  get whichDevice() {
    switch (true) {
      case this.deviceService.isDesktop():
        const userAgent = navigator.userAgent.toLowerCase();
        let isIpad =
          /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
          );
        if (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document) {
          isIpad = true;
          return 'tablet';
        } else {
          return 'desktop';
        }
      case this.deviceService.isTablet():
        return 'tablet';
      case this.deviceService.isMobile():
        return 'mobile';
        break;
      default:
        break;
    }
  }

  get orientation() {
    if (window.matchMedia('(orientation: landscape)').matches) {
      return 'landscape';
    }
    if (window.matchMedia('(orientation: portrait)').matches) {
      return 'portrait';
    }
  }
}

export type TBreakpoint =
  | 'MobilePortrait'
  | 'TabletPortrait'
  | 'WebPortrait'
  | 'MobileLandscape'
  | 'TabletLandscape'
  | 'WebLandscape';

const config = [
  {
    type: 'desktop',
    orientation: 'portrait',
    return: 'WebPortrait',
  },
  {
    type: 'desktop',
    orientation: 'landscape',
    return: 'WebLandscape',
  },
  {
    type: 'tablet',
    orientation: 'portrait',
    return: 'TabletPortrait',
  },
  {
    type: 'tablet',
    orientation: 'landscape',
    return: 'TabletLandscape',
  },
  {
    type: 'mobile',
    orientation: 'portrait',
    return: 'MobilePortrait',
  },
  {
    type: 'mobile',
    orientation: 'landscape',
    return: 'MobileLandscape',
  },
];
