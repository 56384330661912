import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth/auth.service';
import { userRoleConfig } from '../shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class SecureAuthPagesGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const cookie = this.authService.getCookie('FieldPoPDeviceCloud');
    const userRole = localStorage.getItem('role');

    return this.authService.isLoggedInUpdatedRole(cookie, userRole).pipe(
      map((authSate) => {
        if (authSate) {
          this.navigateUser(userRole);
          return false;
        } else {
          return true;
        }
      })
    );
  }

  navigateUser(userRole) {
    if (userRole === userRoleConfig.CE) {
      this.router.navigate(['main', 'template']);
    } else {
      this.router.navigate(['main', 'device']);
    }
  }
}
