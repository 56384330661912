import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import {
  IQuerydevicesRequest,
  IQueryProfileRequest,
  IQueryRequest,
  TFilterSort,
} from 'src/app/shared/interface/api_options';
import {
  IDashboardSummary,
  IListDashboardInstances,
} from 'src/app/shared/interface/dashboard.interface';
import * as _ from 'lodash';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { IDeviceListState, ISortingObject, IFilterObject } from 'src/app/shared/interface/common';

import { TemplateService } from 'src/app/service/template/template.service';
import {
  pmDeviceTable,
  smDeviceTable,
  previewTable,
  userTable,
  editUserTable,
  eventLogTable,
} from 'src/app/shared/constants/table-column';
import * as moment from 'moment-timezone';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { DashEventService } from '../device-dashboard/services/dashboard-event/dash-event.service';
import { deviceImageURL, userRoleConfig, ToasterData } from 'src/app/shared/constants/constants';
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:popstate', ['$event'])
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren('searchBar') searchbar: QueryList<ElementRef>;
  @ViewChild('mobileUI') set mobileUI(element) {
    if (!!element) element.nativeElement.parentNode.scrollTop = 0;
  }
  @Input() renderedFrom;
  @Input() previewDataSource;
  @Input() deviceTitle = 'common.devices';
  @Output() selectedRow = new EventEmitter();
  readonly pageSizeOption = [10, 20, 40, 60, 100];
  readonly dateFormat = 'MM/DD/YYYY HH:mm:ss';
  readonly statuses = ['Normal', 'Alarm', 'Offline', 'Trouble', 'Warning'];
  readonly dummyCompanies = ['ABC', 'Building Alive'];
  readonly iconUrl = deviceImageURL.iconUrl;
  readonly imageUrl = (window as any).smcUrl + 'fieldveu-images/';
  readonly defaultTime = {
    start: '00:00',
    end: '23:59',
  };
  deviceListingState: IDeviceListState;
  showLoader = true;
  subcription: Subscription[] = [];
  displayedColumns: string[] = pmDeviceTable;
  columnsToDisplay: string[];
  columnsToDisplayFilter: string[];
  devices = [];
  dataSource: MatTableDataSource<IDashboardSummary> = new MatTableDataSource([]);
  userRole = localStorage.getItem('role');
  userRoleConfig = userRoleConfig;
  mobileSetting = false;
  itemLength: number;
  pageIndex: number;
  pageSize: number;
  _tableheaderOffsetHeight: any;
  deviceType;
  isLoading = false;
  loadedAll = false;
  skipableValue = {
    [userRoleConfig.PM]: ['deviceName'],
    [userRoleConfig.SE]: ['deviceName', 'company'],
    [userRoleConfig.CE]: ['deviceName', 'company'],
  };
  eventLogSkipableValue = ['notificationMessage'];
  readonly mobileResolution = ['MobilePortrait', 'MobileLandscape'];
  readonly tabletResolution = ['TabletPortrait', 'TabletLandscape'];
  selectedDatasource: string;
  uncheckvalue = [];
  intervalForQueryRunner: any;
  backBtnPressed: boolean;
  ignoreFirstData = true;

  constructor(
    private meshService: MeshServiceProvidor,
    private route: ActivatedRoute,
    private router: Router,
    private _dashEventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService,
    private _templateService: TemplateService,
    private cs: CommonService
  ) {
    this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.backBtnPressed = true;
  }
  ngOnInit(): void {
    if (
      (this.userRole === userRoleConfig?.SE && this.renderedFrom === undefined) ||
      (this.userRole === userRoleConfig?.CE && this.renderedFrom === undefined)
    ) {
      this.displayedColumns = smDeviceTable;
    } else if (this.renderedFrom === 'preview') {
      this.displayedColumns = previewTable;
    } else if (this.renderedFrom === 'users') {
      this.displayedColumns = userTable;
    } else if (this.renderedFrom === 'editusers') {
      this.displayedColumns = editUserTable;
    } else if (this.renderedFrom === 'eventlog') {
      this.displayedColumns = eventLogTable;
    }
    this.initialDeviceListingState();

    this._templateService.flow = 'normal';
  }

  ngAfterViewInit(): void {
    this.updatetableheaderOffsetHeight();
    const sub1 = this.meshService.dashboardInstancesError$.subscribe((error) => {
      this.updatetableheaderOffsetHeight();
      this.showLoader = false;
      this.isLoading = false;
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
    });
    const sub2 = this.meshService.dashboardInstances$.subscribe((data: any) => {
      if (!data || (this.renderedFrom !== undefined && this.renderedFrom !== 'preview')) {
        return;
      }
      if (this.renderedFrom === 'preview') {
        if (data.data?.length > 0) {
          data.dashboards = data.data;
          if (!this.selectedDatasource) {
            const nextDataSource = this.previewDataSource || data.data[0].id;
            this.selectedDatasource = nextDataSource;
            this.selectedRow.emit(nextDataSource);
          }
        } else {
          this.selectedRow.emit();
        }
      }
      if (this.dataSource && this.mobileResolution.includes(this.deviceType)) {
        if (data.dashboards.length > 0) {
          const pageNumber = this.deviceListingState.pageNumber;
          if (pageNumber === 1) {
            this.dataSource.data = data.dashboards;
          } else {
            this.dataSource.data.push(...data.dashboards);
          }
          this.loadedAll = false;
        } else if (this.deviceListingState.pageNumber === 1) {
          this.dataSource.data = [];
        } else {
          this.loadedAll = true;
        }
      } else if (this.dataSource) {
        this.dataSource.data = data.dashboards;
      } else {
        this.dataSource = new MatTableDataSource(data.dashboards);
        this.dataSource.paginator = this.paginator;
      }
      this.updatetableheaderOffsetHeight();
      setTimeout(() => {
        this.setData(data);
      });
    });
    const sub3 = this._dashEventService.scrollEvent.subscribe(() => {
      if (!this.loadedAll && !this.isLoading && !this.mobileSetting) {
        this.isLoading = true;
        this.deviceListingState.pageNumber = this.deviceListingState.pageNumber + 1;
        this.queryData();
      }
    });
    const sub4 = this.meshService.userInstances$.subscribe((data) => {
      if (!data || this.renderedFrom !== 'users') {
        return;
      }
      if (this.dataSource) {
        this.dataSource.data = data.data;
      } else {
        this.dataSource = new MatTableDataSource(data.data);
        this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.setData(data);
      });
    });
    const sub5 = this.meshService.assignedDevices$.subscribe((data) => {
      if (!data || this.renderedFrom !== 'editusers') {
        return;
      }
      if (this.dataSource) {
        this.dataSource.data = data.data;
      } else {
        this.dataSource = new MatTableDataSource(data.data);
        this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.setData(data);
      });
    });
    const sub6 = this.meshService.eventLog$.subscribe((data) => {
      if (!data || this.renderedFrom !== 'eventlog') {
        return;
      }
      if (this.dataSource && this.mobileResolution.includes(this.deviceType)) {
        if (data?.data?.length > 0) {
          const pageNumber = this.deviceListingState.pageNumber;
          if (pageNumber === 1) {
            this.dataSource.data = data.data;
          } else {
            this.dataSource.data.push(...data.data);
          }
          this.loadedAll = false;
        } else if (this.deviceListingState.pageNumber === 1) {
          this.dataSource.data = [];
        } else {
          this.loadedAll = true;
        }
      } else if (this.dataSource) {
        this.dataSource.data = data.data;
      } else {
        this.dataSource = new MatTableDataSource(data.data);
        this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.setData(data);
      });
    });
    const sub7 = this.meshService.eventInstancesError$.subscribe((error) => {
      this.updatetableheaderOffsetHeight();
      this.showLoader = false;
      this.isLoading = false;
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
    });

    this.subcription = [sub1, sub2, sub3, sub4, sub5, sub6, sub7];
  }

  manageQueryParams() {
    this.route.queryParams.subscribe((data) => {
      if (data) {
        Object.keys(data).forEach(async (key) => {
          this.updateFilter(key, data[key], true);
        });
        if (this.backBtnPressed) {
          window.history.back();
          return;
        }
        this.router.navigate(['main', 'eventlog']);
      }
    });
  }

  get tableheaderOffsetHeight() {
    try {
      return (this.table as any)._elementRef.nativeElement.children[0].offsetHeight;
    } catch {
      return '';
    }
  }

  updatetableheaderOffsetHeight() {
    setTimeout(() => {
      this._tableheaderOffsetHeight = this.tableheaderOffsetHeight + 'px';
    });
  }

  setData(data) {
    this.itemLength = data?.pagination?.itemCount;
    this.pageIndex = this.deviceListingState.pageNumber - 1;
    this.pageSize = this.deviceListingState.pageSize;
    this.showLoader = false;
    this.isLoading = false;
    if (this.table) {
      this.table.fixedLayout = true;
    }
  }

  selectedOption(key: string, event: Array<string>) {
    if (key !== 'tableFilter') {
      this.updateFilter(key, event);
    } else {
      this.updateTable(key, event);
    }
  }

  updateTable(key, event) {
    this.columnsToDisplay = this.deviceListingState.columnDisplayed = event.slice();
    this._tableheaderOffsetHeight = 'auto';
    this.updatetableheaderOffsetHeight();
    this.removeFilterNSortingForHiddenColumn();
  }

  checkColumn(col, header) {
    return col[col?.length - 1] === header;
  }

  sortByKeyName(keyName: string, isDualAction?: boolean) {
    const temp = this.deviceListingState.sorting[keyName];
    for (const key in this.deviceListingState.sorting) {
      if (this.deviceListingState.sorting[key]) {
        const element = this.deviceListingState.sorting[key];
        if (key !== keyName) {
          element.isActive = false;
        }
      }
    }
    if (isDualAction) {
      temp.isActive = true;
      temp.order = temp.order === 'asc' ? 'desc' : 'asc';
      temp.icon = temp.order === 'asc' ? 'arrow_upward' : 'arrow_downward';
    } else if (temp.isActive) {
      temp.order = temp.order === 'asc' ? 'desc' : 'asc';
      temp.icon = temp.order === 'asc' ? 'arrow_upward' : 'arrow_downward';
    } else {
      temp.isActive = true;
    }
    this.deviceListingState.pageNumber = 1;
    this.queryData();
  }

  searchText(event: KeyboardEvent) {
    if (event?.code?.includes('Arrow')) {
      return;
    }
    const searchTerm = (event.target as HTMLInputElement).value;
    const columnName = (event.target as HTMLInputElement).id;
    this.updateFilter(columnName, searchTerm);
  }

  dateChange(event) {
    const convertedUnix = {
      start: moment(event.start).valueOf(),
      end: moment(event.end).valueOf(),
    };
    this.updateFilter('dateTime', convertedUnix);
  }

  updateFilter(
    key,
    value: string | Array<string> | { start: number; end: number },
    noQuery = false
  ) {
    this.deviceListingState.filter[key] = {
      name: key,
      value,
    };
    this.deviceListingState.pageNumber = 1;
    if (noQuery) {
      return;
    }
    this.queryData();
  }

  async navigateToDevice(data) {
    if (this.renderedFrom === undefined) {
      this.router.navigate(['/', 'main', 'device', data.id]);
      this._dashEventService.devicesMobile = this.dataSource.data;
    } else if (this.renderedFrom === 'eventlog') {
      const result = await this.getDashboardIdByProfileInstanceId(data.profileInstanceID);
      if (result) {
        this.router.navigate(['/', 'main', 'device', result]);
      }
    }
  }

  navigateUser(e, data) {
    this.router.navigate(['/', 'main', 'users', 'edituser']);
    sessionStorage.setItem('selectedUser', JSON.stringify(data));
  }
  updateCheckStatus(e, data) {
    const index = this.uncheckvalue.findIndex((x) => x === data.profileInstanceID);
    if (index > -1) {
      this.uncheckvalue.splice(index, 1);
    } else {
      this.uncheckvalue.push(data.profileInstanceID);
    }
    this.selectedRow.emit(this.uncheckvalue);
  }
  pageChange(pageEvent: PageEvent) {
    const pageSize = pageEvent.pageSize;
    const nextPage = pageEvent.pageIndex + 1;
    this.deviceListingState.pageSize = pageSize;
    this.deviceListingState.pageNumber = nextPage;
    this.queryData();
  }

  initialDeviceListingState() {
    // Restoring Last State if any and escape

    this.restoreDefaultState(this.renderedFrom).then((result) => {
      if (result === true) {
        return;
      }
      // Setting Property to Empty Object
      Object.defineProperty(this, 'deviceListingState', {
        value: {},
        configurable: true,
        writable: true,
      });
      Object.defineProperty(this.deviceListingState, 'sorting', {
        value: {},
        configurable: true,
        writable: true,
      });
      Object.defineProperty(this.deviceListingState, 'filter', {
        value: {},
        configurable: true,
        writable: true,
      });
      Object.defineProperty(this.deviceListingState, 'columnDisplayed', {
        value: [],
        configurable: true,
        writable: true,
      });

      // Default page setting applied
      this.deviceListingState.pageSize = this.mobileResolution.includes(this.deviceType)
        ? 20
        : this.defaultRecordsPerPage();

      this.deviceListingState.pageNumber = 1;
      // Setting initial Sorting Object
      this.displayedColumns.forEach((keyname) => {
        this.deviceListingState.sorting[keyname] = this.sortInitialObject(keyname);
      });
      if (this.tabletResolution.includes(this.deviceType)) {
        this.columnsToDisplay = this.deviceListingState.columnDisplayed =
          this.displayedColumns.slice(0, 5);
      } else {
        this.columnsToDisplay = this.deviceListingState.columnDisplayed =
          this.displayedColumns.slice();
      }

      // Invoking data by sorting with dual action
      this.setDefaultSortColumn(this.renderedFrom);
    });
  }

  restoreDefaultState(renderedFrom) {
    const resultPromise = new Promise((resolve, reject) => {
      switch (renderedFrom) {
        case undefined: {
          if (this._dashEventService.lastestDeviceListState) {
            this.deviceListingState = this._dashEventService.lastestDeviceListState;
            if (this._dashEventService?.lastestDeviceListState.columnDisplayed !== undefined) {
              this.columnsToDisplay = this._dashEventService.lastestDeviceListState.columnDisplayed;
            } else {
              this.columnsToDisplay = this.displayedColumns;
            }
            this.queryData();
            resolve(true);
          } else {
            resolve(false);
          }
          break;
        }
        case 'preview': {
          resolve(false);
          break;
        }
        case 'users': {
          if (this._dashEventService.lastestUserListState) {
            this.deviceListingState = this._dashEventService.lastestUserListState;
            this.columnsToDisplay = this.displayedColumns;
            this.queryData();
            resolve(true);
          } else {
            resolve(false);
          }
          break;
        }
        default: {
          resolve(false);
        }
      }
    });

    return resultPromise;
  }
  //If height of device is greater than 938 px
  defaultRecordsPerPage() {
    if (window.innerHeight > 938) {
      return 20;
    } else {
      return 10;
    }
  }

  setDefaultSortColumn(renderedFrom) {
    switch (renderedFrom) {
      case undefined: {
        if (this.userRole === userRoleConfig?.PM) {
          this.sortByKeyName('siteName', true);
        } else {
          this.sortByKeyName('company', true);
        }
        break;
      }
      case 'preview': {
        this.sortByKeyName('deviceName', true);
        break;
      }
      case 'users': {
        this.sortByKeyName('lastName', true);
        break;
      }
      case 'editusers': {
        this.sortByKeyName('siteName', true);
        break;
      }
      case 'eventlog': {
        this.manageQueryParams();
        this.sortByKeyName('dateTime', false);
        break;
      }
      default: {
        this.sortByKeyName('company', true);
        break;
      }
    }
    if (!this.renderedFrom || this.renderedFrom === 'eventlog') {
      this.queryRunnerEveryMinute();
    }
  }

  queryData() {
    const result: IQueryRequest = {
      pageNumber: this.deviceListingState.pageNumber,
      pageSize: this._pageSize,
    };
    this.displayedColumns.forEach((keyname) => {
      result[keyname] = {};
      if (this._sortValues[keyname]) {
        if (this._sortValues[keyname].isActive) {
          (result[keyname] as TFilterSort).sort = this._sortValues[keyname].order;
        }
      }
      const tempFilter = this._filterValues[keyname];
      if (tempFilter) {
        if (tempFilter.value && _.isObject(tempFilter.value)) {
          (result[keyname] as TFilterSort).filter = this.createFilterForQuery(tempFilter.value);
        } else if (
          tempFilter.value &&
          (_.isArray(tempFilter.value) || _.isString(tempFilter.value)) &&
          tempFilter.value.length > 0
        ) {
          (result[keyname] as TFilterSort).filter = this.createFilterForQuery(tempFilter.value);
        }
      }
      if (Object.keys(result[keyname]).length < 1) {
        delete result[keyname];
      }
    });
    this.showLoader = true;
    this.setApiCall(this.renderedFrom, result);
  }
  selectRow($event, selectedrow) {
    this.selectedDatasource = selectedrow.id;
    this.selectedRow.emit(selectedrow.id);
  }

  setApiCall(renderedFrom, result) {
    let apiEndpoint;
    switch (renderedFrom) {
      case undefined: {
        apiEndpoint = 'dashboard';
        this.meshService.getdashboardInstances(result, apiEndpoint);
        break;
      }
      case 'preview': {
        apiEndpoint = 'profile';
        const templateDetails = JSON.parse(sessionStorage.getItem('tempInfo'));
        const profileFilter: IQueryProfileRequest = {
          deviceProfileName: templateDetails.modelNumber,
          minimumVersion: templateDetails.minProfileVersion,
          query: result,
        };
        this.meshService.getdashboardInstances(profileFilter, apiEndpoint);
        break;
      }
      case 'users': {
        this.appendRoleFilter(result);
        this.meshService.getUsers(result);
        break;
      }
      case 'editusers': {
        const userDetails = JSON.parse(sessionStorage.getItem('selectedUser'));
        const userDevicesfilter: IQuerydevicesRequest = {
          username: userDetails?.email,
          query: result,
        };
        this.meshService.getUserAssignedDevice(userDevicesfilter);
        break;
      }
      case 'eventlog': {
        const userDevicesfilter: IQueryRequest = result;
        this.meshService.getEventLog(userDevicesfilter);
        break;
      }
      default:
        break;
    }
  }

  appendRoleFilter(queryFilter) {
    if (this.userRole === userRoleConfig.CE) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      queryFilter.fieldveuRole
        ? queryFilter.fieldveuRole.filter
          ? (queryFilter.fieldveuRole.filter.$exists = true)
          : (queryFilter.fieldveuRole.filter = {
              $exists: ['true'],
            })
        : (queryFilter.fieldveuRole = {
            filter: {
              $exists: ['true'],
            },
          });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      queryFilter.fieldveuRole
        ? queryFilter.fieldveuRole.filter
          ? (queryFilter.fieldveuRole.filter.$in = [userRoleConfig.PM, userRoleConfig.SE])
          : (queryFilter.fieldveuRole.filter = {
              $in: [userRoleConfig.PM, userRoleConfig.SE],
            })
        : (queryFilter.fieldveuRole = {
            filter: {
              $in: [userRoleConfig.PM, userRoleConfig.SE],
            },
          });
    }
  }
  outputMobileState(event: IDeviceListState) {
    if (!event) {
      this.mobileSetting = false;
      this._dashEventService.headerState = true;
      return;
    }
    this.deviceListingState.pageNumber = event.pageNumber;
    this.deviceListingState.pageSize = event.pageSize;
    Object.defineProperty(this, 'deviceListingState', {
      value: event,
      writable: true,
    });
    this.mobileSetting = false;
    this._dashEventService.headerState = true;
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    this.queryData();
  }

  removeFilterByValue(value: string) {
    if (!this.deviceListingState && !this.deviceListingState.filter) {
      return;
    }
    for (const key in this.deviceListingState.filter) {
      if (this.deviceListingState.filter[key]) {
        const element = this.deviceListingState.filter[key];
        if (this.renderedFrom === undefined) {
          if (this.skipableValue[this.userRole].includes(key)) {
            continue;
          }
        } else if (this.renderedFrom === 'eventlog') {
          if (this.eventLogSkipableValue.includes(key)) {
            continue;
          }
        }
        if (typeof element.value === 'string') {
          if (element.value === value) {
            delete this.deviceListingState.filter[key];
            this.deviceListingState.pageNumber = 1;
            this.queryData();
            return;
          }
        } else if (_.isArray(element.value)) {
          const index = element.value.indexOf(value);
          if (index !== -1) {
            element.value.splice(index, 1);
            this.deviceListingState.pageNumber = 1;
            this.queryData();
            return;
          }
        }
      }
    }
  }

  openFilter() {
    this.mobileSetting = true;
    this._dashEventService.headerState = false;
  }

  reset() {
    let defaultValue = this.userRole === userRoleConfig.PM ? 'siteName' : 'company';
    defaultValue =
      this.renderedFrom === 'users'
        ? 'lastName'
        : this.renderedFrom === 'editusers'
        ? 'siteName'
        : this.renderedFrom === 'eventlog'
        ? 'dateTime'
        : defaultValue;
    for (const key in this.deviceListingState.sorting) {
      if (this.deviceListingState.sorting[key]) {
        const element = this.deviceListingState.sorting[key];
        if (key === defaultValue && this.columnsToDisplay.includes(defaultValue)) {
          element.isActive = true;
          element.order = defaultValue === 'dateTime' ? 'desc' : 'asc';
          element.icon = defaultValue === 'dateTime' ? 'arrow_downward' : 'arrow_upward';
        } else {
          element.isActive = false;
          element.order = 'desc';
          element.icon = 'arrow_downward';
        }
      }
    }
    this.deviceListingState.filter = {};
    this.deviceListingState.pageNumber = 1;
    this.queryData();
  }

  removeFilterNSortingForHiddenColumn() {
    const sortingKeys = Object.keys(this.deviceListingState.sorting);
    const filteringKeys = Object.keys(this.deviceListingState.filter);
    sortingKeys.forEach((key) => {
      if (!this.columnsToDisplay.includes(key)) {
        this.deviceListingState.sorting[key].isActive = false;
      }
    });
    filteringKeys.forEach((key) => {
      if (!this.columnsToDisplay.includes(key)) {
        delete this.deviceListingState.filter[key];
      }
    });
    this.queryData();
  }

  // helper method

  sortInitialObject(name: string): ISortingObject {
    return { by: name, order: 'desc', isActive: false, icon: 'arrow_downward' };
  }

  get _sortValues(): { [by: string]: ISortingObject } {
    return this.deviceListingState.sorting;
  }

  get _filterValues(): { [name: string]: IFilterObject } {
    return this.deviceListingState?.filter || {};
  }

  get _pageSize(): number {
    return this.deviceListingState.pageSize;
  }

  get _selectedFilter() {
    const temp = [];
    if (!this.deviceListingState && !this.deviceListingState?.filter) {
      return temp;
    }
    for (const key in this.deviceListingState.filter) {
      if (this.deviceListingState.filter[key]) {
        const element = this.deviceListingState.filter[key];
        if (this.renderedFrom === undefined) {
          if (this.skipableValue[this.userRole].includes(key)) {
            continue;
          }
        } else if (this.renderedFrom === 'eventlog') {
          if (this.eventLogSkipableValue.includes(key)) {
            continue;
          }
        }

        if (typeof element.value === 'string') {
          if (element.value.length > 0) {
            const dat = element.value;
            if (!dat.trim()) {
              temp.push(`\"${element.value}\"`);
              continue;
            }
            temp.push(element.value);
          }
        } else if (_.isArray(element.value)) {
          temp.push(...element.value);
        }
      }
    }
    return temp;
  }

  createFilterForQuery(value: string | Array<string> | { start: number; end: number }) {
    const tempValue =
      typeof value === 'string'
        ? value.toLowerCase()
        : _.isArray(value)
        ? value.map((v) => v.trim().toLowerCase())
        : value;
    if (typeof tempValue === 'string') {
      return {
        $regex: [this.handleInvalidRegex(`.*${this.escapeRegExp(tempValue)}.*`), 'i'],
      };
    } else if (_.isArray(tempValue)) {
      return {
        $regex: [`${tempValue.join('|')}`, 'i'],
      };
    } else if (_.isObject(tempValue) && tempValue?.start && tempValue.end) {
      return {
        $lt: tempValue.end,
        $gte: tempValue?.start,
      };
    }
  }

  handleInvalidRegex(regExpression: string) {
    try {
      if (new RegExp(regExpression)) {
        return regExpression;
      }
    } catch {
      return '';
    }
  }

  escapeRegExp(stringVal) {
    return stringVal.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  async getDashboardIdByProfileInstanceId(id: any) {
    const options: IQueryRequest = {
      pageSize: 1,
      profileInstanceID: {
        filter: {
          $eq: id,
        },
      },
    };
    try {
      const result = (await this.meshService.listDashboardInstance(
        options
      )) as IListDashboardInstances;
      return result.dashboards[0]?.id;
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  queryRunnerEveryMinute() {
    this.clearIntervalForQueryRunner();
    const defaultInterval = 60 * 1000;
    this.intervalForQueryRunner = setInterval(() => {
      this.queryData();
    }, defaultInterval);
  }

  clearIntervalForQueryRunner() {
    if (this.intervalForQueryRunner) {
      clearInterval(this.intervalForQueryRunner);
    }
  }

  ngOnDestroy(): void {
    this.subcription.forEach((sub) => sub.unsubscribe());
    if (this.renderedFrom === undefined) {
      this._dashEventService.lastestDeviceListState = this.deviceListingState;
    } else if (this.renderedFrom === 'users') {
      this._dashEventService.lastestUserListState = this.deviceListingState;
    }
    this.clearIntervalForQueryRunner();
    if (this.renderedFrom === 'preview') {
      this.meshService._dashboardInstances.next(null);
    }
  }
}
