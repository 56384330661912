/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/ban-types */
// should be split into two provifors one for app related stuff one for mesh realted stuff. rxstore would be good design

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { ToasterData, unsupportedBrowser } from 'src/app/shared/constants/constants';
import {
  ICloneDashboardTemplateRequest,
  IQuerydevicesRequest,
  IQueryProfileRequest,
  IQueryRequest,
} from 'src/app/shared/interface/api_options';
import { IDashboardListTemplate } from 'src/app/shared/interface/dashboard-template.interface';
import { IListDashboardInstances } from 'src/app/shared/interface/dashboard.interface';
import { IListingDeviceProfile } from 'src/app/shared/interface/device.interface';
import { IHistoricalGraphState } from 'src/app/shared/interface/_base';
import { DetectBrowserService } from 'src/app/shared/services/detect-browser/detect-browser.service';
import { PreLoginRouteManagerService } from 'src/app/shared/services/pre-login-route-manager/pre-login-route-manager.service';
import { CommonService } from '../../shared/services/common/common.service';
import { environment } from './../../../environments/environment';
import { ILogoDetail, MeshClientOptions } from './meshClientOptions';

// import { resolve } from "dns";

declare let MeshClient: any;
declare let window: any; // for debug

const ngServeLocal = '4444';

@Injectable({
  providedIn: 'root',
})
export class MeshServiceProvidor {
  private keepAliveInterval: any;
  isReconnecting;
  constructor(
    private preLoginRouteManager: PreLoginRouteManagerService,
    public detectBrowser: DetectBrowserService,
    private router: Router,
    private cs: CommonService
  ) {
    this.fieldpopPort = environment.fieldpopPort;
    this.fieldpopProtocol = environment.fieldpopProtocol;

    const myBrowser = this.detectBrowser.checkBrowser().toLocaleLowerCase();
    if (unsupportedBrowser.includes(myBrowser)) {
      console.error(`Your Browser ${myBrowser} is not supported`);
      this.router.navigate(['unsupportedbrowser']);
      return;
    }

    this.scriptLoaded = new Promise<boolean>((resolve, reject) => {
      this.getFieldpopUrl()
        .then((url: string) => {
          this.fieldpopUrl = url;
          window.smcUrl = 'https://' + url + '/';
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `${this.fieldpopProtocol}://${this.fieldpopUrl}:${this.fieldpopPort}/api/client.js`;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = (err) => {
            reject(err);
          };
          document.getElementsByTagName('head')[0].appendChild(script);
          this.createMeshReadyPromise();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private meshClientDefaults: MeshClientOptions = {
    port: window.location.port === ngServeLocal ? 55000 : 443,
    protocol: 'https',
    socket: {
      pingTimeout: 90000,
    },
    reconnect: {
      max: 10000, // max reconnect retry backoff 10s
    },
  };

  private meshClientInstance: any;
  private scriptLoaded: Promise<boolean>;
  fieldpopUrl: string;
  fieldpopProtocol: string;
  fieldpopPort: number;
  byPassLink = false;
  resetLink = false;
  public meshReady: Promise<any>;
  private meshReadyResolver: any;

  private _userDetails = new BehaviorSubject({});
  public readonly userDetails$: Observable<any> = this._userDetails.asObservable();

  private _userDetailsError: ReplaySubject<any> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly userDetailsError$: Observable<any> = this._userDetailsError.asObservable();

  _dashboardInstances: ReplaySubject<IListDashboardInstances> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly dashboardInstances$: Observable<IListDashboardInstances> =
    this._dashboardInstances.asObservable().pipe(
      filter((value) => !value === false),
      delay(1)
    );

  private _dashboardInstancesError: ReplaySubject<any> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly dashboardInstancesError$: Observable<any> = this._dashboardInstancesError
    .asObservable()
    .pipe(
      filter((value) => !value === false),
      delay(1)
    );

  private _logOutObserver: Subject<any> = new Subject(); // can be a normal subject if only used through async pipe
  public logOutObserver: Observable<any> = this._logOutObserver; // can be a normal subject if only used through async pipe

  private _loginObserver: Subject<any> = new Subject(); // can be a normal subject if only used through async pipe
  public loginObserver: Observable<any> = this._loginObserver; // can be a normal subject if only used through async pipe

  // can be a normal subject if only used through async pipe
  private _subscribedDashboardInstances: Map<string, BehaviorSubject<Object>> = new Map();
  // sub scribing to this will give you the last three observables returning the last 3 dahsboardInstances loaded
  private _loadedDashboardInstances: ReplaySubject<object> = new ReplaySubject(3);
  public readonly loadedDashboardInstances$: Observable<any> =
    this._loadedDashboardInstances.asObservable();

  private datapointObservers: Map<string, BehaviorSubject<Object>> = new Map();

  public status: BehaviorSubject<any> = new BehaviorSubject(false);
  cast = this.status.asObservable();

  private _userInstances: ReplaySubject<any> = new ReplaySubject(1);
  public readonly userInstances$: Observable<any> = this._userInstances.asObservable().pipe(
    filter((value) => !value === false),
    delay(1)
  );

  private _userInstancesError: ReplaySubject<any> = new ReplaySubject(1);
  public readonly userInstancesError$: Observable<any> = this._userInstancesError
    .asObservable()
    .pipe(
      filter((value) => !value === false),
      delay(1)
    );

  private _assignedDevices: ReplaySubject<any> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly assignedDevices$: Observable<any> = this._assignedDevices.asObservable().pipe(
    filter((value) => !value === false),
    delay(1)
  );

  private eventLog: ReplaySubject<any> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly eventLog$: Observable<any> = this.eventLog.asObservable().pipe(delay(1));

  private _eventInstancesError: ReplaySubject<any> = new ReplaySubject(1);
  public readonly eventInstancesError$: Observable<any> = this._eventInstancesError
    .asObservable()
    .pipe(
      filter((value) => !value === false),
      delay(1)
    );

  private _assignedDevicesError: ReplaySubject<any> = new ReplaySubject(1); // can be a normal subject if only used through async pipe
  public readonly assignedDevicesError$: Observable<any> = this._assignedDevicesError
    .asObservable()
    .pipe(
      filter((value) => !value === false),
      delay(1)
    );
  private createMeshReadyPromise() {
    this.meshReady = new Promise<void>((resolve) => {
      this.meshReadyResolver = resolve;
    });
  }

  changeStatus(type, val = false) {
    if (type === 'header') {
      return this.status.next(!this.status.value);
    } else {
      if (val === true) {
        return this.status.next(true);
      } else {
        return this.status.next(false);
      }
    }
  }
  clearStatus(): any {
    return this.status.next(false);
  }

  async disconnectMeshClient() {
    this.removeCookieEventListeners();
    await this.meshClientInstance?.disconnect({
      revokeToken: true,
      deleteCookie: true,
    });
  }

  async logout() {
    await this.disconnectMeshClient();
    await this.redirectToLogin();
  }

  async redirectToLogin() {
    this.createMeshReadyPromise();
    localStorage.clear();
    this._logOutObserver.next();
  }

  meshReadyWithTimeout(): Promise<any> {
    let timeOut;
    const timeoutPromise = new Promise((_, reject) => {
      timeOut = setTimeout(() => {
        reject({ message: 'Mesh client not ready, are you logged in' });
      }, 15000);
    });
    return Promise.race([timeoutPromise, this.meshReady]).then(() => {
      clearTimeout(timeOut);
    });
  }

  async awaitFieldpopHappnerClient(meshClientOptions: MeshClientOptions = this.meshClientDefaults) {
    if (unsupportedBrowser.includes(this.detectBrowser.checkBrowser().toLocaleLowerCase())) {
      return;
    }
    try {
      const self = this;
      await this.scriptLoaded;

      meshClientOptions.hostname = this.fieldpopUrl || meshClientOptions.hostname;
      meshClientOptions.protocol = this.fieldpopProtocol || meshClientOptions.protocol;
      meshClientOptions.port = this.fieldpopPort || meshClientOptions.port;
      meshClientOptions.cookieEventHandler = function (event, cookie) {
        if (event === 'cookie-deleted') {
          if (window.location.pathname.includes('main')) {
            self.logout();
          }
        }
        if (event === 'cookie-created') {
          if (!window.location.pathname.includes('main')) {
            self._loginObserver.next();
          }
        }
      };
      meshClientOptions.cookieName = 'FieldPoPDeviceCloud';

      this.meshClientInstance = new MeshClient(meshClientOptions);

      this.isReconnecting = null;
      this.meshClientInstance.on(
        'connection/ended',
        this.connectionEnded.bind(this),
        function (error) {
          if (error) return console.error('connection/ended subscription error : ' + error);
        }
      );
      this.meshClientInstance.on(
        'reconnect/scheduled',
        this.reconnectScheduled.bind(this),
        function (error) {
          if (error) return console.error('reconnect/scheduled subscription error : ' + error);
        }
      );
      this.meshClientInstance.on(
        'reconnect/successful',
        this.reconnectSuccessful.bind(this),
        function (error) {
          if (error) return console.error('reconnect/successful subscription error : ' + error);
        }
      );

      this.meshClientInstance.on('session/ended', function () {
        self.redirectToLogin();
      });
      window.meshClientInstance = this.meshClientInstance;
      if (
        (this.router as any).location._platformLocation.location.href.includes('reset-password') ||
        (this.router as any).location._platformLocation.location.href.includes('register')
      ) {
        this.resetLink = true;
      }

      try {
        await this.meshClientInstance.login({ useCookie: true });

        this.meshReadyResolver();
      } catch (e) {
        const storeOnlyFor = 'main';
        if (window.location.pathname.includes(storeOnlyFor)) {
          this.preLoginRouteManager.redirectTo = window.location.pathname;
        }

        if (this.resetLink === false) {
          this.router.navigate(['login']);
        }
      }
    } catch (err) {
      this.router.navigate(['unavailableservice']);
    }
  }

  redirectRememberRouter() {
    this.preLoginRouteManager.goRedirect();
  }

  async reconnectSuccessful() {
    const data: ToasterData = {
      type: 'success',
      message: 'reconnected',
    };
    this.cs.openToaster(data);
    this.isReconnecting = null;
  }
  async reconnectScheduled() {
    if (this.isReconnecting) return;
    const data: ToasterData = {
      type: 'success-custom',
      message: 'Reconnecting...',
    };
    this.cs.openToaster(data, 0);
    this.isReconnecting = true;
  }
  async connectionEnded() {
    const data: ToasterData = {
      type: 'error',
      message: 'connection-lost',
    };
    this.cs.openToaster(data, 0);
  }

  async login(username: string, password: string) {
    try {
      await this.meshClientInstance.login({ username, password });
      // mesh is considered ready when logged in
      this.meshReadyResolver();
    } catch (err) {
      throw err;
    }
  }

  async getUserDetails() {
    try {
      // this._userDetails.next(null); -----Commented this line to prevent expression changed error (Need testing)
      await this.meshReadyWithTimeout();
      const userDetails = await this.meshClientInstance.exchange.fieldveu.getUserDetails();
      this._userDetails.next(userDetails);
    } catch (err) {
      console.log(err.message);
      this._userDetailsError.next(err.message);
      // this._userDetails.next(null);
    }
  }

  async getUserDetailsPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const userDetails = await this.meshClientInstance.exchange.fieldveu.getUserDetails();
        resolve(userDetails);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getdashboardInstances(option: IQueryRequest | IQueryProfileRequest, api) {
    try {
      this._dashboardInstances.next(null);
      this._dashboardInstancesError.next(null);
      await this.meshReadyWithTimeout();
      if (api !== 'profile') {
        const dashboardInstances =
          await (this.meshClientInstance.exchange.fieldveu.listDashboardInstances(
            option
          ) as IListDashboardInstances);
        this._dashboardInstances.next(dashboardInstances);
      } else {
        const dashboardInstances =
          await (this.meshClientInstance.exchange.fieldveu.listProfileInstances(
            option
          ) as IListDashboardInstances);
        this._dashboardInstances.next(dashboardInstances);
      }
    } catch (err) {
      console.log(err.message);
      this._dashboardInstancesError.next(err.message);
      this._dashboardInstances.next(null);
    }
  }

  addSubscribedDashboardInstance(id: string): Observable<Object> {
    let promiseSource;
    // try catch because 'from' might fail, this is not the promise rejecting, it is the from failing to creaate a promise from argument
    try {
      promiseSource = from(
        this.meshClientInstance.exchange.fieldveu.getDashboardInstance({
          id,
        })
      );
      this.meshClientInstance.exchange.fieldveu.keepalive({ id }).then((data) => {});
      this.keepAliveInterval = setInterval(() => {
        this.meshClientInstance.exchange.fieldveu.keepalive({ id }).then((data) => {});
      }, 55000); // replace with better solution once have time
    } catch (err) {
      return throwError(err.message);
    }
    promiseSource.subscribe(
      (res) => {
        this._loadedDashboardInstances.next(res);
      },
      (err) => {
        console.log(err.message);
      }
    );

    this._subscribedDashboardInstances.set(id, promiseSource);
    return promiseSource;
  }

  clearKeepAlive() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
    }
  }

  addDatapointSubscription(dataPointPath: string): Observable<any> {
    if (this.datapointObservers.has(dataPointPath)) {
      return this.datapointObservers.get(dataPointPath);
    }

    const newSubject = new BehaviorSubject<object>({});
    if (!dataPointPath) {
      return;
    }
    this.meshClientInstance.event.liveDataServer.on(
      dataPointPath,
      (data) => {
        newSubject.next(data);
      },
      (err) => {
        if (err) {
          console.error(err);
        }
      }
    );
    this.datapointObservers.set(dataPointPath, newSubject);
    return newSubject;
  }

  async getHistoricalData(
    deviceId: string,
    path: string,
    dateRange: IHistoricalGraphState,
    count: number
  ) {
    const minDate = Math.round(dateRange.minDateTime / 1000);
    const maxDate = Math.round(dateRange.maxDateTime / 1000);
    try {
      await this.meshReadyWithTimeout();
      const histDataInstance = await window.meshClientInstance.exchange.historianStore.getLogs({
        path,
        startUTCsec: minDate,
        endUTCsec: maxDate,
        count,
      });

      return histDataInstance;
    } catch (err) {
      console.log(err.message);
      return Promise.resolve([]);
    }
  }

  async getGaugeData(path: string, update, type?: String) {
    try {
      await this.meshReadyWithTimeout();
      if (!path) {
        return;
      }
      const gaugeDataInstance = await window.meshClientInstance.event.liveDataServer.on(
        path,
        (data, meta) => update(data)
      );
      const initialValue = await window.meshClientInstance.data.get(path);
      return { gaugeDataInstance, initialValue };
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async destroyGaugeData(value) {
    try {
      if (!value) {
        return;
      }
      await this.meshReadyWithTimeout();
      const histDataInstance = await window.meshClientInstance.event.liveDataServer.off(value);
    } catch (err) {
      console.log(err.message);
    }
  }

  async getLogo(): Promise<ILogoDetail> {
    try {
      await this.meshReadyWithTimeout();
      return await window.meshClientInstance.exchange.fieldpop_user_mgr.getData('BRANDING_LOGO');
    } catch (err) {
      console.log(err.message);
      return null;
    }
  }
  async widgetsDataPoint(deviceName, version) {
    try {
      await this.meshReadyWithTimeout();
      const result = window.meshClientInstance.exchange.fieldveu.getDeviceProfile({
        deviceProfileName: deviceName,
        version,
      });
      return result;
    } catch (err) {
      console.log(err.message);
      return Promise.resolve([]);
    }
  }

  listDashboardTemplate(options): Promise<IDashboardListTemplate> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.listDashboardTemplates(options);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
  //
  saveTemplate(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.updateDashboardTemplate(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
  createTemplate(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.createDashboardTemplate(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  listDeviceProfile(option: IQueryRequest): Promise<IListingDeviceProfile> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.listDeviceProfiles(option);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  listDeviceProfileVersions(deviceProfileName: string): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const option = {
          deviceProfileName,
        };
        await this.meshReadyWithTimeout();
        const result =
          window.meshClientInstance.exchange.fieldveu.listDeviceProfileVersions(option);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  cloneDashboardTemplate(option: ICloneDashboardTemplateRequest): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.cloneDashboardTemplate(option);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDashboardTemplateInstance(dashboardTemplateId: string) {
    //getDashboardTemplate
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.getDashboardTemplate({
          id: dashboardTemplateId,
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteTemplate(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.deleteDashboardTemplate(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  getPreviewDashboardInstance(dashboardTemplateID: string, profileInstanceID: string) {
    //getDashboardTemplate
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.getPreviewDashboardInstance({
          dashboardTemplateID,
          profileInstanceID,
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  publishTemplate(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.publishDashboardTemplate(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  unPublishTemplate(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.unpublishDashboardTemplate(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getUsers(option: IQueryRequest) {
    try {
      this._userInstances.next(null);
      this._userInstancesError.next(null);
      await this.meshReadyWithTimeout();

      const _userInstances = await this.meshClientInstance.exchange.fieldpop_user_mgr.getPagedUsers(
        option
      );
      this._userInstances.next(_userInstances);
    } catch (err) {
      this._userInstancesError.next(err.message);
      this._userInstances.next(null);
    }
  }

  async getUserAssignedDevice(option: IQuerydevicesRequest) {
    try {
      await this.meshReadyWithTimeout();

      const _assignedDevices =
        await this.meshClientInstance.exchange.fieldveu.listUserDashboardInstances(option);
      this._assignedDevices.next(_assignedDevices);
    } catch (err) {
      this._assignedDevicesError.next(err.message);
      this._assignedDevices.next(null);
    }
  }

  createUser(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.createUser(options);

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  revokeProfileInstanceAccess(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result =
          await window.meshClientInstance.exchange.fieldveu.revokeProfileInstanceAccess(options);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteUser(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldpop_user_mgr.deleteAccount(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  updateUserRole(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.setFieldveuRole(options);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
  forgotPassword(options, update) {
    this.meshReadyResolver();
    this.byPassLink = true;
    return new Promise(async (resolve, reject) => {
      try {
        const defaultUser = {
          username: '_ANONYMOUS',
        };

        await window.meshClientInstance.login(defaultUser);
        const result = await window.meshClientInstance.exchange.fieldpop_user_mgr.forgotPassword(
          options
        );
        this.deleteCookie();
        resolve(result);
      } catch (error) {
        this.deleteCookie();
        reject(error);
      }
    });
  }
  resetPassword(email, password, update) {
    email = decodeURIComponent(email).split(' ').join('+');
    // this.meshReadyResolver();
    this.byPassLink = true;
    return new Promise(async (resolve, reject) => {
      try {
        const defaultUser = {
          username: '_ANONYMOUS',
        };

        await window.meshClientInstance.login(defaultUser);

        const result = await window.meshClientInstance.exchange.fieldpop_user_mgr.resetPassword(
          email,
          password
        );
        this.deleteCookie();

        resolve(result);
      } catch (error) {
        this.deleteCookie();

        reject(error);
      }
    });
  }

  activateUser(userData) {
    this.meshReadyResolver();
    return new Promise(async (resolve, reject) => {
      try {
        const defaultUser = {
          username: '_ANONYMOUS',
        };
        await window.meshClientInstance.login(defaultUser);
        const result = await window.meshClientInstance.exchange.fieldveu.activateAccount(userData);
        this.deleteCookie();
        resolve(result);
      } catch (err) {
        this.deleteCookie();
        console.log(err.message);
        reject(err);
      }
    });
  }

  async deleteCookie() {
    await this.meshClientInstance?.disconnect({
      revokeToken: true,
      deleteCookie: true,
    });
    localStorage.removeItem('role');
  }

  changePassword(options) {
    // this.meshReadyResolver();

    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.security.updateOwnUser(options);

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  shareDashboardInstance(dashboardID: string, emails: Array<string>) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.shareDashboardInstance({
          dashboardID,
          emails,
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  setUserDetails(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.setUserDetails(options);
        this.getUserDetails();
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  permissionUserList(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = await window.meshClientInstance.exchange.fieldveu.listProfileInstanceUsers(
          options
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getEventLog(option: IQueryRequest) {
    try {
      await this.meshReadyWithTimeout();
      const eventLog = await this.meshClientInstance.exchange.fieldveu.listDeviceEvents(option);
      this.eventLog.next(eventLog);
    } catch (err) {
      this._eventInstancesError.next(err.message);
    }
  }

  listDashboardInstance(options: IQueryRequest) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const result = window.meshClientInstance.exchange.fieldveu.listDashboardInstances(options);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  getNotificationSettings(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const dataPoints = await this.meshClientInstance.exchange.fieldveu.getNotificationPoints(
          options
        );
        resolve(dataPoints);
      } catch (err) {
        console.log(err.message);
        reject(err);
      }
    });
  }

  setNotificationSettings(options) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const dataPoints = await this.meshClientInstance.exchange.fieldveu.setNotificationPoints(
          options
        );
        resolve(dataPoints);
      } catch (err) {
        reject(err);
      }
    });
  }

  getTunnelUrl(deviceId) {
    const err = {
      message:
        'Sorry, the secure tunnel could not be established at this time. Please try again later.',
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.meshReadyWithTimeout();
        const details =
          await this.meshClientInstance.exchange.fieldpop_user_mgr.getDeviceDetailsAndTunnel(
            deviceId
          );
        if (!!details && !!details.tunnelProxyUrl) {
          resolve(details.tunnelProxyUrl);
        } else {
          reject(err);
        }
      } catch (error) {
        reject(err);
      }
    });
  }
  private async getFieldpopUrl() {
    return new Promise((resolve) => {
      if (environment.fieldpopUrl !== '') {
        resolve(environment.fieldpopUrl);
      }
      const hostname = window.location.hostname;
      if (hostname === '127.0.0.1' || hostname === 'localhost') {
        return resolve('fvpoc.fptest.net');
      }
      const meshClientLbEndpoint = hostname.slice(hostname.indexOf('.') + 1);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://${meshClientLbEndpoint}/meshclienthost`;
      script.onload = () => {
        if (window.meshClientHostname) {
          return resolve(window.meshClientHostname);
        }
        console.warn(
          `meshclienthost load-balancer endpoint did not set window.meshClientHostname defaulting to ${meshClientLbEndpoint}`
        );
        resolve(meshClientLbEndpoint);
      };
      script.onerror = (err) => {
        console.error(err);
        console.warn(
          `meshclienthost load-balancer endpoint failed to load defaulting to ${meshClientLbEndpoint}`
        );
        resolve(meshClientLbEndpoint);
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  checkforValidInvitation(email, username) {
    this.meshReadyResolver();
    this.byPassLink = true;
    return new Promise(async (resolve, reject) => {
      try {
        const defaultUser = {
          username: 'defaultUser',
          password: 'password',
        };
        await window.meshClientInstance.login(defaultUser);
        const details = await window.meshClientInstance.exchange.fieldpop_user_mgr.isLinkExpired(
          username,
          email
        );
        resolve(details);
      } catch (err) {
        console.log(err.message);
        reject(err);
      }
    });
  }

  reloadApp() {
    if (this.router.url.indexOf('unavailableservice') > -1) {
      this.deleteCookie();
    }
    window.location.reload();
  }

  removeCookieEventListeners() {
    this.meshClientInstance.removeListener('reconnect/successful');
    this.meshClientInstance.removeListener('connection/ended');
    this.meshClientInstance.removeListener('reconnect/scheduled');
    this.meshClientInstance.removeListener('session/ended');
  }
}
