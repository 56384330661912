import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from 'src/app/shared/components/toaster/toaster.component';
import { ToasterData } from '../../../shared/constants/constants';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private _snackBar: MatSnackBar) {}
  openToaster(data: ToasterData, duration = 5000) {
    this._snackBar.openFromComponent(ToasterComponent, {
      data,
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['custom-snackbar'],
    });
  }
}
