<mat-progress-bar
  *ngIf="!subscriptionHandler && type !== 'template'"
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="card-content"
  fxFlex
  fxLayout="column"
  [ngClass]="{
    'cardtop-trouble': currentStatus === 'Trouble',
    'cardtop-warning': currentStatus === 'Warning',
    'cardtop-alarm': currentStatus === 'Alarm',
    'cardtop-offline': currentStatus === 'Offline',
    'opacity-1': !stateValue?.text && type !== 'template'
  }"
>
  <div class="main-content" fxFlex fxLayout="column">
    <div class="content1" fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <span
        class="card-main-head truncate"
        matTooltipClass="tooltip-hover"
        data-test="text_powerwidget-title"
        >{{ widget?.title | translate }}</span
      >
      <span
        (click)="edit('binary', widget)"
        *ngIf="type == 'template'"
        data-test="edit_powerwidget"
      >
        <mat-icon
          class="status-dropdown cursor"
          [ngStyle]="{ color: '#404040' }"
          >edit</mat-icon
        >
      </span>
      <img
        *ngIf="currentStatus == 'Warning'"
        class="card-status-image"
        src="../../../../assets/images/status/warning-status.svg"
        data-test="img_powerwarning"
      />
      <img
        *ngIf="currentStatus == 'Alarm'"
        class="card-status-image"
        src="../../../../assets/images/status/alarm-status.svg"
        data-test="img_poweralarm"
      />
      <img
        *ngIf="currentStatus == 'Trouble'"
        class="card-status-image"
        src="../../../../assets/images/status/trouble-status.svg"
        data-test="img_powertrouble"
      />
      <img
        *ngIf="currentStatus == 'Offline'"
        class="card-status-image"
        src="../../../../assets/images/status/offline-status.svg"
        data-test="img_poweroffline"
      />
    </div>
    <div
      class="content-2"
      [ngClass]="{
        'card-blur': currentStatus == 'Offline'
      }"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      <span *ngIf="stateValue?.icon !== '' && stateValue?.icon !== 'none'">
        <mat-icon
          [ngStyle]="{ color: stateValue?.iconColor }"
          data-test="icon_powerState"
          >{{ stateValue?.icon }}</mat-icon
        >
        <!-- <img
            class="device-card-img"
            src="../../../assets/images/power.svg"
          /> -->
      </span>
      <span
        *ngIf="stateValue?.icon !== '' && stateValue?.icon !== 'none'"
        class="card-main-head truncate-binary device-imp-status-details device-card-status-align"
        matTooltipClass="tooltip-hover"
        [matTooltip]="stateValue?.text"
        [ngStyle]="{ color: stateValue?.textColor }"
        data-test="text_powerState"
        >{{ stateValue?.text }}</span
      >
      <span
        *ngIf="stateValue?.icon == '' || stateValue?.icon === 'none'"
        class="card-main-head truncate-binary device-imp-status-details device-card-status-noalign"
        [ngStyle]="{ color: stateValue?.textColor }"
        data-test="text_powerState"
        >{{ stateValue?.text }}</span
      >
    </div>
    <div
      class="content-2"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between end"
    >
      <span class="time-stamp-font" data-test="text-powertime">{{
        timeValue
      }}</span>
    </div>
  </div>
</div>
