<app-ce-subheader></app-ce-subheader>
<section class="create-dashboard">
  <form #createForm="ngForm">
    <div fxLayout="column">
      <div class="template-name">
        <h4 class="field-label">{{ "common.template_name" | translate }}</h4>
        <mat-form-field class="tempname" appearance="legacy">
          <input
            data-test="input_template_name"
            matInput
            type="text"
            [(ngModel)]="createTemplate.templateName"
            [placeholder]="'common.enter_template_name' | translate"
            name="templateName"
            required
            #templateName="ngModel"
          />
        </mat-form-field>
        <div class="alert alert-danger">
          {{ "message.template_name_required" | translate }}
        </div>
      </div>

      <div class="model-number">
        <h4 class="field-label">
          {{ "common.model_number_device" | translate }}
        </h4>
        <mat-form-field class="model-num" appearance="legacy">
          <input
            data-test="input_model_number"
            [disabled]="!templateName.valid"
            matInput
            type="text"
            [(ngModel)]="createTemplate.modelNumber"
            [placeholder]="'common.select_search' | translate"
            [matAutocomplete]="modelNumber"
            name="modelName"
            autocomplete="off"
            required
            #modelName="ngModel"
            #modelNameInput="matInput"
            (keyup)="keyUpModelNumber($event)"
            (blur)="handleValue($event)"
            (focus)="fetchDeviceProfileList()"
          />
          <mat-autocomplete
            #modelNumber="matAutocomplete"
            (optionSelected)="handleValue(modelNameInput)"
          >
            <mat-option
              *ngFor="let deviceProfile of listOfDeviceProfile?.deviceProfiles"
              [value]="deviceProfile.deviceProfileName"
              [title]="deviceProfile.deviceProfileName"
            >
              {{ deviceProfile.deviceProfileName }}
            </mat-option>
          </mat-autocomplete>
           <label for="modelName">
          <mat-icon> arrow_drop_down </mat-icon>
        </label>
        </mat-form-field>
       
        <div class="alert alert-danger">
          {{ "message.model_number_required" | translate }}
        </div>
      </div>

      <div class="min-profile-version">
        <h4 class="field-label">
          {{ "common.min_device_version" | translate }}
        </h4>
        <mat-form-field  class="prof-ver" appearance="legacy">
          <mat-select
            data-test="input_min_version"
            [disabled]="!modelName.valid"
            [placeholder]="'common.select' | translate"
            [(value)]="createTemplate.minProfileVersion"
            required
            #minDeviceProfile
          >
            <mat-option
              *ngFor="let version of listOfDeviceProfileVersions"
              [value]="version"
            >
              {{ version }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="alert alert-danger">
          {{ "message.min_version_required" | translate }}
        </div>
        <div
          data-test="action_help"
          class="help"
          appTooltip
          customClass="version-help"
          [toolTipTitle]="helpMinVersion"
        >
          <mat-icon data-test="help"> info </mat-icon>
        </div>
      </div>

      <div class="select-template" *ngIf="listDashboardTemplate">
        <h4 class="field-label">{{ "common.select_template" | translate }}</h4>
        <div class="grid-list" fxLayout="row wrap" fxLayoutGap="28px">
          <div
            class="new-blank"
            fxFlex="30%"
            [ngClass]="
              !!this.listDashboardTemplate &&
              this.listDashboardTemplate['templates'].length === 0
                ? 'noTempAvailable'
                : 'new-blank'
            "
          >
            <button
            data-test="action_create_dashboard"
              mat-flat-button
              class="btn btn-primary center"
              (click)="createDashboard()"
              [disabled]="!minDeviceProfile.value"
            >
              <mat-icon>add</mat-icon>
              {{ "action.blank-template" | translate }}
            </button>
          </div>
          <ng-container
            *ngFor="let dasboardInfo of listDashboardTemplate?.templates"
          >
            <app-template-card
              [ngClass]="{ 'disabled-btn': !minDeviceProfile.value }"
              fxFlex="30%"
              [templateInfo]="dasboardInfo"
              [buttonConfig]="btnConfig"
              [imagePath]="dasboardInfo?.dashboardConfig?.deviceImageUrl"
              (actionClickBtn)="cardEventManager($event)"
            >
            </app-template-card>
          </ng-container>
        </div>
        <div class="overlay" *ngIf="!minDeviceProfile.value"></div>
      </div>
    </div>
  </form>
</section>
