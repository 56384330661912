<app-user-mgmt-subheader
  [unsaved]="unsaved"
  [pageType]="'editUser'"
></app-user-mgmt-subheader>

<div class="edit-user-container">
  <div class="userDetails">
    <div class="singleUserDetail">
      <label for="firstName">{{ "common.firstName" | translate }}</label>
      <p>{{ userDetails?.firstName }}</p>
    </div>
    <div class="singleUserDetail">
      <label for="lastName"> {{ "common.lastName" | translate }}</label>
      <p>{{ userDetails?.lastName }}</p>
    </div>
    <div class="singleUserDetail">
      <label for="lastName"> {{ "common.email-address" | translate }}</label>
      <p>{{ userDetails?.email }}</p>
    </div>
    <div class="singleUserDetail">
      <label for="lastName">{{ "common.user-role" | translate }}</label>
      <div class="select-container">
        <mat-select
          (selectionChange)="checkforRole($event)"
          [disabled]="
            userRole == userRoleList.SE ||
            userRole == userRoleList.PM ||
            userDetails?.fieldveuRole == userRoleList.PM
          "
          [(value)]="selectedUserRole"
          class="dropdown"
        >
          <mat-option
            *ngFor="let role of roles"
            [value]="role"
            [disabled]="role == userRoleList.PM"
          >
            {{ role }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div class="assignedDeviceTable">
    <div class="table-header">
      <h3>{{ "common.assigned-devices" | translate }}</h3>
      <button
        data-test="action_reset_filter"
        class="btn secondary-btn"
        mat-flat-button
        (click)="resetTable()"
      >
        <mat-icon> refresh </mat-icon>
        {{ "action.reset-filter" | translate | uppercase }}
      </button>
    </div>
    <app-devices
      [renderedFrom]="'editusers'"
      (selectedRow)="uncheckedDevices($event)"
    ></app-devices>
    <div class="btn_wrapper">
      <button
        data-test="action_delete_user"
        class="btn secondary-btn no-background"
        mat-flat-button
        (click)="cancel()"
        type="button"
      >
        {{ "action.cancel" | translate | uppercase }}
      </button>
      <button
        type="button"
        data-test="action_delete_user"
        class="btn secondary-btn"
        mat-flat-button
        (click)="deleteUser()"
      >
        {{ "action.delete-user" | translate | uppercase }}
      </button>
      <button
        data-test="action_save"
        class="btn primary-btn"
        [disabled]="
          uncheckDevices?.length === 0 &&
          selectedUserRole == userDetails?.fieldveuRole
        "
        (click)="save()"
        mat-flat-button
      >
        {{ "action.save" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
