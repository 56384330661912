<div class="filterSection" fxLayout="column" fxLayoutGap="15px">
  <div class="searchInput" fxLayout="row" fxFlex="80">
    <input
      #mobileCompany
      placeholder="Search by company..."
      type="text"
      name="company"
      fxFlex="90"
      id="company"
      [value]="value1"
      (keyup)="searchText($event)"
      autocomplete="off"
      data-test="input_company"
    />
    <span fxFlex="10"
      ><img
        *ngIf="!mobileCompany.value"
        src="../../../assets/images/ic-search.svg"
        data-test="media_search"
      />
      <img
        (click)="_updateFilter(mobileCompany.id, '')"
        *ngIf="mobileCompany.value"
        src="../../../assets/images/ic-close.svg"
        data-test="media_close"
      />
    </span>
  </div>
  <div class="searchInput" fxLayout="row" fxFlex="80">
    <input
      #mobileDeviceName
      placeholder="Search by device name..."
      type="text"
      name="deviceName"
      fxFlex="90"
      id="deviceName"
      [value]="value2"
      (keyup)="searchText($event)"
      autocomplete="off"
      data-test="input_devicename"
    />
    <span fxFlex="10"
      ><img
        *ngIf="!mobileDeviceName.value"
        src="../../../assets/images/ic-search.svg"
        data-test="media_search"
      />
      <img
        (click)="_updateFilter(mobileDeviceName.id, '')"
        *ngIf="mobileDeviceName.value"
        src="../../../assets/images/ic-close.svg"
        data-test="media_close"
      />
    </span>
  </div>
  <div
    data-test="text_filter"
    class="more_filter"
    
    fxLayout="row"
    fxLayoutGap="5px"
  >
  <div data-test="media_filter" (click)="_openFilter()">
    <img
      class="slider"
      src="../../../assets/images/solid-sliders-h.svg"
    />
    <p>{{ "action.add-more-filter" | translate | uppercase }}</p>
  </div>
  </div>
</div>
