<mat-progress-bar
  *ngIf="
    !subscriptionHandler &&
    widget.type !== 'combination-chart' &&
    type !== 'template'
  "
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="gauge-position"
  fxFlex="100%"
  fxLayout="column"
  [ngClass]="{
    'cardtop-trouble': currentStatus === 'Trouble',
    'cardtop-warning': currentStatus === 'Warning',
    'cardtop-alarm': currentStatus === 'Alarm',
    'cardtop-offline': currentStatus === 'Offline',
    'opacity-1': !isDataAvailable && type !== 'template'
  }"
>
  <div class="">
    <div
      class="gauge-header"
      style="width: 100%"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div>
        <span class="gauge-type card-main-head" data-test="text_gaugeTitle">{{
          cardTitle
        }}</span>
      </div>
      <span
        (click)="edit('gauge', widget)"
        class="edit-icn-align"
        *ngIf="type == 'template' && widget['type'] == 'gauge'"
        data-test="edit_gaugewidget"
      >
        <mat-icon
          class="status-dropdown cursor"
          [ngStyle]="{ color: '#404040' }"
          >edit</mat-icon
        >
      </span>
      <img
        *ngIf="currentStatus == 'Warning'"
        class="gauge-status-image"
        src="../../../../assets/images/status/warning-status.svg"
        data-test="img_gaugewarning"
      />
      <img
        *ngIf="currentStatus == 'Alarm'"
        class="gauge-status-image"
        src="../../../../assets/images/status/alarm-status.svg"
        data-test="img_gaugealarm"
      />
      <img
        *ngIf="currentStatus == 'Trouble'"
        class="gauge-status-image"
        src="../../../../assets/images/status/trouble-status.svg"
        data-test="img_gaugetrouble"
      />
      <img
        *ngIf="currentStatus == 'Offline'"
        class="gauge-status-image"
        src="../../../../assets/images/status/offline-status.svg"
        data-test="img_gaugeoffline"
      />
    </div>
  </div>

  <div
    [ngClass]="{
      'gauge-status-linear': currentStatus !== 'Normal',
      'gauge-nostatus-linear': currentStatus == 'Normal',
      'card-blur': currentStatus === 'Offline'
    }"
    fxLayoutAlign="center"
  >
    <app-fv-gauge-chart
      id="gauge-area"
      (onresize)="onResize($event)"
      [canvasWidth]="canvasWidth"
      [needleValue]="needleValue"
      [centralLabel]="centralLabel"
      [options]="options"
      [bottomLabel]="bottomLabel"
      [bottomLabelFont]="bottomLabelFont"
      data-test="chart_gauge"
    ></app-fv-gauge-chart>
  </div>
  <div
    [ngClass]="widget.type == 'gauge' ? 'gauge-bottom' : 'gauge-bottom-noval'"
    fxLayoutAlign="flex-start flex-end"
  >
    <span class="gauge-type time-stamp-font" data-test="text_gaugetime">{{
      timeValue
    }}</span>
  </div>
</div>
