<section class="share-dashboard-popup">
  <div class="header">
    <h1>{{ "common.share-dashboard-header" | translate }}</h1>
    <mat-icon data-test="action-close-share" (click)="close()">close</mat-icon>
  </div>
  <div class="content">
    <p>{{ "common.invite-people-label" | translate }}</p>
    <mat-form-field class="email-input" appearance="legacy">
      <mat-chip-list #chipList multiple>
        <div class="single-chip">
          <mat-chip
            class="email-tag"
            *ngFor="let useremail of usersEmail; let i = index"
            [value]="useremail"
            [ngClass]="{ invalid: !useremail.isValid, selected: useremail.isSelected }"
            (click)="selectChip($event,i)"
            (focusout)="onFocusOutEvent($event)" 
          >
            <span class="cursor">{{ useremail.email }}</span>
            <input [id]="'chip_'+ i" autocomplete="off" class="email-input" [(ngModel)]="useremail.email" (keydown.backspace)="onMatChipKeyPress($event)" (keydown)="onKeyEnter($event,i,useremail.email)" focus/>
            <mat-icon data-test="action_remove_email" matChipRemove *ngIf="!useremail.isSelected" (click)="removeKeyword(i)">close</mat-icon>
          </mat-chip> 
          </div>
          <input
          class="email-input"
          placeholder="Enter email address"
          data-test="input_share_email"
          autocomplete="off"
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="addKeywordFromInput($event)"
          (paste)="pasted($event)"
          (blur)="blured($event)"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (focus)="deSelectEmails(usersEmail)"
        />        
      </mat-chip-list>
    </mat-form-field>
    <p *ngIf="isError" class="alert">
      {{ "message.invites-invalid-email" | translate }}
    </p>
  </div>
  <div class="action-footer">
    <div
      class="btn-group"
      fxLayout="row"
      fxFlexAlign="flex-end"
      fxLayoutGap="10px"
    >
      <button mat-flat-button class="secondary" (click)="close()" type="button">
        {{ "action.cancel" | translate | uppercase }}
      </button>
      <button
        mat-flat-button
        [disabled]="!usersEmail.length || isError"
        data-test="action_share_email"
        class="primary"
        (click)="share()"
      >
        {{ "action.share" | translate | uppercase }}
      </button>
      <button hidden="true" cdkFocusInitial></button>
    </div>
  </div>
</section>
