<!---#################### DESKTOP/Tablet UI ################################################-->

<section
  class="devices-listing"
  [ngClass]="{
    preview: renderedFrom != undefined && renderedFrom != 'eventlog'
  }"
  *ngIf="deviceType != 'MobilePortrait' && deviceType != 'MobileLandscape'"
>
  <div
    class="header"
    *ngIf="renderedFrom == undefined || renderedFrom == 'eventlog'"
  >
    <button
      data-test="action_reset_filter"
      class="clear-filter"
      mat-flat-button
      (click)="reset()"
    >
      <mat-icon> refresh </mat-icon>
      {{ 'action.reset-filter' | translate | uppercase }}
    </button>
    <h1 data-test="text_devices_title">{{ deviceTitle | translate }}</h1>
  </div>
  <div class="content">
    <div class="mat-elevation-z2">
      <mat-progress-bar
        *ngIf="showLoader"
        [ngStyle]="{ top: _tableheaderOffsetHeight }"
        class="progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>

      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          data-test="table_devicelisting"
        >
          <ng-container
            *ngFor="let column of displayedColumns"
            [matColumnDef]="column"
          >
            <!-- ################# TABLE HEADER TEMPLATE  ####################### -->
            <th
              mat-header-cell
              *matHeaderCellDef
              [ngStyle]="{
                height: _tableheaderOffsetHeight
              }"
            >
              <div fxLayout="column" class="header-content">
                <div class="title" *ngIf="column != 'selection'">
                  <p [attr.data-test]="'text' + column">
                    {{ 'common.' + column | translate }}
                  </p>
                  <mat-icon
                    class="sort-icon"
                    (click)="sortByKeyName(column)"
                    [ngClass]="{ active: _sortValues[column]?.isActive }"
                    [attr.data-test]="'action_' + column"
                    *ngIf="column != 'edit' && column != 'assigned'"
                  >
                    {{ _sortValues[column]?.icon }}
                  </mat-icon>
                  <div
                    class="tablefilter cursor"
                    *ngIf="
                      checkColumn(columnsToDisplay, column) &&
                      ((userRole == userRoleConfig?.SE &&
                        renderedFrom == undefined) ||
                        (userRole == userRoleConfig?.CE &&
                          renderedFrom == undefined))
                    "
                  >
                    <app-multi-select-dropdown
                      [listOfOption]="displayedColumns"
                      [selectedItem]="columnsToDisplay || []"
                      (selectedOption)="selectedOption('tableFilter', $event)"
                      [selectType]="'dropdown'"
                    ></app-multi-select-dropdown>
                  </div>
                </div>
                <div
                  class="search-bar"
                  *ngIf="
                    column != 'status' &&
                    column != 'selection' &&
                    column != 'edit' &&
                    column != 'assigned' &&
                    column != 'dateTime'
                  "
                >
                  <input
                    #searchBar
                    placeholder="Search..."
                    type="text"
                    class="search"
                    [name]="column"
                    [id]="column"
                    [value]="_filterValues[column]?.value || ''"
                    (keyup)="searchText($event)"
                    [attr.data-test]="'input_' + column"
                    autocomplete="off"
                  />
                  <mat-icon
                    [attr.data-test]="'action_clear_' + column"
                    class="right-icon"
                    *ngIf="searchBar.value"
                    (click)="updateFilter(column, '')"
                  >
                    clear
                  </mat-icon>
                </div>

                <div class="search-bar" *ngIf="column === 'dateTime'">
                  <app-datetime-picker
                    [startRangeDate]="_filterValues[column]?.value?.start || ''"
                    [endRangeDate]="_filterValues[column]?.value?.end || ''"
                    [placeholder]="'Select a date'"
                    [positionUpdateBlockOnError]="true"
                    [tooltip]="true"
                    [displayDateFormat]="dateFormat"
                    [clearBtn]="true"
                    [defaultTime]="defaultTime"
                    (selectedRange)="dateChange($event)"
                    (clearAction)="updateFilter(column, '')"
                  ></app-datetime-picker>
                </div>

                <div class="search-bar" *ngIf="column == 'status'">
                  <app-multi-select-dropdown
                    [listOfOption]="statuses"
                    [selectedItem]="_filterValues[column]?.value || []"
                    (selectedOption)="selectedOption(column, $event)"
                    [selectType]="'select'"
                  ></app-multi-select-dropdown>
                </div>
              </div>
            </th>
            <!-- ################# END  ####################### -->
            <!-- ################# TABLE BODY TEMPLATE  ####################### -->
            <ng-container
              *ngIf="
                column != 'status' &&
                column != 'selection' &&
                column != 'edit' &&
                column != 'assigned' &&
                column != 'dateTime'
              "
            >
              <td mat-cell *matCellDef="let element; let i = index">
                <div
                  class="device-name-content"
                  fxLayout="row"
                  fxLayoutGap="6px"
                  *ngIf="column === 'deviceName'; else defaultView"
                >
                  <div *ngIf="element['deviceImageUrl']">
                    <img
                      *ngIf="element.iconType == undefined"
                      [src]="element['deviceImageUrl']"
                      [alt]="element[column]"
                      [attr.data-test]="'image_' + i"
                    />
                    <img
                      *ngIf="element?.iconType == 'icon'"
                      [src]="iconUrl + element['deviceImageUrl']"
                      [alt]="element[column]"
                      [attr.data-test]="'image_' + i"
                    />
                    <img
                      *ngIf="element?.iconType == 'image'"
                      [src]="imageUrl + element['deviceImageUrl']"
                      [alt]="element[column]"
                      [attr.data-test]="'image_' + i"
                    />
                  </div>
                  <div>
                    <div class="text" [attr.data-test]="'text_' + column + i">
                      {{ element[column] }}
                    </div>
                  </div>
                </div>
                <ng-template #defaultView>
                  <span [attr.data-test]="'text_' + column + i">{{
                    element[column]
                  }}</span>
                </ng-template>
              </td>
            </ng-container>
            <ng-container *ngIf="column == 'status'"
              ><td mat-cell *matCellDef="let element; let i = index">
                <span
                  [attr.data-test]="'text_' + column + i"
                  [appStatusStyle]="element.status"
                  >{{ element.status }}
                </span>
              </td>
            </ng-container>
            <ng-container *ngIf="column == 'dateTime'"
              ><td mat-cell *matCellDef="let element">
                <span>
                  {{ element.dateTime | dateformat: dateFormat }}
                </span>
              </td>
            </ng-container>
            <ng-container *ngIf="column == 'selection'"
              ><td mat-cell *matCellDef="let element; let i = index">
                <div class="selection-container" *ngIf="column == 'selection'">
                  <input
                    type="radio"
                    [(ngModel)]="selectedDatasource"
                    name="select-datasource"
                    [value]="element['id']"
                    [id]="'id' + element['id']"
                    [attr.data-test]="'input_' + column + i"
                  />
                  <label
                    class="type-label cursor"
                    [attr.data-test]="'label_' + element['id']"
                    [for]="'id' + element['id']"
                    (click)="selectRow($event, element)"
                    [attr.data-test]="'label' + column + i"
                  ></label>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="column == 'edit'"
              ><td mat-cell *matCellDef="let element; let i = index">
                <mat-icon
                  [attr.data-test]="'edit' + column + i"
                  (click)="navigateUser($event, element)"
                  >edit</mat-icon
                >
              </td>
            </ng-container>
            <ng-container *ngIf="column == 'assigned'"
              ><td mat-cell *matCellDef="let element; let i = index">
                <label
                  class="msd-checkbox-wrap cursor"
                  [attr.data-test]="'label' + column + i"
                >
                  <input
                    class="msd-input"
                    type="checkbox"
                    name="assigned_device"
                    id="assigned_device"
                    [checked]="
                      !uncheckvalue.includes(element.profileInstanceID)
                    "
                    (change)="updateCheckStatus($event, element)"
                    [attr.data-test]="'checkbox' + column + i"
                  />
                  <span class="msd-input-mask"></span>
                </label>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr
            class="table-row content-row"
            mat-row
            [id]="row.id"
            [attr.data-test]="'row_' + i"
            (click)="navigateToDevice(row)"
            *matRowDef="let row; columns: columnsToDisplay; let i = index"
          ></tr>
          <tr *matNoDataRow>
            <td
              *ngIf="!showLoader"
              class="message"
              [attr.colspan]="displayedColumns.length"
            >
              {{ 'message.no-data-found' | translate }}
            </td>
            <td
              *ngIf="showLoader"
              class="message"
              [attr.colspan]="displayedColumns.length"
            >
              {{ 'message.loading' | translate }}
            </td>
          </tr>
        </table>
      </div>
      <div class="pagination-section">
        <mat-paginator
          [pageSizeOptions]="pageSizeOption"
          showFirstLastButtons
          [length]="itemLength"
          (page)="pageChange($event)"
          [pageIndex]="pageIndex"
          [pageSize]="pageSize"
          data-test="action_paginate"
        ></mat-paginator>
        <div *ngIf="showLoader" class="overlay-pagination"></div>
      </div>
    </div>
  </div>
</section>

<!---#################### MOBILE UI ################################################-->

<mat-progress-bar
  *ngIf="
    showLoader &&
    (deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape')
  "
  class="progress-bar p-absolute-top-64"
  mode="indeterminate"
></mat-progress-bar>

<section
  class="mobile-content position-align"
  *ngIf="deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape'"
>
  <div class="content" fxLayout="column" *ngIf="!mobileSetting">
    <div #mobileUI>
    <ng-container *ngIf="renderedFrom === undefined">
      <ng-container *ngIf="userRole === userRoleConfig?.PM">
        <app-pm-listing-search
          [value]="_filterValues['deviceName']?.value || ''"
          (searchingText)="searchText($event)"
          (openFilter)="openFilter()"
          (updateFilter)="updateFilter($event?.id, $event?.value)"
        >
        </app-pm-listing-search>
      </ng-container>
      <ng-container
        *ngIf="
          userRole === userRoleConfig?.SE || userRole === userRoleConfig?.CE
        "
      >
        <app-se-listing-search
          [value1]="_filterValues['company']?.value || ''"
          [value2]="_filterValues['deviceName']?.value || ''"
          (searchingText)="searchText($event)"
          (openFilter)="openFilter()"
          (updateFilter)="updateFilter($event?.id, $event?.value)"
        >
        </app-se-listing-search>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="renderedFrom === 'eventlog'">
      <app-event-log-search 
      [value]="_filterValues['notificationMessage']?.value || ''"
      (searchingText)="searchText($event)"
      (openFilter)="openFilter()"
      (updateFilter)="updateFilter($event?.id, $event?.value)"></app-event-log-search>
    </ng-container>
    </div>
    <div class="filter-tags" *ngIf="_selectedFilter.length > 0">
      <mat-chip-list>
        <ng-container *ngFor="let item of _selectedFilter">
          <mat-chip>
            {{ item }}
            <mat-icon (click)="removeFilterByValue(item)" matChipRemove
              >clear</mat-icon
            >
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <app-device-card
      *ngFor="let data of dataSource.data"
      [deviceData]="data"
      [renderedFrom]="renderedFrom"
      (click)="navigateToDevice(data)"
      [id]="'device-' + data.id"
    ></app-device-card>
    <div
      class="text-center"
      *ngIf="dataSource.data.length === 0 && !showLoader"
    >
      {{ 'message.no-data-found' | translate }}
    </div>
    <div class="text-center" *ngIf="showLoader && !isLoading">
      {{ 'message.loading' | translate }}
    </div>
    <div fxLayout="row" fxLayoutAlign="center" *ngIf="isLoading && !loadedAll">
      <mat-progress-spinner
        class="custom-spinner"
        mode="indeterminate"
        diameter="50"
      ></mat-progress-spinner>
    </div>
  </div>
  <div *ngIf="mobileSetting">
    <app-mobile-filter-sorting
      [statuses]="statuses"
      [sortbyList]="displayedColumns"
      [deviceListingState]="deviceListingState"
      (outputState)="outputMobileState($event)"
      [renderedFrom]="renderedFrom"
    ></app-mobile-filter-sorting>
  </div>
</section>
