import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FlexLayoutModule } from '@angular/flex-layout';

function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './localization/', '.json');
}

const TRANSLATOR_CONFIG = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  defaultLanguage: 'en-US',
};

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslateModule.forChild(TRANSLATOR_CONFIG)],
  exports: [TranslateModule, NgxMaterialTimepickerModule, FlexLayoutModule],
  providers: [TranslateService, TranslateStore],
})
export class ExternalModule {
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en-US');
  }
}
