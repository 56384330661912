<div class="uploader-container" appDnd (fileDropped)="onFileDropped($event)">
  <p class="message">{{ "common.dnd" | translate }}</p>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    single
    (change)="fileBrowseHandler($event.target.files)"
    accept="image/jpeg, image/png, image/svg+xml"
  />
  <button
    mat-flat-button
    class="btn secondary-btn"
    (click)="fileDropRef.click()"
  >
    {{ "common.browse" | translate }}
  </button>
</div>
