<div class="filterSection" fxLayoutAlign="flex-start center" fxLayoutGap="15px">
    <div class="searchInput" fxLayout="row" fxFlex="80">
      <input
        #mobileEventMessage
        placeholder="Search by event message..."
        type="text"
        name="notificationMessage"
        fxFlex="90"
        id="notificationMessage"
        [value]="value"
        (keyup)="searchText($event)"
        autocomplete="off"
        data-test="input_eventmessage"
      />
      <span fxFlex="10"
        ><img
          *ngIf="!mobileEventMessage.value"
          src="../../../assets/images/ic-search.svg"
          data-test="media_search"
        />
        <img
          (click)="_updateFilter(mobileEventMessage.id, '')"
          *ngIf="mobileEventMessage.value"
          src="../../../assets/images/ic-close.svg"
          data-test="media_close"
        />
      </span>
    </div>
    <div
      data-test="text_filter"
      class="more_filter"
      
      fxLayout="row"
      fxLayoutGap="5px"
    >
    <div (click)="_openFilter()">
      <img
        class="slider"
        data-test="media_filter"
        src="../../../assets/images/solid-sliders-h.svg"
      />
    </div>
    </div>
  </div>
  