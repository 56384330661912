import { Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { emailRegex } from '../../constants/constants';

@Component({
  selector: 'app-share-dashboard',
  templateUrl: './share-dashboard.component.html',
  styleUrls: ['./share-dashboard.component.scss'],
})
export class ShareDashboardComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB, SPACE] as const;
  usersEmail: {
    email: string;
    isValid: boolean;
    isSelected?: boolean;
    success?: boolean;
    reason?: string;
  }[] = [];
  id: string;
  constructor(
    public dialogRef: MatDialogRef<ShareDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.id = this.data.id;
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (!event.value) {
      return;
    }
    this.usersEmail.push({
      email: event.value,
      isValid: this.validateEmail(event.value),
    });
    event.input.value = '';
  }

  pasted(event: ClipboardEvent) {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/\s|;|,|\n/)
      .forEach((value) => {
        if (value.trim()) {
          this.usersEmail.push({
            email: value.trim(),
            isValid: this.validateEmail(value.trim()),
          });
        }
      });
  }

  blured(event: FocusEvent) {
    (event.target as any).value.split(/\s|;|,|\n/).forEach((value) => {
      if (value.trim()) {
        this.usersEmail.push({
          email: value.trim(),
          isValid: this.validateEmail(value.trim()),
        });
      }
    });
    (event.target as any).value = '';
  }

  removeKeyword(idx) {
    if (idx > -1) {
      this.usersEmail.splice(idx, 1);
    }
  }

  close() {
    this.dialogRef.close();
  }

  validateEmail(email) {
    const re = emailRegex;
    return re.test(String(email).toLowerCase());
  }

  get isError() {
    return !this.usersEmail.every((useremail) => useremail.isValid);
  }

  async share() {
    if (this.isError || this.usersEmail.length < 1) {
      return;
    }
    const emails = this.usersEmail.map((userEmail) => userEmail.email);
    this.dialogRef.close([...new Set(emails)]);
  }

  selectChip(e, i) {
    if (e.srcElement.nextElementSibling === null) {
      return;
    }
    if (!this.usersEmail[i].isSelected) {
      this.usersEmail.forEach((userEmail) => (userEmail.isSelected = false));
      this.usersEmail[i].isSelected = true;
    }
  }

  onFocusOutEvent(e) {
    if (e.relatedTarget) {
      if (!e.relatedTarget.classList.contains('email-input')) {
        this.deSelectEmails(this.usersEmail);
      }
    } else {
      this.deSelectEmails(this.usersEmail);
    }
  }

  onMatChipKeyPress(e) {
    e.stopImmediatePropagation();
  }

  onKeyEnter(e, i, value) {
    if (e.code === 'Space' || e.code === 'Enter' || e.code === 'Tab' || e.code === 'Comma') {
      this.usersEmail.forEach((userEmail) => (userEmail.isSelected = false));
      if (value !== '') {
        this.usersEmail[i].isValid = this.validateEmail(value);
      } else {
        this.usersEmail.splice(i, 1);
      }
    }
  }

  deSelectEmails(userEmails) {
    userEmails.forEach((userEmail, index) => {
      if (userEmail.isSelected) {
        if (userEmail.email !== '') {
          userEmail.isValid = this.validateEmail(userEmail.email);
        } else {
          this.usersEmail.splice(index, 1);
        }
      }
      userEmail.isSelected = false;
    });
  }
}
