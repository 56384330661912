/* eslint-disable max-len */
import { environment } from 'src/environments/environment';
export const userRoleConfig = {
  SE: 'FieldVEU Admin',
  PM: 'FieldVEU User',
  CE: 'FieldVEU Owner',
};

export interface ToasterData {
  type: string;
  message: string;
}

export interface DialogData {
  data: {
    id: string;
    modalType: string;
  };
  height: string;
  width: string;
}

export interface TemplateDetails {
  [x: string]: any;
}

export const deviceImageURL = {
  iconUrl: '../../../../assets/images/device-icon/',
  imageUrl: (window as any).smcUrl + 'fieldveu-images/',
};
export const urls = {
  terms: 'https://www.fieldpop.io/tos',
  privacy: 'https://www.fieldpop.io/privacy',
};
export const unsupportedBrowser = ['IE'];

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
