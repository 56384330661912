import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss'],
})
export class TemplateDetailsComponent implements OnInit {
  @Input() templateDetails;
  model: string;
  version: string;
  templateName: string;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.model = this.templateDetails.modelNumber;
    this.version = this.templateDetails.minProfileVersion;
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.templateName = queryParams.templateName || this.templateDetails.templateName;
  }

  changeQuery() {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...queryParams, ...{ templateName: this.templateName } },
    });
  }
}
