<mat-progress-bar
  *ngIf="!subscriptionHandler && type !== 'template' && type != 'widget'"
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="card-content"
  fxFlex
  fxLayout="column"
  [ngClass]="{ 'opacity-1': !statusDetails }"
>
  <div class="main-content" fxFlex fxLayout="column">
    <div class="content1" fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <span
        [ngClass]="
          colorInfo == 'Warning'
            ? 'card-main-head head-pos'
            : 'card-main-head-no-warning head-pos'
        "
        >{{ widget?.title | translate }}</span
      >

      <!--MENU FOR DESKTOP-->
      <span
        *ngIf="device?.includes('Web') || device?.includes('TabletLandscape')"
      >
        <span *ngIf="type == 'template'">
          <mat-icon
            (click)="edit('device-status', widget)"
            class="status-dropdown cursor"
            *ngIf="colorInfo !== 'Warning'"
            [ngStyle]="{ color: 'white' }"
            data-test="edit_devicestatus"
            >edit</mat-icon
          >
        </span>
        <span *ngIf="statusDetails?.length > 0 && colorInfo !== 'Offline'">
          <span
            (click)="openStatusMenu()"
            class="cursor"
            [ngClass]="
              colorInfo == 'Warning'
                ? 'card-main-head-status'
                : 'card-main-head-status-no-warning'
            "
            data-test="action_checkstatus"
          >
            {{ "common.detail" | translate | uppercase }}

            <mat-icon
              class="status-dropdown"
              [matMenuTriggerFor]="statusMenu"
              *ngIf="colorInfo == 'Warning'"
              [ngStyle]="{ color: 'black' }"
              >expand_more</mat-icon
            >
            <mat-icon
              class="status-dropdown"
              [matMenuTriggerFor]="statusMenu"
              *ngIf="colorInfo !== 'Warning'"
              [ngStyle]="{ color: 'white' }"
              >expand_more</mat-icon
            >
          </span>
          <mat-menu
            #statusMenu="matMenu"
            class="status-menu"
            backdropClass="has-up-arrow-status"
          >
            <div
              *ngFor="let status of statusDetails; let i = index"
              class="status-menu-container cursor-pointer"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              (click)="getStatusDetails(status)"
            >
              <div class="status-type-margin">
                <span data-test="text_status" class="status-font">{{
                  status?.title
                }}</span>
              </div>
              <div>
                <button
                  [appStatusStyle]="status.status"
                  class="status-btn-back"
                >
                  <span> </span>
                  <span class="status-margin">{{ status?.status }}</span>
                </button>
              </div>
            </div>
          </mat-menu>
        </span>
      </span>
      <!--END MENU FOR DESKTOP-->

      <!--MENU FOR TABLET AND MOBILE-->

      <span
        *ngIf="device?.includes('TabletPortrait') || device?.includes('Mobile')"
      >
        <span *ngIf="statusDetails?.length > 0 && colorInfo !== 'Offline'">
          <span
            (click)="openStatusMenu()"
            class="cursor"
            [ngClass]="
              colorInfo == 'Warning'
                ? 'card-main-head-status'
                : 'card-main-head-status-no-warning'
            "
          >
            {{ "common.detail" | translate | uppercase }}

            <mat-icon
              class="status-dropdown"
              [matMenuTriggerFor]="statusMenu"
              *ngIf="colorInfo == 'Warning'"
              [ngStyle]="{ color: 'black' }"
              >expand_more</mat-icon
            >
            <mat-icon
              class="status-dropdown"
              [matMenuTriggerFor]="statusMenu"
              *ngIf="colorInfo !== 'Warning'"
              [ngStyle]="{ color: 'white' }"
              >expand_more</mat-icon
            >
          </span>
          <mat-menu
            #statusMenu="matMenu"
            class="status-menu-tablet"
            backdropClass="has-up-arrow-status"
          >
            <div
              *ngFor="let status of statusDetails; let i = index"
              class="status-menu-container cursor-pointer"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              (click)="getStatusDetails(status)"
            >
              <div class="status-type-margin">
                <span class="status-font">{{ status?.title }}</span>
              </div>
              <div>
                <button
                  [appStatusStyle]="status.status"
                  class="status-btn-back"
                >
                  <span> </span>
                  <span class="status-margin">{{ status?.status }}</span>
                </button>
              </div>
            </div>
          </mat-menu>
        </span>
      </span>

      <!--END MENU FOR TABLET AND MOBILE-->
    </div>

    <div
      class="content-2"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      <span>
        <img
          *ngIf="colorInfo == 'Warning'"
          class="device-card-img"
          src="../../../../assets/images/warning.svg"
          data-test="img_warning"
        />
        <img
          *ngIf="colorInfo == 'Alarm'"
          class="device-card-img"
          src="../../../../assets/images/alarm-white.svg"
          data-test="img_alarm"
        />
        <img
          *ngIf="colorInfo == 'Trouble'"
          class="device-card-img"
          src="../../../../assets/images/warning-white.svg"
          data-test="img_trouble"
        />
        <img
          *ngIf="colorInfo == 'Normal'"
          class="device-card-img"
          src="../../../../assets/images/check_white.svg"
          data-test="img_checked"
        />
        <img
          *ngIf="colorInfo == 'Offline'"
          class="device-card-img"
          src="../../../../assets/images/close-white.svg"
          data-test="img_crossoffline"
        />
      </span>

      <span
        *ngIf="cardStatusCount?.length == 0"
        [ngClass]="
          colorInfo == 'Warning'
            ? 'card-main-head device-imp-details device-card-status-align'
            : 'card-main-head-no-warning device-imp-details-no-warning device-card-status-align'
        "
      >
        {{ "" }} {{ colorInfo }}
      </span>

      <span
        *ngIf="cardStatusCount?.length == 1 && colorInfo !== 'Offline'"
        [ngClass]="
          colorInfo == 'Warning'
            ? 'card-main-head device-imp-details device-card-status-align'
            : 'card-main-head-no-warning device-imp-details-no-warning device-card-status-align'
        "
      >
        {{ statusDetails?.length == 1 ? statusDetails?.length : "" }} Alert
      </span>

      <span
        *ngIf="cardStatusCount?.length > 1 && colorInfo !== 'Offline'"
        [ngClass]="
          colorInfo == 'Warning'
            ? 'card-main-head device-imp-details device-card-status-align'
            : 'card-main-head-no-warning device-imp-details-no-warning device-card-status-align'
        "
      >
        {{ statusDetails?.length > 1 ? statusDetails?.length : "" }} Alerts
      </span>

      <span
        *ngIf="cardStatusCount?.length >= 1 && colorInfo == 'Offline'"
        [ngClass]="
          colorInfo == 'Warning'
            ? 'card-main-head device-imp-details device-card-status-align'
            : 'card-main-head-no-warning device-imp-details-no-warning device-card-status-align'
        "
      >
        {{
          cardStatusCount?.length > 0 && colorInfo !== "Offline"
            ? cardStatusCount?.length
            : ""
        }}
        {{ colorInfo }}
      </span>
    </div>
    <div
      class="content-2"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between end"
    >
      <span
        [ngClass]="
          colorInfo == 'Warning'
            ? 'time-stamp-font'
            : 'time-stamp-font-no-warning'
        "
        >{{ timeValue }}</span
      >
    </div>
  </div>
</div>
