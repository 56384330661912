import { Component, Input, OnInit } from '@angular/core';
import { userRoleConfig, deviceImageURL } from 'src/app/shared/constants/constants';
import { DashEventService } from '../services/dashboard-event/dash-event.service';
@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnInit {
  @Input() widget;
  @Input() device;
  @Input() type;
  @Input() templateDetails;
  deviceDetailsData = {};

  userRoleConfig = userRoleConfig;
  userRole: string;
  roleBasedDataConfig = {
    [userRoleConfig.PM]: ['siteName', 'profileName', 'profileVersion'],
    [userRoleConfig.SE]: [
      'company',
      'macAddress',
      'siteName',
      'profileName',
      'serialNumber',
      'gatewaySerialNumber',
      'profileVersion',
    ],
    [userRoleConfig.CE]: [
      'company',
      'macAddress',
      'siteName',
      'profileName',
      'serialNumber',
      'gatewaySerialNumber',
      'profileVersion',
    ],
  };
  objectKeys = Object.keys;
  readonly iconUrl = deviceImageURL.iconUrl;
  readonly imageUrl = (window as any).smcUrl + 'fieldveu-images/';
  constructor(private _dashEventService: DashEventService) {}

  ngOnInit(): void {
    this.getDeviceDetails();
  }

  getDeviceDetails() {
    this.extractDataBasedOnRole();
  }
  get deviceName() {
    const deviceData = this._dashEventService._deviceDetail;
    if (deviceData) {
      return deviceData.deviceName;
    }
    return '';
  }

  extractDataBasedOnRole() {
    const defaultRole = userRoleConfig.PM;

    this.userRole = localStorage.getItem('role');
    const role = this.userRole || defaultRole;
    const data = this._dashEventService._deviceDetail;
    const extractorList = this.roleBasedDataConfig[role];
    for (const item of extractorList) {
      if (item) {
        const key = item;
        if (data[key]) {
          this.deviceDetailsData[key] = data[key];
        }
      }
    }

    if (this.type === 'template') {
      this.deviceDetailsData = {
        company: ' ',
        macAddress: '',
        siteName: '',
        profileName: this.templateDetails.modelNumber || '',
        serialNumber: '',
        gatewaySerialNumber: '',
      };
    }
  }
}
