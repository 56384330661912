<div fxFlex="100%" fxLayout="row" class="gauge-widget">
  <form [formGroup]="registrationForm" class="w-100">
    <div class="inp-container" fxFlex="38%">
      <div class="gauge-widget-container">
        <mat-tab-group
          [selectedIndex]="selectedTab"
          (selectedTabChange)="onTabClickedChanged($event)"
          [ngClass]="{
            'error-data-tab': dataTabError,
            'error-design-tab': designTabError
          }"
        >
          <mat-tab label="DATA">
            <ng-template mat-tab-label>
              <div>
                <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between">
                  <div class="data-align">DATA</div>
                  <div class="tab-icon-align" *ngIf="dataTabError">
                    <mat-icon
                      [ngStyle]="{ color: '#e60000' }"
                      class="example-tab-icon"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <div formGroupName="data" class="top-margin">
              <div class="left-margin data-top-margin">
                <!-- <mat-form-field class="dropdown-field data-top-margin"> -->
                <div fxFlex="100%" fxLayout="column">
                  <div class="label-margin">
                    <mat-label class="label-clr">{{
                      "common.data-point" | translate
                    }}</mat-label>
                  </div>
                  <mat-select
                    class="dropdown-binary"
                    [ngClass]="{
                      'dropdown-binary-error':
                        !registrationForm.valid &&
                        submitted &&
                        registrationForm.controls['data']['controls'][
                          'dataPoint'
                        ]?.errors?.required
                    }"
                    formControlName="dataPoint"
                    placeholder="{{
                      'placeholder_messages.datapoint' | translate
                    }}"
                  >
                    <mat-option
                      *ngFor="let data of dataset"
                      [value]="data.path"
                    >
                      {{ data.path }}
                    </mat-option>
                  </mat-select>

                  <!-- </mat-form-field> -->
                  <div
                    class="invalid-feedback-text"
                    *ngIf="
                      !registrationForm.valid &&
                      submitted &&
                      registrationForm.controls['data']['controls']['dataPoint']
                        ?.errors?.required
                    "
                  >
                    <span>*</span>{{ "message.valid-data-point" | translate }}
                  </div>
                </div>
              </div>

              <div class="left-margin top-margin main-top-margin">
                <div class="label-margin">
                  <span>
                    <label class="label-clr">{{
                      "common.units" | translate
                    }}</label></span
                  >
                </div>
                <div class="example-full-width-gauge auto-main">
                  <!-- <mat-form-field class="example-full-width"> -->

                  <div fxFlex="100%" fxLayout="row">
                    <input
                      class="auto-input"
                      matInput
                      placeholder="{{
                        'placeholder_messages.unit' | translate
                      }}"
                      aria-label="State"
                      id="plain"
                      (change)="setUnits($event, 'inp')"
                      [matAutocomplete]="auto"
                      formControlName="stateCtrl"
                    />
                    <mat-icon
                      class="status-dropdown drop-align auto-icon-pos-gauge"
                      [ngStyle]="{ color: '#404040' }"
                      >arrow_drop_down</mat-icon
                    >
                  </div>
                  <mat-autocomplete
                    class="auto-container-pos"
                    (optionSelected)="setUnits($event, 'sel')"
                    #auto="matAutocomplete"
                  >
                    <mat-option
                      *ngFor="let state of filteredStates | async"
                      [value]="state.name"
                    >
                      <span>{{ state.name }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <!-- </mat-form-field> -->
                <div
                  class="invalid-feedback-auto"
                  *ngIf="
                    !registrationForm.valid &&
                    submitted &&
                    registrationForm.controls['stateCtrl']?.errors?.required
                  "
                >
                  <span>*</span>{{ "message.valid-units" | translate }}
                </div>
              </div>
              <div></div>

              <div
                fxLayout="row"
                class="btn-placements main-top-margin top-margin left-margin"
              >
                <button
                  type="button"
                  data-test="cancel-binary"
                  class="btn secondary-btn"
                  mat-flat-button
                  (click)="onCancel()"
                >
                  {{ "action.cancel" | translate | uppercase }}
                </button>
                <button
                  type="button"
                  *ngIf="type == 'edit'"
                  class="btn secondary-btn"
                  mat-flat-button
                  data-test="action_delete"
                  (click)="delete()"
                >
                  {{ "action.delete" | translate }}
                </button>

                <button
                  type="submit"
                  (click)="onSubmit()"
                  data-test="submit-binary"
                  class="btn primary-btn"
                  mat-flat-button
                >
                  {{ "action.save-widget" | translate | uppercase }}
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="design-tab-ht" label="DESIGN">
            <div class="tab-scroll" cdkScrollable>
              <ng-template mat-tab-label>
                <div>
                  <div
                    fxFlex="100%"
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                  >
                    <div class="data-align">DESIGN</div>
                    <div
                      class="tab-icon-align"
                      *ngIf="
                        designTabError || minError || maxError || rangeError
                      "
                    >
                      <mat-icon
                        [ngStyle]="{ color: '#e60000' }"
                        class="example-tab-icon"
                        >cancel</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- *ngFor="let gridline of colorgridOptions; let i = index" -->
              <div class="inp-container-range">
                <div formGroupName="design" class="active-group">
                  <div *ngIf="rangeLength !== 0">
                    <div
                      *ngFor="let gridline of designDat; let i = index"
                      class="
                        textbox-align
                        top-margin
                        left-margin
                        main-top-margin
                      "
                      fxLayout="column"
                    >
                      <div class="label-clr-head">
                        {{ gridline.name }}
                      </div>

                      <div class="label-margin" fxFlex="100%" fxLayout="column">
                        <div fxFlex="100%" class="top-margin" fxLayout="row">
                          <div fxFlex="100%" fxLayout="column">
                            <div class="label-margin">
                              <label *ngIf="i == 0" class="label-clr">{{
                                "common.min-val" | translate
                              }}</label>
                              <label *ngIf="i != 0" class="label-clr">{{
                                "common.from" | translate
                              }}</label>
                            </div>
                            <div>
                              <!-- placeholder="{{ 'common.from' | translate }}" -->
                              <span class="top-margin"
                                ><input
                                  [ngClass]="{
                                    'inp-field-design-gauge-disabled':
                                      i !== 0 || (i == 0 && allValues),
                                    'inp-field-design-gauge-error':
                                      minError || reqMin,
                                    'inp-field-design-gauge':
                                      !minError || !reqMin
                                  }"
                                  matInput
                                  [disabled]="i !== 0 || (i == 0 && allValues)"
                                  [value]="gridline.from"
                                  (change)="setFrom($event, i)"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                          <div fxFlex="100%" fxLayout="column">
                            <div class="left-margin label-margin">
                              <label
                                *ngIf="i == designDat.length - 1"
                                class="label-clr"
                                >{{ "common.max-val" | translate }}</label
                              >
                              <label
                                *ngIf="i != designDat.length - 1"
                                class="label-clr"
                                >{{ "common.to" | translate }}</label
                              >
                            </div>
                            <div>
                              <span class="top-margin left-margin"
                                ><input
                                  [ngClass]="{
                                    'inp-field-design-gauge-disabled':
                                      i !== designDat.length - 1 ||
                                      (i == designDat.length - 1 && allValues),
                                    'inp-field-design-gauge-error':
                                      maxError || reqMax,
                                    'inp-field-design-gauge':
                                      !maxError || !reqMax
                                  }"
                                  matInput
                                  [disabled]="
                                    i !== designDat.length - 1 ||
                                    (i == designDat.length - 1 && allValues)
                                  "
                                  value="{{ gridline.to }}"
                                  (change)="setTo($event, i)"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                          <div class="color-inp-margin left-margin">
                            <span class="top-margin"
                              ><input
                                class="color-field"
                                value="{{ gridline.color }}"
                                (change)="setColor($event, i)"
                                matInput
                                type="color"
                            /></span>
                          </div>
                        </div>
                        <div
                          class="invalid-feedback-text"
                          *ngIf="minError && i == 0"
                        >
                          {{ "message.minmaxerror-gauge" | translate }}
                        </div>
                        <div
                          class="invalid-feedback-text"
                          *ngIf="reqMin && i == 0"
                        >
                          {{ "message.valid-min" | translate }}
                        </div>
                        <div
                          class="invalid-feedback-text"
                          *ngIf="maxError && i == designDat.length - 1"
                        >
                          {{ "message.maxminerror-gauge" | translate }}
                        </div>
                        <div
                          class="invalid-feedback-text"
                          *ngIf="reqMax && i == designDat.length - 1"
                        >
                          {{ "message.valid-max" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="rangeLength == 0" fxFlex="100%" fxLayout="column">
                    <div
                      *ngFor="let gridline of designDat; let i = index"
                      class="top-margin left-margin"
                      fxFlex="100%"
                      fxLayout="column"
                    >
                      <div class="boxes" fxFlex="100%" fxLayout="column">
                        <div fxFlex="100%" fxLayout="row">
                          <div fxFlex="100%" fxLayout="column">
                            <div class="label-margin">
                              <label class="label-clr">{{
                                "common.min-val" | translate
                              }}</label>
                            </div>
                            <div>
                              <span
                                ><input
                                  [ngClass]="{
                                    'inp-field-design-gauge-error':
                                      minError || reqMin,
                                    'inp-field-design-gauge':
                                      !minError || !reqMin
                                  }"
                                  matInput
                                  [value]="gridline.from"
                                  (change)="setFrom($event, i)"
                                  placeholder="{{
                                    'common.min-val' | translate
                                  }}"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                          <div fxFlex="100%" fxLayout="column">
                            <div class="label-margin">
                              <label class="label-clr">{{
                                "common.max-val" | translate
                              }}</label>
                            </div>
                            <div>
                              <span
                                ><input
                                  [ngClass]="{
                                    'inp-field-design-gauge-error':
                                      maxError || reqMax,
                                    'inp-field-design-gauge':
                                      !maxError || reqMax
                                  }"
                                  matInput
                                  value="{{ gridline.to }}"
                                  (change)="setTo($event, i)"
                                  placeholder="{{
                                    'common.max-val' | translate
                                  }}"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                          <div class="label-margin"></div>

                          <div class="color-inp-margin right-margin">
                            <span class=""
                              ><input
                                class="color-field-range"
                                matInput
                                value="{{ gridline.color }}"
                                (change)="setColor($event, i)"
                                type="color"
                            /></span>
                          </div>
                        </div>
                        <div class="invalid-feedback-text" *ngIf="minError">
                          {{ "message.minmaxerror-gauge" | translate }}
                        </div>
                        <div class="invalid-feedback-text" *ngIf="maxError">
                          {{ "message.maxminerror-gauge" | translate }}
                        </div>
                        <div class="invalid-feedback-text" *ngIf="reqMin">
                          {{ "message.valid-min" | translate }}
                        </div>

                        <div class="invalid-feedback-text" *ngIf="reqMax">
                          {{ "message.valid-max" | translate }}
                        </div>
                      </div>

                      <div class="main-top-margin">
                        <div class="label-margin">
                          <label class="label-clr"
                            >{{
                              "common.arc-labels" | translate
                            }}
                            (optional)</label
                          >
                        </div>
                        <span
                          ><input
                            [ngClass]="{
                              'inp-field-error-gauge-arc': rangeError,
                              'inp-field-design-gauge-arc': !rangeError
                            }"
                            matInput
                            value="{{ gridline.arcRanges }}"
                            (keyup)="checkComma($event)"
                            (change)="setArcRanges($event, i, 'input')"
                            placeholder="{{ 'common.arc-labels' | translate }}"
                            type="text"
                          />
                        </span>
                        <div class="invalid-feedback-text" *ngIf="rangeError">
                          {{ "message.valid-range" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  fxLayout="row"
                  class="
                    btn-padding-btm btn-placements
                    main-top-margin
                    top-margin
                    left-margin
                  "
                >
                  <button
                    type="button"
                    data-test="cancel-binary"
                    class="btn secondary-btn"
                    mat-flat-button
                    (click)="onCancel()"
                  >
                    {{ "action.cancel" | translate | uppercase }}
                  </button>
                  <button
                    *ngIf="type == 'edit'"
                    class="btn secondary-btn"
                    mat-flat-button
                    data-test="action_delete"
                    (click)="delete()"
                    type="button"
                  >
                    {{ "action.delete" | translate }}
                  </button>
                  <button
                    type="submit"
                    (click)="onSubmit()"
                    data-test="submit-binary"
                    class="btn primary-btn"
                    mat-flat-button
                  >
                    {{ "action.save-widget" | translate | uppercase }}
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div
      fxFlex="62%"
      fxLayoutAlign="center"
      class="view-container preview-left-margin top-margin"
    >
      <div class="gauge-container preview-left-margin">
        <div
          *ngIf="
            registrationForm.controls['data']['controls']['dataPoint'].value !==
            ''
          "
          class="left-align-cont preview-notitle"
          matTooltipClass="tooltip-hover"
          [matTooltip]="
            registrationForm.controls['data']['controls']['dataPoint'].value
          "
        >
          {{ registrationForm.controls["data"]["controls"]["dataPoint"].value }}
        </div>
        <div
          *ngIf="
            registrationForm.controls['data']['controls']['dataPoint'].value ==
            ''
          "
          class="left-align-cont preview-title"
        >
          {{ "common.widget-title" | translate }}
        </div>
        <app-fv-gauge-chart
          id="gauge-area"
          [canvasWidth]="canvasWidth"
          [needleValue]="needleValue"
          [centralLabel]="centralLabel"
          [options]="options"
          [bottomLabel]="bottomLabel"
          [bottomLabelFont]="bottomLabelFont"
        ></app-fv-gauge-chart>
        <div class="left-align-cont">
          <div class="preview-left-margin preview-footer">00:00:00</div>
        </div>
      </div>
    </div>
  </form>
</div>
