import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ToasterData, userRoleConfig } from '../../../shared/constants/constants';
import { emailRegex } from '../../../shared/constants/constants';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  roles = [{ name: 'FieldVEU Owner' }, { name: 'FieldVEU Admin' }, { name: 'FieldVEU User' }];
  submitted = false;
  addUserForm = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex)]],
    fieldveuRole: ['', Validators.required],
  });
  showEmailErrorMsg = false;
  sentrequest = false;
  unsaved = false;
  constructor(
    public fb: FormBuilder,
    public _meshService: MeshServiceProvidor,
    private router: Router,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    const userRole = localStorage.getItem('role');
    if (userRole === userRoleConfig.CE) {
    } else if (userRole === userRoleConfig.SE) {
      this.roles.shift();
    } else if (userRole === userRoleConfig.PM) {
      this.roles = [];
    }

    const sub2 = this.addUserForm.valueChanges.pipe().subscribe((val) => {
      this.unsaved = val.email !== '' || val.fieldveuRole !== '' ? true : false;
    });
  }

  showEmailError(): any {
    if (
      !!this.addUserForm.controls.email.errors &&
      !!this.addUserForm.controls.email.errors.pattern
    ) {
      this.showEmailErrorMsg = true;
    } else {
      this.showEmailErrorMsg = false;
    }
  }

  cancel(): any {
    this.router.navigate(['main', 'users']);
  }

  save(): any {
    this.submitted = true;
    if (this.addUserForm.valid) {
      this.sentrequest = true;
      this._meshService
        .createUser(this.addUserForm.value)
        .then((res) => {
          const data: ToasterData = {
            type: 'success',
            message: 'newuser',
          };
          this.sentrequest = false;

          this.cs.openToaster(data);
          this.unsaved = false;
          this.router.navigate(['main', 'users']);
        })
        .catch((err) => {
          this.sentrequest = false;

          const data: ToasterData = {
            type: 'error-custom',
            message: err.toString(),
          };
          this.unsaved = true;

          this.cs.openToaster(data);
        });
    }
  }
}
