<div
  *ngIf="showPage"
  class="change-container"
  [ngClass]="{
    'mobile-device':
      deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape',
    'mobile-landscape': deviceType == 'MobileLandscape'
  }"
>
  <mat-progress-bar
    class="progress-bar p-absolute-top"
    mode="indeterminate"
    *ngIf="
      loginDissabled &&
      (deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape')
    "
  ></mat-progress-bar>
  <div
    class="changeform-container"
    [ngClass]="{ formDisabled: loginDissabled }"
  >
    <div>
      <mat-card class="change-card">
        <mat-progress-bar
          class="progress-bar p-absolute-top"
          mode="indeterminate"
          *ngIf="
            loginDissabled &&
            deviceType != 'MobilePortrait' &&
            deviceType != 'MobileLandscape'
          "
        ></mat-progress-bar>
        <mat-card-content>
          <mat-card-title>{{
            "common.change-password" | translate
          }}</mat-card-title>
          <form
            #loginForm="ngForm"
            class="text-center"
            (ngSubmit)="login(loginForm)"
          >
            <div class="row">
              <label
                for="inputEmail3"
                class="loginfield-label"
                data-test="text_email"
                >{{ "common.current-password" | translate }}
              </label>
              <div class="field-container">
                <input
                  type="password"
                  required
                  placeholder="Enter current password"
                  (keyup)="checkOldPassword($event, loginForm)"
                  [(ngModel)]="changePasswordModel.oldpassword"
                  #oldpassword="ngModel"
                  class="form-control"
                  name="oldpassword"
                  email
                  [disabled]="loginDissabled"
                  (focusout)="showEmailError = true"
                  [ngClass]="{
                    error: loginForm.submitted && oldpassword?.errors?.required
                  }"
                  data-test="input_email"
                />
                <div
                  class="error-message"
                  *ngIf="loginForm.submitted && !loginForm.valid"
                >
                  <p
                    data-test="required_password"
                    *ngIf="oldpassword?.errors?.required"
                  >
                    {{ "message.password-required" | translate }}
                  </p>
                </div>
                <p
                  class="warning-txt"
                  [ngClass]="{
                    warningTxtError: wrongOldPass
                  }"
                  *ngIf="wrongOldPass == true"
                >
                  {{ "message.old-password" | translate }}
                </p>
              </div>
            </div>
            <div class="row">
              <label
                for="inputPassword3"
                data-test="text_password"
                class="loginfield-label"
              >
                {{ "common.create-password" | translate }}
                <span
                  data-test="text_show_hide"
                  (click)="
                    !loginDissabled ? (hidePassword = !hidePassword) : ''
                  "
                >
                  {{
                    hidePassword
                      ? ("common.show" | translate)
                      : ("common.hide" | translate)
                  }}
                  <img
                    *ngIf="hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility.svg"
                    alt="Visible"
                    data-test="media_visibility"
                  />
                  <img
                    *ngIf="hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-disabled.svg"
                    alt="visibility-disabled"
                    data-test="media_visibility_disabled"
                  />
                  <img
                    *ngIf="!hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off.svg"
                    alt="visibility-off"
                    data-test="media-visibility_off"
                  />
                  <img
                    *ngIf="!hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off-disabled.svg"
                    alt="visibility-off-disabled"
                    data-test="media_visibility_off_disabled"
                  />
                </span>
              </label>
              <div class="field-container">
                <div class="inp-div">
                  <input
                    placeholder="Create a password"
                    [type]="hidePassword ? 'password' : 'text'"
                    required
                    [(ngModel)]="changePasswordModel.newpassword"
                    #newpassword="ngModel"
                    (keyup)="checkPass($event, loginForm)"
                    (focus)="warning = true"
                    class="form-control"
                    id="inputPassword"
                    [disabled]="loginDissabled"
                    name="newpassword"
                    data-test="input_password"
                    [ngClass]="{
                      error:
                        loginForm.submitted && newpassword?.errors?.required,
                      inputError: passStrength === 'weak'
                    }"
                  />

                  <div class="weak-password" *ngIf="passStrength == 'weak'">
                    <span class="pass-strength-padding">{{
                      "message.weak-password" | translate
                    }}</span>
                  </div>
                  <div class="avg-password" *ngIf="passStrength == 'average'">
                    <span class="pass-strength-padding">
                      {{ "message.avg-password" | translate }}</span
                    >
                  </div>
                  <div class="strong-password" *ngIf="passStrength == 'strong'">
                    <span class="pass-strength-padding">
                      {{ "message.strong-password" | translate }}</span
                    >
                  </div>
                </div>
                <div
                  class="error-message"
                  *ngIf="loginForm.submitted && !loginForm.valid"
                >
                  <p
                    data-test="required_password"
                    *ngIf="newpassword?.errors?.required"
                  >
                    {{ "message.password-required" | translate }}
                  </p>
                </div>
                <p
                  class="warning-txt"
                  [ngClass]="{
                    warningTxtError: samePass
                  }"
                  *ngIf="samePass == true"
                >
                  {{ "message.same-password" | translate }}
                </p>
                <p
                  *ngIf="warning"
                  class="warning-txt"
                  [ngClass]="{
                    warningTxtError: passStrength === 'weak'
                  }"
                  data-test="required_password"
                >
                  {{ "message.password-message" | translate }}
                </p>
                <mat-password-strength
                  #passwordComponent1
                  (onStrengthChanged)="onStrength($event)"
                  [password]="newpassword.value"
                >
                </mat-password-strength>
              </div>
            </div>
            <div
              class="failed-message alert alert-warning"
              role="alert"
              *ngIf="errorMessage.length && !loginDissabled && loginForm.valid"
              data-test="text_error"
            >
              <img
                class="margin-icon-menu"
                src="../../../assets/images/failed.svg"
                alt="Failed"
              />
              {{ errorMessage }}
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="flex-end"
              class="
                btn-placements
                pad-btn
                main-top-margin
                top-margin
                left-margin
              "
            >
              <button
                data-test="new-template"
                class="btn secondary-btn"
                (click)="onCancel()"
                type="button"
                value="Cancel"
                mat-flat-button
              >
                {{ "action.cancel" | translate | uppercase }}
              </button>
              <button
                [disabled]="fetchingRes"
                [ngClass]="{ resetDisable: fetchingRes }"
                data-test="new-template"
                class="btn primary-btn"
                mat-flat-button
              >
                {{ "action.change" | translate | uppercase }}
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
