import { cloneDeep, isEmpty, isObject, toArray } from 'lodash';
import { ceData } from 'src/app/shared/constants/ce-data';
import { IDataPointDataset, IDataPointEventRules } from '../interface/mutliseries.inteface';
import { MutliSeriesChartModel } from '../model/mutliserieschart.model';

export function convertMultiSeriesModelToFormObject(
  data: MutliSeriesChartModel,
  datasets: IDataPointDataset[]
) {
  const foundItem = datasets.find((dataset) => data.primaryDataPoint === dataset.path);
  const formObject: MultiSeriesFormObject = {
    datapoints: data.dataPath.map((path, index) => {
      const matchedDataset = datasets.find((eachDataSet) => eachDataSet.path === path);
      return {
        datapoint: cloneDeep(matchedDataset),
        unitOfMeasure: data.units[index],
        color: data.colors[index],
      };
    }),
    autoScale: data.autoscale,
    gridLines: datasets.find((eachDataSet) => (eachDataSet.path = data.primaryDataPoint)).path,
    widgetTitle: data.title,
    timeFilter: sessionStorage.getItem('defaultTimeFilter'),
    gridLinesColors: creategridLinesColorsFromAnnotationData(
      foundItem?.eventRules,
      data.annotationData
    ),
  };
  return addMinMaxToFormObject(data.dualYAxis, formObject, data.min, data.max);
}

function creategridLinesColorsFromAnnotationData(
  eventRules: IDataPointEventRules | IDataPointEventRules[],
  annotationData: { y: number; color: string }[]
) {
  if (isEmpty(eventRules)) return [];
  const gridLineColor: { name: string; color: string; checked: boolean }[] = [];
  if (isObject(eventRules)) {
    eventRules = toArray(eventRules);
  }
  eventRules.forEach((eventRule) => {
    const foundAnnotation = annotationData.find(
      (annotation) => annotation.y === eventRule.alarm_setpoint
    );
    if (foundAnnotation) {
      gridLineColor.push({
        name: `${eventRule?.type} (${eventRule?.alarm_setpoint})`,
        color: foundAnnotation.color,
        checked: true,
      });
    } else {
      const key = Object.keys(ceData.gridLine).includes(eventRule.type) ? eventRule.type : 'other';
      const color = ceData.gridLine[key];
      gridLineColor.push({
        name: `${eventRule?.type} (${eventRule?.alarm_setpoint})`,
        color,
        checked: false,
      });
    }
  });

  return gridLineColor;
}

function addMinMaxToFormObject(
  isDualAxis: boolean,
  formObject: MultiSeriesFormObject,
  min: Array<number>,
  max: Array<number>
) {
  if (isDualAxis) {
    formObject.datapoints.forEach((datapoint, index) => {
      datapoint.min = min[index];
      datapoint.max = max[index];
    });
  } else if (!formObject.autoScale) {
    formObject.min = min[0];
    formObject.max = max[0];
  }
  return formObject;
}

interface MultiSeriesFormObject {
  datapoints: {
    datapoint: IDataPointDataset;
    unitOfMeasure: string;
    color: string;
    min?: number;
    max?: number;
  }[];
  autoScale: boolean;
  timeFilter: string;
  widgetTitle: string;
  gridLines: string;
  gridLinesColors: {
    name: string;
    color: string;
    checked: boolean;
  }[];
  min?: number;
  max?: number;
}
