<div class="statuswidget-container">
  <p class="message">
    {{ "message.device-status-widget" | translate }}
  </p>
  <div class="card-container">
    <mat-card class="colorcard">
      <app-device-status
        type="widget"
        [statusCardData]="statusCardData"
        [widget]="card"
        device="device-status"
      ></app-device-status>
    </mat-card>
  </div>
  <div class="button-container">
    <button
      class="btn secondary-btn"
      mat-flat-button
      data-test="action_cancel"
      (click)="cancel()"
      type="button"
    >
      {{ "action.cancel" | translate }}
    </button>
    <button
      *ngIf="type == 'edit'"
      class="btn secondary-btn"
      mat-flat-button
      data-test="action_delete"
      (click)="delete()"
      type="button"
    >
      {{ "action.delete" | translate }}
    </button>
    <button
      class="btn primary-btn"
      mat-flat-button
      data-test="action_save_widget"
      (click)="addWidget()"
    >
      {{ "action.save-widget" | translate }}
    </button>
  </div>
</div>
