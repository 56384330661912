<div
  class="login-container login"
  [ngClass]="{
    'mobile-device':
      deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape',
    'mobile-landscape': deviceType == 'MobileLandscape'
  }"
  *ngIf="resetPasswordConfirm == false"
>
  <mat-progress-bar
    class="progress-bar p-absolute-top"
    mode="indeterminate"
    *ngIf="
      loginDissabled &&
      (deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape')
    "
  ></mat-progress-bar>
  <div class="loginform-container" [ngClass]="{ formDisabled: loginDissabled }">
    <div>
      <mat-card class="login-card">
        <mat-progress-bar
          class="progress-bar p-absolute-top"
          mode="indeterminate"
          *ngIf="
            loginDissabled &&
            deviceType != 'MobilePortrait' &&
            deviceType != 'MobileLandscape'
          "
        ></mat-progress-bar>
        <div class="header-content">
          <!-- <div class="logo"><p>Logo</p></div> -->
          <div class="logo-img">
            <img
              src="../../assets/images/atoms-brands-grid-green-and-grey.svg"
            />
          </div>
          <div><p class="field-title">- &nbsp;FieldVEU</p></div>
        </div>
        <mat-card-content>
          <mat-card-title>{{ 'common.sign-in' | translate }}</mat-card-title>
          <form
            #loginForm="ngForm"
            class="text-center"
            (ngSubmit)="login(loginForm)"
          >
            <div class="row">
              <label
                for="inputEmail3"
                class="loginfield-label"
                data-test="text_email"
                >{{ 'common.email' | translate }}
                <span
                  data-test="text_tooltip"
                  [matTooltip]="'message.email-tooltip' | translate"
                  matTooltipClass="customtooltip"
                  matTooltipPosition="right"
                >
                  <img
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-help.svg"
                    alt="help"
                    data-test="media_help"
                  />
                </span>
              </label>
              <div class="field-container">
                <input
                  type="email"
                  required
                  placeholder="Enter your email address"
                  [(ngModel)]="loginModel.username"
                  #username="ngModel"
                  class="form-control"
                  name="username"
                  email
                  [disabled]="loginDissabled"
                  (keyup)="checkUser(username)"
                  (focusout)="showEmailError = true"
                  [ngClass]="{
                    error:
                      (!loginForm.valid &&
                        username?.errors?.email &&
                        showEmailError) ||
                      (username?.errors?.required && loginForm.submitted) ||
                      emailError
                  }"
                  data-test="input_email"
                />
                <div class="error-message" *ngIf="!loginForm.valid">
                  <p
                    data-test="invalid_email"
                    *ngIf="username?.errors?.email && showEmailError"
                  >
                    {{ 'message.valid-email' | translate }}
                  </p>
                  <p
                    data-test="required_email"
                    *ngIf="
                      (username?.errors?.required && loginForm.submitted) ||
                      emailError
                    "
                  >
                    {{ 'message.email-required' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <label
                for="inputPassword3"
                data-test="text_password"
                class="loginfield-label"
              >
                {{ 'common.password' | translate }}
                <span
                  data-test="text_show_hide"
                  (click)="
                    !loginDissabled ? (hidePassword = !hidePassword) : ''
                  "
                >
                  {{
                    hidePassword
                      ? ('common.show' | translate)
                      : ('common.hide' | translate)
                  }}
                  <img
                    *ngIf="hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility.svg"
                    alt="Visible"
                    data-test="media_visibility"
                  />
                  <img
                    *ngIf="hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-disabled.svg"
                    alt="visibility-disabled"
                    data-test="media_visibility_disabled"
                  />
                  <img
                    *ngIf="!hidePassword && !loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off.svg"
                    alt="visibility-off"
                    data-test="media-visibility_off"
                  />
                  <img
                    *ngIf="!hidePassword && loginDissabled"
                    class="margin-icon-menu"
                    src="../../../assets/images/ic-visibility-off-disabled.svg"
                    alt="visibility-off-disabled"
                    data-test="media_visibility_off_disabled"
                  />
                </span>
              </label>
              <div class="field-container">
                <input
                  placeholder="Enter your password"
                  [type]="hidePassword ? 'password' : 'text'"
                  required
                  [(ngModel)]="loginModel.password"
                  #password="ngModel"
                  class="form-control"
                  id="inputPassword"
                  [disabled]="loginDissabled"
                  name="password"
                  data-test="input_password"
                  [ngClass]="{
                    error: loginForm.submitted && password?.errors?.required
                  }"
                />
                <div
                  class="error-message"
                  *ngIf="loginForm.submitted && !loginForm.valid"
                >
                  <p
                    data-test="required_password"
                    *ngIf="password?.errors?.required"
                  >
                    {{ 'message.password-required' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="failed-message alert alert-warning"
              role="alert"
              *ngIf="errorMessage.length && !loginDissabled && loginForm.valid"
              data-test="text_error"
            >
              <img
                class="margin-icon-menu"
                src="../../../assets/images/failed.svg"
                alt="Failed"
              />
              {{ errorMessage }}
            </div>
            <div class="row margin-topbottom">
              <p data-test="text_forgot_password">
                <a (click)="forgotPassword(username)">{{
                  'common.forgot-password' | translate
                }}</a>
              </p>
            </div>

            <button
              class="btn btn-primary login-btn"
              id="signin-btn"
              mat-flat-button
              [disabled]="loginDissabled"
              data-test="action_signin"
            >
              {{ 'common.sign-in' | translate }}
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="image-container">
      <img
        src="../../../assets/images/atoms-brands-msa-field-server-green-and-black-on-white.svg"
        alt="brand img"
      />
    </div>
  </div>
</div>

<!--RESET PASSWORD UI CONFIRM-->
<div
  *ngIf="resetPasswordConfirm == true"
  class="login-container forgot-ht"
  [ngClass]="{
    'mobile-device':
      deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape',
    'mobile-landscape': deviceType == 'MobileLandscape'
  }"
>
  <div class="loginform-container">
    <div>
      <mat-card class="login-card">
        <div class="header-content">
          <!-- <div class="logo"><p>Logo</p></div> -->
          <div class="logo-img">
            <img
              src="../../assets/images/atoms-brands-grid-green-and-grey.svg"
            />
          </div>
          <div><p class="field-title">- &nbsp;FieldVEU</p></div>
        </div>
        <mat-card-content>
          <mat-card-title>{{
            'common.reset-password' | translate
          }}</mat-card-title>
          <div class="text-center">
            <div *ngIf="!passwordSent && !resetStatus" style="width: 300px">
              <p class="forgot-text">
                {{ 'message.forgotpassword-confirmmessage' | translate }}
              </p>
            </div>
            <div *ngIf="passwordSent" fxLayout="row" style="width: 300px">
              <span>
                <img src="../../assets/images/success.svg" />
              </span>
              <p class="forgot-text margin-lft">
                {{ 'message.forgotpassword-sent' | translate }}
              </p>
            </div>
            <div *ngIf="resetStatus" fxLayout="row" style="width: 300px">
              <span>
                <img src="../../assets/images/failed.svg" />
              </span>
              <p class="forgot-text margin-lft">
                {{ 'message.unknown' | translate }}
              </p>
            </div>
            <div [ngClass]="{ successmail: passwordSent }" *ngIf="!resetStatus">
              <p class="forgot-text-bold">
                {{ loginModel.username }}
              </p>
            </div>

            <div
              *ngIf="!resetStatus"
              fxLayout="row"
              fxLayoutAlign="flex-end"
              class="
                btn-placements
                pad-btn
                main-top-margin
                top-margin
                left-margin
              "
            >
              <button
                data-test="new-template"
                (click)="onCancel()"
                class="btn secondary-btn"
                mat-flat-button
                type="button"
              >
                {{ 'action.cancel' | translate | uppercase }}
              </button>
              <button
                *ngIf="!passwordSent"
                [disabled]="fetchRes"
                [ngClass]="{ resetDisable: fetchRes }"
                data-test="new-template"
                (click)="onSubmit()"
                class="btn primary-btn"
                mat-flat-button
              >
                {{ 'common.reset' | translate | uppercase }}
              </button>
            </div>
            <div class="reset-error-align" *ngIf="resetStatus">
              <button
                *ngIf="!passwordSent"
                type="button"
                [disabled]="fetchRes"
                data-test="new-template"
                (click)="onSubmit()"
                [ngClass]="{ loginDisable: fetchRes }"
                class="btn primary-btn resend-width"
                mat-flat-button
              >
                {{ 'action.resend-link' | translate | uppercase }}
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="image-container">
      <img
        src="../../../assets/images/atoms-brands-msa-field-server-green-and-black-on-white.svg"
        alt="brand img"
      />
    </div>
  </div>
</div>
<!--END RESET PASSWORD UI CONFIRM -->
