import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { TemplateService } from 'src/app/service/template/template.service';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { Observable } from 'rxjs/internal/Observable';
import { ceData } from 'src/app/shared/constants/ce-data';
import * as _ from 'lodash';

export class State {
  constructor(public name: string) {}
}
@Component({
  selector: 'app-addedit-numerical',
  templateUrl: './addedit-numerical.component.html',
  styleUrls: ['./addedit-numerical.component.scss'],
})
export class AddeditNumericalComponent implements OnInit {
  @Input() type;
  @Input() id;
  title = '';
  sub: any;

  filteredStates: Observable<any[]>;
  states: State[];
  editedData:
    | {
        currentPath: string;
        dataPath: string;
        height: number;
        historyPath: string;
        pointStatusPath: string;
        title: string;
        type: string;
        url: string;
        width: number;
        widgetID: string;
      }
    | {
        currentPath: string;
        dataPath: string;
        height: number;
        pointStatusPath: string;
        title: string;
        type: string;
        width: number;
        widgetID: string;
        historyPath?: undefined;
        url?: undefined;
      };
  registrationForm = this.fb.group({
    selectedValue: ['', [Validators.required]],
    stateCtrl: ['None'],
  });
  submitted: boolean;

  dataset: any;
  showTruncate = false;

  defaultValue: any = '143,575.0';
  defaultTime: any = '00:00:00';
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private ws: WidgetsService,
    private _templateService: TemplateService,
    public dialog: MatDialog
  ) {
    this.states = _.cloneDeep(ceData.unitOptions);
    this.states.push({ name: 'None' });
    this.filteredStates = this.registrationForm.get('stateCtrl').valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this.filterStates(state) : this.states.slice()))
    );

    this.dataset =
      this.ws.dataPoints === undefined
        ? JSON.parse(sessionStorage.getItem('dataPoints'))
        : this.ws.dataPoints;
  }

  ngOnInit(): void {
    // if (this.ws.dataPoints == undefined) {
    //   let dP = JSON.parse(sessionStorage.getItem("dataPoints"));
    //   this.dataset = dP;
    // } else {
    //   this.dataset = this.ws.dataPoints;
    // }

    if (this.type === 'edit') {
      const d = this._templateService.getEditData(this.id);
      if (!!d) {
        this.editedData = d[0];
        this.registrationForm.patchValue({
          selectedValue: this.editedData.dataPath,
          stateCtrl: (this.editedData as any).units,
        });
        this.title = this.editedData.dataPath;
      }
    }

    this.sub = this.registrationForm.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        this.title =
          value.selectedValue !== null || value.selectedValue !== '' ? value.selectedValue : '';
      });
  }

  comparedatapointObjects(object1: any, object2: any) {
    return object1 && object2 && object1.trim() === object2.trim();
  }
  filterStates(name: string) {
    return this.states.filter((state) => state.name.toLowerCase().includes(name.toLowerCase()));
  }
  onCancel() {
    this.registrationForm.reset();
    this.router.navigate(['/main/template/blanktemplate']);
  }
  onSubmit() {
    if (!this.registrationForm.valid) {
      this.submitted = true;
      return false;
    } else {
      const fm = this.registrationForm.value;
      const obj = this._templateService.tempData;

      if (this.type === 'add') {
        obj.push({
          widgetID: 'numerical-kpi' + Math.floor(Math.random() * 1000000),
          dataPath: fm.selectedValue,
          height: 1,
          title: this.title,
          type: 'numerical-kpi',
          width: 1,
          units: fm.stateCtrl.trim(),
        });

        this._templateService.tempData = obj;
      } else {
        const foundIndex = obj.findIndex((x) => x.widgetID === this.id);

        const newObj = {
          ...this.editedData,
          dataPath: fm.selectedValue,
          title: this.title,
          units: fm.stateCtrl.trim(),
        };

        obj[foundIndex] = newObj;

        this._templateService.tempData = obj;
        sessionStorage.setItem('tempData', JSON.stringify(obj));
      }

      this.router.navigate(['/main/template/blanktemplate']);
    }
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }
}
