import { Component, OnInit, Input } from '@angular/core';
import { userRoleConfig, deviceImageURL } from '../../constants/constants';
@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss'],
})
export class DeviceCardComponent implements OnInit {
  @Input() deviceData: any;
  @Input() renderedFrom: any;
  public userRole = localStorage.getItem('role');
  readonly iconUrl = deviceImageURL.iconUrl;
  readonly imageUrl = (window as any).smcUrl + 'fieldveu-images/';
  readonly dateFormat = 'MM/DD/YYYY HH:mm:ss';
  readonly defaultImage = '../../../../assets/images/ic-add-a-photo.svg';
  userRoleConfig = userRoleConfig;
  constructor() {}
  ngOnInit(): void {}
}
