import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { TemplateService } from 'src/app/service/template/template.service';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { waitFewMoments } from 'src/app/shared/class/util';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { TemplateDetails, ToasterData } from '../../../shared/constants/constants';
import { CommonService } from '../../../shared/services/common/common.service';
import { DashEventService } from '../../device-dashboard/services/dashboard-event/dash-event.service';
import { CreateTemplateModel } from '../create-template/create-template.model';
@Component({
  selector: 'app-addedit-templates',
  templateUrl: './addedit-templates.component.html',
  styleUrls: ['./addedit-templates.component.scss'],
})
export class AddeditTemplatesComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  @ViewChildren(CdkDropList) dropsQuery: QueryList<CdkDropList>;
  subcription: Subscription[] = [];
  unSavechanges = false;
  removedWidget = false;
  drops: CdkDropList[];
  cards: any;
  deviceType: any;
  templateDetails: TemplateDetails;
  templateStatus: boolean;
  editStatus: boolean;
  saveClicked = false;
  responseID: any;
  type: any;
  // type = sessionStorage.getItem("flowType");
  flowType: any;
  statusCardData = {
    status: 'Warnings',
    count: 3,
    time: '12:53:23',
    statusDetails: [
      { type: 'Pressure 1', status: 'Warning' },
      { type: 'Temperature In', status: 'Warning' },
      { type: 'Temperature Out', status: 'Warning' },
    ],
    img: 'Image',
  };
  readonly defaultImage = '../../../../assets/images/ic-add-a-photo.svg';
  handleChange = false;

  constructor(
    private _resolutionResolver: ResolutionResolverService,
    private activatedRoute: ActivatedRoute,
    private meshServiceProvidor: MeshServiceProvidor,
    private ws: WidgetsService,
    private router: Router,
    private _templateService: TemplateService,
    private toastr: ToastrService,
    private cs: CommonService,
    public dialog: MatDialog,
    private dashEventService: DashEventService
  ) {}
  entered($event: CdkDragEnter) {
    moveItemInArray(this.cards, $event.item.data, $event.container.data);
    sessionStorage.setItem('tempData', JSON.stringify(this.cards));
    this._templateService.tempData = this.cards;
  }
  entered2($event: CdkDragEnter) {
    moveItemInArray(this.cards, $event.item.data, $event.container.data);
    sessionStorage.setItem('tempData', JSON.stringify(this.cards));
  }

  ngOnInit(): void {
    this.getQueryParams();
    this._templateService.flow = 'template';
    this.type = this._templateService.flow;

    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
    this.subcription.push(sub0);

    if (!this.templateDetails.dashboardTemplateId) {
      const tData = JSON.parse(sessionStorage.getItem('tempData'));
      const cd = this._templateService.tempData;
      if (tData !== null) {
        if (tData.length > cd.length) {
          this.cards = tData;
          sessionStorage.setItem('tempData', JSON.stringify(this.cards));
          this._templateService.tempData = this.cards;
        } else {
          this.cards = cd;
          const index1 = this.cards.findIndex((item) => item.type === 'device-info');
          const index2 = tData.findIndex((item) => item.type === 'device-info');
          if (this.cards[index1] !== undefined && tData[index2] !== undefined) {
            if ('url' in tData[index2] && 'url' in this.cards[index1]) {
              if (this.cards[index1].url) {
                this.cards[index1].url = tData[index2].url;
              } else {
                this.cards[index1].url = '';
              }
            }
            if ('iconType' in tData[index2] && 'iconType' in this.cards[index1]) {
              if (this.cards[index1].iconType) {
                this.cards[index1].iconType = tData[index2].iconType;
              } else {
                this.cards[index1].iconType = '';
              }
            }
          }
          // this.cards.find((item) => item.type === "device-info").url =
          //   tData.find((item) => item.type === "device-info").url;
          // this.cards.find((item) => item.type === "device-info").iconType =
          //   tData.find((item) => item.type === "device-info").iconType;

          sessionStorage.setItem('tempData', JSON.stringify(this.cards));
          this._templateService.tempData = this.cards;
        }
      } else {
        const i = cd.findIndex((item) => item.type === 'device-info');
        if (cd[i] !== undefined && 'url' in cd[i]) {
          if (cd[i].url) cd[i].url = this.defaultImage;
          cd[i].iconType = undefined;
        }
        this.cards = cd;

        sessionStorage.setItem('tempData', JSON.stringify(this.cards));
        this._templateService.tempData = this.cards;
        this.checkUnsaveChanges();
      }
      if (
        sessionStorage.getItem('defaultTimeFilter') === undefined ||
        sessionStorage.getItem('defaultTimeFilter') === null
      ) {
        sessionStorage.setItem('defaultTimeFilter', 'Month');
      }
    }
    const sub1 = this.activatedRoute.queryParams.subscribe((data: CreateTemplateModel) => {
      const inittempName = sessionStorage.getItem('initTempName');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      inittempName == null
        ? sessionStorage.setItem('initTempName', this.templateDetails.templateName)
        : inittempName !== data.templateName
        ? (this.unSavechanges = true)
        : this.checkUnsaveChanges();
    });

    //check for removed widgets
    const removedwidgetData = sessionStorage.getItem('removedWidget');
    if (!!removedwidgetData && JSON.parse(removedwidgetData) === true) {
      this.removedWidget = true;
    } else {
      this.removedWidget = false;
    }

    // this.subcription.push(sub1);
  }
  ngAfterViewInit() {
    const sub = this.dropsQuery.changes.subscribe(async () => {
      await waitFewMoments;
      this.drops = this.dropsQuery.toArray();
    });
    this.subcription.push(sub);
    Promise.resolve().then(async () => {
      await waitFewMoments;
      this.drops = this.dropsQuery.toArray();
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.handleChange = true;
    }, 100);
  }

  getColor(e) {
    const stat: any = document.querySelectorAll('.colorcard');
    for (const item of stat) {
      switch (e) {
        case 'Alarm': {
          (item as any).style.backgroundColor = '#e42326';
          break;
        }

        case 'Trouble': {
          (item as any).style.backgroundColor = '#1961A6';
          break;
        }

        case 'Offline': {
          (item as any).style.backgroundColor = '#636363';
          break;
        }

        case 'Warning': {
          (item as any).style.backgroundColor = '#FFD500';
          break;
        }

        case 'Normal': {
          (item as any).style.backgroundColor = '#009534';
          break;
        }

        default:
          break;
      }
    }
  }

  async getQueryParams() {
    this.editStatus = false;
    // this.templateStatus = true;

    const tData = JSON.parse(sessionStorage.getItem('tempInfo'));
    if (tData == null) {
      const sub = this.activatedRoute.queryParams.subscribe((data: CreateTemplateModel) => {
        this.templateDetails = data;
        sessionStorage.setItem('tempInfo', JSON.stringify(this.templateDetails));
        this.getDataPoints(data.modelNumber, data.minProfileVersion);
      });
      this.subcription.push(sub);
    } else {
      this.templateDetails = JSON.parse(sessionStorage.getItem('tempInfo'));

      this.getDataPoints(this.templateDetails.modelNumber, this.templateDetails.minProfileVersion);
    }
    if (this.templateDetails.published === 'true') {
      this.editStatus = true;
      this.templateStatus = true;
    } else {
      this.editStatus = true;
      this.templateStatus = false;
    }

    const id = this.templateDetails.id || this.templateDetails.dashboardTemplateId;

    if (this.templateDetails.dashboardTemplateId) {
      const editDataSession = JSON.parse(sessionStorage.getItem('tempData'));
      const cdService = this._templateService.tempData;

      if (editDataSession == null) {
        try {
          const templateData: any = await this.meshServiceProvidor.getDashboardTemplateInstance(id);
          await waitFewMoments;
          this.cards = templateData.dashboardConfig.widgets;
          const defaultTimeFilter = templateData.dashboardConfig.defaultTimeWindow
            ? templateData.dashboardConfig.defaultTimeWindow
            : 'Month';
          sessionStorage.setItem('defaultTimeFilter', defaultTimeFilter);

          sessionStorage.setItem('tempData', JSON.stringify(this.cards));

          if (sessionStorage.getItem('initTempData') == null) {
            sessionStorage.setItem('initTempData', JSON.stringify(this.cards));
          }
          this._templateService.tempData = this.cards;
          this.checkUnsaveChanges();
        } catch (error) {
          const data: ToasterData = {
            type: 'error-custom',
            message: error.toString(),
          };

          this.cs.openToaster(data);
        }
      } else {
        if (editDataSession.length < cdService.length) {
          this.cards = cdService;

          sessionStorage.setItem('tempData', JSON.stringify(this.cards));
          this.checkUnsaveChanges();
        } else {
          this.cards = editDataSession;

          sessionStorage.setItem('tempData', JSON.stringify(this.cards));
          this.checkUnsaveChanges();
        }
      }
    } else {
      this.editStatus = false;
    }
  }
  getDataPoints(deviceName: string, version: string) {
    const sub = this.meshServiceProvidor

      .widgetsDataPoint(deviceName, version)
      .then((res: any) => {
        this.ws.dataPoints = res.dataPoints;
        sessionStorage.setItem('dataPoints', JSON.stringify(res.dataPoints));
      })
      .catch((error) => {
        const data: ToasterData = {
          type: 'error-custom',
          message: error.toString(),
        };
        this.cs.openToaster(data);
      });
  }

  edit(type, data) {
    this.router.navigate(['/', 'main', 'template', 'editwidget'], {
      queryParams: {
        type,
        id: data.widgetID,
      },
    });
  }

  createTemplate(e) {
    let urlData = '';
    let iconData = '';
    this.saveClicked = true;
    const device = this.cards.filter((dat) => {
      return dat.type === 'device-info';
    });

    if (device && device[0]) {
      urlData = device[0].url;
      iconData = device[0].iconType;
    }

    const filterType =
      sessionStorage.getItem('defaultTimeFilter') !== null
        ? sessionStorage.getItem('defaultTimeFilter')
        : 'Month';

    const options = {
      deviceProfileName: this.templateDetails.modelNumber,
      minimumVersion: this.templateDetails.minProfileVersion,
      name: this.templateDetails.templateName,

      dashboardConfig: {
        widgets: this.cards,
        deviceImageUrl: urlData,
        defaultTimeWindow: filterType,
        iconType: iconData,
      },
    };

    const sub = this.meshServiceProvidor
      .createTemplate(options)
      .then((res) => {
        if (res) {
          this.unSavechanges = false;
          const data: ToasterData = {
            type: 'success',
            message: 'save',
          };
          this.templateDetails = Object.assign({}, this.templateDetails);
          this.templateDetails.id = this.templateDetails.dashboardTemplateId = res;
          sessionStorage.setItem('initTempData', sessionStorage.getItem('tempData'));
          if (e === 'publish' || e === 'savepublish') {
            const option = { id: this.templateDetails.dashboardTemplateId };
            this.publishTemplate(option, e);
          } else {
            this.cs.openToaster(data);
          }

          sessionStorage.setItem('tempInfo', JSON.stringify(this.templateDetails));
          this.saveClicked = false;
        } else {
          const data: ToasterData = {
            type: 'error',
            message: 'save',
          };
          this.cs.openToaster(data);
        }
      })
      .catch((error) => {
        const data: ToasterData = {
          type: 'error',
          message: 'save',
        };
        this.cs.openToaster(data);
      });
  }
  updateTemplate(e) {
    let urlData = '';
    let iconData = '';
    this.saveClicked = true;
    const id = this.templateDetails.dashboardTemplateId;
    const device = this.cards.filter((dat) => {
      return dat.type === 'device-info';
    });
    if (device && device[0]) {
      urlData = device[0].url;
      iconData = device[0].iconType;
    }
    const filterType =
      sessionStorage.getItem('defaultTimeFilter') !== null
        ? sessionStorage.getItem('defaultTimeFilter')
        : 'Month';
    const options = {
      deviceProfileName: this.templateDetails.modelNumber,
      minimumVersion: this.templateDetails.minProfileVersion,
      name: this.templateDetails.templateName,

      dashboardConfig: {
        widgets: this.cards,
        deviceImageUrl: urlData,
        defaultTimeWindow: filterType,
        iconType: iconData,
      },
      id,
    };
    const sub = this.meshServiceProvidor
      .saveTemplate(options)
      .then((res) => {
        if (res) {
          this.unSavechanges = false;
          const data: ToasterData = {
            type: 'success',
            message: 'save',
          };
          sessionStorage.setItem('initTempData', sessionStorage.getItem('tempData'));
          if (e === 'publish' || e === 'savepublish') {
            const option = { id: this.templateDetails.dashboardTemplateId };
            this.publishTemplate(option, e);
          } else {
            this.cs.openToaster(data);
          }
          this.saveClicked = false;
          //CALL PUBLISH API
        } else {
          const data: ToasterData = {
            type: 'error',
            message: 'save',
          };
          this.cs.openToaster(data);
        }
      })
      .catch((error) => {
        const data: ToasterData = {
          type: 'error',
          message: 'save',
        };
        this.cs.openToaster(data);
      });
  }
  savePublishAllDetails(e) {
    if (e === 'save' || e === 'publish' || e === 'savepublish') {
      if ('dashboardTemplateId' in this.templateDetails) {
        this.updateTemplate(e);
      } else {
        this.createTemplate(e);
      }
    } else if (e === 'unpublish') {
      this.unPublishTemplate(this.templateDetails.dashboardTemplateId);
    }
  }
  deleteTemplate() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: 'auto',
      width: '500px',
      data: {
        templateId: this.templateDetails.dashboardTemplateId,
        modalType: 'delete-template',
      },
    });
  }
  publishTemplate(options, from) {
    const dp = this.ws?.dataPoints;
    const dataList = [];
    const dataPointWidget = [];
    const alarmData = [];
    dp?.forEach((e) => {
      if (e.eventRules) {
        alarmData.push(e);
      }
    });
    alarmData?.forEach((el) => {
      dataList.push(el.path);
    });
    this.cards?.forEach((el) => {
      dataPointWidget.push(el.dataPath);
    });

    const uniqueArr = [...new Set(dataPointWidget)];

    const finalModal = dataList.filter((r) => uniqueArr.indexOf(r) === -1);
    if (finalModal.length > 0) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        height: '400px',
        width: '500px',
        data: {
          data: finalModal,
          modalType: 'datapoint-missing',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.publishService(options, from);
        }
      });
    } else {
      this.publishService(options, from);
    }
  }
  publishService(options, from) {
    this.meshServiceProvidor
      .publishTemplate(options)
      .then((res) => {
        if (res) {
          const data: ToasterData = {
            type: 'success',
            message: from,
          };
          this.cs.openToaster(data);
          this.templateDetails = Object.assign({}, this.templateDetails);
          this.templateDetails.published = true;
          this.templateStatus = true;
          sessionStorage.setItem('tempInfo', JSON.stringify(this.templateDetails));
          this.router.navigate(['/main/template']);
        }
      })
      .catch((error) => {
        const data: ToasterData = {
          type: 'error',
          message: 'publish',
        };
        this.cs.openToaster(data);
      });
  }

  unPublishTemplate(id) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: 'auto',
      width: '500px',
      data: {
        templateId: id,
        modalType: 'unpublish-template',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'unpublish-success') {
        const data: ToasterData = {
          type: 'success',
          message: 'unpublish',
        };
        this.cs.openToaster(data);
        this.templateDetails = Object.assign({}, this.templateDetails);
        this.templateDetails.published = false;
        this.templateStatus = false;
        sessionStorage.setItem('tempInfo', JSON.stringify(this.templateDetails));
      } else if (result === 'unpublish-error') {
        const data: ToasterData = {
          type: 'error',
          message: 'unpublish',
        };
        this.cs.openToaster(data);
      }
    });
  }

  checkUnsaveChanges() {
    const initTempData = sessionStorage.getItem('initTempData');
    const tempInfo = JSON.parse(sessionStorage.getItem('tempInfo'));
    if (tempInfo.dashboardTemplateId === undefined) {
      this.unSavechanges = true;
    } else if (initTempData == null) {
      sessionStorage.setItem('initTempData', sessionStorage.getItem('tempData'));
    } else {
      this.unSavechanges = !_.isEqual(
        JSON.parse(initTempData),
        JSON.parse(sessionStorage.getItem('tempData'))
      );
    }
  }

  closebanner() {
    sessionStorage.removeItem('removedWidget');
    this.removedWidget = false;
  }
  ngOnDestroy(): void {
    this.subcription.forEach((sub) => sub.unsubscribe());
    this.dashEventService.clearSeries();
  }
}
