import { Component, EventEmitter, Input, Output } from '@angular/core';
import { deviceImageURL } from '../../constants/constants';
import { ITemplateListingSummary } from '../../interface/dashboard-template.interface';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
})
export class TemplateCardComponent {
  @Input() imagePath = '../../../../assets/images/ic-add-a-photo.svg';
  @Input() public templateInfo: ITemplateListingSummary;
  @Input() public buttonConfig: ITemplateBtnConfig[];
  @Input() public showBtnOnHover: boolean;
  @Output() private actionClickBtn = new EventEmitter<ITemplateCardEvent>();

  readonly iconUrl = deviceImageURL.iconUrl;
  readonly imageUrl = (window as any).smcUrl + 'fieldveu-images/';
  readonly fallbackImagePath = '../../../../assets/images/ic-add-a-photo.svg';

  userClickEventEmitter(eventName: string) {
    this.actionClickBtn.emit({
      eventName,
      eventData: this.templateInfo,
    });
  }
}

export interface ITemplateBtnConfig {
  name: string;
  icon: string;
  type: 'primary' | 'secondary';
}

export interface ITemplateCardEvent {
  eventName: string;
  eventData: ITemplateListingSummary;
}
