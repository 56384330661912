import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { waitFewMoments } from 'src/app/shared/class/util';
import { IDashboardListTemplate } from 'src/app/shared/interface/dashboard-template.interface';
import {
  ITemplateBtnConfig,
  ITemplateCardEvent,
} from 'src/app/shared/components/template-card/template-card.component';
import { LocalizationService } from 'src/app/shared/services/localization/localization.service';
import { CreateTemplateModel } from '../create-template/create-template.model';
import { fakeTempData } from 'src/app/shared/constants/ce-templateData';
import { TemplateService } from 'src/app/service/template/template.service';
import { Router } from '@angular/router';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('hasData') private readonly hasDataEl: TemplateRef<any>;
  @ViewChild('noData') private readonly noDataEl: TemplateRef<any>;
  @ViewChild('mobileAlert') private readonly mobileAlertEl: TemplateRef<any>;
  @ViewChild('loading') private readonly loadingEl: TemplateRef<any>;

  loadUI: TemplateRef<any>;
  listDashboardTemplate: IDashboardListTemplate;
  createTemplate = new CreateTemplateModel('', '', '');
  btnConfig: ITemplateBtnConfig[] = [
    {
      name: this.localizationService.translate('action.preview').toUpperCase(),
      icon: 'visibility',
      type: 'secondary',
    },
    {
      name: this.localizationService.translate('action.edit').toUpperCase(),
      icon: 'edit',
      type: 'secondary',
    },
  ];
  defaultQuerOption = {
    pageSize: 1000,
    publishDate: {
      sort: 'desc',
    },
  };
  deviceType: string;
  fxFlexWidth: string;
  cardFilterStatus = {
    publish: true,
    draft: true,
    get status() {
      if (this.publish === this.draft) {
      } else if (this.publish) {
        return true;
      } else if (this.draft) {
        return false;
      }
    },
  };
  private subscription: Subscription[] = [];
  constructor(
    private _resolutionResolver: ResolutionResolverService,
    private meshService: MeshServiceProvidor,
    private localizationService: LocalizationService,
    private router: Router,
    private _templateService: TemplateService,
    private cs: CommonService
  ) {
    sessionStorage.removeItem('initTempData');
    sessionStorage.removeItem('initTempName');
  }

  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    this.detectDevice();
  }

  detectDevice() {
    const sub0 = this._resolutionResolver._deviceType.subscribe(async (deviceType) => {
      if (deviceType && deviceType.toLowerCase().includes('mobile')) {
        this.deviceType = deviceType;
        this.loadUI = this.mobileAlertEl;
      } else {
        await waitFewMoments;
        this.loadUI = this.loadingEl;
        this.fetchDashboardTemplateList(this.defaultQuerOption);
      }
      this.fxFlexWidth = deviceType?.toLowerCase()?.includes('tablet') ? '45%' : '30%';
    });
    this.subscription.push(sub0);
  }
  createDashboard(endpath = 'blanktemplate') {
    const data = this.createTemplate;

    const excludeKeys = ['dashboardTemplateId', 'published', 'cloneData', 'previewDataSource'];

    if (
      Object.keys(data).every((k) => excludeKeys.includes(k) || (data[k] && data[k].length > 0))
    ) {
      sessionStorage.removeItem('tempData');
      sessionStorage.removeItem('tempInfo');
      sessionStorage.removeItem('dataPoints');
      sessionStorage.removeItem('defaultTimeFilter');
      sessionStorage.removeItem('removedWidget');

      this._templateService.tempData = fakeTempData;
      if (endpath === 'preview') {
        this._templateService.flow = 'preview';
      }
      this.router.navigate(['/', 'main', 'template', endpath], {
        queryParams: { ...data },
      });
    }
  }

  cardEventManager(event: ITemplateCardEvent) {
    this.createTemplate.modelNumber = event.eventData.deviceProfileName;
    this.createTemplate.templateName = event.eventData.name || 'UNKNOWN';
    this.createTemplate.minProfileVersion = event.eventData.minimumVersion;
    this.createTemplate.dashboardTemplateId = event.eventData.id;
    this.createTemplate.id = event.eventData.id;
    this.createTemplate.published = !!event.eventData.published;
    this.createTemplate.previewDataSource = event.eventData.previewDataSource;
    switch (event.eventName.toLowerCase()) {
      case 'edit':
        this.createDashboard();
        break;
      case 'preview':
        this.createDashboard('preview');
        break;
      default:
        void 0;
        break;
    }
  }

  async fetchDashboardTemplateList(options) {
    try {
      this.listDashboardTemplate = await this.meshService.listDashboardTemplate(options);
      if (this.listDashboardTemplate.templates.length > 0) {
        const templateClone = _.cloneDeep(this.listDashboardTemplate.templates);
        this.listDashboardTemplate.templates = [
          ..._.filter(templateClone, (value) => !!value.published === true),
          ..._.filter(templateClone, (value) => !!value.published === false),
        ];
        this.loadUI = this.hasDataEl;
      } else {
        this.loadUI = this.noDataEl;
      }
    } catch (error) {
      this.loadUI = this.noDataEl;
      const data: ToasterData = {
        type: 'error-custom',
        message: error?.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
