<div class="service-container">
  <div class="img-container">
    <img src="../../assets/images/service-unavailable.svg" />
  </div>
  <div class="msg-container">
    <p class="title">{{ "message.unavailableservice-title" | translate }}</p>
    <p class="msg">{{ "message.unavailableservice-message" | translate }}</p>
    <button
      data-test="cancel-binary"
      class="btn secondary-btn"
      (click)="reload()"
      mat-flat-button
      type="button"
    >
      {{ "action.reload" | translate | uppercase }}
    </button>
  </div>
</div>
