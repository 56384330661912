import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeshServiceProvidor } from '../service/mesh/mesh.service';
@Component({
  selector: 'app-unavailable-service',
  templateUrl: './unavailable-service.component.html',
  styleUrls: ['./unavailable-service.component.scss'],
})
export class UnavailableServiceComponent implements OnInit {
  constructor(public router: Router, public service: MeshServiceProvidor) {}

  ngOnInit(): void {}

  reload() {
    this.service.reloadApp();
  }
}
