export class CreateTemplateModel {
  constructor(
    public templateName: string,
    public modelNumber: string,
    public minProfileVersion: string,
    public dashboardTemplateId?: string,
    public id?: string,
    public published?: boolean,
    public previewDataSource?: string
  ) {}
}
