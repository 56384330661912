import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { TemplateService } from 'src/app/service/template/template.service';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { addClass, removeClass, waitFewMoments } from 'src/app/shared/class/util';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { ceData } from 'src/app/shared/constants/ce-data';
import { CombinationModel, GaugeModel } from './gauge.model';

@Component({
  selector: 'app-add-combination-chart',
  templateUrl: './add-combination-chart.component.html',
  styleUrls: ['./add-combination-chart.component.scss'],
})
export class AddCombinationChartComponent implements OnInit, AfterViewInit {
  @Input() id;
  @Input() type = 'add';
  @ViewChild('dataPointSelector') dataPointerSelector: MatSelect;
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  @ViewChild('gaugeContainer') gaugeContainer: ElementRef;
  @ViewChild('charttt', { static: true })
  dataset: any;
  currentActiveTab = 0;
  unitValue: string;
  tabConfig = {
    dataTab: {
      title: 'DATA',
      iconName: 'cancel',
      iconColor: '#e60000',
      isIconVisible: false,
    },
    designTab: {
      title: 'DESIGN',
      iconName: 'cancel',
      iconColor: '#e60000',
      isIconVisible: false,
    },
  };
  defaultDesign = {
    that: this,
    title: '',
    fieldOne: {
      name: 'Min',
      value: undefined,
      isDirty: false,
    },
    fieldTwo: {
      name: 'Max',
      value: undefined,
      isDirty: false,
    },
    fieldColor: {
      name: 'color',
      value: ceData.gridLine.default,
      isDirty: false,
    },
    errorMsg: undefined,
    get error() {
      return !this.fieldOne.value &&
        this.fieldOne.value !== 0 &&
        !this.fieldTwo.value &&
        this.fieldTwo.value !== 0
        ? 'message.valid-min-max'
        : !this.fieldOne.value && this.fieldOne.value !== 0
        ? 'message.valid-min'
        : !this.fieldTwo.value && this.fieldTwo.value !== 0
        ? 'message.valid-max'
        : this.errorMsg;
    },
  };
  ranges: any;
  designMutlipeValue: {
    title: string;
    fieldOne: {
      name: string;
      value: string;
      isOriginal: boolean;
      isDisabled: boolean;
      error?: string;
      errorMsg?: string;
      isDirty?: boolean;
    };
    fieldTwo: {
      name: string;
      value: string;
      isOriginal: boolean;
      isDisabled: boolean;
      error?: string;
      errorMsg?: string;
      isDirty?: boolean;
    };
    fieldColor: {
      name: string;
      value: string;
      error?: string;
      errorMsg?: string;
      isDirty?: boolean;
    };
  }[] = [];
  arcLabelError: boolean;
  arcLabelInput: string;
  openedType: string;
  charttt: ChartComponent = new ChartComponent();
  matRadioValue = 1;
  stroke: any;
  chart: any;
  chartContext: any;
  autoScale: boolean;
  annotations: {
    position: 'back';
  };
  dataObject = { ...ceData.linechartDefaultData };
  defaultData: any;
  dataTabError = false;
  selectedTab: any = 0;
  submitted = false;
  colorgridOptions = [];
  units: string;
  filteredUnits: any;
  sub: Subscription[] = [];
  titleUpdated = false;
  gridLines = false;
  editedData: any;
  defaultTimeWindow: string;
  alertHandler = {
    isFormSubmitted: false,
    isDataPointSelected: false,
    isChangeHappen: false,
    get dataPointError() {
      return this.isDataPointSelected
        ? undefined
        : this.isFormSubmitted
        ? 'message.valid-data-point'
        : undefined;
    },
  };
  readonly labelValidationRegex = /[0-9\,.-]/g;
  public defaultBottomLabel = '128';
  /* LINE  */

  public series: ApexAxisChartSeries;
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: any;
  public tooltip: ApexTooltip;
  public disabled = false;

  public unitOptions = ceData.unitOptions.slice();

  component: {
    title: string;
    fieldOne: { name: string; isDirty: boolean };
    fieldTwo: { name: string; isDirty: boolean };
    fieldColor: { name: string; value: string; isDirty: boolean };
    error: string;
  };
  /* ##### */

  public readonly canvasWidth = 280;
  public readonly needleValue = 50;
  public readonly centralLabel = '';
  public readonly name = 'Gauge chart';
  public readonly bottomLabelFont = 30;
  public readonly options = {
    hasNeedle: true,
    needleColor: ceData.gridLine.needleColor,
    needleUpdateSpeed: 1000,
    arcColors: [ceData.gridLine.default],
    arcDelimiters: [],
    arcLabels: [''],
    rangeLabel: [],
    arcOverEffect: false,
    rangeLabelFontSize: 12,
    arcPadding: 100,
    needleStartValue: 50,
  };
  public gaugeData = new GaugeModel(
    this.canvasWidth,
    this.needleValue,
    this.centralLabel,
    this.name,
    '',
    this.bottomLabelFont,
    this.options
  );
  constructor(
    public ws: WidgetsService,
    private _templateService: TemplateService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.ws.dataPoints === undefined) {
      const dP = JSON.parse(sessionStorage.getItem('dataPoints'));
      this.dataset = dP;
    } else {
      this.dataset = _.cloneDeep(this.ws.dataPoints);
    }

    this.defaultData = { ...ceData.linechartDefaultData };

    this.dataObject.defaultTimeWindow = sessionStorage.getItem('defaultTimeFilter')
      ? sessionStorage.getItem('defaultTimeFilter')
      : 'Month';
    this.defaultData.defaultTimeWindow = this.dataObject.defaultTimeWindow;
    this.defaultTimeWindow = this.dataObject.defaultTimeWindow;

    if (this.type === 'add') {
      this.ws.selectWidgetChange.subscribe(async (p) => {
        if (p === 'combination_chart') {
          this.openedType = 'combination_chart';
          await waitFewMoments;
          await waitFewMoments;
          await waitFewMoments;
          this.currentActiveTab = 0;
          this.setApexchart();
        }
      });
    } else if (this.type === 'edit') {
      this.setApexchart();
    }
  }

  async ngAfterViewInit() {
    this.currentActiveTab = 0;
    if (this.type === 'edit') {
      const d = this._templateService.getEditData(this.id);
      if (!!d) {
        await waitFewMoments;
        this.editedData = d[0];
        this.assignValues();
      }
    }
  }

  onSelectedTabChange(e) {
    this.currentActiveTab = e.index;
    this.matTabGroup.selectedIndex = e.index;
    this.manageTabInkbarClass();
  }

  keyUpUnitOptions(event) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return;
    }
    const searchTerm = (event.target as HTMLInputElement).value.toLowerCase();
    this.unitOptions = ceData.unitOptions
      .slice()
      .filter((unit) => unit.name.toLowerCase().includes(searchTerm));
  }

  handleValue(event) {
    const inputValue = event.value || (event.target as HTMLInputElement).value;
    const isValidValue = ceData.unitOptions
      .slice()
      .find((x) => x.name.toLowerCase() === inputValue.toLowerCase());
    if (isValidValue) {
      this.setUnitOptions();
    } else {
      this.unitValue = '';
      this.gaugeData.bottomLabel = this.defaultBottomLabel;
    }
  }

  setUnitOptions() {
    this.unitOptions = ceData.unitOptions.slice();
  }

  unitChange(event, tp) {
    let vale;
    if (tp === 'sel') {
      vale = event.option.value;
    } else {
      vale = event.target.value;
    }
    const unit = vale.split('-')[0];

    this.gaugeData.bottomLabel = `${this.defaultBottomLabel} ${unit}`;
    this.units = unit;
    this.dataObject.units = this.units;
    this.setUnits();
  }

  datapointChange(event: MatSelectChange) {
    this.alertHandler.isDataPointSelected = true;
    this.ranges = event.value.ranges;
    this.designMutlipeValue = [];
    this.gaugeData.options.arcDelimiters = [];
    this.gaugeData.options.arcColors = [ceData.gridLine.default];
    this.gaugeData.options.arcLabels = [];
    this.gaugeData.options.rangeLabel = [];
    this.defaultBottomLabel = '128';
    this.gaugeData.bottomLabel = '128';
    if (this.ranges) {
      const values = [];
      this.ranges.forEach((range, index) => {
        const from = range.from;
        const to = range.to;
        if (from > -1) {
          values.push(from);
        }
        if (to > -1) {
          values.push(to);
        }
        this.designMutlipeValue.push({
          title: range.name,
          fieldOne: {
            name: index === 0 ? 'Min' : 'From',
            value: range.from,
            isOriginal: range.from === 0 || !!range.from,
            isDisabled:
              index === 0
                ? range.from != null && this.ranges[this.ranges.length - 1].to != null
                : true,
            get error() {
              return this.value || this.value === 0 ? this.errorMsg : 'message.valid-min';
            },
          },
          fieldTwo: {
            name: index === this.ranges.length - 1 ? 'Max' : 'To',
            value: range.to,
            isOriginal: !!range.to,
            isDisabled:
              index === this.ranges.length - 1
                ? range.to != null && this.ranges[0].to != null
                : true,
            get error() {
              return this.value || this.value === 0 ? this.errorMsg : 'message.valid-max';
            },
          },
          fieldColor: {
            name: 'color',
            value: ceData.gridLine[range.name.toLowerCase()],
          },
        });
      });
      const [minValue, maxValue] = this.getMinMaxValue(values);
      const adjustedMaxValue = (maxValue - minValue).toString();
      let isValueMissing = false;
      const temp = this.ranges.map((value) => {
        const adjustedMinValue = (value.to - minValue).toString();
        if (value.to) {
          const finalValue = (parseFloat(adjustedMinValue) / parseFloat(adjustedMaxValue)) * 100;
          if (finalValue > 0 && finalValue < 100) {
            this.gaugeData.options.arcLabels.push(value.to);
            return finalValue;
          }
        } else {
          isValueMissing = true;
        }
      });
      this.needleUpdate(minValue, maxValue);
      if (!isValueMissing) {
        this.gaugeData.options.arcDelimiters = temp.filter((v) => v !== undefined);
        this.gaugeData.options.arcColors = this.ranges.map(
          (value) => ceData.gridLine[value.name.toLowerCase()]
        );
        this.gaugeData.options.rangeLabel = [
          minValue.toFixed(2).toString(),
          maxValue.toFixed(2).toString(),
        ];
      } else {
        this.gaugeData.options.arcDelimiters = [];
        this.gaugeData.options.arcColors = [ceData.gridLine.default];
        this.gaugeData.options.rangeLabel = [];
      }
    } else {
      if (!this.defaultDesign.error) {
        this.defaultDesign.fieldOne.value = undefined;
        this.defaultDesign.fieldTwo.value = undefined;
        this.defaultDesign.fieldOne.isDirty = false;
        this.defaultDesign.fieldTwo.isDirty = false;
        this.defaultDesign.fieldColor.value = ceData.gridLine.default;
        this.arcLabelInput = undefined;
        this.dataObject.color = ceData.gridLine.default;
        this.setStroke();
      }
    }
    this.dataObject.dataPath = event.value.path;
    this.titleUpdated = true;
    this.dataObject.title = this.dataObject.dataPath;
    this.checkforAnno(this.dataObject.dataPath);
  }

  arcLabelTyped(event) {
    if (!this.defaultDesign.fieldOne.value && !this.defaultDesign.fieldTwo.value) {
      if (!this.defaultDesign.fieldOne.value) {
        this.defaultDesign.fieldOne.isDirty = true;
      }
      if (!this.defaultDesign.fieldTwo.value) {
        this.defaultDesign.fieldTwo.isDirty = true;
      }
      return;
    }
    if (event.target.value === '') {
      this.gaugeData.options.arcColors = [this.defaultDesign.fieldColor.value];
      this.gaugeData.options.arcDelimiters = [];
      this.gaugeData.options.arcLabels = [];
      this.arcLabelError = false;
      return;
    }
    if (
      event.target.value &&
      event.target.value.match(this.labelValidationRegex).join('') === event.target.value
    ) {
      let temp = event.target.value.split(',');
      if (
        !temp.every(
          (value1) =>
            value1 > this.defaultDesign.fieldOne.value && value1 < this.defaultDesign.fieldTwo.value
        )
      ) {
        this.arcLabelError = true;
        return;
      }
      temp = temp.filter((v) => v !== '');
      const inNumber = [...new Set<number>(temp.map((v) => +v))];
      if (!this.customValidate(inNumber)) {
        this.arcLabelError = true;
        return;
      }
      const tempArr = [];
      inNumber.forEach((e) => {
        if (e.toString().includes('.')) {
          tempArr.push(parseFloat(e.toFixed(2)));
        } else {
          tempArr.push(e);
        }
      });
      this.arcLabelInput = tempArr.join(',');
      this.gaugeData.options.arcLabels = tempArr as unknown as Array<string>;
      const value = inNumber.map((v) => {
        const minVal = v - parseFloat(this.defaultDesign.fieldOne.value);
        const maxVal =
          parseFloat(this.defaultDesign.fieldTwo.value) -
          parseFloat(this.defaultDesign.fieldOne.value);
        const finalvalue = (minVal / maxVal) * 100;
        if (finalvalue >= 0 && finalvalue < 100) {
          return finalvalue;
        }
        this.needleUpdate(minVal, maxVal);
      });

      this.gaugeData.needleValue =
        (parseFloat(this.defaultBottomLabel) / this.defaultDesign.fieldTwo.value) * 100;

      this.gaugeData.options.arcDelimiters = value;
      this.gaugeData.options.arcColors = [];
      value.forEach((element) => {
        this.gaugeData.options.arcColors.push(this.defaultDesign.fieldColor.value);
      });
      this.gaugeData.options.arcColors.push(this.defaultDesign.fieldColor.value);
      this.arcLabelError = false;
    } else {
      this.arcLabelError = true;
    }
  }

  valueChanged(event, name?: string) {
    this.alertHandler.isFormSubmitted = false;
    if (this.designMutlipeValue.length) {
      const length = this.designMutlipeValue.length;
      const firstValue = this.designMutlipeValue[0].fieldOne.value;
      const lastValue = this.designMutlipeValue[length - 1].fieldTwo.value;
      if (!firstValue && +firstValue !== 0 && !lastValue) {
        return;
      }
      if ((firstValue || +firstValue === 0) && !lastValue) {
        this.designMutlipeValue[length - 1].fieldTwo.isDirty = true;
        return;
      }
      if (!firstValue && +firstValue !== 0 && lastValue) {
        this.designMutlipeValue[0].fieldOne.isDirty = true;
        return;
      }
      this.gaugeData.options.arcColors = [];
      this.gaugeData.options.arcLabels = [];
      const values = [];
      for (const designMutlipeValue of this.designMutlipeValue) {
        if (designMutlipeValue) {
          const currentValue = designMutlipeValue;
          this.gaugeData.options.arcColors.push(currentValue.fieldColor.value);
          this.gaugeData.options.arcLabels.push(currentValue.fieldTwo.value);
          values.push(currentValue.fieldOne.value);
          values.push(currentValue.fieldTwo.value);
        }
      }
      let hasError = false;
      this.designMutlipeValue.forEach((design) => {
        if (!design.fieldOne.isOriginal && design.fieldOne.value >= design.fieldTwo.value) {
          design.fieldOne.errorMsg = 'message.minmaxerror-gauge';
          hasError = true;
        } else {
          design.fieldOne.errorMsg = undefined;
        }
        if (!design.fieldTwo.isOriginal && design.fieldTwo.value <= design.fieldOne.value) {
          design.fieldTwo.errorMsg = 'message.maxminerror-gauge';
          hasError = true;
        } else {
          design.fieldTwo.errorMsg = undefined;
        }
      });
      if (hasError) {
        return;
      }
      const [minValue, maxValue] = this.getMinMaxValue(values);
      const adjustedMaxValue = (maxValue - minValue).toString();
      const temp = this.ranges.map((value) => {
        const adjustedMinValue = (value.to - minValue).toString();
        if (value.to) {
          const finalValue = (parseFloat(adjustedMinValue) / parseFloat(adjustedMaxValue)) * 100;
          if (finalValue >= 0 && finalValue < 100) {
            return finalValue;
          }
        }
      });
      this.needleUpdate(minValue, maxValue);
      this.gaugeData.options.arcDelimiters = temp.filter((v) => v !== undefined);
      this.gaugeData.options.needleStartValue = 50;
      this.gaugeData.needleValue = (parseFloat(this.defaultBottomLabel) / maxValue) * 100;
      this.gaugeData.options.arcDelimiters = temp.filter((v) => v !== undefined);

      this.gaugeData.options.needleStartValue = 50;
      this.gaugeData.options.rangeLabel = [
        minValue.toFixed(2).toString(),
        maxValue.toFixed(2).toString(),
      ];
      if (!this.dataObject.autoscale) {
        this.dataObject.min = minValue.toString();
        this.dataObject.max = maxValue.toString();
        this.setMinandMax(this.dataObject.min, this.dataObject.max);
      }
    } else {
      const minValue = this.defaultDesign.fieldOne.value;
      const maxValue = this.defaultDesign.fieldTwo.value;
      if (name?.toLowerCase() === 'min') {
        if (minValue >= maxValue) {
          this.defaultDesign.errorMsg = 'message.minmaxerror-gauge';
        } else {
          this.defaultDesign.errorMsg = undefined;
        }
      } else if (name?.toLowerCase() === 'max') {
        if (maxValue <= minValue) {
          this.defaultDesign.errorMsg = 'message.maxminerror-gauge';
        } else {
          this.defaultDesign.errorMsg = undefined;
        }
      }
      this.gaugeData.options.arcDelimiters = [];
      this.gaugeData.options.arcLabels = [];
      this.gaugeData.options.arcColors = [this.defaultDesign.fieldColor.value];
      if (this.arcLabelInput && this.arcLabelInput.length > 0) {
        this.arcLabelTyped({
          target: {
            value: this.arcLabelInput,
          },
        });
      }

      if (minValue && maxValue) {
        if (
          this.defaultDesign.fieldOne.value?.toString().includes('.') &&
          this.defaultDesign.fieldTwo.value?.toString().includes('.')
        ) {
          this.gaugeData.options.rangeLabel = [
            this.defaultDesign.fieldOne.value?.toFixed(2).toString(),
            this.defaultDesign.fieldTwo.value?.toFixed(2).toString(),
          ];
        } else if (this.defaultDesign.fieldOne.value?.toString().includes('.')) {
          this.gaugeData.options.rangeLabel = [
            this.defaultDesign.fieldOne.value?.toFixed(2).toString(),
            this.defaultDesign.fieldTwo.value?.toString(),
          ];
        } else if (this.defaultDesign.fieldTwo.value?.toString().includes('.')) {
          this.gaugeData.options.rangeLabel = [
            this.defaultDesign.fieldOne.value?.toString(),
            this.defaultDesign.fieldTwo.value?.toFixed(2).toString(),
          ];
        } else {
          this.gaugeData.options.rangeLabel = [
            this.defaultDesign.fieldOne.value?.toString(),
            this.defaultDesign.fieldTwo.value?.toString(),
          ];
        }
      }
      if (!this.dataObject.autoscale) {
        this.dataObject.min = this.defaultDesign.fieldOne.value?.toString();
        this.dataObject.max = this.defaultDesign.fieldTwo.value?.toString();
        this.setMinandMax(this.dataObject.min, this.dataObject.max);
      }
      this.needleUpdate(minValue, maxValue);
    }
  }

  toggleScale(event: MatRadioChange) {
    if (event.value === 1) {
      this.dataObject.autoscale = true;
      this.dataObject.min = null;
      this.dataObject.max = null;
      this.yaxis = {};
    } else {
      this.dataObject.autoscale = false;
      this.valueChanged(undefined);
    }
  }

  setApexchart() {
    const { minDate, maxDate } = this.ws.getMinMaxDate('Month');

    const chartData = this.ws.getDataforChart(minDate, maxDate);
    this.initChartData(chartData);
    this.setStroke();
    this.setUnits();
  }

  setStroke() {
    this.stroke = {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: this.dataObject.color,
      width: 2,
      dashArray: 0,
    };
  }

  setUnits() {
    const units = this.dataObject.units;
    this.tooltip = {
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const displayDate =
          '<div class="arrow_box">' +
          '<span>' +
          +series[seriesIndex][dataPointIndex] +
          ' ' +
          units +
          '</span>' +
          '</div>';
        return displayDate;
      },
    };
  }

  public initChartData(chartData): void {
    const series = [
      {
        name: 'Historical Chart',
        data: chartData,
      },
    ];
    this.series = [...series];
    const that = this;
    this.chart = {
      type: 'line',
      height: 350,
      stacked: false,
      width: '97%',
      animations: {
        enabled: false,
      },
      redrawOnParentResize: true,

      toolbar: {
        show: false,
      },
      zoom: {
        type: 'x',
        enabled: false,
      },
      events: {
        mounted(chartContext, config) {
          that.chartContext = chartContext;
          if (
            !!chartContext &&
            chartContext.annotations !== undefined &&
            chartContext.annotations != null
          ) {
            that.addAnnotation();
          }
          if (this.type === 'edit') {
            this.checkfordataPoint(that.dataObject.dataPath);
          }
        },
      },
    };

    this.dataLabels = {
      enabled: false,
    };
    this.markers = {
      size: 0,
    };

    this.yaxis = {};

    this.tooltip = {
      shared: false,
      y: {
        formatter(val) {
          return (val / 1000000).toFixed(0);
        },
      },
    };

    this.xaxis = {
      type: 'datetime',
      offsetX: 30,
      tickAmount: 5,
      labels: {
        show: true,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
        maxHeight: 120,
        datetimeUTC: true,

        formatter(value) {
          return moment(value).format('MMM D');
        },
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
        formatter(value) {
          return moment(value).format('MMM D,HH:mm:ss');
        },
      },
    };
  }

  addAnnotation() {
    this.colorgridOptions.map((p) => {
      if (!!this.chartContext && p.checked === true) {
        const idname = p.id.toString();

        this.chartContext.addYaxisAnnotation(
          {
            id: 'my-annotation' + p.id,
            y: parseInt(p.val, 10),
            borderColor: p.color,
            strokeDashArray: 0,
          },
          true,
          this.chartContext
        );
      }
    });
  }

  lineColorChange(event) {
    this.dataObject.color = event.target.value;
    this.setStroke();
  }

  checkboxStatusChange(event: MatCheckboxChange, name) {
    const temp = this.colorgridOptions.find((v) => name.includes(v.message));
    temp.checked = event.checked;
    if (temp.checked) {
      this.addOneAnno(temp.id);
    } else {
      this.removeAnno(temp.id);
    }
  }

  gridlineColorChange(event, name) {
    const temp = this.colorgridOptions.find((v) => name.includes(v.message));
    temp.color = event.target.value;
    this.removeAnno(temp.id);
    this.addOneAnno(temp.id);
  }

  saveWidget() {
    let isErrorDatatab = false;
    this.alertHandler.isFormSubmitted = true;
    if (this.alertHandler.dataPointError) {
      this.tabConfig.dataTab.isIconVisible = true;
      this.manageTabInkbarClass();
      isErrorDatatab = true;
    }

    if (this.designMutlipeValue.length) {
      const temp = this.designMutlipeValue.some((v) => !!v.fieldOne.error || !!v.fieldTwo.error);
      if (temp) {
        this.tabConfig.designTab.isIconVisible = true;
        this.manageTabInkbarClass();
        this.currentActiveTab = isErrorDatatab ? 0 : 1;
        return;
      }
    } else if (this.defaultDesign.error) {
      this.tabConfig.designTab.isIconVisible = true;
      this.manageTabInkbarClass();
      this.currentActiveTab = isErrorDatatab ? 0 : 1;
      return;
    }
    if (isErrorDatatab) {
      this.currentActiveTab = 0;
      return;
    }
    if (this.arcLabelError) {
      this.currentActiveTab = isErrorDatatab ? 0 : 1;
      return;
    }

    const colors = [];

    this.colorgridOptions.map((p) => {
      if (p.checked === true) {
        const colorobj = {
          y: p.val,
          color: p.color,
        };
        colors.push(colorobj);
      }
    });
    const data = new CombinationModel(
      this.dataObject.dataPath,
      this.dataObject.title,
      this.dataObject.autoscale,
      this.dataObject.color,
      colors,
      this.extractMinAndMaxValues()[0],
      this.extractMinAndMaxValues()[1],
      this.gaugeData.options,
      this.gaugeData.options.arcColors,
      this.gaugeData.options.arcDelimiters,
      this.gaugeData.options.arcLabels,
      this.gaugeData.options.rangeLabel,
      this.units || '',
      {
        bottomLabel: this.gaugeData.bottomLabel,
        config: this.getGaugeConfig(),
        needleValue: this.gaugeData.needleValue,
        units: this.units || '',
      }
    );
    const templateData = this._templateService.tempData;

    if (this.type === 'edit') {
      data.widgetID = this.editedData.widgetID;
      const index = templateData.findIndex(
        (template) => template.widgetID === this.editedData.widgetID
      );
      templateData[index] = data;
    } else {
      templateData.push(data);
    }
    sessionStorage.setItem('defaultTimeFilter', this.dataObject.defaultTimeWindow);
    this._templateService.tempData = templateData;
    sessionStorage.setItem('tempData', JSON.stringify(templateData));
    this.router.navigate(['/main/template/blanktemplate']);
  }

  extractMinAndMaxValues() {
    if (this.designMutlipeValue.length) {
      const values = [];
      this.designMutlipeValue.forEach((value) => {
        values.push(value.fieldOne.value);
        values.push(value.fieldTwo.value);
      });
      return this.getMinMaxValue(values);
    } else {
      return [this.defaultDesign.fieldOne.value, this.defaultDesign.fieldTwo.value];
    }
  }

  checkforAnno(datapoint) {
    this.tabConfig.dataTab.isIconVisible = false;
    this.tabConfig.designTab.isIconVisible = false;
    if (
      this.type === 'add' ||
      (this.type === 'edit' && this.dataObject.dataPath !== this.editedData.dataPath)
    ) {
      const colorgridarr = this.dataset.filter(function (o) {
        return o.path.trim() === datapoint.trim();
      });
      if (!!colorgridarr) {
        const obj = colorgridarr[0];
        if (!!obj && 'eventRules' in obj) {
          this.create_colorgridOptions(obj.eventRules);
          this.gridLines = true;
        } else {
          this.colorgridOptions = [];
          this.gridLines = false;
          if (!!this.chartContext) {
            this.chartContext.clearAnnotations();
          }
        }
      }
    } else if (this.type === 'edit' && this.dataObject.dataPath === this.editedData.dataPath) {
      if (!!this.dataObject.colors && this.dataObject.colors.length > 0) {
        this.gridLines = true;
        this.addAnnotation();
        this.colorgridOptions = [...this.dataObject.colorgridOptions];
      } else if (
        this.dataObject.colors.length === 0 &&
        this.dataObject.colorgridOptions.length > 0
      ) {
        this.gridLines = true;
        this.dataObject.colorgridOptions.map((p) => {
          if (p.type.includes('warning')) {
            p.color = ceData.gridLine.warning;
          } else if (p.type.includes('alarm')) {
            p.color = ceData.gridLine.alarm;
          } else {
            p.color = ceData.gridLine.other;
          }
        });

        this.colorgridOptions = [...this.dataObject.colorgridOptions];
      } else {
        this.gridLines = false;
        this.colorgridOptions = [];
        if (!!this.chartContext) {
          this.chartContext.clearAnnotations();
        }
      }
    }
  }

  getGaugeConfig() {
    const config = [];
    if (this.designMutlipeValue.length) {
      this.designMutlipeValue.forEach((value) => {
        config.push({
          color: value.fieldColor.value,
          from: value.fieldOne.value,
          name: value.title,
          range: true,
          to: value.fieldTwo.value,
        });
      });
    } else {
      config.push({
        arcRanges: this.gaugeData.options.arcLabels.join(','),
        color: this.defaultDesign.fieldColor.value,
        from: this.defaultDesign.fieldOne.value,
        range: false,
        to: this.defaultDesign.fieldTwo.value,
      });
    }
    return config;
  }

  create_colorgridOptions(eventRules) {
    let data = [];
    if (!Array.isArray(eventRules)) {
      data.push(eventRules);
    } else {
      data = eventRules;
    }

    this.colorgridOptions = [];
    data.map((p, i) => {
      const obj = {
        checked: true,
        val: p.alarm_setpoint,
        type: p.type,
        id: i,
        color: '',
        message: `${p.type} ( ${p.alarm_setpoint} )`,
      };
      if (p.type.includes('warning')) {
        obj.color = ceData.gridLine.warning;
      } else if (p.type.includes('alarm')) {
        obj.color = ceData.gridLine.alarm;
      } else {
        obj.color = ceData.gridLine.other;
      }
      this.colorgridOptions.push(obj);
    });
    this.addAnnotation();
  }

  getMinMaxValue(values: Array<number>) {
    const newValue = values.filter((v) => v !== undefined).sort((a, b) => a - b);
    return [newValue[0], newValue[newValue.length - 1]];
  }

  setMinandMax(min, max) {
    if (min && max && !this.dataObject.autoscale) {
      this.yaxis = {
        min: +min,
        max: +max,
      };
      this.setStroke();
    }
    if (this.dataObject.autoscale) {
      this.yaxis = {};
      this.dataObject.min = null;
      this.dataObject.max = null;
      this.setStroke();
    }
  }

  addOneAnno(id) {
    if (!!this.chartContext) {
      this.chartContext.addYaxisAnnotation(
        {
          id: 'my-annotation' + id,
          y: parseInt(this.colorgridOptions[id].val, 10),
          borderColor: this.colorgridOptions[id].color,
          strokeDashArray: 0,
        },
        true,
        this.chartContext
      );
    }
  }

  setcolorgridOptions(colors, datapath) {
    const colorgridarr = this.dataset.filter(function (o) {
      return o.path.trim() === datapath.trim();
    });
    if (!!colorgridarr) {
      const obj = colorgridarr[0];
      if (!!obj && 'eventRules' in obj) {
        this.create_colorgridOptions(obj.eventRules);
        if (colors.length === this.colorgridOptions.length) {
          this.colorgridOptions.map((p) => {
            const color = colors.filter((x) => x.y === p.val);
            p.color = color[0].color;
          });
        } else {
          this.colorgridOptions.map((p) => {
            const color = colors.filter((x) => x.y === p.val);
            if (color.length > 0) {
              p.color = color[0].color;
            } else {
              p.checked = false;
            }
          });
        }
      } else {
        this.gridLines = false;
      }
    }
  }

  removeAnno(id) {
    this.chartContext.removeAnnotation('my-annotation' + id);
  }

  cancel() {
    this.router.navigate(['/main/template/blanktemplate']);
  }

  async assignValues() {
    await waitFewMoments;
    await waitFewMoments;
    this.dataObject.title = this.editedData.dataPath;
    this.dataObject.autoscale = this.editedData.autoscale;
    this.dataObject.color = this.editedData.color;
    this.setStroke();
    this.dataObject.colors = this.editedData.colors;
    this.dataObject.defaultTimeWindow = sessionStorage.getItem('defaultTimeFilter');
    if (this.editedData.autoscale) {
      this.dataObject.min = null;
      this.dataObject.max = null;
      this.matRadioValue = 1;
    } else {
      this.dataObject.min = this.editedData.min;
      this.dataObject.max = this.editedData.max;
      this.matRadioValue = 2;
      this.yaxis = {
        min: +this.editedData.min,
        max: +this.editedData.max,
      };
    }

    this.dataPointerSelector.writeValue(
      this.dataset.find((v) => v.path === this.editedData.dataPath)
    );
    const tempMatSelect = new MatSelectChange(
      this.dataPointerSelector,
      this.dataPointerSelector.value
    );
    this.datapointChange(tempMatSelect);
    this.units = this.editedData.units;
    if (this.units) {
      this.unitValue =
        this.unitOptions.find((unit) => unit.name.includes(this.units + '-')).name || this.units;
      this.gaugeData.bottomLabel = this.defaultBottomLabel + this.units;
    }
    if (this.designMutlipeValue.length) {
      const totalSize = this.designMutlipeValue.length;
      this.designMutlipeValue[0].fieldOne.value = this.editedData.min;
      this.designMutlipeValue[totalSize - 1].fieldTwo.value = this.editedData.max;
      this.designMutlipeValue.forEach((v, i) => {
        const value = this.editedData.gaugeConfig.config.find(
          (config) => config.to === v.fieldTwo.value
        );
        if (value) {
          v.fieldColor.value = value.color;
        }
      });
      this.valueChanged(undefined);
      this.setcolorgridOptions(this.editedData.colors, this.editedData.dataPath);
      this.colorgridOptions.forEach((colorOption) => {
        const newColor = this.editedData.colors.find((color) => color.y === colorOption.val);
        if (newColor) {
          colorOption.color = newColor.color;
        } else {
          colorOption.checked = false;
        }
      });
      this.dataObject.colorgridOptions = this.colorgridOptions;
      this.checkforAnno(this.editedData.dataPath);
    } else {
      this.defaultDesign.fieldOne.value = this.editedData.min;
      this.defaultDesign.fieldTwo.value = this.editedData.max;
      this.defaultDesign.fieldColor.value = this.editedData.gaugeData.arcColors[0];
      this.arcLabelInput = this.editedData.gaugeData.arcLabels.join(',');
    }
    this.valueChanged(undefined);
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.editedData.widgetID, modalType: 'delete-widget' },
    });
  }

  customValidate(array) {
    const length = array.length;
    return array.every(function (value, index) {
      const nextIndex = index + 1;
      return nextIndex < length ? value <= array[nextIndex] : true;
    });
  }

  needleUpdate(min, max) {
    const minValue: any = +min;
    const maxValue: any = +max;
    if (!minValue || !maxValue) {
      return;
    }

    const newNeedleValue: any = Math.floor(
      Math.random() * (parseFloat(maxValue) - parseFloat(minValue) + 1) + parseFloat(minValue)
    );
    // const newNeedleValueInPer = (newNeedleValue / maxValue) * 100;
    const newNeedleValueInPer = ((newNeedleValue - minValue) / (maxValue - minValue)) * 100;
    if (this.units === undefined) {
      this.units = '';
    }
    this.gaugeData.needleValue = newNeedleValueInPer;
    if (newNeedleValue?.toString().includes('.')) {
      this.gaugeData.bottomLabel = newNeedleValue.toFixed(2).toString() + ' ' + this.units;
    } else {
      this.gaugeData.bottomLabel = newNeedleValue.toString() + ' ' + this.units;
    }

    this.defaultBottomLabel = newNeedleValue.toFixed(2).toString();
  }

  manageTabInkbarClass() {
    const selector = 'app-add-combination-chart .mat-ink-bar';
    const className = 'error';
    if (this.currentActiveTab === 0) {
      if (this.tabConfig?.dataTab?.isIconVisible) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    } else {
      if (this.tabConfig?.designTab?.isIconVisible) {
        addClass(selector, className);
      } else {
        removeClass(selector, className);
      }
    }
  }
}
