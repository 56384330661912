import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs/internal/Subscription';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressBar } from '@angular/material/progress-bar';
import { IWidgetEvent } from './historical-chart/historical-chart.component';
import * as moment from 'moment';

import { ISelectedRange } from 'src/app/shared/components/datetime-picker/datetime-picker.component';
import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';

import { BreakpointObserver } from '@angular/cdk/layout';
import { IHistoricalGraphStateGrouped } from 'src/app/shared/interface/common';
import { HistchartDialogComponent } from './histchart-dialog/histchart-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { MatGridList } from '@angular/material/grid-list';
import { MatTabGroup } from '@angular/material/tabs';
import * as _ from 'lodash';
import { ToasterData, userRoleConfig } from 'src/app/shared/constants/constants';
import { TemplateService } from 'src/app/service/template/template.service';
import { waitFewMoments } from 'src/app/shared/class/util';
import { CreateTemplateModel } from '../controls-engineer/create-template/create-template.model';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { DashEventService } from './services/dashboard-event/dash-event.service';
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html',
  styleUrls: ['./device-dashboard.component.scss'],
})
export class DeviceDashboardComponent implements OnInit, OnDestroy {
  @ViewChildren(CdkDropList) dropsQuery: QueryList<CdkDropList>;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @ViewChildren(MatProgressBar) matProgressBar: QueryList<MatProgressBar>;
  userRoleConfig = userRoleConfig;
  userRole: string;
  id: any;
  historyData: IHistoricalGraphStateGrouped = {};
  drops: CdkDropList[];
  deviceType: string;
  templateDetails: any;
  isSelectedData = true;
  previewDataSourceId: string;

  cards: any;
  routeSub: Subscription;
  timeValue: any = '12:53:23';
  deviceAgent: any;
  subscription: Subscription[] = [];
  model = 'RMX2200';
  statusCardData = {
    status: 'Warnings',
    count: 3,
    time: '12:53:23',
    statusDetails: [
      { type: 'Pressure 1', status: 'Warning' },
      { type: 'Temperature In', status: 'Warning' },
      { type: 'Temperature Out', status: 'Warning' },
    ],
    img: 'Image',
  };

  powerData = { status: 'On', time: '12:53:23', img: 'Image' };

  cycleCountData = { value: '143,575.0', time: '12:53:23' };

  hoursData = { value: '143:75:06', time: '12:53:23' };
  gridCols: any;
  dialogRef: any;
  spaceCard = false;
  gaugeHeight: any;
  dashboardData: any;
  firstLoad = false;
  tabName: any;
  type: any;
  subscriptionForDashboardInstance: Subscription;
  mode: string;
  selectedDataSource;
  public widgetData: any;
  public deviceId: any;
  constructor(
    private meshServiceProvidor: MeshServiceProvidor,
    private route: ActivatedRoute,
    private router: Router,
    private _dashEventService: DashEventService,
    private breakpointObserver: BreakpointObserver,
    private _resolutionResolver: ResolutionResolverService,
    public dialog: MatDialog,
    private _templateService: TemplateService,
    private activatedRoute: ActivatedRoute,
    private cs: CommonService
  ) {
    if (this.router.url.includes('preview')) {
      this.mode = 'preview';
    } else {
      this.mode = 'edit';
    }
  }

  ngOnInit(): void {
    this.type = this._templateService.flow;
    this.deviceAgent = window.navigator.userAgent;
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;

      if (this.deviceType === 'TabletLandscape' && !!this.dialogRef) {
        this.dialogRef.updateSize('90%', '80%');
      } else if (this.deviceType === 'TabletPortrait' && !!this.dialogRef) {
        this.dialogRef.updateSize('90%', '62%');
      }
      if (this.routeSub) {
        this.routeSub.unsubscribe();
      }
    });
    this.routeSub = this.route.params.subscribe((params) => {
      if (!_.isEmpty(params)) {
        this.addSubscribedDashboardInstance(params.id);
        this.id = params.id;
      } else {
        this.getQueryParams();
      }
    });

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.routeSub = this.route.params.subscribe((params) => {
      if (!_.isEmpty(params)) {
        this.addSubscribedDashboardInstance(params.id);
        this.id = params.id;
      } else {
        this.getQueryParams();
      }
    });

    this.updateHistory();
  }

  addSubscribedDashboardInstance(templateId: string) {
    if (this.subscriptionForDashboardInstance) {
      this.subscriptionForDashboardInstance.unsubscribe();
    }
    const sub = _.cloneDeep(
      this.meshServiceProvidor.addSubscribedDashboardInstance(templateId)
    ).subscribe(
      (res: any) => {
        this._dashEventService.notifSettingsInfo = res;
        this.initDasboardValue(res);
      },
      (err) => {
        if (!!err && !!err.message && err.message.includes('No matching dashboard found for ID')) {
          this.router.navigate(['main', 'not-found']);
        } else {
          const data: ToasterData = {
            type: 'error-custom',
            message: err.message,
          };
          this.cs.openToaster(data);
        }
      }
    );

    this.subscriptionForDashboardInstance = sub;

    if (this.deviceType !== null || this.deviceType !== undefined) {
      if (this.deviceType === 'MobileLandscape' && this.firstLoad === true) {
        this.gridCols = 2;
        for (const card of this.cards) {
          if (card) {
            card.width = 1;
          }
        }
      }
    }
    this.rendererDashboard();
  }

  initDasboardValue(res) {
    const dashDataClone = _.cloneDeep(res);
    this.widgetData = [];
    this._dashEventService._deviceDetail = res;
    this.widgetData = dashDataClone?.config?.widgets;
    this.dashboardData = _.cloneDeep(res);
    this.deviceId = res.deviceId;
    this.model = res.variant;
    if (res.config && res.config.defaultTimeWindow) {
      this._dashEventService.defaultTimeWindow = res.config.defaultTimeWindow;
    }
  }

  rendererDashboard() {
    setTimeout(() => {
      this.firstLoad = true;
      this.gridCols = 3;

      this.cards = this.widgetData;
      if (this.deviceType.includes('Web')) {
        this.gridCols = 3;
        this.cards = this.widgetData;
      }
      if (this.cards !== undefined) {
        if (this.deviceType.includes('TabletLandscape')) {
          this.gridCols = 3;
          this.cards = this.widgetData;
          /* eslint-disable-next-line @typescript-eslint/prefer-for-of */
          for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].type === 'space') {
              this.cards[i].width = 0;
              this.cards[i].height = 0;
            }
            if (
              this.cards[i].type === 'combination-chart' ||
              this.cards[i].type === 'line-chart' ||
              this.cards[i].type === 'multiseries-chart'
            ) {
              this.cards[i].width = 3;
            }
          }
        }
        if (
          this.deviceType.includes('TabletPortrait') ||
          this.deviceType.includes('MobileLandscape')
        ) {
          this.gridCols = 2;

          this.cards = this.widgetData;
          /* eslint-disable-next-line @typescript-eslint/prefer-for-of */
          for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].type === 'space') {
              this.cards[i].width = 0;
              this.cards[i].height = 0;
            }
            if (
              this.cards[i].type === 'combination-chart' ||
              this.cards[i].type === 'line-chart' ||
              this.cards[i].type === 'multiseries-chart'
            ) {
              this.cards[i].width = 2;
              this.cards[i].height = 2;
            }
          }
        }
        if (this.deviceType.includes('MobilePortrait')) {
          this.cards = this.widgetData;
          this.userRole = localStorage.getItem('role');
          this.gridCols = 2;
          /* eslint-disable-next-line @typescript-eslint/prefer-for-of */
          for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].type === 'device-info') {
              this.cards[i].width = 3;

              this.cards[i].height =
                this.userRole === userRoleConfig.SE || this.userRole === userRoleConfig.CE ? 2 : 1;
            }
            if (this.cards[i].type !== 'device-info' && this.cards[i].type !== 'space') {
              this.cards[i].width = 3;
            } else if (this.cards[i].type === 'space') {
              this.cards[i].width = 0;
              this.cards[i].height = 0;
            }
          }
        }
      }
    }, 1200);
  }

  getColor(e) {
    let stat;
    if (this.deviceType.includes('Mobile') || this.deviceType.includes('Tablet')) {
      stat = document.querySelectorAll('.colorcardmobile');
    } else {
      stat = document.querySelectorAll('.colorcard');
    }
    for (const item of stat) {
      switch (e) {
        case 'Alarm': {
          item.style.backgroundColor = '#e42326';
          break;
        }

        case 'Trouble': {
          item.style.backgroundColor = '#1961A6';
          break;
        }

        case 'Offline': {
          item.style.backgroundColor = '#636363';
          break;
        }

        case 'Warning': {
          item.style.backgroundColor = '#FFD500';
          break;
        }

        case 'Normal': {
          item.style.backgroundColor = '#009534';
          break;
        }

        default:
          break;
      }
    }
  }

  widgetEventHandler(event: IWidgetEvent): void {
    const id = event.id;
    const isVisiable = !(event.status === 'completed');
    this.toggleLoader(id, isVisiable);
  }

  getTime(e) {
    this.timeValue = e;
  }

  selectTab(evt, tabLabel, idx) {
    const Live = 'Live';
    const Historical = 'Historical';
    const tabcontent = document.getElementById(tabLabel + idx);
    tabcontent.style.display = 'block';
    if (tabLabel === Live) {
      document.getElementById(Historical + idx).style.display = 'none';
      document.getElementById('gaugeLive' + idx).classList.add('active');
      document.getElementById('historicalLive' + idx).classList.remove('active');
    } else {
      document.getElementById(Live + idx).style.display = 'none';
      document.getElementById('gaugeLive' + idx).classList.remove('active');
      document.getElementById('historicalLive' + idx).classList.add('active');
    }
  }

  scrollto(e) {
    const filtered = this.cards.filter((fil) => e.title === fil.title);
    const title = filtered[0].title;
    const pt = document.getElementById(title);
    pt.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });

    setTimeout(() => {
      const scrolledY = window.scrollY;
      if (scrolledY) {
        window.scroll(0, scrolledY - 250);
      } else {
        window.scroll(0, 0);
      }
    }, 1000);
  }

  toggleLoader(id: string, isVisiable: boolean): void {
    /* eslint-disable-next-line @typescript-eslint/dot-notation */
    const elements = this.matProgressBar['_results'];
    for (const key in elements) {
      if (elements[key]._elementRef.nativeElement.id) {
        const _id = elements[key]._elementRef.nativeElement.id;
        if (_id === id) {
          const progressBar = elements[key];
          if (!progressBar) return;
          const value = isVisiable ? 'block' : 'none';
          progressBar._elementRef.nativeElement.style.display = value;
        }
      }
    }
  }

  updateChartDate(event: ISelectedRange) {
    const minDate = moment(event.start).valueOf();
    const maxDate = moment(event.end).valueOf();
    const temp = this._dashEventService.createHistoricalGraphState(minDate, maxDate, 'custom');
    this._dashEventService.setAll_historicalGraphState(temp);
  }

  get_minDateTime(id: string) {
    if (this.historyData[id]) {
      return this.historyData[id].minDateTime;
    }
    return void 0;
  }

  get_maxDateTime(id: string) {
    if (this.historyData[id]) {
      return this.historyData[id].maxDateTime;
    }
    return void 0;
  }

  updateHistory() {
    const sub = this._dashEventService.getAll_historicalGraphState().subscribe((data) => {
      setTimeout(() => {
        this.historyData = data;
      }, 2000);
    });
    this.subscription.push(sub);
  }
  openDialog(deviceId, widget, id, type): void {
    if (
      this.deviceType === 'WebLandscape' ||
      this.deviceType === 'WebPortrait' ||
      this.deviceType === 'TabletLandscape'
    ) {
      this.dialogRef = this.dialog.open(HistchartDialogComponent, {
        maxWidth: '100vw',
        width: '90%',
        height: '80%',
        panelClass: 'c-graph-dialog',
        data: { deviceId, widget, id, type },
      });

      this.dialogRef.afterClosed().subscribe((result) => {});
    } else if (this.deviceType === 'TabletPortrait') {
      this.dialogRef = this.dialog.open(HistchartDialogComponent, {
        maxWidth: '100vw',
        width: '90%',
        height: '62%',
        panelClass: 'c-graph-dialog',
        data: { deviceId, widget, id, type },
      });

      this.dialogRef.afterClosed().subscribe((result) => {});
    } else if (this.deviceType === 'MobileLandscape') {
      this.dialogRef = this.dialog.open(HistchartDialogComponent, {
        maxWidth: '100vw',
        width: '95%',
        height: '95%',
        panelClass: 'c-graph-dialog-mlandscape',
        data: { deviceId, widget, id, type },
      });

      this.dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  async getQueryParams() {
    const tData = JSON.parse(sessionStorage.getItem('tempInfo'));
    if (tData === null) {
      this.activatedRoute.queryParams.subscribe((data: CreateTemplateModel) => {
        this.templateDetails = data;
        this.id = this.templateDetails.id;
        this.previewDataSourceId = data.previewDataSource;
        sessionStorage.setItem('tempInfo', JSON.stringify(this.templateDetails));
      });
    } else {
      this.templateDetails = JSON.parse(sessionStorage.getItem('tempInfo'));
      this.id = this.templateDetails.id;
      this.previewDataSourceId = this.templateDetails.previewDataSource;
    }
  }

  async getSelectedPreviewInstance(options) {
    try {
      const templateData: any = await this.meshServiceProvidor.getPreviewDashboardInstance(
        options.dashboardTemplateID,
        options.profileInstanceID
      );
      await waitFewMoments;
      this.initDasboardValue(templateData);
      this.rendererDashboard();
      this.selectedDataSource = templateData;
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };

      this.cs.openToaster(data);
    }
  }

  dataSelected(selectedData) {
    if (!selectedData) {
      this.isSelectedData = false;
      return;
    }
    const options = {
      profileInstanceID: selectedData,
      dashboardTemplateID: this.templateDetails.id || this.templateDetails.dashboardTemplateId,
    };
    this.isSelectedData = true;
    this.getSelectedPreviewInstance(options);
  }

  ngOnDestroy() {
    if (this.subscriptionForDashboardInstance) {
      this.subscriptionForDashboardInstance.unsubscribe();
    }
    this.routeSub.unsubscribe();
    this.dialog.closeAll();
    this.subscription.forEach((sub) => sub.unsubscribe);
    this._dashEventService.delAll_historicalGraphState();
    this._dashEventService.clear_marker();
    this.meshServiceProvidor.clearKeepAlive();
  }
}
