<div class="container-register-back" *ngIf="validInvite">
  <div>
    <div class="container-register">
      <form [formGroup]="registerFrom">
        <div class="header-content">
          <div class="logo-img">
            <img
              src="../../../../assets/images/atoms-brands-grid-green-and-grey.svg"
            />
          </div>
          <div><p class="field-title">- &nbsp;FieldVEU</p></div>
        </div>
        <div class="register-profile-title">
          {{ "common.create-account" | translate }}
        </div>

        <div class="register-field">
          <div class="register-header">
            {{ "common.email-address" | translate }}
          </div>
          <div class="register-value">{{ email }}</div>
        </div>
        <div class="register-field">
          <div class="register-header">
            {{ "common.firstName" | translate }}
          </div>
          <input
            class="nameinput"
            maxlength="100"
            formControlName="firstname"
            [placeholder]="'placeholder_messages.firstname' | translate"
            [ngClass]="{
              'err-border':
                !registerFrom.valid &&
                submitted &&
                registerFrom.controls['firstname']?.errors?.required
            }"
          />
          <div
            class="errorMsg"
            *ngIf="
              !registerFrom.valid &&
              submitted &&
              registerFrom.controls['firstname']?.errors?.required
            "
          >
            {{ "message.valid-firstname" | translate }}
          </div>
        </div>
        <div class="register-field">
          <div class="register-header">
            {{ "common.lastName" | translate }}
          </div>
          <input
            class="nameinput"
            maxlength="100"
            formControlName="lastname"
            [placeholder]="'placeholder_messages.lastname' | translate"
            [ngClass]="{
              'err-border':
                !registerFrom.valid &&
                submitted &&
                registerFrom.controls['firstname']?.errors?.required
            }"
          />
          <div
            class="errorMsg"
            *ngIf="
              !registerFrom.valid &&
              submitted &&
              registerFrom.controls['lastname']?.errors?.required
            "
          >
            {{ "message.valid-lastname" | translate }}
          </div>
        </div>

        <div class="register-field">
          <div class="register-header">
            {{ "common.mobile" | translate }}
            <img
              class="help"
              [matTooltipPosition]="'after'"
              [matTooltip]="'message.register-tooltip' | translate"
              matTooltipClass="customtooltip-reg"
              src="../../../../assets/images/ic-help.svg"
            />
          </div>
          <div class="mobileNo">
            <input
              id="phone"
              [ngClass]="{
                errorBorder: !validPhone && number !== '' && number !== null
              }"
              [placeholder]="'placeholder_messages.mobile' | translate"
              formControlName="phone"
              type="tel"
              (change)="checkifValid($event)"
              (keyup)="checkifValid($event)"
              (blur)="checkifValid($event)"
            />
          </div>
          <div
            class="errorMsg"
            *ngIf="!validPhone && number !== '' && number !== null"
          >
            {{ "message.valid-mobile" | translate }}
          </div>
        </div>

        <div class="register-field">
          <div class="register-header">
            {{ "common.timezone" | translate }}
          </div>
          <mat-select
            [compareWith]="comparedatapointObjects"
            formControlName="timezone"
            class="timezoneselect"
            [(value)]="selectedTimezone"
          >
            <mat-option *ngFor="let time of timezone" [value]="time">
              {{ time.displayText }}
            </mat-option>
          </mat-select>
        </div>
        <div class="register-field">
          <div class="register-header">
            {{ "common.create-password-register" | translate }}
            <div
              *ngIf="hidePassword"
              class="showhidepassword"
              (click)="showPassword(true)"
            >
              Show
              <img
                class="showhidepassword-icon"
                src="../../../assets/images/ic-visibility.svg"
                alt="Visible"
                data-test="media_visibility"
              />
            </div>
            <div
              *ngIf="!hidePassword"
              class="showhidepassword"
              (click)="showPassword(false)"
            >
              Hide
              <img
                class="showhidepassword-icon"
                src="../../../assets/images/ic-visibility-off.svg"
                alt="visibility-off"
                data-test="media-visibility_off"
              />
            </div>
          </div>

          <div class="inp-div">
            <input
              placeholder="Create a password"
              [type]="hidePassword ? 'password' : 'text'"
              required
              (keyup)="checkPass($event)"
              (focus)="warning = true"
              class="nameinput"
              formControlName="password"
              name="newpassword"
              data-test="input_password"
              [ngClass]="{
                'err-border':
                  (!registerFrom.valid &&
                    submitted &&
                    registerFrom.controls['password']?.errors?.required) ||
                  passStrength == 'weak'
              }"
            />
            <div class="weak-password" *ngIf="passStrength == 'weak'">
              <span class="pass-strength-padding">{{
                "message.weak-password" | translate
              }}</span>
            </div>
            <div class="avg-password" *ngIf="passStrength == 'average'">
              <span class="pass-strength-padding">
                {{ "message.avg-password" | translate }}</span
              >
            </div>
            <div class="strong-password" *ngIf="passStrength == 'strong'">
              <span class="pass-strength-padding">
                {{ "message.strong-password" | translate }}</span
              >
            </div>
          </div>

          <mat-password-strength
            #passwordComponent1
            (onStrengthChanged)="onStrength($event)"
            [password]="newpassword"
          >
          </mat-password-strength>
        </div>

        <div
          class="register-field password-message"
          [ngClass]="{
            errorMsg:
              (!registerFrom.valid &&
                submitted &&
                registerFrom.controls['password']?.errors?.required) ||
              passStrength == 'weak'
          }"
        >
          {{ "message.password-message" | translate }}
        </div>
        <div>
          <button
            type="submit"
            class="btn primary-btn createacct"
            mat-flat-button
            data-test="create-account"
            (click)="create()"
            [ngClass]="{ disableClick: disableClick }"
          >
            {{ "action.create-account" | translate | uppercase }}
          </button>
        </div>
        <div class="termsandservice">
          {{ "message.registermessgage" | translate }}

          <span
            ><a class="termsandservice-link" link="">
              {{ "message.terms" | translate }}
            </a>
            {{ "message.and" | translate }}
            <a class="termsandservice-link" link="">
              {{ "message.data-privacy" | translate }}
            </a></span
          >
        </div>
      </form>
    </div>

    <div class="registerBottomIcon">
      <img
        src="../../../assets/images/atoms-brands-msa-field-server-green-and-black-on-white.svg"
        alt="brand img"
      />
    </div>
  </div>
</div>
