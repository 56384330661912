import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';

import { ResolutionResolverService } from '../shared/services/resolution-resolver/resolution-resolver.service';
import { CreateTemplateModel } from './controls-engineer/create-template/create-template.model';
import { DashEventService } from './device-dashboard/services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  deviceType: any;
  queryParamsDigest = {};
  constructor(
    private _dashEventService: DashEventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _resolutionResolver: ResolutionResolverService
  ) {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
    const sub = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('users')) {
          this._dashEventService.clearLastestUserListState();
        }
        if (val.url.includes('main/device')) {
          return;
        }
        this._dashEventService.clearLastestDeviceListState();
        const urlPath = this.router.url.split('?');
        if (this.queryParamsDigest && this.queryParamsDigest[urlPath[0]]) {
          this.router.navigate([urlPath[0]], {
            queryParams: { ...this.queryParamsDigest[urlPath[0]] },
          });
        }
      }
    });
    this.subscription.push(sub);
    this.manageQueryParams();
  }
  ngOnInit(): void {
    const sub = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
    this.subscription.push(sub);
  }

  manageQueryParams(): any {
    const sub = this.activatedRoute.queryParams.subscribe((data: CreateTemplateModel) => {
      if (_.isEmpty(data)) {
        return;
      }
      const urlPath = this.router.url.split('?');
      const ignoreFor = ['eventlog'];
      for (const item of ignoreFor) {
        if (item) {
          if (this.router.url.includes(item)) {
            return;
          }
        }
      }
      this.queryParamsDigest = {
        [urlPath[0]]: data,
      };
    });
    this.subscription.push(sub);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
