<mat-progress-bar
  *ngIf="!widget"
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<span
  class="edit-posn cursor"
  [routerLink]="['/', 'main', 'template', 'updateInfo']"
  *ngIf="type == 'template'"
>
  <img
    src="../../../../assets/images/ic-edit.svg"
    class="edit-img"
    data-test="edit_deviceinfo"
/></span>
<div
  class="card-content"
  fxLayout="column"
  fxLayoutGap="10px"
  *ngIf="
    device.includes('Web') ||
    device.includes('Tablet') ||
    device.includes('MobileLandscape')
  "
  [ngClass]="{ 'opacity-1': !widget }"
>
  <div fxLayout="row" fxFlex="50%">
    <img
      [src]="widget['url']"
      *ngIf="widget['iconType'] == undefined"
      class="boilerimg"
      data-test="img_deviceimage"
    />
    <img
      [src]="iconUrl + widget['url']"
      class="boilerimg"
      *ngIf="widget['iconType'] == 'icon'"
      data-test="img_deviceimage"
    />
    <img
      [src]="imageUrl + widget['url']"
      class="boilerimg"
      *ngIf="widget['iconType'] == 'image'"
      data-test="img_deviceimage"
    />
  </div>
  <div fxFlex="50%" class="main-content" fxLayout="column">
    <div
      class="detail-content"
      fxLayout="row"
      fxLayoutGap="20px"
      *ngFor="let item of objectKeys(deviceDetailsData)"
    >
      <ng-container *ngIf="item != 'deviceName' && type === 'template'">
        <span fxFlex="55%" class="card-main-head-device">
          {{ 'common.' + item | translate }}
        </span>
        <span
          fxFlex="45%"
          [class]="'device-fnt-clr ' + item"
          [attr.data-test]="'text_' + item"
        >
          {{ deviceDetailsData[item] || '' }}
        </span>
      </ng-container>
      <ng-container *ngIf="item != 'deviceName' && type !== 'template'">
        <span fxFlex="60%" class="card-main-head-device">
          {{ 'common.' + item | translate }}
        </span>
        <span
          fxFlex="40%"
          [class]="'device-fnt-clr ' + item"
          [attr.data-test]="'text_' + item"
        >
          {{ deviceDetailsData[item] || '' }}
        </span>
      </ng-container>
    </div>
  </div>
  <div class="device-ht"></div>
</div>

<!--MOBILE-->
<div
  class="card-content"
  fxLayout="column"
  *ngIf="device.includes('MobilePortrait')"
  [ngClass]="{ 'opacity-1': !widget }"
>
  <div fxFlex="40%" fxLayoutAlign="center center">
    <img
      [src]="widget['url']"
      *ngIf="widget['iconType'] == undefined"
      [ngClass]="{
        boilerimgmobileSE:
          device.includes('MobilePortrait') &&
          (userRole == userRoleConfig.SE || userRole == userRoleConfig.CE),
        boilerimgmobilePM:
          device.includes('MobilePortrait') && userRole == userRoleConfig.PM
      }"
      data-test="img_deviceimage"
    />
    <img
      *ngIf="widget['iconType'] == 'icon'"
      [src]="iconUrl + widget['url']"
      [ngClass]="{
        boilerimgmobileSE:
          device.includes('MobilePortrait') &&
          (userRole == userRoleConfig.SE || userRole == userRoleConfig.CE),
        boilerimgmobilePM:
          device.includes('MobilePortrait') && userRole == userRoleConfig.PM
      }"
    />
    <img
      *ngIf="widget['iconType'] == 'image'"
      [src]="imageUrl + widget['url']"
      [ngClass]="{
        boilerimgmobileSE:
          device.includes('MobilePortrait') &&
          (userRole == userRoleConfig.SE || userRole == userRoleConfig.CE),
        boilerimgmobilePM:
          device.includes('MobilePortrait') && userRole == userRoleConfig.PM
      }"
    />
  </div>
  <div fxFlex="60%" class="main-content mobile-top-margin" fxLayout="column">
    <div
      class="detail-content"
      fxLayout="row"
      fxLayoutGap="20px"
      *ngFor="let item of objectKeys(deviceDetailsData)"
    >
      <ng-container>
        <span fxFlex="60%" class="card-main-head-device">
          {{ 'common.' + item | translate }}
        </span>
        <span
          fxFlex="40%"
          *ngIf="item !== 'deviceName'"
          [class]="'device-fnt-clr ' + item"
        >
          {{ deviceDetailsData[item] }}
        </span>
      </ng-container>
    </div>
  </div>
  <div class="device-ht"></div>
</div>
