<div class="section-sub-header">
  <mat-toolbar
    class="subheader-toolbar"
    [ngClass]="{ templateListing: templatePage == 'template' }"
  >
    <!--EMPTY TEMPLATE-->
    <div
      *ngIf="templatePage == 'template'"
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div
        class="dashboard-template"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <span>{{ "common.template" | translate }}</span>
      </div>
      <div fxLayoutAlign="center center" fxLayout="row">
        <button
          data-test="new-template"
          class="new-template"
          [ngStyle]="{ 'margin-right': hasScroll ? '9px' : '24px' }"
          mat-flat-button
          [routerLink]="['../template/create-dashboard-template']"
        >
          <mat-icon>add</mat-icon>
          {{ "action.new-template" | translate | uppercase }}
        </button>
      </div>
    </div>
    <!--END EMPTY TEMPLATE-->

    <!--BLANK NEW TEMPLATE-->
    <div
      *ngIf="templatePage == 'blanktemplate'"
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div
        class="dashboard-template"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <img
          (click)="redirect()"
          class="subhead-func-arrow-img btn-align-ce"
          src="../../../assets/images/ic-keyboard-arrow-left.svg"
          [matTooltip]="'action.back' | translate"
          matTooltipClass="customtooltip"
        />
        <span *ngIf="!editStatus">{{
          "common.blank-template" | translate
        }}</span>
        <span *ngIf="editStatus">{{ "common.edit-template" | translate }}</span>
        <button *ngIf="!templateStatus" class="ce-draft-btn">Draft</button>
      </div>
      <div *ngIf="!templateStatus" fxLayout="row">
        <div fxLayoutAlign="center center" fxLayout="row">
          <span
            [ngClass]="disableClick === true ? 'disableClick' : 'enableClick'"
            fxLayoutAlign="center center"
            (click)="delete()"
            ><img
              class="subhead-func-img icon-align-ce"
              [matTooltip]="'action.delete' | translate"
              matTooltipClass="customtooltip"
              src="../../../assets/images/ic-delete.svg"
          /></span>
          <span
            [ngClass]="saveClicked === true ? 'disableClick' : 'enableClick'"
            (click)="savePublish('save')"
            fxLayoutAlign="center center"
            ><img
              class="subhead-func-img icon-align-ce"
              [matTooltip]="'action.save' | translate"
              matTooltipClass="customtooltip"
              src="../../../assets/images/ic-save.svg"
          /></span>
          <!-- <span fxLayoutAlign="center center"
            ><img
              class="subhead-func-img icon-align-ce"
              matTooltip="Share"
              src="../../../assets/images/ic-share.svg"
          /></span> -->
          <button
            data-test="new-template"
            class="btn secondary-btn inactive-btn btn-align-ce"
            mat-flat-button
            (click)="previewInNewWindow()"
          >
            <img
              class="inactive-btn btn-icon-align"
              src="../../../../assets/images/ic-remove-red-eye.svg"
              alt="new template"
            />
            <span class="btn-temp-text">
              {{ "action.preview" | translate | uppercase }}</span
            >
          </button>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row">
          <button
            data-test="new-template"
            class="btn secondary-btn inactive-btn btn-align-ce"
            mat-flat-button
            (click)="savePublish('publish')"
          >
            <img
              class="inactive-btn btn-icon-align"
              src="../../../../assets/images/ic-publish.svg"
              alt="new template"
            />
            <span class="btn-temp-text">{{
              "action.publish" | translate | uppercase
            }}</span>
          </button>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row">
          <div *ngIf="maxWidget === false">
            <button
              data-test="new-template"
              class="new-template btn-align-ce"
              mat-flat-button
              [routerLink]="['/', 'main', 'template', 'addwidget']"
            >
              <mat-icon class="add-btn-icon-align">add</mat-icon>
              <span class="btn-temp-text">
                {{ "action.add-widget" | translate | uppercase }}</span
              >
            </button>
          </div>
          <div
            *ngIf="maxWidget === true"
            [matTooltip]="'message.max-widget' | translate"
            matTooltipClass="customtooltip"
          >
            <button
              data-test="new-template"
              class="new-template-disabled btn-align-ce"
              mat-flat-button
              [routerLink]="['/', 'main', 'template', 'addwidget']"
            >
              <img
                class="new-template-disabled"
                src="../../../assets/images/add_max-widget.svg"
                alt="new template"
              />
              {{ "action.add-widget" | translate | uppercase }}
            </button>
          </div>
        </div>
      </div>
      <!--SAVE PUBLISHED TEMPLATE-->
      <div *ngIf="templateStatus" fxLayout="row">
        <div fxLayoutAlign="center center" fxLayout="row">
          <span fxLayoutAlign="center center" (click)="delete()"
            ><img
              class="subhead-func-img icon-align-ce"
              [matTooltip]="'action.delete' | translate"
              matTooltipClass="customtooltip"
              src="../../../assets/images/ic-delete.svg"
          /></span>

          <!-- <span fxLayoutAlign="center center"
            ><img
              class="subhead-func-img icon-align-ce"
              matTooltip="Share"
              src="../../../assets/images/ic-share.svg"
          /></span> -->
          <span fxLayoutAlign="center center" (click)="unPublish('unpublish')"
            ><img
              class="subhead-func-img icon-align-ce"
              [matTooltip]="'action.unpublish' | translate"
              matTooltipClass="customtooltip"
              src="../../../../assets/images/ic-unpublish.svg"
          /></span>
          <button
            data-test="new-template"
            class="btn secondary-btn inactive-btn btn-align-ce"
            mat-flat-button
            (click)="previewInNewWindow()"
          >
            <img
              class="inactive-btn btn-icon-align"
              src="../../../../assets/images/ic-remove-red-eye.svg"
              alt="new template"
            />
            <span class="btn-temp-text">{{
              "action.preview" | translate | uppercase
            }}</span>
          </button>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row">
          <button
            data-test="new-template"
            class="btn secondary-btn inactive-btn btn-align-ce"
            mat-flat-button
            (click)="savePublish('savepublish')"
          >
            <img
              src="../../../../assets/images/ic-publish.svg"
              alt="new template"
            />
            <span class="btn-temp-text">
              {{ "action.save-publish" | translate | uppercase }}</span
            >
          </button>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row">
          <div *ngIf="maxWidget === false">
            <button
              data-test="new-template"
              class="new-template btn-align-ce"
              mat-flat-button
              [routerLink]="['/', 'main', 'template', 'addwidget']"
            >
              <mat-icon class="add-btn-icon-align">add</mat-icon>
              <span class="btn-temp-text">
                {{ "action.add-widget" | translate | uppercase }}</span
              >
            </button>
          </div>
          <div
            *ngIf="maxWidget === true"
            [matTooltip]="'message.max-widget' | translate"
            matTooltipClass="customtooltip"
          >
            <button
              data-test="new-template"
              class="new-template-disabled btn-align-ce"
              mat-flat-button
              [routerLink]="['/', 'main', 'template', 'addwidget']"
            >
              <img
                class="new-template-disabled"
                src="../../../assets/images/add_max-widget.svg"
                alt="new template"
              />
              {{ "action.add-widget" | translate | uppercase }}
            </button>
          </div>
        </div>
      </div>
      <!--END-->
    </div>
    <!--END BLANK NEW TEMPLATE-->

    <!--ADD WIDGET -->
    <div
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
      *ngIf="templatePage == 'addwidget'"
    >
      <div class="widget" fxLayoutAlign="center center" fxLayout="row">
        <span data-test="text_addwidget">{{
          "action.add-widget" | translate
        }}</span>
      </div>
      <div class="btn-container" fxLayoutAlign="center center" fxLayout="row">
        <button
          data-test="action_cancel"
          class="btn secondary-btn"
          mat-flat-button
          [routerLink]="['/', 'main', 'template', 'blanktemplate']"
          *ngIf="!selectedWidget"
        >
          {{ "action.cancel" | translate | uppercase }}
        </button>
      </div>
    </div>
    <!--END ADD WIDGET -->

    <!--Edit WIDGET -->
    <div class="subhead-container" *ngIf="templatePage == 'editwidget'">
      <div class="widget" fxLayoutAlign="center center" fxLayout="row">
        <span data-test="text_addwidget">{{
          "common.edit-widget" | translate
        }}</span>
      </div>
    </div>
    <!--END edit WIDGET -->

    <!--CREATE DASHBOARD TEMPLATE-->
    <div
      *ngIf="templatePage == 'create-dashboard'"
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div
        class="dashboard-template"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <img
          class="subhead-func-arrow-img btn-align-ce"
          src="../../../assets/images/ic-keyboard-arrow-left.svg"
          (click)="redirect()"
          [matTooltip]="'action.back' | translate"
          matTooltipClass="customtooltip"
        />
        <span>{{ "common.create-dashboard" | translate }}</span>
      </div>
    </div>
    <!--END CREATE DASHBOARD TEMPLATE-->

    <!--UPDATE DEVICE INFO-->
    <div
      *ngIf="templatePage == 'updateInfo'"
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div class="widget" fxLayoutAlign="center center" fxLayout="row">
        <span>{{ "common.update-device" | translate }}</span>
      </div>
    </div>
    <!--END UPDATE DEVICE INFO-->

    <!--PREVIEW TEMPLATE-->
    <div
      *ngIf="templatePage == 'preview'"
      class="subhead-container"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div
        class="dashboard-template"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <img
          *ngIf="!noAction"
          [routerLink]="['/', 'main', 'template']"
          class="subhead-func-arrow-img btn-align-ce"
          src="../../../assets/images/ic-keyboard-arrow-left.svg"
          [matTooltip]="'action.back' | translate"
          matTooltipClass="customtooltip"
        />
        <span
          >{{ "action.preview" | translate }} :
          {{ templateDetails.templateName }}</span
        >
      </div>
      <div
        *ngIf="previewBtnName && !noAction"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <button
          data-test="new-template"
          class="new-template"
          mat-flat-button
          (click)="manageRedirect()"
        >
          <mat-icon>edit</mat-icon>
          {{ previewBtnName }}
        </button>
      </div>
    </div>
    <!--END PREVIEW TEMPLATE-->
  </mat-toolbar>
</div>

<div *ngIf="templatePage == 'blanktemplate'">
  <app-template-details
    [templateDetails]="templateDetails"
  ></app-template-details>
</div>
