<div class="toaster-container">
  <div
    class="toaster-state"
    [ngClass]="{
      error:
        toasterData?.type == 'error' || toasterData?.type == 'error-custom',
      success:
        toasterData?.type == 'success' || toasterData?.type == 'success-custom'
    }"
  >
    <img
      src="../../../../assets/images/close-white.svg"
      *ngIf="toasterData?.type == 'error'"
    />
    <img
      src="../../../../assets/images/success-fill.svg"
      *ngIf="toasterData?.type == 'success'"
    />
  </div>
  <div class="toaster-body">
    <p
      *ngIf="
        toasterData?.message == 'publish' && toasterData?.type == 'success'
      "
    >
      {{ "message.publish-success-message" | translate }}
    </p>
    <p
      *ngIf="toasterData?.message == 'publish' && toasterData?.type == 'error'"
    >
      {{ "message.publish-error-message" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'savepublish' && toasterData?.type == 'success'
      "
    >
      {{ "message.savepublish-success-message" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'savepublish' && toasterData?.type == 'error'
      "
    >
      {{ "message.savepublish-error-message" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'unpublish' && toasterData?.type == 'success'
      "
    >
      {{ "message.unpublish-success-message" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'unpublish' && toasterData?.type == 'error'
      "
    >
      {{ "message.unpublish-error-message" | translate }}
    </p>
    <!--NEW SAVE PASSWORD-->
    <p
      *ngIf="toasterData?.message == 'change' && toasterData?.type == 'success'"
    >
      {{ "message.password-changed" | translate }}
    </p>
    <p *ngIf="toasterData?.message == 'change' && toasterData?.type == 'error'">
      {{ "message.save-error-message" | translate }}
    </p>
    <!--END-->
    <p *ngIf="toasterData?.message == 'save' && toasterData?.type == 'success'">
      {{ "message.save-success-message" | translate }}
    </p>
    <p *ngIf="toasterData?.message == 'save' && toasterData?.type == 'error'">
      {{ "message.save-error-message" | translate }}
    </p>

    <p
      *ngIf="
        toasterData?.message == 'inviteSend' && toasterData?.type == 'success'
      "
    >
      {{ "message.invite-send-success" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'inviteSend' && toasterData?.type == 'error'
      "
    >
      {{ "message.invite-send-error" | translate }}
    </p>

    <!-- user management toaster messages -->

    <p
      *ngIf="
        toasterData?.message == 'newuser' && toasterData?.type == 'success'
      "
    >
      {{ "message.newuser-added" | translate }}
    </p>

    <p *ngIf="toasterData?.type == 'error-custom' || toasterData?.type == 'success-custom'">
      {{ toasterData.message }}
    </p>

    <p
      *ngIf="
        toasterData?.message == 'userUpdated' && toasterData?.type == 'success'
      "
    >
      {{ "message.save-success-message" | translate }}
    </p>
    <p
      *ngIf="
        toasterData?.message == 'userUpdated' && toasterData?.type == 'error'
      "
    >
      {{ "message.save-error-message" | translate }}
    </p>
    <!-- dashboard permission -->
    <p
      *ngIf="
        toasterData?.message == 'dashboardPermission-success' &&
        toasterData?.type == 'success'
      "
    >
      {{ "message.save-success-message" | translate }}
    </p>
    <!-- profile updated  -->
    <p
      *ngIf="
        toasterData?.message == 'profileUpdated' &&
        toasterData?.type == 'success'
      "
    >
      {{ "message.profileUpdated-message" | translate }}
    </p>
    <!-- Notification saved -->
    <p
      *ngIf="
        toasterData?.message == 'notification-saved' &&
        toasterData?.type == 'success'
      "
    >
      {{ "message.notification-saved" | translate }}
    </p>

    <!-- Cookie events -->
    <p
            *ngIf="
        toasterData?.message == 'reconnected' &&
        toasterData?.type == 'success'
      "
    >
      {{ "message.reconnected" | translate }}
    </p>
    <p
            *ngIf="
        toasterData?.message == 'connection-lost' &&
        toasterData?.type == 'error'
      "
    >
      {{ "message.connection-lost" | translate }}
    </p>
    <p
            *ngIf="
        toasterData?.message == 'unable-to-reconnect' &&
        toasterData?.type == 'error'
      "
    >
      {{ "message.unable-to-reconnect" | translate }}
    </p>
  </div>
  <div class="toaster-action">
    <img
      class="cursor dismissbtn"
      (click)="dismiss()"
      src="../../../../assets/images/ic-clear.svg"
    />
  </div>
</div>
