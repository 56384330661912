import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/service/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-addedit-blankspace',
  templateUrl: './addedit-blankspace.component.html',
  styleUrls: ['./addedit-blankspace.component.scss'],
})
export class AddeditBlankspaceComponent implements OnInit {
  @Input() id;
  @Input() type;
  readonly widgetDetail = {
    dataPath: '',
    height: 1,
    title: 'Blank Space',
    type: 'space',
    width: 1,
    widgetID: 'space' + Math.floor(Math.random() * 1000000),
  };
  constructor(
    private _templateService: TemplateService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {}

  addWidget() {
    if (this.type === 'add') {
      this._templateService.tempData.push(this.widgetDetail);
    }
    this.router.navigate(['/main/template/blanktemplate']);
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }

  cancel() {
    this.router.navigate(['/main/template/blanktemplate']);
  }
}
