import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commonFilter',
})
export class CommonFilterPipe implements PipeTransform {
  transform(items: any[], by: string, filter: string | boolean): any {
    if (!items || !by || filter === undefined) {
      return items;
    }
    return items.filter((item) =>
      item[by] === filter || (!item.hasOwnProperty(by) && filter === false) ? true : false
    );
  }
}
