<div
  *ngIf="
    deviceType == 'TabletPortrait' ||
    deviceType == 'TabletLandscape' ||
    deviceType == 'WebPortrait' ||
    deviceType == 'WebLandscape'
  "
>
  <mat-toolbar class="menu-toolbar" color="primary">
    <img
      (click)="expandMenu()"
      class="header-icon"
      src="../../../assets/images/ic-menu.svg"
      alt="Menu icon"
      data-test="media_desktop_menu"
    />

    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="row">
        <div class="title">
          <ng-container *ngIf="logoData; else noLogo">
            <img
              [src]="logoData?.logoPath ? logoSource + logoData?.logoPath : ''"
              alt=""
            />
          </ng-container>
          <ng-template #noLogo>
            <div class="logo">OEM logo</div>
          </ng-template>
        </div>
        <div class="field-logo">
          <span class="field-title">- &nbsp;FieldVEU</span>
        </div>
      </div>

      <div class="user-settings">
        <span
          class="margin-icons name-pos header-icon"
          data-test="action_username"
          (click)="openMenu()"
          >{{ userDetails?.username | usernameFormat }}
          <img
            [matMenuTriggerFor]="menu"
            class="margin-icon-dropdown"
            src="../../../assets/images/ic-keyboard-arrow-down.svg"
            alt="Dropdown icon"
          />
        </span>

        <mat-menu
          #menu="matMenu"
          class="user-menu"
          backdropClass="has-up-arrow"
        >
          <button
            (click)="navigateToProfile()"
            class="menu-btn"
            id="userprofile"
            data-test="action_myprofile"
            mat-menu-item
          >
            <span class="user-menubtn-font">{{
              'action.my-profile' | translate
            }}</span>
          </button>
          <button
            (click)="navigateToChangePassword()"
            class="menu-btn"
            id="changepassword"
            data-test="action_change_password"
            mat-menu-item
          >
            <span class="user-menubtn-font">{{
              'action.change-password' | translate
            }}</span>
          </button>
          <button
            class="menu-btn"
            id="logout"
            data-test="action_logout"
            (click)="logout()"
            mat-menu-item
          >
            <span class="user-menubtn-font">
              <img src="../../../assets/images/solid-sign-out-alt.svg" />{{
                'action.logout' | translate
              }}</span
            >
          </button>
        </mat-menu>

        <img
          [matMenuTriggerFor]="helpmenu"
          class="margin-icons"
          src="../../../assets/images/ic-help.svg"
          alt="Help icon"
          data-test="media_help"
          [matTooltip]="'x-refer.help' | translate"
          matTooltipClass="customtooltip"
        />

        <mat-menu
          #helpmenu="matMenu"
          class="help-menu"
          backdropClass="has-up-arrow"
        >
          <!-- <button class="menu-btn" data-test="action_help" mat-menu-item>
            <span class="user-menubtn-font">{{
              "x-refer.help" | translate
            }}</span>
          </button> -->
          <button
            (click)="goTo('terms')"
            class="menu-btn"
            data-test="action_tc"
            mat-menu-item
          >
            <span class="user-menubtn-font">{{
              'x-refer.tc' | translate
            }}</span>
          </button>
          <button
            (click)="goTo('privacy')"
            class="menu-btn"
            data-test="action_privacy"
            mat-menu-item
          >
            <span class="user-menubtn-font">{{
              'x-refer.privacy' | translate
            }}</span>
          </button>
        </mat-menu>

        <mat-menu
          #notifyMenu="matMenu"
          class="notify-menu"
          backdropClass="has-up-arrow"
        >
          <div
            class="notify-content"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <div class="sub-heading">
              <span class="subhead-text">{{
                'common.notication' | translate
              }}</span>
            </div>
            <div class="notify-link">
              <span class="notify-all">{{ 'action.see-all' | translate }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>

          <div
            class="notify-content"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <div
              class="sub-heading"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="flex-start"
            >
              <span><img src="../../../assets/images/alarm.svg" /> </span>
              <div class="notify-data" fxFlex fxLayout="column">
                <span class="notify-status-text">Bolier1 status to Alarm</span>
                <span class="notify-status-time">13:52</span>
              </div>
            </div>

            <div class="notify-link">
              <mat-icon class="notify-icon">chevron_right</mat-icon>
            </div>
          </div>

          <div
            class="notify-content"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <div
              class="sub-heading"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="flex-start"
            >
              <span><img src="../../../assets/images/alarm.svg" /> </span>
              <div class="notify-data" fxFlex fxLayout="column">
                <span class="notify-status-text"
                  >Invitation to view Bolier 2</span
                >
                <span class="notify-status-time">4 hours ago</span>
              </div>
            </div>

            <div class="notify-link">
              <mat-icon class="notify-icon">chevron_right</mat-icon>
            </div>
          </div>
        </mat-menu>
        <img
          [matMenuTriggerFor]="switchApp"
          class="margin-icons"
          src="../../../assets/images/ic-apps.svg"
          alt="SwitchApp icon"
          *ngIf="
            userRole == userRoleConfig?.SE || userRole == userRoleConfig?.CE
          "
          data-test="media_switch_apps"
          [matTooltip]="'common.switch-to' | translate"
          matTooltipClass="customtooltip"
        />

        <mat-menu
          #switchApp="matMenu"
          class="switch-menu"
          backdropClass="has-up-arrow"
        >
          <div class="switchApp" fxFlex fxLayout="column">
            <div class="heading">
              <p>{{ 'common.switch-to' | translate }}</p>
            </div>
            <mat-divider class="custom-divider"></mat-divider>
            <div class="switchApp-content" fxFlex fxLayout="row">
              <div fxFlexAlign="flex-start">
                <img src="../../../assets/images/favicon.png" width="43" />
              </div>
              <div class="switchApp-content1">
                <p class="primary-text">
                  {{ 'action.fieldserver-cloud' | translate }}
                </p>
              </div>
              <div class="btn-wrapper">
                <button
                  class="cursor openbtn"
                  (click)="openLink()"
                  mat-flat-button
                >
                  {{ 'action.open' | translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</div>

<div *ngIf="deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape'">
  <mat-toolbar class="menu-toolbar-mobile" color="primary">
    <img
      (click)="expandMenu()"
      class="header-icon mobile-itm-align"
      src="../../../assets/images/menu-white.svg"
      alt="Menu icon"
      data-test="media_menu"
    />

    <div
      class="mobile-itm-align"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between flex-end"
    >
      <div class="mobile-header-align" fxLayout="row">
        <div class="">
          <span *ngIf="devicesPage == false" class="field-title-mobile">{{
            'common.template' | translate
          }}</span>

          <span *ngIf="devicesPage == true && !eventlogPage" class="field-title-mobile">{{
            "common.devices" | translate
          }}</span>

          <span *ngIf="eventlogPage == true" class="field-title-mobile">{{
            "common.event-log" | translate
          }}</span>

        </div>
      </div>

      <!-- <div class="notofication-icon-mobile-align">
        <img
          class="margin-icons-mobile"
          src="../../../assets/images/notifications-white.svg"
          alt="Notifications icon"
          data-test="media_notification"
        />
      </div> -->
    </div>
  </mat-toolbar>
</div>
