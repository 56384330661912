import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MeshModule } from './service/mesh/mesh.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { LogoutComponent } from './logout/logout.component';
import { ToastrModule } from 'ngx-toastr';
import 'intl-tel-input';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UnavailableServiceComponent } from './unavailable-service/unavailable-service.component';
import { DashEventService } from './core/device-dashboard/services/dashboard-event/dash-event.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    UnsupportedBrowserComponent,
    ResetPasswordComponent,
    UnavailableServiceComponent,
  ],
  imports: [
    MeshModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxJsonViewerModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    OverlayModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [DashEventService],
  bootstrap: [AppComponent],
})
export class AppModule {}
