<section *ngIf="deviceType.includes('Web') || deviceType.includes('Tablet')">
  <div class="notification-container">
    <!-- Header -->
    <div class="notif-header">
      <img
        [matTooltip]="'Back'"
        (click)="cancel()"
        class="subhead-func-arrow-img"
        src="../../assets/images/ic-keyboard-arrow-left.svg"
        data-test="action_back"
      /><span class="notification-title">
        {{ "common.notification-settings" | translate }}:
        {{ dashboardInfo["deviceName"] }}</span
      >
    </div>
    <div class="datapoint-container">
      <!-- Settings -->
      <div class="notif-datapoints">
        <div class="notification-subtitle">
          <div>
            {{ "message.notification-point-message" | translate }}
          </div>
          <div>
            {{ "message.point-status-message" | translate }}
          </div>
        </div>
        <div class="datapoints">
          <div
            class="notif-check"
            *ngFor="let datapoint of notificationPoints | keyvalue"
          >
            <mat-checkbox
              class="notif-checkbox"
              [checked]="datapoint.value"
              (change)="datapointsset($event, datapoint.key)"
              data-test="notification-datapoints"
              >{{ datapoint.key }}
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="notifedit-block">
        <div class="notif-edit">
          <div class="notif-delivery">
            {{ "common.notification-delivery" | translate }}
          </div>
          <div class="notif-editicon" (click)="gotoProfile()">
            <img
              src="../../../../assets/images/ic-edit.svg"
              class="edit-img"
            /><span class="notif-edit-text">{{
              "action.edit" | translate
            }}</span>
          </div>
        </div>
        <div *ngIf="no_notification_delivery" class="notif-err">
          <img src="../../../assets/images/failed.svg" alt="Failed" />
          <span class="notif-err-msg">{{
            "message.nodeliveryMethods" | translate
          }}</span>
        </div>
        <div *ngIf="!no_notification_delivery">
          <div
            *ngIf="userDetails['notificationMethods']['email'] == true"
            class="fieldtitle"
          >
            {{ "common.email-address" | translate }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['email'] == true"
            class="fieldvalue"
          >
            {{ userDetails["username"] }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['sms'] == true"
            class="fieldtitle"
          >
            {{ "common.mobile_number" | translate }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['sms'] == true"
            class="fieldvalue"
          >
            {{ userDetails["phoneNumber"] }}
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div class="btn-container">
        <button
          [ngClass]="sentRequest === true ? 'disable-click' : 'enable-click'"
          class="btn secondary-btn cancel"
          mat-flat-button
          data-test="action_cancel"
          (click)="cancel()"
          type="button"
        >
          {{ "action.cancel" | translate | uppercase }}
        </button>
        <button
          [ngClass]="
            no_notification_delivery === true ||
            sentRequest === true ||
            unsavedChanges === false
              ? 'disable-click-save'
              : 'enable-click'
          "
          type="button"
          class="btn primary-btn save"
          mat-flat-button
          data-test="save-profile"
          (click)="save()"
        >
          {{ "action.save" | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</section>

<section class="mobile-scroll" *ngIf="deviceType.includes('Mobile')">
  <div class="notification-container-mobile notification-backColor">
    <!-- Header -->
    <div class="notif-header-mobile">
      <img
        [matTooltip]="'Back'"
        (click)="cancel()"
        class="subhead-func-arrow-img chevron-back-mobile"
        src="../../assets/images/ic-chevron-left-white.svg"
        data-test="action_back"
      />
      <div class="notification-title-mobile">
        {{ "common.notification-settings" | translate }}:
        <div class="truncate-name">{{ dashboardInfo["deviceName"] }}</div>
      </div>
    </div>
    <div class="datapoint-container-mobile">
      <!-- Settings -->
      <div class="notif-datapoints">
        <div class="notification-subtitle">
          <div>
            {{ "message.notification-point-message" | translate }}
          </div>
          <div>
            {{ "message.point-status-message" | translate }}
          </div>
        </div>
        <div class="datapoints">
          <div
            class="notif-check"
            *ngFor="let datapoint of notificationPoints | keyvalue"
          >
            <mat-checkbox
              class="notif-checkbox"
              [checked]="datapoint.value"
              (change)="datapointsset($event, datapoint.key)"
              data-test="notification-datapoints"
              >{{ datapoint.key }}
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="notifedit-block">
        <div class="notif-edit">
          <div class="notif-delivery">
            {{ "common.notification-delivery" | translate }}
          </div>
          <div class="notif-editicon" (click)="gotoProfile()">
            <img
              src="../../../../assets/images/ic-edit.svg"
              class="edit-img mr-10"
            /><span class="notif-edit-text">{{
              "action.edit" | translate
            }}</span>
          </div>
        </div>
        <div *ngIf="no_notification_delivery" class="notif-err">
          <img src="../../../assets/images/failed.svg" alt="Failed" />
          <span class="notif-err-msg">{{
            "message.nodeliveryMethods" | translate
          }}</span>
        </div>
        <div *ngIf="!no_notification_delivery">
          <div
            *ngIf="userDetails['notificationMethods']['email'] == true"
            class="fieldtitle"
          >
            {{ "common.email-address" | translate }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['email'] == true"
            class="fieldvalue"
          >
            {{ userDetails["username"] }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['sms'] == true"
            class="fieldtitle"
          >
            {{ "common.mobile_number" | translate }}
          </div>
          <div
            *ngIf="userDetails['notificationMethods']['sms'] == true"
            class="fieldvalue"
          >
            {{ userDetails["phoneNumber"] }}
          </div>
        </div>
      </div>
      <!-- Buttons -->
    </div>
    <!--BUTTON-->
    <div class="action btn-container">
      <button
        [ngClass]="sentRequest === true ? 'disable-click' : 'enable-click'"
        class="btn secondary-btn cancel"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ "action.cancel" | translate | uppercase }}
      </button>
      <button
        [ngClass]="
          no_notification_delivery === true ||
          sentRequest === true ||
          unsavedChanges === false
            ? 'disable-click-save'
            : 'enable-click'
        "
        type="button"
        class="btn primary-btn save btn-save-margin"
        mat-flat-button
        data-test="save-profile"
        (click)="save()"
      >
        {{ "action.save" | translate | uppercase }}
      </button>
    </div>
  </div>
</section>
