<mat-progress-bar
  *ngIf="!subscriptionHandler && type !== 'template'"
  class="progress-bar p-absolute-top"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="card-content"
  fxFlex
  fxLayout="column"
  [ngClass]="{
    'cardtop-trouble': currentStatus === 'Trouble',
    'cardtop-warning': currentStatus === 'Warning',
    'cardtop-alarm': currentStatus === 'Alarm',
    'cardtop-offline': currentStatus === 'Offline',
    'opacity-1': !value
  }"
>
  <div class="main-content" fxFlex fxLayout="column">
    <div class="content1" fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <span
        class="card-main-head truncate"
        matTooltipClass="tooltip-hover"
        data-test="text_totalhrs-title"
        >{{ widget.title | translate }}</span
      >
      <span *ngIf="type == 'template'" data-test="edit_totalhrs">
        <mat-icon
          class="status-dropdown cursor"
          [ngStyle]="{ color: '#404040' }"
          (click)="edit('numerical-kpi', widget)"
          >edit</mat-icon
        >
      </span>
      <img
        *ngIf="currentStatus == 'Warning'"
        class="card-status-image"
        src="../../../../assets/images/status/warning-status.svg"
        data-test="img_totalhrs-warning"
      />
      <img
        *ngIf="currentStatus == 'Alarm'"
        class="card-status-image"
        src="../../../../assets/images/status/alarm-status.svg"
        data-test="img_totalhrs-alarm"
      />
      <img
        *ngIf="currentStatus == 'Trouble'"
        class="card-status-image"
        src="../../../../assets/images/status/trouble-status.svg"
        data-test="img_totalhrs-trouble"
      />
      <img
        *ngIf="currentStatus == 'Offline'"
        class="card-status-image"
        src="../../../../assets/images/status/offline-status.svg"
        data-test="img_totalhrs-offline"
      />
    </div>
    <div
      class="content-2"
      [ngClass]="{
        'card-blur': currentStatus == 'Offline'
      }"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      <span
        class="device-imp-status-details"
        data-test="text_totalhrs-status"
        >{{ value }}</span
      >
    </div>
    <div
      class="content-2"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between end"
    >
      <span class="time-stamp-font" data-test="text_totalhrs-timevalue">{{
        timeValue
      }}</span>
    </div>
  </div>
</div>
