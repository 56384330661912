<section
  id="datetime-picker"
  class="datetime-picker"
  [ngClass]="{
    datetimetablet: deviceType === 'TabletPortrait'
  }"
>
  <div
    class="input colorDatecontrl"
    fxLayout="row"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <div data-test="open-ranger-picker" style="flex-grow: 1" (click)="toggleOpen()">
      <ng-container *ngIf="rangeSelected.range.start; else placeholderT">
        <p
          *ngIf="tooltip"
          id="dt-input-text"
          [matTooltip]="
            (rangeSelected.range.start | dateformat: displayDateFormat) +
            ' - ' +
            (rangeSelected.range.end | dateformat: displayDateFormat)
          "
        >
          {{ rangeSelected.range.start | dateformat: displayDateFormat }}
          - {{ rangeSelected.range.end | dateformat: displayDateFormat }}
        </p>

        <p *ngIf="!tooltip" id="dt-input-text">
          {{ rangeSelected.range.start | dateformat: displayDateFormat }}
          - {{ rangeSelected.range.end | dateformat: displayDateFormat }}
        </p>
      </ng-container>
      <ng-template #placeholderT>
        <p id="dt-input-text">
          {{ placeholder }}
        </p>
      </ng-template>
    </div>
    <div (click)="toggleOpen()">
      <mat-icon> event </mat-icon>
    </div>
    <div data-test="action_clear" (click)="clear()">
      <mat-icon *ngIf="rangeSelected.range.start && clearBtn"> close </mat-icon>
    </div>
  </div>
</section>

<ng-template
  cdkConnectedOverlay
  (backdropClick)="cancelSelection()"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayPush]="true"
  cdkConnectedOverlayPanelClass="dt-calendar"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
>
  <mat-card class="dropdown mat-elevation-z8">
    <mat-card-content fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
      <div>
        <mat-calendar
          [minDate]="rangeSelected.calendar.minDate"
          [maxDate]="rangeSelected.calendar.maxDate"
          [dateClass]="dateClass()"
          (selectedChange)="updateDate($event)"
          [headerComponent]="headerComponent.componentType"
        ></mat-calendar>
      </div>
      <div
        class="time-group"
        fxLayout="column"
        fxLayout.lt-sm="row"
        fxLayoutGap="20px"
      >
        <div>
          <p class="label-text">{{ "common.start-date" | translate }}</p>
          <ngx-timepicker-field
            (timeChanged)="startTime($event)"
            [format]="24"
            [controlOnly]="true"
            [defaultTime]="
              (rangeSelected.range.start | dateformat: 'HH:mm') ||
              defaultTime?.start
            "
          ></ngx-timepicker-field>
        </div>
        <div>
          <p class="label-text">{{ "common.end-date" | translate }}</p>
          <ngx-timepicker-field
            [ngClass]="{ alert: isError }"
            (timeChanged)="endTime($event)"
            [format]="24"
            [controlOnly]="true"
            [defaultTime]="
              (rangeSelected.range.end | dateformat: 'HH:mm') ||
              defaultTime?.end
            "
          ></ngx-timepicker-field>
        </div>
      </div>
    </mat-card-content>
    <div>
      <mat-card-footer fxLayout="column">
        <p class="alert-text" *ngIf="isError">
          {{ "message.invalid-date-range" | translate }}
        </p>
        <div fxLayout="row">
          <mat-icon class="information-icon"> error_outline </mat-icon>
          <p class="calender-info">{{ "message.calender-info" | translate }}</p>
        </div>
      </mat-card-footer>
      <mat-card-actions fxLayout="row" style="float: right">
        <button
          data-test="action_cancel"
          mat-flat-button
          class="btn-style"
          (click)="cancelSelection()"
          type="button"
        >
          {{ "action.cancel" | translate | uppercase }}
        </button>
        <button
          mat-flat-button
          class="btn-style"
          [disabled]="!this.startRange || !this.endRange"
          (click)="applySelectedRange()"
        >
          {{ "action.apply" | translate | uppercase }}
        </button>
      </mat-card-actions>
    </div>
  </mat-card>
</ng-template>