import { Component, Input, OnInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { WidgetsService } from 'src/app/service/widgets/widgets.service';
import { TemplateService } from 'src/app/service/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { ceData } from 'src/app/shared/constants/ce-data';
import * as _ from 'lodash';
export class State {
  constructor(public name: string) {}
}

@Component({
  selector: 'app-addedit-text',
  templateUrl: './addedit-text.component.html',
  styleUrls: ['./addedit-text.component.scss'],
})
export class AddeditTextComponent implements OnInit {
  @Input() type;
  @Input() id;
  stateCtrl: FormControl;
  dataValue: FormControl;
  submitted: boolean;
  filteredStates: Observable<any[]>;
  states: State[];

  registrationForm = this.fb.group({
    dataValue: ['', [Validators.required]],
  });
  selectedValue: string;
  dataset: any;
  editedData = [];
  defaultValue: any = 'Sample text';
  defaultTime: any = '00:00:00';

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private ws: WidgetsService,
    private _templateService: TemplateService,
    public dialog: MatDialog
  ) {
    this.states = _.cloneDeep(ceData.unitOptions);
    this.states.push({ name: 'None' });

    this.dataset =
      this.ws.dataPoints === undefined
        ? JSON.parse(sessionStorage.getItem('dataPoints'))
        : this.ws.dataPoints;
  }

  ngOnInit(): void {
    // if (this.ws.dataPoints == undefined) {
    //   let dP = JSON.parse(sessionStorage.getItem("dataPoints"));
    //   this.dataset = dP;
    // } else {
    //   this.dataset = this.ws.dataPoints;
    // }
    if (this.type === 'edit') {
      const d = this._templateService.getEditData(this.id);
      if (!!d) {
        this.editedData = d[0];

        this.registrationForm.patchValue({
          dataValue: (this.editedData as any).dataPath,
        });
      }
    }
  }

  filterStates(name: string) {
    return this.states.filter((state) => state.name.toLowerCase().includes(name.toLowerCase()));
  }
  onCancel() {
    this.registrationForm.reset();
    this.router.navigate(['/main/template/blanktemplate']);
  }
  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      height: '281px',
      width: '500px',
      data: { id: this.id, modalType: 'delete-widget' },
    });
  }
  onSubmit() {
    if (!this.registrationForm.valid) {
      this.submitted = true;
      return false;
    } else {
      const fm = this.registrationForm.value;
      const obj = this._templateService.tempData;
      if (this.type === 'add') {
        obj.push({
          widgetID: 'text-kpi' + Math.floor(Math.random() * 1000000),
          currentPath: '/current/malachitevulture.ea.ayj9au/Boiler 1/',
          dataPath: fm.dataValue,
          height: 1,
          historyPath: 'malachitevulture_ea-aYj9AU/Boiler 1/',
          pointStatusPath: '/_data/fieldveu/pointStatus/malachitevulture_ea-aYj9AU/Boiler 1/',
          title: fm.dataValue,
          type: 'text-kpi',
          width: 1,
        });

        this._templateService.tempData = obj;
      } else {
        const foundIndex = obj.findIndex((x) => x.widgetID === this.id);

        const newObj = {
          ...this.editedData,
          dataPath: fm.dataValue,
          title: fm.dataValue,
        };

        obj[foundIndex] = newObj;

        this._templateService.tempData = obj;
        sessionStorage.setItem('tempData', JSON.stringify(obj));
      }
      this.router.navigate(['/main/template/blanktemplate']);
    }
  }
}
