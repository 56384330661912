import { AfterViewChecked, Component, OnInit } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import '../../../../../node_modules/intl-tel-input/build/js/utils.js';
import { FormBuilder, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ToasterData } from 'src/app/shared/constants/constants.js';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewChecked {
  register: boolean;
  inputTel: any;
  initialViewCheck: any;
  iti: any;
  registerFrom = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    timezone: ['', [Validators.required]],
    password: ['', [Validators.required]],
    phone: [''],
  });
  email: string;
  validPhone = true;
  timezone = [];
  selectedTimezone: string;
  user_name: any;
  submitted = false;
  numberVal: any;
  userCreated: boolean;
  hidePassword = true;
  disableClick = false;
  passStrength: string;
  warning: boolean;
  newpassword: any;
  validInvite = false;
  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private _meshService: MeshServiceProvidor,
    private router: Router,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!!params) {
        this.email = params.origin_email;
        this.user_name = !!params.user_name ? params.user_name.split(' ').join('+') : '';
        this._meshService
          .checkforValidInvitation(this.email, this.user_name)
          .then(() => {
            this.validInvite = true;
            this.getTimeZoneInfo();
            this.formValueChange();
          })
          .catch((err) => {
            this.validInvite = false;
            if (!!err && err.message.includes('Link Is No Longer Valid')) {
              this.validInvite = false;
              this.router.navigate(['invitation-expired']);
            } else {
              const data: ToasterData = {
                type: 'error-custom',
                message: err.toString(),
              };
              this.cs.openToaster(data);
              this.router.navigate(['not-found']);
            }
          });
      }
    });
  }

  formValueChange() {
    const sub = this.registerFrom.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        if (value.password !== null) {
          this.newpassword = value.password;
        }

        this.phonenumberValididtycheck(value.phone);
      });
  }

  create(): any {
    this.submitted = true;
    if (
      this.registerFrom.valid &&
      (this.passStrength === 'strong' || this.passStrength === 'average')
    ) {
      if (this.validPhone || this.numberVal === '' || this.numberVal === null) {
        this.disableClick = true;
        const data = {
          userId: this.user_name,
          password: this.registerFrom.value.password,
          firstName: this.registerFrom.value.firstname,
          lastName: this.registerFrom.value.lastname,
          phoneNumber: this.numberVal,
          userTimeZone:
            typeof this.registerFrom.value.timezone === 'string'
              ? this.registerFrom.value.timezone
              : this.registerFrom.value.timezone.name,
        };

        this._meshService
          .activateUser(data)
          .then((res) => {
            if (res) {
              localStorage.clear();
              localStorage.setItem('lastLoggedIn', (res as any).username);
              this.router.navigate(['login']);
            }
          })
          .catch((err) => {
            this.userCreated = false;
            if (!!err && err.message.includes('Link Is No Longer Valid')) {
              this.router.navigate(['invitation-expired']);
            } else {
              const data1: ToasterData = {
                type: 'error-custom',
                message: err.toString(),
              };

              this.cs.openToaster(data1);
            }
          });
      }
    } else {
      this.disableClick = false;
    }
  }
  checkifValid(event): any {
    if (!!event && !!event.target.value) {
      const num = event.target.value;
      this.phonenumberValididtycheck(num);
    }
  }

  phonenumberValididtycheck(num) {
    this.numberVal = this.iti.getNumber().trim();
    if (this.iti.getNumber().trim()) {
      if (
        this.iti.isValidNumber() &&
        (this.iti.getNumberType() === (window as any).intlTelInputUtils.numberType.MOBILE ||
          this.iti.getNumberType() === (window as any).intlTelInputUtils.numberType.FIXED_LINE ||
          this.iti.getNumberType() ===
            (window as any).intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE)
      ) {
        const reg = /[ `!@#$%^&*_\=\[\]{};':"\\|,.<>\/?~a-zA-Z]/;
        if (!reg.test(num) || this.numberVal === '') {
          this.validPhone = true;
        } else {
          this.validPhone = false;
        }
      } else {
        this.validPhone = false;
      }
    } else {
      this.validPhone = true;
    }
  }

  getTimeZoneInfo(): any {
    const countries = moment.tz.names();
    countries.map((country) => {
      const obj = {
        name: country,
        format: moment().tz(country).format('Z'),
        displayText: `${country} (UTC ${moment().tz(country).format('Z')})`,
      };
      this.timezone.push(obj);
    });

    this.selectedTimezone = moment.tz.guess();
    this.registerFrom.get('timezone').setValue(this.selectedTimezone);
  }

  comparedatapointObjects(object1: any, object2: any): any {
    return object1 && object2 && object1.name.trim() === object2.trim();
  }

  ngAfterViewChecked(): any {
    if (this.validInvite) {
      if (!this.initialViewCheck) {
        this.initIntTelInput();
        this.initialViewCheck = true;
      }
    }
  }

  showPassword(show): any {
    this.hidePassword = show === true ? false : true;
  }

  checkPass(e): any {
    if (e.target.value === '') {
      this.passStrength = '';
    }
  }

  enableWarning(): any {
    this.warning = true;
  }
  onStrength(e) {
    setTimeout(() => {
      if (e < 50) {
        this.passStrength = 'weak';
      } else if (e >= 50 && e <= 80) {
        this.passStrength = 'average';
      } else {
        this.passStrength = 'strong';
      }
    }, 10);
  }

  private initIntTelInput() {
    const that = this;
    this.inputTel = document.querySelector('#phone');
    this.iti = intlTelInput(this.inputTel, {
      utilsScript: '../../../../../node_modules/intl-tel-input/build/js/utils.js',
      autoPlaceholder: 'off',
      formatOnDisplay: false,
    });

    this.inputTel.addEventListener('countrychange', function () {
      const num = that.iti.getNumber().trim();
      that.phonenumberValididtycheck(num);
    });
  }
}
