import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MeshServiceProvidor } from '../../../service/mesh/mesh.service';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { ChangePasswordDetail } from 'src/app/shared/interface/common';
import { Location } from '@angular/common';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { waitFewMoments } from 'src/app/shared/class/util';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('loginForm', { static: true }) ngForm: NgForm;
  loginForm: FormGroup;

  loginDissabled = false;
  changePasswordModel = new ChangePasswordDetail();
  hidePassword = true;
  errorMessage = '';
  samePass = false;
  wrongOldPass = false;
  fetchingRes = false;
  showEmailError = false;
  showPage = true;
  forgotPasswordStatus = false;
  resetPasswordConfirm = false;
  passwordSent = false;
  passStrength = '';
  emailError = false;
  warning = false;
  pattern = new RegExp('/^(?=.*[A-Za-z])(?=.*d)(?=.*[$@$!%*#?&])[A-Za-zd$@$!%*#?&]{8,}$/');
  deviceType;
  constructor(
    private meshServiceProvidor: MeshServiceProvidor,
    private router: Router,
    private location: Location,
    private _resolutionResolver: ResolutionResolverService,
    public cs: CommonService,
    private toastr: ToastrService
  ) {
    this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }

  ngOnInit(): void {}
  login(form): any {
    const user = localStorage.getItem('lastLoggedIn');

    const options = {
      username: user,
      password: form.value.newpassword,
      oldPassword: form.value.oldpassword,
    };

    if (
      options.password !== undefined &&
      options.oldPassword !== undefined &&
      !this.samePass &&
      options.oldPassword !== '' &&
      options.password !== ''
    ) {
      if (this.passStrength === 'strong' || this.passStrength === 'average') {
        this.fetchingRes = true;
        this.meshServiceProvidor
          .changePassword(options)
          .then((res) => {
            if (res) {
              this.showPage = false;
              const data: ToasterData = {
                type: 'success',
                message: 'change',
              };
              this.fetchingRes = true;
              this.cs.openToaster(data);
              setTimeout(() => {
                this.router.navigate(['main', 'device']);
              }, 1000);
            }
          })
          .catch((err) => {
            this.fetchingRes = false;
            if (err.message === 'Invalid old password') {
              this.wrongOldPass = true;
            }
            const data: ToasterData = {
              type: 'error',
              message: 'change',
            };

            this.cs.openToaster(data);
          });
      } else {
      }
    }
    setTimeout(() => {
      this.fetchingRes = false;
    }, 500);
  }
  checkPass(e, val) {
    const len = e.target.value.length;
    if (e.target.value === '') {
      this.passStrength = '';
    }

    if (val.form.value.oldpassword !== undefined) {
      if (val.form.value.oldpassword === val.form.value.newpassword) {
        this.samePass = true;
        this.warning = false;
      } else {
        this.samePass = false;
        this.warning = true;
      }
    }
    if (len === 0) {
      this.samePass = false;
      this.warning = true;
    }
  }
  checkOldPassword(e, val): any {
    const len = e.target.value.length;
    if (val.form.value.oldpassword !== undefined || val.form.value.oldpassword !== '') {
      if (val.form.value.oldpassword === val.form.value.newpassword) {
        this.samePass = true;
        this.warning = false;
      } else {
        this.samePass = false;
        this.warning = true;
        this.wrongOldPass = false;
      }
    }
    if (len === 0) {
      this.samePass = false;
      this.warning = true;
      this.wrongOldPass = false;
    }
  }

  onCancel(): any {
    this.location.back();
  }
  enableWarning(): any {
    this.warning = true;
  }
  onStrength(e): any {
    // await waitFewMoments;
    setTimeout(() => {
      if (e < 50) {
        this.passStrength = 'weak';
      } else if (e >= 50 && e <= 80) {
        this.passStrength = 'average';
      } else {
        this.passStrength = 'strong';
      }
    }, 10);
  }
}
