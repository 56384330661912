import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppRoutingModule } from '../app-routing.module';
import { DevicesComponent } from './devices/devices.component';
import { SharedModule } from '../shared/shared.module';
import { HistoricalChartComponent } from './device-dashboard/historical-chart/historical-chart.component';
import { DateControllerComponent } from './device-dashboard/date-controller/date-controller.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { DeviceDashboardComponent } from './device-dashboard/device-dashboard.component';
import { DeviceDetailsComponent } from './device-dashboard/device-details/device-details.component';
import { DeviceStatusComponent } from './device-dashboard/device-status/device-status.component';
import { DevicePowerComponent } from './device-dashboard/device-power/device-power.component';
import { DeviceCyclecountComponent } from './device-dashboard/device-cyclecount/device-cyclecount.component';
import { DeviceTotalhoursComponent } from './device-dashboard/device-totalhours/device-totalhours.component';
import { HistChartToolbarComponent } from './device-dashboard/hist-chart-toolbar/hist-chart-toolbar.component';
import { FormsModule } from '@angular/forms';
import { SubheaderComponent } from './device-dashboard/subheader/subheader.component';
import { GaugeChartComponent } from './device-dashboard/gauge-chart/gauge-chart.component';
import { HistchartDialogComponent } from './device-dashboard/histchart-dialog/histchart-dialog.component';
import { DemoGaugeComponent } from './device-dashboard/demo-gauge/demo-gauge.component';
import { UsernameFormatPipe } from '../shared/pipe/userName/username-format.pipe';
import { MobileFilterSortingComponent } from './devices/mobile-filter-sorting/mobile-filter-sorting.component';
import { CeSubheaderComponent } from './controls-engineer/ce-subheader/ce-subheader.component';
import { DashboardTemplateComponent } from './controls-engineer/dashboard-template/dashboard-template.component';
import { CreateTemplateComponent } from './controls-engineer/create-template/create-template.component';
import { TemplatesComponent } from './controls-engineer/templates/templates.component';
import { PmListingSearchComponent } from './devices/pm-listing-search/pm-listing-search.component';
import { SeListingSearchComponent } from './devices/se-listing-search/se-listing-search.component';
import { AddWidgetComponent } from './controls-engineer/add-widget/add-widget.component';
import { AddeditDevicestatusComponent } from './controls-engineer/addedit-devicestatus/addedit-devicestatus.component';

import { TemplateDetailsComponent } from './controls-engineer/template-details/template-details.component';
import { AddeditTemplatesComponent } from './controls-engineer/addedit-templates/addedit-templates.component';
import { AddeditNumericalComponent } from './controls-engineer/addedit-numerical/addedit-numerical.component';
import { AddeditTextComponent } from './controls-engineer/addedit-text/addedit-text.component';
import { AddeditBinaryComponent } from './controls-engineer/addedit-binary/addedit-binary.component';
import { AddeditLinechartComponent } from './controls-engineer/addedit-linechart/addedit-linechart/addedit-linechart.component';
import { AddeditBlankspaceComponent } from './controls-engineer/addedit-blankspace/addedit-blankspace.component';
import { AddeditImageComponent } from './controls-engineer/addedit-image/addedit-image.component';
import { AddeditGaugeComponent } from './controls-engineer/addedit-gauge/addedit-gauge.component';
import { EditWidgetComponent } from './controls-engineer/edit-widget/edit-widget.component';
import { ProfileComponent } from './user-management/profile/profile.component';
import { UserListingComponent } from './user-management/user-listing/user-listing.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { UserMgmtSubheaderComponent } from './user-management/user-mgmt-subheader/user-mgmt-subheader.component';
import { AddCombinationChartComponent } from './controls-engineer/add-combination-chart/add-combination-chart.component';
import { RegisterComponent } from './user-management/register/register.component';
import 'intl-tel-input';
import { ChangePasswordComponent } from './user-management/change-password/change-password.component';
import { EventLogComponent } from './event-log/event-log.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InvitationExpiredComponent } from './invitation-expired/invitation-expired.component';
import { EventLogSearchComponent } from './devices/event-log-search/event-log-search.component';
import { MultiseriesChartComponent } from './device-dashboard/multiseries-chart/multiseries-chart.component';
import { DashEventService } from './device-dashboard/services/dashboard-event/dash-event.service';
import { SelectLinechartTypeComponent } from './controls-engineer/addedit-linechart-v2/select-linechart-type/select-linechart-type.component';
import { LinechartEditorComponent } from './controls-engineer/addedit-linechart-v2/linechart-editor/linechart-editor.component';
import { AddeditLinechartV2Component } from './controls-engineer/addedit-linechart-v2/addedit-linechart-v2.component';

@NgModule({
  declarations: [
    MainComponent,
    DevicesComponent,
    HeaderComponent,
    SidebarComponent,
    HistoricalChartComponent,
    DateControllerComponent,
    DeviceDashboardComponent,
    DeviceDetailsComponent,
    DeviceStatusComponent,
    DevicePowerComponent,
    DeviceCyclecountComponent,
    DeviceTotalhoursComponent,
    HistChartToolbarComponent,
    SubheaderComponent,
    GaugeChartComponent,
    HistchartDialogComponent,
    DemoGaugeComponent,
    UsernameFormatPipe,
    MobileFilterSortingComponent,
    CreateTemplateComponent,
    CeSubheaderComponent,
    DashboardTemplateComponent,
    TemplatesComponent,
    PmListingSearchComponent,
    SeListingSearchComponent,
    AddWidgetComponent,
    AddeditDevicestatusComponent,
    TemplateDetailsComponent,
    AddeditTemplatesComponent,
    AddeditNumericalComponent,
    AddeditTextComponent,
    AddeditBinaryComponent,
    AddeditLinechartComponent,
    AddeditBlankspaceComponent,
    AddeditImageComponent,
    AddeditGaugeComponent,
    EditWidgetComponent,
    ProfileComponent,
    UserListingComponent,
    EditUserComponent,
    AddUserComponent,
    UserMgmtSubheaderComponent,
    RegisterComponent,
    AddCombinationChartComponent,
    ChangePasswordComponent,
    EventLogComponent,
    NotificationSettingsComponent,
    NotFoundComponent,
    InvitationExpiredComponent,
    EventLogSearchComponent,
    MultiseriesChartComponent,
    SelectLinechartTypeComponent,
    LinechartEditorComponent,
    AddeditLinechartV2Component,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
    NgApexchartsModule,
    GaugeChartModule,
    NgApexchartsModule,
  ],
  providers: [DashEventService],
})
export class CoreModule {}
