import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { WidgetsService } from '../../../service/widgets/widgets.service';
import { AddeditLinechartV2Component } from '../addedit-linechart-v2/addedit-linechart-v2.component';
@Component({
  selector: 'app-add-widget',
  templateUrl: './add-widget.component.html',
  styleUrls: ['./add-widget.component.scss'],
})
export class AddWidgetComponent implements AfterViewChecked {
  @ViewChild(AddeditLinechartV2Component) addEditLineChartV2: AddeditLinechartV2Component;
  readonly widgetList = [
    { name: 'device_status', isActive: false },
    { name: 'binary', isActive: false },
    { name: 'text_kpi', isActive: false },
    { name: 'numeric_kpi', isActive: false },
    { name: 'gauge', isActive: false },
    { name: 'line_chart', isActive: false },
    { name: 'combination_chart', isActive: false },
    { name: 'blank_space', isActive: false },
  ];
  activeWidgetPosition = {
    left: '0px',
    width: '0px',
  };
  data: any;
  activeWidget = '';

  handleChange = false;
  constructor(public ws: WidgetsService) {
    this.ws.set_selectedWidget = null;
  }

  ngAfterViewChecked(): void {
    this.handleChange = true;
  }

  selectTab(e, widget) {
    this.ws.set_selectedWidget = widget.name;
    this.activeWidgetPosition.left = e.currentTarget.offsetLeft + 'px';
    this.activeWidgetPosition.width = e.currentTarget.offsetWidth + 'px';
    this.widgetList.forEach((widgetVal) => (widgetVal.isActive = false));
    widget.isActive = true;
    this.activeWidget = widget.name;
  }

  checkIsArrayState(arr) {
    return arr.every((e) => e.isActive === false);
  }

  backToSelectPage() {
    this.handleChange = false;
    this.addEditLineChartV2.seletedGraphType = undefined;
  }
}
