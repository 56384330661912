<app-ce-subheader></app-ce-subheader>
<div class="margin-top-left">
  <app-addedit-devicestatus
    *ngIf="type == 'device-status'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-devicestatus>
  <app-addedit-linechart
    *ngIf="type == 'line-chart'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-linechart>
  <app-addedit-linechart-v2
    [id]="id"
    *ngIf="type === 'multiseries-chart'"
  >
  </app-addedit-linechart-v2>
  <app-addedit-binary
    *ngIf="type == 'binary'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-binary>
  <app-addedit-numerical
    *ngIf="type == 'numerical-kpi'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-numerical>
  <app-addedit-gauge *ngIf="type == 'gauge'" [type]="'edit'" [id]="id">
  </app-addedit-gauge>
  <app-addedit-text
    *ngIf="type == 'text-kpi'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-text>
  <app-addedit-blankspace
    *ngIf="type == 'space'"
    [type]="'edit'"
    [id]="id"
  ></app-addedit-blankspace>
  <app-add-combination-chart
    *ngIf="type == 'combination-chart'"
    [type]="'edit'"
    [id]="id"
  ></app-add-combination-chart>
</div>
