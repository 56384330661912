import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesComponent } from '../../devices/devices.component';
@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.scss'],
})
export class UserListingComponent implements OnInit {
  @ViewChild(DevicesComponent) childComponent: DevicesComponent;
  constructor(private router: Router) {}

  ngOnInit(): void {}
  resetTable(): any {
    this.childComponent.reset();
  }
  addUser(): any {
    this.router.navigate(['main', 'users', 'addUser']);
  }
}
