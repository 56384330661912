import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { IDeviceListState, IHistoricalGraphStateGrouped } from 'src/app/shared/interface/common';
import { IHistoricalGraphState, ITemporal } from 'src/app/shared/interface/_base';

@Injectable({
  providedIn: 'root',
})
export class DashEventService {
  defaultTimeWindow: ITemporal = 'month';
  scrollEvent = new EventEmitter();
  allDevices = [];
  _notificationSettings: any;
  seriesSelectedInfo: any[] = [];
  seriesSelectedInfo$ = new BehaviorSubject([]);
  private deviceListLatestState: IDeviceListState = undefined;
  private deviceDetail = {};

  private historicalGraphStates = new BehaviorSubject<IHistoricalGraphStateGrouped>({});
  private markerState = new BehaviorSubject({});
  private saveForSometime: IHistoricalGraphState;

  private mobileHeaderHide = true;
  private userListLatestState: IDeviceListState = undefined;

  private _notifSettingsInfo: any;
  constructor() {}

  setMarker(id: string, event) {
    const temp = this.markerState.value;

    temp[id] = event;
    this.markerState.next(temp);
  }

  getMarker(id: string) {
    return this.markerState.asObservable().pipe(
      map((value) => value[id]),
      distinctUntilChanged()
    );
  }

  isMarkerInit(id: string) {
    const temp = this.markerState.value;
    if (temp[id]) {
      return false;
    }
    temp[id] = null;
    this.markerState.next(temp);
    return true;
  }

  clear_marker() {
    this.markerState.next({});
  }

  init_historicalGraphState(id: string) {
    const temp = this.historicalGraphStates.value;
    if (temp[id]) {
      return false;
    }
    temp[id] = null;
    this.historicalGraphStates.next(temp);
    return true;
  }

  set_historicalGraphState(id: string, data: IHistoricalGraphState) {
    const temp = this.historicalGraphStates.value;
    temp[id] = data;
    this.historicalGraphStates.next(temp);
  }

  setAll_historicalGraphState(data: IHistoricalGraphState, isFirst = false) {
    const temp = this.historicalGraphStates.value;
    if (isFirst) {
      setTimeout(() => {
        this.saveForSometime = undefined;
      }, 100);
    }
    /* eslint-disable-next-line guard-for-in */
    for (const key in temp) {
      temp[key] = this.saveForSometime || data;
    }
    this.historicalGraphStates.next(temp);
    if (isFirst) this.saveForSometime = this.saveForSometime || data;
  }

  get_historicalGraphState(id: string) {
    return this.historicalGraphStates.asObservable().pipe(
      map((value) => value[id]),
      distinctUntilChanged()
    );
  }

  delAll_historicalGraphState() {
    this.historicalGraphStates.next({});
  }

  getAll_historicalGraphState() {
    return this.historicalGraphStates.asObservable();
  }

  createHistoricalGraphState(minDT: number, maxDT: number, type: ITemporal): IHistoricalGraphState {
    return {
      minDateTime: minDT,
      maxDateTime: maxDT,
      type,
    };
  }

  set lastestDeviceListState(value: IDeviceListState) {
    this.deviceListLatestState = value;
  }

  get lastestDeviceListState(): IDeviceListState {
    return this.deviceListLatestState;
  }

  clearLastestDeviceListState() {
    this.deviceListLatestState = undefined;
  }

  set _deviceDetail(value: any) {
    this.deviceDetail = value;
  }

  get _deviceDetail() {
    return this.deviceDetail;
  }

  set devicesMobile(data) {
    this.allDevices = data;
  }

  get deviceslistMobile() {
    return this.allDevices;
  }

  clearAllDeviceListList() {
    this.allDevices = undefined;
  }

  get headerState() {
    return this.mobileHeaderHide;
  }

  set headerState(state: boolean) {
    this.mobileHeaderHide = state;
  }

  set lastestUserListState(value: IDeviceListState) {
    this.userListLatestState = value;
  }

  get lastestUserListState(): IDeviceListState {
    return this.userListLatestState;
  }

  set notifSettingsInfo(value: any) {
    const obj = {
      deviceName: value.deviceName,
      profileInstanceID: value.profileInstanceID,
      dashboardInstanceID: value.id,
    };
    this._notifSettingsInfo = obj;
  }

  get notifSettingsInfo() {
    return this._notifSettingsInfo;
  }

  get notificationSettings() {
    return this._notificationSettings;
  }

  set notificationSettings(value: any) {
    this._notificationSettings = value;
  }

  clearLastestUserListState() {
    this.userListLatestState = undefined;
  }

  //multichart fucntions

  initSeriesSelectedInfo(dataPathArr, id) {
    const mainObj = {
      chardId: id,
      info: [],
    };
    dataPathArr.map((dataPoint, i) => {
      const obj = {
        id: i,
        show: true,
        seriesName: dataPoint,
      };
      mainObj.info = [...mainObj.info, obj];
    });
    this.seriesSelectedInfo = [...this.seriesSelectedInfo, mainObj];
    this.seriesSelectedInfo$.next(this.seriesSelectedInfo);
  }

  setSeries(index, chartId) {
    this.seriesSelectedInfo.map((p) => {
      if (p.chardId === chartId) {
        p.info.map((data) => {
          if (data.id === index) {
            data.show = data.show ? false : true;
          }
        });
      }
    });

    this.seriesSelectedInfo$.next(this.seriesSelectedInfo);
  }

  getSeries(id) {
    const x = this.seriesSelectedInfo.filter((p) => p.chardId === id);
    return x && x.length > 0 ? x[0].info : undefined;
  }

  clearSeries() {
    this.seriesSelectedInfo = [];
    this.seriesSelectedInfo$.next([]);
  }
}
