<input cdkFocusInitial type="text" style="display: none" />

<div class="main-delete-widget" *ngIf="modalType == 'delete-widget'">
  <div class="delete-widget">
    <div class="delete-close">
      <img
        class="closeBtn"
        src="../../../assets/images/ic-close.svg"
        alt="Close"
        (click)="close()"
      />
    </div>

    <div class="deletetext-body">
      <div class="delete-title">
        <img
          class="errorBtn"
          src="../../../assets/images/ic-error.svg"
          alt="error-icon"
        />
        <span class="del-title">
          {{ 'common.delete-widget-title' | translate }}</span
        >
      </div>
      <div class="delete-text">{{ 'message.delete-widget' | translate }}</div>
    </div>
  </div>

  <div class="modal-button-container">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn deletebtn"
        mat-flat-button
        data-test="action_delete"
        (click)="delete()"
      >
        {{ 'action.delete' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="main-delete-widget" *ngIf="modalType == 'delete-template'">
  <div class="delete-widget">
    <div class="delete-body deleteTemplate">
      <div class="delete-title">
        <mat-icon>delete</mat-icon>
        <span class="del-title">
          {{ 'common.delete-template-title' | translate }}</span
        >
      </div>
      <div class="delete-text">{{ 'message.delete-template' | translate }}</div>
    </div>
  </div>

  <div class="modal-button-container delete-template">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn deletebtn"
        mat-flat-button
        data-test="action_delete"
        [disabled]="disablebtn"
        (click)="deleteTemplate()"
      >
        {{ 'action.delete' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="main-delete-widget" *ngIf="modalType == 'unpublish-template'">
  <div class="delete-widget">
    <div class="delete-close">
      <img
        class="closeBtn"
        src="../../../assets/images/ic-close.svg"
        alt="Close"
        (click)="close()"
      />
    </div>
    <div class="delete-body unpublish">
      <div class="delete-title">
        <span> <img src="../../../../assets/images/ic-unpublish.svg" /></span>
        <span class="del-title">
          {{ 'common.unpublish-template-title' | translate }}</span
        >
      </div>
      <div class="delete-text">
        {{ 'message.unpublish-template' | translate }}
      </div>
    </div>
  </div>

  <div class="modal-button-container delete-template">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn primary-btn"
        mat-flat-button
        data-test="action_delete"
        (click)="unPublish()"
      >
        {{ 'action.unpublish' | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="main-delete-widget"
  *ngIf="
    modalType == 'unsaved-changes' ||
    modalType == 'user-unsaved' ||
    modalType == 'unsaved-changes-notif'
  "
>
  <div class="delete-widget discard-template">
    <div class="delete-close">
      <img
        class="closeBtn"
        src="../../../assets/images/ic-close.svg"
        alt="Close"
        (click)="cancel()"
      />
    </div>
    <div class="delete-body">
      <div class="delete-title">
        <span> <img src="../../../../assets/images/ic-error.svg" /></span>
        <span class="del-title"> {{ 'common.unsaved-title' | translate }}</span>
      </div>
      <div class="delete-text">
        {{ 'message.unsaved-changes-template' | translate }}
      </div>
    </div>
  </div>

  <div class="modal-button-container delete-template">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn discardbtn deletebtn"
        mat-flat-button
        data-test="action_delete"
        (click)="discardChanges()"
      >
        {{ 'action.donot-save' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="main-delete-widget" *ngIf="modalType == 'delete-user'">
  <div class="delete-widget">
    <div class="delete-body user">
      <div class="delete-title">
        <mat-icon>delete</mat-icon>
        <span class="del-title">
          {{ 'message.delete-usertitle' | translate }}
          {{ userName }} ?</span
        >
      </div>
      <div class="delete-text">
        {{ 'message.delete-usermessage' | translate }}
      </div>
    </div>
  </div>
  <div class="modal-button-container delete-template">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        [disabled]="disablebtn"
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn deletebtn-user"
        mat-flat-button
        data-test="action_delete_user"
        [disabled]="disablebtn"
        (click)="deleteUser()"
      >
        {{ 'action.delete-user' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>

<div class="modal-container" *ngIf="modalType == 'datapoint-missing'">
  <div class="delete-widget discard-template">
    <div class="delete-body">
      <div class="delete-title dialog-top">
        <span>
          <img src="../../../../assets/images/status/warning-status.svg"
        /></span>
        <span class="del-title">
          {{ 'common.missing-widgets' | translate }}</span
        >
      </div>
      <div class="autoscroll">
        <div class="data-missing-text dialog-top">
          {{ 'message.missing-points' | translate }}
        </div>
        <div class="delete-text">
          {{ 'message.missing-pointscause' | translate }}
        </div>
        <div class="delete-text">
          {{ 'message.missing-pointscontinue' | translate }}
        </div>
        <div *ngFor="let loop of missingLoop">
          <ul class="list-container">
            <li class="data-missing-text">- {{ loop }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-button-container delete-template">
    <div>
      <button
        class="btn secondary-btn cancelbtn"
        mat-flat-button
        data-test="action_cancel"
        (click)="cancel()"
        type="button"
      >
        {{ 'action.cancel' | translate }}
      </button>
      <button
        class="btn primary-btn"
        mat-flat-button
        data-test="action_delete"
        (click)="continueChanges()"
      >
        {{ 'action.continue' | translate }}
      </button>
    </div>
  </div>
</div>
