import { CdkConnectedOverlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResolutionResolverService } from '../../services/resolution-resolver/resolution-resolver.service';

interface IRangeSelectDateTime {
  calendar: {
    minDate: Date;
    maxDate: Date;
  };
  range: {
    start: Date;
    end: Date;
  };
}

export interface ISelectedRange {
  start: Date;
  end: Date;
}

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent {
  @Input() displayDateFormat = 'DD/MM/YYYY HH:mm';
  @Input() clearBtn: boolean;
  @Input() tooltip: boolean;
  @Input() placeholder: string;
  @Input() positionUpdateBlockOnError: boolean;
  @Input() defaultTime: { start: string; end: string };
  @Output() selectedRange = new EventEmitter<ISelectedRange>();
  @Output() clearAction = new EventEmitter<any>();
  @ViewChild(CdkConnectedOverlay)
  readonly _cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  rangeSelected: IRangeSelectDateTime;
  startRange: Date;
  endRange: Date;
  headerComponent: any;
  isOpen = false;
  deviceType: string;
  scrollStrategy: any;
  isError: boolean;
  private nextActionName: 'start' | 'end' = 'start';

  @Input() set startRangeDate(value: Date) {
    if (!value) {
      this.startRange = undefined;
      this.rangeSelected.range.start = this.startRange;
      return;
    }
    this.startRange = moment(value).toDate();
    this.rangeSelected.range.start = this.startRange;
  }
  @Input() set endRangeDate(value: Date) {
    if (!value) {
      this.endRange = undefined;
      this.rangeSelected.range.end = this.endRange;
      return;
    }
    this.endRange = moment(value).toDate();
    this.rangeSelected.range.end = this.endRange;
  }
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private readonly sso: ScrollStrategyOptions,
    private _resolutionResolver: ResolutionResolverService
  ) {
    this.initCalendarObject();
    this.headerComponent = this.componentFactoryResolver.resolveComponentFactory(
      HeaderDatetimePickerComponent
    );
    this.scrollStrategy = this.sso.close();

    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }

  toggleOpen(): any {
    this.isError = false;
    this.isOpen = !this.isOpen;
    if (!this.isOpen) return;
    const start = this.rangeSelected.range.start;
    const end = this.rangeSelected.range.end;
    this.startRange = start;
    this.endRange = end;
  }

  updateDate(event: moment.MomentInput): any {
    //const inputDate = event;
    if (this.defaultTime) {
      event = moment(event).add();
    }
    this.isError = false;
    const startRange = this.startRange;
    const endRange = this.endRange;
    if (startRange && endRange) {
      this.predictNextAction(event);
    }
    const stageDate = this.nextActionName === 'start' ? this.startRange : this.endRange;
    const nextValue = this.addTime(moment(event).toDate(), stageDate);
    if (this.nextActionName === 'start') {
      this.startRange = nextValue;
    } else {
      this.endRange = nextValue;
    }
    if (this.nextActionName === 'start' && this.defaultTime?.start) {
      this.startTime(this.defaultTime.start);
    } else if (this.nextActionName === 'end' && this.defaultTime?.end) {
      this.endTime(this.defaultTime.end);
    }
    this.nextActionName = this.nextActionName === 'start' ? 'end' : 'start';
    if (this.calendar) this.calendar.updateTodaysDate();
  }

  startTime(event: string): any {
    this.isError = false;
    const split = event.split(':');
    const HH = +split[0];
    const MM = +split[1];
    const startTime = this.startRange;
    this.startRange = moment(startTime).set('hour', HH).set('minute', MM).toDate();
  }

  endTime(event: string): any {
    this.isError = false;
    const split = event.split(':');
    const HH = +split[0];
    const MM = +split[1];
    const endTime = this.endRange;
    this.endRange = moment(endTime).set('hour', HH).set('minute', MM).toDate();
  }

  initCalendarObject(): any {
    this.rangeSelected = {
      calendar: {
        minDate: moment().subtract(30, 'day').toDate(),
        maxDate: moment().toDate(),
      },
      range: {
        start: undefined,
        end: undefined,
      },
    };
  }

  updateCalendarObject(parentKey: string, childKey: string, value: Date): any {
    this.rangeSelected[parentKey][childKey] = value;
  }

  predictNextAction(newValue: moment.MomentInput): any {
    const startRange = this.startRange;
    const endRange = this.endRange;
    const isBeforeStartValue = moment(newValue).isBefore(startRange);
    const isAfterEndValue = moment(newValue).isAfter(endRange);
    if (this.nextActionName === 'end' && isBeforeStartValue) {
      this.nextActionName = 'start';
    } else if (this.nextActionName === 'start' && isAfterEndValue) {
      this.nextActionName = 'end';
    }
  }

  addTime(dateTime: any, value: Date) {
    const HH = moment(value).get('hour');
    const MM = moment(value).get('minute');
    return moment(dateTime).set('hour', HH).set('minute', MM).toDate();
  }

  dateClass(): any {
    if (this.rangeSelected) {
      return (date: Date): MatCalendarCellCssClasses => {
        const start = this.startRange;
        const end = this.endRange;
        if (start && moment(date).isSame(start, 'day')) {
          return 'start-range';
        } else if (end && moment(date).isSame(end, 'day')) {
          return 'end-range';
        } else if (start && end && moment(date).isBetween(start, end)) {
          return 'in-range';
        }
      };
    }
    return '';
  }

  applySelectedRange(): any {
    this.isError = false;
    if (!this.startRange || !this.endRange) return;
    if (moment(this.startRange).isSameOrAfter(this.endRange)) {
      this.isError = true;
      if (this.positionUpdateBlockOnError) {
        return;
      }
      this._cdkConnectedOverlay.overlayRef.updatePositionStrategy(
        this._cdkConnectedOverlay.positionStrategy
      );
      this._cdkConnectedOverlay.overlayRef.updatePosition();
      return;
    }
    this.updateCalendarObject('range', 'start', this.startRange);
    this.updateCalendarObject('range', 'end', this.endRange);
    this.isOpen = false;
    this.selectedRange.emit({
      start: this.startRange,
      end: this.endRange,
    });
  }

  cancelSelection(): any {
    this.startRange = undefined;
    this.endRange = undefined;
    this.isOpen = false;
  }

  clear() {
    this.clearAction.emit();
  }
}

@Component({
  selector: 'app-header-datetime-picker',
  styleUrls: ['./datetime-picker.component.scss'],
  template: `<div class="date-time-header" fxLayout="row">
    <div class="month" fxLayout="row">
      <mat-icon [attr.data-disabled]="previousEnabled" (click)="previousClicked()"
        >arrow_left</mat-icon
      >
      <p>{{ month }}</p>
      <mat-icon [attr.data-disabled]="nextEnabled" (click)="nextClicked()">arrow_right</mat-icon>
    </div>
    <div class="year">{{ year }}</div>
  </div>`,
})
export class HeaderDatetimePickerComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get month(): any {
    return moment(this._calendar.activeDate).format('MMMM').toUpperCase();
  }

  get year(): any {
    return moment(this._calendar.activeDate).format('YYYY');
  }

  get previousEnabled(): any {
    const activeMonth = moment(this._calendar.activeDate).month();
    const month = moment(this._calendar.minDate).month();
    if (activeMonth <= month) {
      return true;
    }
    return false;
  }

  get nextEnabled(): any {
    const activeMonth = moment(this._calendar.activeDate).month();
    const month = moment(this._calendar.maxDate).month();
    if (activeMonth >= month) {
      return true;
    }
    return false;
  }

  previousClicked(): any {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked(): any {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
