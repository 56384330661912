import { Component, OnInit } from '@angular/core';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';

@Component({
  selector: 'app-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.scss'],
})
export class DashboardTemplateComponent implements OnInit {
  deviceType: string;

  constructor(private _resolutionResolver: ResolutionResolverService) {}

  ngOnInit(): void {
    localStorage.setItem('flowType', 'template');
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }
}
