<div>
  <div class="addedituser-header">
    <img
      (click)="redirect()"
      class="backbtn"
      src="../../../assets/images/ic-keyboard-arrow-left.svg"
      [matTooltip]="'action.back' | translate"
      matTooltipClass="customtooltip"
    />
    <div *ngIf="pageType == 'editUser'" class="um-header-text">
      {{ "action.edit-user" | translate }}
    </div>
    <div *ngIf="pageType == 'addUser'" class="um-header-text">
      {{ "action.add-newuser" | translate }}
    </div>
  </div>
</div>
