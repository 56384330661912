import * as _ from 'lodash';

const dataSet = {
  get ceData() {
    return _.cloneDeep({
      unitOptions: [
        { name: 'A-Amp' },
        { name: '°C-Degrees Celsius' },
        { name: '°F-Degrees Fahrenheit' },
        { name: 'f-foot' },
        { name: 'Hrs-Hours' },
        { name: 'kPa-Kilopascal' },
        { name: 'kg-kilograms' },
        { name: 'lb-pound' },
        { name: 'm-meter' },
        { name: 'Min-Minutes' },
        { name: 'psi-Pounds per square inch' },
        { name: 'RPM-Revolutions per minute' },
        { name: 's-Seconds' },
        { name: 'V-Volts' },
      ],
      activeStates: [
        { name: 'check', view: 'Check' },
        { name: 'check_circle', view: 'Circle Check' },
        { name: 'thumb_up', view: 'Thumbs Up' },
        { name: 'thumb_down', view: 'Thumbs Down' },
        { name: 'close', view: 'Close' },
        { name: 'cancel', view: 'Cancel' },
        { name: 'unpublished', view: 'Unpublished' },
        { name: 'warning', view: 'Warning' },
        { name: 'bolt', view: 'Bolt' },
        { name: 'report', view: 'Report' },
        { name: 'notifications', view: 'Notifications' },
        { name: 'notifications_active', view: 'Notifications Active' },
        { name: 'sentiment_satisfied', view: 'Sentiment Satisfied' },
        { name: 'sentiment_dissatisfied', view: 'Sentiment Dissatisfied' },
        { name: 'power_settings_new', view: 'Power Settings' },
        { name: 'check_box', view: 'Check Box' },
        { name: 'check_box_outline_blank', view: 'Check Box Outline Blank' },
        { name: 'radio_button_checked', view: 'Radio Button Checked' },
        { name: 'radio_button_unchecked', view: 'Radio Button Unchecked' },
      ],
      inactiveStates: [
        { name: 'check', view: 'Check' },
        { name: 'check_circle', view: 'Circle Check' },
        { name: 'thumb_up', view: 'Thumbs Up' },
        { name: 'thumb_down', view: 'Thumbs Down' },
        { name: 'close', view: 'Close' },
        { name: 'cancel', view: 'Cancel' },
        { name: 'unpublished', view: 'Unpublished' },
        { name: 'warning', view: 'Warning' },
        { name: 'bolt', view: 'Bolt' },
        { name: 'report', view: 'Report' },
        { name: 'notifications', view: 'Notifications' },
        { name: 'notifications_active', view: 'Notifications Active' },
        { name: 'sentiment_satisfied', view: 'Sentiment Satisfied' },
        { name: 'sentiment_dissatisfied', view: 'Sentiment Dissatisfied' },
        { name: 'power_settings_new', view: 'Power Settings' },
        { name: 'check_box', view: 'Check Box' },
        { name: 'check_box_outline_blank', view: 'Check Box Outline Blank' },
        { name: 'radio_button_checked', view: 'Radio Button Checked' },
        { name: 'radio_button_unchecked', view: 'Radio Button Unchecked' },
      ],

      linechartDefaultData: {
        color: '#5ca794',
        dataPath: '',
        title: 'Widget title',
        type: 'line-chart',
        units: '',
        autoscale: true,
        colors: [],
        defaultTimeWindow: 'Month',
        min: '',
        max: '',
        colorgridOptions: [],
      },

      gridLine: {
        normal: '#009534',
        warning: '#ffd500',
        alarm: '#e60000',
        other: '#4d4d4d',
        default: '#5ca794',
        needleColor: '#404040',
      },

      widgetList: [
        { name: 'device_status', isActive: false },
        { name: 'binary', isActive: false },
        { name: 'text_kpi', isActive: false },
        { name: 'numeric_kpi', isActive: false },
        { name: 'gauge', isActive: false },
        { name: 'line_chart', isActive: false },
        { name: 'combination_chart', isActive: false },
        { name: 'blank_space', isActive: false },
      ],
    });
  },
};
export const ceData = dataSet.ceData;
