import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgControl, NgForm } from '@angular/forms';
import { _MatSelectBase } from '@angular/material/select';
import { Router } from '@angular/router';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import {
  ITemplateBtnConfig,
  ITemplateCardEvent,
} from 'src/app/shared/components/template-card/template-card.component';
import {
  ICloneDashboardTemplateRequest,
  IQueryRequest,
} from 'src/app/shared/interface/api_options';
import {
  IDashboardListTemplate,
  ITemplateListingSummary,
} from 'src/app/shared/interface/dashboard-template.interface';
import { IListingDeviceProfile } from 'src/app/shared/interface/device.interface';
import { LocalizationService } from 'src/app/shared/services/localization/localization.service';
import { CreateTemplateModel } from './create-template.model';
import { fakeTempData } from 'src/app/shared/constants/ce-templateData';
import { TemplateService } from 'src/app/service/template/template.service';
import { ToasterData } from 'src/app/shared/constants/constants';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss'],
})
export class CreateTemplateComponent implements OnInit {
  @ViewChild('createForm') createForm: NgForm;
  @ViewChild('modelName') modelNumberInputNgControl: NgControl;
  @ViewChild('minDeviceProfile') minDeviceProfile: _MatSelectBase<any>;
  createTemplate = new CreateTemplateModel('', '', '');
  listDashboardTemplate: IDashboardListTemplate;
  listOfDeviceProfile: IListingDeviceProfile;
  btnConfig: ITemplateBtnConfig[] = [
    {
      name: this.localizationService.translate('action.preview').toUpperCase(),
      icon: 'visibility',
      type: 'secondary',
    },
    {
      name: this.localizationService.translate('action.use-template').toUpperCase(),
      icon: '',
      type: 'primary',
    },
  ];

  listOfDeviceProfileVersions: string[];
  helpMinVersion: string;
  defaultQuerOption = {
    pageSize: 1000,
    published: {
      sort: 'desc',
    },
    publishDate: {
      sort: 'desc',
    },
  };
  removedWidget = false;
  constructor(
    private meshService: MeshServiceProvidor,
    private localizationService: LocalizationService,
    private router: Router,
    private _templateService: TemplateService,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    this.helpMinVersion = `<strong>${this.localizationService.translate(
      'help-text.template-compatibility'
    )}</strong><br/><br/>${this.localizationService.translate(
      'help-text.template-compatibility-desc'
    )}`;
    this.fetchDeviceProfileList();
    this.fetchDashboardTemplateList();
  }

  createDashboard(endpath = 'blanktemplate') {
    const data = this.createTemplate;

    const excludeKeys = [
      'dashboardTemplateId',
      'published',
      'id',
      'cloneData',
      'previewDataSource',
    ];
    if (
      Object.keys(data).every((k) => excludeKeys.includes(k) || (data[k] && data[k].length > 0))
    ) {
      sessionStorage.removeItem('tempData');
      sessionStorage.removeItem('tempInfo');
      sessionStorage.removeItem('dataPoints');
      sessionStorage.removeItem('defaultTimeFilter');
      if (this.removedWidget === false) {
        sessionStorage.removeItem('removedWidget');
      }

      this._templateService.tempData = fakeTempData;
      if (endpath === 'preview') {
        this._templateService.flow = 'preview';
      }
      this.router.navigate(['/', 'main', 'template', endpath], {
        queryParams: { ...data },
      });
    }
  }

  async fetchDashboardTemplateList() {
    try {
      this.listDashboardTemplate = await this.meshService.listDashboardTemplate(
        this.defaultQuerOption
      );
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  keyUpModelNumber(event) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return;
    }
    const searchTerm = (event.target as HTMLInputElement).value;
    this.fetchDeviceProfileList(this.queryCreator(searchTerm));
  }

  handleValue(event) {
    const inputValue = event.value || (event.target as HTMLInputElement).value;
    const isValidValue = this.listOfDeviceProfile.deviceProfiles.find(
      (x) => x.deviceProfileName.toLowerCase() === inputValue.toLowerCase()
    );
    if (isValidValue) {
      this.fetchDeviceProfileVersionList(inputValue);
    } else {
      this.modelNumberInputNgControl.reset();
      this.createForm.controls.modelName.markAsTouched();
      this.minDeviceProfile.writeValue(undefined);
    }
  }

  async fetchDeviceProfileList(query: IQueryRequest = null) {
    try {
      this.listOfDeviceProfile = await this.meshService.listDeviceProfile(query);
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  async fetchDeviceProfileVersionList(deviceProfileName: string) {
    try {
      this.listOfDeviceProfileVersions = await await this.meshService.listDeviceProfileVersions(
        deviceProfileName
      );
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  queryCreator(value: string): IQueryRequest {
    return {
      deviceProfileName: {
        filter: {
          $regex: [value, 'i'],
        },
      },
    };
  }

  cardEventManager(event: ITemplateCardEvent) {
    switch (event.eventName.toLowerCase()) {
      case 'use template':
        this.cloneDashboardTemplate(this.extraxtCloneOption(event.eventData));
        this.createTemplate.published = event.eventData.published;
        break;
      case 'preview':
        this.createTemplate.dashboardTemplateId = event.eventData.id;
        sessionStorage.setItem(
          'cloneData',
          JSON.stringify(this.extraxtCloneOption(event.eventData))
        );
        this.createDashboard('preview');
        break;
      default:
        void 0;
        break;
    }
  }

  async cloneDashboardTemplate(option: ICloneDashboardTemplateRequest) {
    let cloneData;
    try {
      cloneData = await this.meshService.cloneDashboardTemplate(option);
      this.createTemplate.dashboardTemplateId = cloneData.id;
      if (!!cloneData && cloneData.removedCount && cloneData.removedCount > 0) {
        this.removedWidget = true;
      } else {
        this.removedWidget = false;
      }

      sessionStorage.setItem('removedWidget', JSON.stringify(true));
      this.createDashboard('blanktemplate');
    } catch (error) {
      const data: ToasterData = {
        type: 'error-custom',
        message: error.toString(),
      };
      this.cs.openToaster(data);
    }
  }

  extraxtCloneOption(data: ITemplateListingSummary): ICloneDashboardTemplateRequest {
    return {
      sourceID: data.id,
      deviceProfileName: this.createTemplate.modelNumber,
      minimumVersion: this.createTemplate.minProfileVersion,
      dashboardConfig: data.dashboardConfig,
      name: this.createTemplate.templateName,
    };
  }
}
