<div class="blankwidget-container">
  <p class="message" data-test="text_blankspace">
    {{ "message.blank-space-widget" | translate }}
  </p>
  <mat-card class="blankspace-card">
    <p data-test="text_blankspacecontent">
      {{ "common.blank_space" | translate }}
    </p>
  </mat-card>
  <div class="button-container">
    <button
      class="btn secondary-btn"
      (click)="cancel()"
      mat-flat-button
      data-test="action_cancel"
      type="button"
    >
      {{ "action.cancel" | translate }}
    </button>
    <button
      *ngIf="type == 'edit'"
      class="btn secondary-btn"
      mat-flat-button
      data-test="action_delete"
      (click)="delete()"
      type="button"
    >
      {{ "action.delete" | translate }}
    </button>
    <button
      class="btn primary-btn"
      mat-flat-button
      data-test="action_save_widget"
      (click)="addWidget()"
    >
      {{ "action.save-widget" | translate }}
    </button>
  </div>
</div>
