import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ILogoDetail } from 'src/app/service/mesh/meshClientOptions';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { urls, userRoleConfig } from 'src/app/shared/constants/constants';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { FocusMonitor } from '@angular/cdk/a11y';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  userDetails;
  logoData: ILogoDetail = {
    logoPath: '',
    company: '',
  };
  logoSource: string;
  deviceType: string;
  userRole = localStorage.getItem('role');
  userRoleConfig = userRoleConfig;
  subscriber: Subscription[] = [];

  devicesPage: boolean;
  currentUrl: any;
  eventlogPage: boolean;
  constructor(
    private service: MeshServiceProvidor,
    private _resolutionResolver: ResolutionResolverService,
    private router: Router,
    private _focusMonitor: FocusMonitor
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = router.url;
        this.eventlogPage = router.url.includes('eventlog') ? true : false;
      }
    });
  }

  async ngOnInit() {
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
    this.service.getUserDetails();
    const sub1 = this.service.userDetails$.subscribe((userDetail) => {
      setTimeout(() => {
        this.userDetails = userDetail;
        if (this.logoData !== undefined) {
          this.logoData.logoPath = userDetail?.oemLogoURI;
        }
      }, 1000);
    });
    this.subscriber.push(sub0);
    this.subscriber.push(sub1);
    this.logoSource = 'https://' + this.service.fieldpopUrl + '/';

    this.devicesPage = this.router.url === '/main/template' ? false : true;
    this.eventlogPage = this.router.url.includes('eventlog') ? true : false;
  }
  expandMenu(): void {
    this.service.changeStatus('header');
  }
  openMenu(): any {
    this.menu.openMenu();
    this._focusMonitor.stopMonitoring(document.getElementById('userprofile'));
  }
  logout(): any {
    this.service.logout();
  }
  windowWrapper() {
    return window;
  }
  openLink(): any {
    this.windowWrapper().open((this.windowWrapper() as any).smcUrl);
  }

  goTo(page) {
    if (page === 'terms') window.open(urls.terms);
    else window.open(urls.privacy);
  }

  navigateToProfile(): any {
    this.router.navigate(['main', 'profile']);
  }
  navigateToChangePassword(): any {
    this.router.navigate(['main', 'change-password']);
  }

  ngOnDestroy(): void {
    this.service.clearStatus();
    this.subscriber.forEach((sub) => sub.unsubscribe());
  }
}
