import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { ILogoDetail } from 'src/app/service/mesh/meshClientOptions';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { urls, userRoleConfig } from 'src/app/shared/constants/constants';
import { DashEventService } from '../device-dashboard/services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  @HostListener('window:scroll', ['$event'])
  isExpanded: boolean;
  userRole: string = localStorage.getItem('role');
  userRoleConfig = userRoleConfig;
  logoSource: string;
  userDetails: any;
  logoData: ILogoDetail = {
    logoPath: '',
    company: '',
  };
  deviceType: string;
  currentUrl: any;
  sub: any;
  scrollTop = 0;
  hideNav = false;
  isEventPage: boolean;

  constructor(
    private service: MeshServiceProvidor,
    private _resolutionResolver: ResolutionResolverService,
    private router: Router,
    private _dashEventService: DashEventService
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = router.url;
        this.isEventPage = this.router.url.includes('eventlog') ? true : false;
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((ev) => {
      if (document.querySelector('.mat-sidenav-content')) {
        document.querySelector('.mat-sidenav-content').scrollTop = 0;
      }
    });

    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
      if (this.deviceType.includes('Mobile') || this.deviceType.includes('Tablet')) {
        this.service.changeStatus('sidebar', false);
      } else {
        this.service.changeStatus('sidebar', true);
      }
    });
    this.service.cast.subscribe((res) => {
      this.isExpanded = res;
      if (this.isExpanded === false) {
        if (
          !!this.sidenav &&
          !!this.deviceType &&
          (this.deviceType === 'MobilePortrait' || this.deviceType === 'MobileLandscape')
        ) {
          this.sidenav.close();
          this.usernameLogoDetails();
        }
      } else if (
        this.isExpanded === true &&
        !!this.sidenav &&
        !!this.deviceType &&
        (this.deviceType === 'MobilePortrait' || this.deviceType === 'MobileLandscape')
      ) {
        this.sidenav.open();
        this.usernameLogoDetails();
      }
    });
  }

  onScroll($event): any {
    if (this.scrollTop < $event.target.scrollTop && $event.target.scrollTop > 20) {
      this.hideNav = true;
    } else {
      this.hideNav = false;
    }
    this.scrollTop = $event.target.scrollTop;
    if (this.scrollTop === 0) {
      this.hideNav = false;
      window.scrollTo({
        top: 16,
        behavior: 'smooth',
      });
    }
    if (
      Math.round($event.target.scrollTop + $event.target.offsetHeight) ===
      Math.round($event.target.scrollHeight)
    ) {
      this._dashEventService.scrollEvent.emit();
    }
  }

  async usernameLogoDetails() {
    this.service.getUserDetails();
    this.service.userDetails$.subscribe((userDetail) => {
      setTimeout(() => {
        this.userDetails = userDetail;
        this.logoData.logoPath = userDetail?.oemLogoURI;
      }, 1000);
    });
    this.logoSource = 'https://' + this.service.fieldpopUrl + '/';
  }

  close(): any {
    this.isExpanded = false;
    this.service.changeStatus('sidebar', false);
  }

  closeMobile(): any {
    this.isExpanded = false;
    this.sidenav.close();
    this.service.changeStatus('sidebar', false);
  }

  goTo(page) {
    if (page === 'terms') {
      window.open(urls.terms);
    } else {
      window.open(urls.privacy);
    }
  }
  navigateTo(device, to): any {
    if (to === 'template') {
      this.router.navigate(['/main/template']);
    } else if (to === 'device') {
      this.router.navigate(['/main/device']);
    }
    if (this.deviceType.includes('Mobile')) {
      this.closeMobile();
    } else if (this.deviceType.includes('Tablet')) {
      this.isExpanded = false;
      this.service.changeStatus('sidebar', false);
    }
  }

  redirectTo(route: Array<string>) {
    this.router.navigate(route);
    this.isExpanded = false;
    this.service.changeStatus('sidebar', false);
  }

  logout(): any {
    this.isExpanded = false;
    this.sidenav.close();
    this.service.changeStatus('sidebar', false);
    this.service.logout();
  }
}
