<section class="device-dashboard-section">
  <div
    [ngClass]="{
      ipad: deviceAgent?.includes('iPad'),
      griddesktop:
        deviceType === 'WebLandscape' || deviceType === 'WebPortrait',
      gridcontainer:
        deviceType === 'TabletPortrait' ||
        deviceType === 'TabletLandscape' ||
        deviceType === 'MobileLandscape'
    }"
  >
    <div
      *ngIf="
        deviceType == 'WebLandscape' ||
        deviceType == 'WebPortrait' ||
        deviceType == 'TabletPortrait' ||
        deviceType == 'TabletLandscape' ||
        deviceType === 'MobileLandscape'
      "
    >
      <div class="section-sub-header">
        <div
          *ngIf="
            deviceType == 'WebLandscape' ||
            deviceType == 'WebPortrait' ||
            deviceType == 'TabletPortrait' ||
            deviceType == 'TabletLandscape'
          "
        >
          <app-subheader *ngIf="!templateDetails"></app-subheader>
          <app-ce-subheader
            *ngIf="this.templateDetails"
            [templateDetails]="templateDetails"
            [previewBtnName]="
              (templateDetails.id ? 'action.edit' : 'action.use-template')
                | translate
                | uppercase
            "
          ></app-ce-subheader>
        </div>
        <!-- <app-header></app-header> -->

        <mat-progress-bar
          *ngIf="!cards"
          class="progress-bar p-absolute-bottom"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
      <mat-accordion class="custom-expansion-panel" *ngIf="mode == 'preview'">
        <mat-expansion-panel hideToggle="false" [expanded]="!isSelectedData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "common.data-source" | translate }}</mat-panel-title
            >
            <mat-panel-description>
              <p class="title">{{ selectedDataSource?.deviceName }}</p>
              <p class="side-heading">
                {{ "common.change-source" | translate | uppercase }}
              </p>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-devices
            [renderedFrom]="'preview'"
            [previewDataSource]="previewDataSourceId"
            (selectedRow)="dataSelected($event)"
          ></app-devices>
        </mat-expansion-panel>
      </mat-accordion>
      <div
        class="grids-wrapper"
        [ngClass]="{
          ipad: deviceAgent?.includes('iPad')
        }"
        *ngIf="cards; else loading"
      >
        <mat-grid-list [cols]="gridCols" rowHeight="260">
          <mat-grid-tile
            *ngFor="let card of cards; let i = index"
            [colspan]="cards[i]['width']"
            [rowspan]="cards[i]['height']"
          >
            <cdk-drop-list>
              <mat-card
                [ngClass]="{
                  colorcard:
                    card.type == 'device-status' && deviceType.includes('Web'),
                  nostatus:
                    card.type !== 'device-status' &&
                    card.type !== 'space' &&
                    card.type !== 'device-info' &&
                    deviceType.includes('Web'),
                  spacecontainer:
                    card.type === 'space' ||
                    (card.type === 'device-info' && deviceType.includes('Web')),

                  noborder:
                    card.type == 'gauge' ||
                    (card.type === 'multiseries-chart' &&
                      deviceType.includes('Web')) ||
                    (card.type === 'line-chart' && deviceType.includes('Web')),
                  nocolorcard:
                    card.type == 'device-info' ||
                    (card.type == 'space' &&
                      (deviceType == 'MobileLandscape' ||
                        deviceType == 'MobilePortrait')),
                  colorcardmobile:
                    card.type == 'device-status' &&
                    (deviceType.includes('Tablet') ||
                      deviceType == 'MobileLandscape'),
                  nostatusmobile:
                    card.type !== 'device-status' &&
                    card.type !== 'space' &&
                    card.type !== 'device-info' &&
                    (deviceType.includes('Tablet') ||
                      deviceType.includes('MobileLandscape')),
                  deviceinfo:
                    card.type == 'device-info' &&
                    (deviceType.includes('Tablet') ||
                      deviceType == 'MobileLandscape'),
                  liveinteraction:
                    card.type === 'combination-chart' ||
                    card.type == 'line-chart' ||
                    (card.type === 'multiseries-chart' &&
                      (deviceType.includes('Tablet') ||
                        deviceType.includes('MobileLandscape'))),
                  noborder:
                    card.type === 'gasket' &&
                    (deviceType.includes('Tablet') ||
                      deviceType.includes('MobileLandscape')),
                  nobordermobile:
                    card.type == 'space' &&
                    (deviceType.includes('Tablet') ||
                      deviceType.includes('MobileLandscape')),
                  nocolorcard:
                    card.type == 'space' &&
                    (deviceType == 'TabletLandscape' ||
                      deviceType == 'TabletPortrait' ||
                      deviceType.includes('MobileLandscape'))
                }"
              >
                <app-device-details
                  style="width: 100%; border: none; padding: 15px"
                  *ngIf="card.type == 'device-info'"
                  [widget]="card"
                  [device]="deviceType"
                ></app-device-details>

                <app-device-status
                  [ngClass]="{ pad15: deviceType === 'MobileLandscape' }"
                  style="width: 100%"
                  [statusCardData]="statusCardData"
                  [widget]="card"
                  id="{{ card.title }}"
                  *ngIf="card.type == 'device-status'"
                  [device]="deviceType"
                  (scrolling)="scrollto($event)"
                  (color)="getColor($event)"
                ></app-device-status>

                <div
                  style="box-shadow: none"
                  *ngIf="card.type === 'space'"
                ></div>

                <app-device-power
                  style="width: 100%"
                  [widget]="card"
                  [type]="type"
                  *ngIf="card.type == 'binary'"
                  id="{{ card.title }}"
                  [powerData]="powerData"
                ></app-device-power>

                <app-device-cyclecount
                  style="width: 100%"
                  *ngIf="card.type == 'text-kpi'"
                  [cycleCountData]="cycleCountData"
                  [widget]="card"
                  id="{{ card.title }}"
                ></app-device-cyclecount>

                <app-device-totalhours
                  style="width: 100%"
                  *ngIf="card.type == 'numerical-kpi'"
                  [widget]="card"
                  [hoursData]="hoursData"
                  id="{{ card.title }}"
                ></app-device-totalhours>

                <div
                  class="full-width"
                  *ngIf="card.type == 'combination-chart'"
                >
                  <!--DESKTOP GAUGE CHART-->
                  <div
                    *ngIf="
                      deviceType == 'WebLandscape' ||
                      deviceType == 'WebPortrait'
                    "
                    class="border gauge-card"
                    fxFlex="32%"
                  >
                    <div class="gauge-container">
                      <div fxLayout="column" class="gauge-content">
                        <app-gauge-chart
                          (timeStamp)="getTime($event)"
                          [device]="deviceType"
                          [widget]="card"
                          style="height: 100%"
                          [id]="i"
                        ></app-gauge-chart>
                        <span id="{{ card.title }}"></span>
                      </div>
                    </div>
                  </div>
                  <!--END DESKTOP GAUGE CHART-->

                  <!--MOBILE TABLET GAUGE CHART-->
                  <div
                    *ngIf="
                      deviceType.includes('Tablet') ||
                      deviceType.includes('MobileLandscape')
                    "
                    class="border gauge-card"
                    fxFlex="50%"
                  >
                    <div class="gauge-container-tablet">
                      <div fxLayout="column" class="gauge-content">
                        <app-gauge-chart
                          (timeStamp)="getTime($event)"
                          [device]="deviceType"
                          [widget]="card"
                          style="height: 100%"
                          [id]="i"
                        ></app-gauge-chart>
                        <span id="{{ card.title }}"></span>
                      </div>
                    </div>
                  </div>
                  <!--END MOBILE TABLET GAUGE CHART-->

                  <!--DESKTOP COMBO HIST CHART-->
                  <div
                    fxFlex="67%"
                    class="border historical-container"
                    *ngIf="
                      deviceType == 'WebLandscape' ||
                      deviceType == 'WebPortrait'
                    "
                  >
                    <div class="widget" fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>
                        <div class="toolbar-show-hide">
                          <app-hist-chart-toolbar
                            [id]="'1' + i + '_' + id"
                          ></app-hist-chart-toolbar>
                          <img
                            class="expand-histchart"
                            src="../../../assets/images/ic-expand.svg"
                            alt="expand"
                            (click)="
                              openDialog(deviceId, card, '1' + i + '_' + id, 'line-chart')
                            "
                            [matTooltip]="'Expand'"
                            matTooltipClass="customtooltip"
                          />
                        </div>
                      </div>

                      <div
                        fxLayoutGap="20px"
                        fxLayout="row"
                        fxLayout.md="column"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'1' + i + '_' + id"
                        ></app-date-controller>
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('1' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('1' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                      </div>

                      <app-historical-chart
                        style="width: 100%"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [deviceId]="deviceId"
                        [widget]="card"
                        [id]="'1' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-historical-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'1' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                    </div>
                  </div>
                  <!--END DESKTOP COMBO HIST CHART-->

                  <!--TABLET PORTRAIT COMBO HIST CHART-->
                  <div
                    fxFlex="50%"
                    class="border historical-container"
                    *ngIf="
                      deviceType == 'TabletPortrait' ||
                      deviceType == 'MobilePortrait' ||
                      deviceType == 'MobileLandscape' ||
                      deviceType == 'TabletLandscape'
                    "
                  >
                    <div class="widget" fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>
                        <div class="toolbar-show">
                          <img
                            class="expand-histchart"
                            src="../../../assets/images/ic-expand.svg"
                            alt="expand"
                            (click)="
                              openDialog(deviceId, card, '1' + i + '_' + id, 'line-chart')
                            "
                            [matTooltip]="'Expand'"
                            matTooltipClass="customtooltip"
                          />
                        </div>
                      </div>

                      <div
                        fxLayoutGap="10px"
                        fxLayout="row"
                        fxLayoutAlign="space-around"
                        [class]="
                          'temporal ' +
                          deviceType?.toLowerCase() +
                          ' ' +
                          card.type
                        "
                      >
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('1' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('1' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>

                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [id]="'1' + i + '_' + id"
                          [isDialog]="false"
                        ></app-date-controller>
                      </div>

                      <div class="toolbar-show">
                        <app-hist-chart-toolbar
                          [id]="'1' + i + '_' + id"
                        ></app-hist-chart-toolbar>
                      </div>

                      <app-historical-chart
                        style="width: 100%"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [deviceId]="deviceId"
                        [widget]="card"
                        [id]="'1' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-historical-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'1' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                    </div>
                  </div>

                  <!--END TABLET PORTRAIT COMBO HIST CHART-->
                </div>

                <div
                  [ngClass]="{ 'mat-elevation-z8': deviceType.includes('Web') }"
                  class="gauge-container-tablet border"
                  *ngIf="card.type == 'gauge'"
                  fxLayout="column"
                >
                  <div
                    class="item{{ i }}"
                    fxLayout="column"
                    class="gauge-content"
                  >
                    <app-gauge-chart
                      style="height: 100%"
                      (timeStamp)="getTime($event)"
                      [device]="deviceType"
                      [widget]="card"
                      [id]="i"
                    ></app-gauge-chart>
                    <span id="{{ card.title }}"></span>
                  </div>
                </div>

                <div
                  *ngIf="
                    card.type == 'line-chart' &&
                    (deviceType == 'TabletPortrait' ||
                      deviceType == 'MobilePortrait' ||
                      deviceType == 'MobileLandscape' ||
                      deviceType == 'TabletLandscape')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>

                        <img
                          *ngIf="
                            deviceType.includes('Tablet') ||
                            deviceType.includes('Web')
                          "
                          class="expand-histchart"
                          src="../../../assets/images/ic-expand.svg"
                          alt="expand"
                          (click)="
                            openDialog(
                              deviceId,
                              card,
                              '2' + i + '_' + id,
                              card.type
                            )
                          "
                          [matTooltip]="'Expand'"
                          matTooltipClass="customtooltip"
                        />
                      </div>

                      <div
                        fxLayout="row wrap"
                        fxLayoutGap="10px"
                        fxLayout.sm="1px"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                      </div>
                      <app-hist-chart-toolbar
                        [id]="'2' + i + '_' + id"
                      ></app-hist-chart-toolbar>
                      <app-historical-chart
                        [deviceId]="deviceId"
                        [chartType]="card.type"
                        [device]="deviceType"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-historical-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
                <!--MULTISERIES CHART MOBILE AND TABLET-->
                <div
                  *ngIf="
                    card.type == 'multiseries-chart' &&
                    (deviceType == 'TabletPortrait' ||
                      deviceType == 'MobilePortrait' ||
                      deviceType == 'MobileLandscape' ||
                      deviceType == 'TabletLandscape')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>

                        <img
                          *ngIf="
                            deviceType.includes('Tablet') ||
                            deviceType.includes('Web')
                          "
                          class="expand-histchart"
                          src="../../../assets/images/ic-expand.svg"
                          alt="expand"
                          (click)="
                            openDialog(
                              deviceId,
                              card,
                              '2' + i + '_' + id,
                              card.type
                            )
                          "
                          [matTooltip]="'Expand'"
                          matTooltipClass="customtooltip"
                        />
                      </div>

                      <div
                        fxLayout="row wrap"
                        fxLayoutGap="10px"
                        fxLayout.sm="1px"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                      </div>
                      <app-hist-chart-toolbar
                        [id]="'2' + i + '_' + id"
                      ></app-hist-chart-toolbar>
                      <app-multiseries-chart
                        [deviceId]="deviceId"
                        [chartType]="card.type"
                        [device]="deviceType"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-multiseries-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
                <!--END MULTISERIES CHART MOBILE AND TABLET-->

                <!--MULTISERIES CHART DESKTOP-->
                <div
                  *ngIf="
                    card.type == 'multiseries-chart' &&
                    (deviceType == 'WebLandscape' ||
                      deviceType == 'WebPortrait')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>
                        <div class="toolbar-show-hide">
                          <app-hist-chart-toolbar
                            [id]="'2' + i + '_' + id"
                          ></app-hist-chart-toolbar>

                          <img
                            class="expand-histchart"
                            src="../../../assets/images/ic-expand.svg"
                            alt="expand"
                            (click)="
                              openDialog(
                                deviceId,
                                card,
                                '2' + i + '_' + id,
                                card.type
                              )
                            "
                            [matTooltip]="'Expand'"
                            matTooltipClass="customtooltip"
                          />
                        </div>
                      </div>

                      <div
                        fxLayout="row"
                        fxLayoutGap="20px"
                        fxLayout.lt-md="column"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                      </div>
                      <app-multiseries-chart
                        [deviceId]="deviceId"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-multiseries-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
                <!--END MULTISERIES CHART DESKTOP-->

                <div
                  *ngIf="
                    card.type == 'line-chart' &&
                    (deviceType == 'WebLandscape' ||
                      deviceType == 'WebPortrait')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>
                        <div class="toolbar-show-hide">
                          <app-hist-chart-toolbar
                            [id]="'2' + i + '_' + id"
                          ></app-hist-chart-toolbar>

                          <img
                            class="expand-histchart"
                            src="../../../assets/images/ic-expand.svg"
                            alt="expand"
                            (click)="
                              openDialog(
                                deviceId,
                                card,
                                '2' + i + '_' + id,
                                card.type
                              )
                            "
                            [matTooltip]="'Expand'"
                            matTooltipClass="customtooltip"
                          />
                        </div>
                      </div>

                      <div
                        fxLayout="row"
                        fxLayoutGap="20px"
                        fxLayout.lt-md="column"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                      </div>
                      <app-historical-chart
                        [deviceId]="deviceId"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-historical-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
              </mat-card>
            </cdk-drop-list>
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <ng-template #loading>
        <div
          class="loading-text"
          *ngIf="mode != 'preview' || (mode == 'preview' && selectedDataSource)"
        >
          {{ "message.loading" | translate }}
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="deviceType == 'MobilePortrait'">
    <div class="top-align-mobile">
      <mat-progress-bar
        *ngIf="!cards"
        class="progress-bar p-absolute-top-64"
        mode="indeterminate"
      ></mat-progress-bar>

      <!--DASHBOARD STARTS MOBILE-->
      <div
        [ngClass]="{
          gridcontainer: deviceType === 'MobilePortrait'
        }"
        *ngIf="cards; else loading"
      >
        <mat-grid-list [cols]="3" [gutterSize]="'15px'" rowHeight="1:2">
          <mat-grid-tile
            class="mat-grid-tile-mobile"
            *ngFor="let card of cards; let i = index"
            [colspan]="cards[i]['width']"
            [rowspan]="cards[i]['height']"
          >
            <cdk-drop-list
              [ngClass]="{
                cdkDetails: card.type == 'device-info',
                'scroll-auto': card.type == 'device-info',
                cdkNormal: card.type !== 'device-info'
              }"
            >
              <mat-card
                [ngClass]="{
                  colorcardmobile: card.type == 'device-status',
                  nostatusmobile:
                    card.type !== 'device-status' &&
                    card.type !== 'space' &&
                    card.type !== 'device-info',
                  liveinteraction:
                    card.type === 'combination-chart' ||
                    card.type == 'line-chart' ||
                    card.type === 'multiseries-chart',
                  noborder: card.type === 'device-info',
                  nobordermobile: card.type == 'space',
                  nocolorcard:
                    card.type == 'space' &&
                    (deviceType == 'MobileLandscape' ||
                      deviceType == 'MobilePortrait')
                }"
              >
                <app-device-details
                  class="full-width"
                  style="border: none"
                  *ngIf="card.type == 'device-info'"
                  [device]="deviceType"
                  [widget]="card"
                ></app-device-details>

                <app-device-status
                  class="full-width"
                  [statusCardData]="statusCardData"
                  [widget]="card"
                  id="{{ card.title }}"
                  *ngIf="card.type == 'device-status'"
                  [device]="deviceType"
                  (scrolling)="scrollto($event)"
                  (color)="getColor($event)"
                ></app-device-status>

                <div *ngIf="card.type === 'space'"></div>

                <app-device-power
                  class="full-width"
                  [widget]="card"
                  *ngIf="card.type == 'binary'"
                  id="{{ card.title }}"
                  [powerData]="powerData"
                ></app-device-power>

                <app-device-cyclecount
                  class="full-width"
                  *ngIf="card.type == 'text-kpi'"
                  [cycleCountData]="cycleCountData"
                  [widget]="card"
                  id="{{ card.title }}"
                ></app-device-cyclecount>

                <app-device-totalhours
                  class="full-width"
                  *ngIf="card.type == 'numerical-kpi'"
                  [widget]="card"
                  [hoursData]="hoursData"
                  id="{{ card.title }}"
                ></app-device-totalhours>

                <div
                  class="full-width gauge-width"
                  id="myDIV"
                  *ngIf="card.type == 'combination-chart'"
                >
                  <!--EXPERIMNTAL TAB CODE-->
                  <div class="tab">
                    <button
                      id="gaugeLive{{ i }}"
                      class="tablinks active"
                      (click)="selectTab($event, 'Live', i)"
                    >
                      Live
                    </button>
                    <button
                      id="historicalLive{{ i }}"
                      class="tablinks"
                      (click)="selectTab($event, 'Historical', i)"
                    >
                      Historical
                    </button>
                  </div>

                  <div id="Live{{ i }}" class="tabcontent">
                    <!--START MOBILE COMBO GAUGE-->
                    <div
                      class="border gauge-card-combo-mobile"
                      fxFlex="100%"
                      style="height: 100%; overflow: hidden"
                    >
                      <div class="gauge-container-tablet">
                        <div fxLayout="column" class="gauge-content">
                          <app-gauge-chart
                            (timeStamp)="getTime($event)"
                            [device]="deviceType"
                            [widget]="card"
                            [id]="i"
                          ></app-gauge-chart>
                          <span id="{{ card.title }}"></span>
                        </div>
                      </div>
                    </div>
                    <!--END MOBILE COMBO GAUGE-->
                  </div>

                  <div id="Historical{{ i }}" class="tabcontent">
                    <div
                      id="histoCombo"
                      class="border historical-container"
                      *ngIf="
                        deviceType == 'TabletPortrait' ||
                        deviceType == 'MobilePortrait' ||
                        deviceType == 'MobileLandscape' ||
                        deviceType == 'TabletLandscape'
                      "
                    >
                      <div class="widget histo-width" fxLayout="column">
                        <div
                          class="histchart-header1"
                          fxLayout="row"
                          fxLayoutAlign="space-between"
                        >
                          <div class="charttitle">{{ card.title }}</div>
                          <div class="toolbar-show">
                            <!-- <img
                            class="expand-histchart"
                            src="../../../assets/images/ic-expand.svg"
                            alt="expand"
                            (click)="
                              openDialog(deviceId, card, '1' + i + '_' + id)
                            "
                          /> -->
                          </div>
                        </div>

                        <div
                          fxLayoutGap="20px"
                          fxLayout="row"
                          [class]="'temporal ' + deviceType?.toLowerCase()"
                        >
                          <app-datetime-picker
                            [startRangeDate]="
                              get_minDateTime('1' + i + '_' + id)
                            "
                            [endRangeDate]="get_maxDateTime('1' + i + '_' + id)"
                            (selectedRange)="updateChartDate($event)"
                          ></app-datetime-picker>

                          <app-date-controller
                            [device]="deviceType"
                            [type]="card.type"
                            [id]="'1' + i + '_' + id"
                            [isDialog]="false"
                          ></app-date-controller>
                        </div>

                        <div class="toolbar-show">
                          <app-hist-chart-toolbar
                            [id]="'1' + i + '_' + id"
                          ></app-hist-chart-toolbar>
                        </div>
                        <span id="{{ card.title }}"></span>
                        <app-historical-chart
                          class="full-width"
                          [device]="deviceType"
                          [chartType]="card.type"
                          [deviceId]="deviceId"
                          [widget]="card"
                          [id]="'1' + i + '_' + id"
                          (statusEvent)="widgetEventHandler($event)"
                          [isDialogView]="false"
                        ></app-historical-chart>

                        <mat-progress-bar
                          class="progress-bar below"
                          mode="indeterminate"
                          [id]="'1' + i + '_' + id"
                          style="display: none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                  </div>
                  <!--END CODE-->
                </div>

                <div
                  class="gauge-container-tablet border"
                  *ngIf="card.type == 'gauge'"
                  fxLayout="column"
                >
                  <div
                    class="item{{ i }}"
                    fxLayout="column"
                    class="gauge-content"
                  >
                    <app-gauge-chart
                      style="height: 100%"
                      (timeStamp)="getTime($event)"
                      [device]="deviceType"
                      [widget]="card"
                      [id]="i"
                    ></app-gauge-chart>
                    <span id="{{ card.title }}"></span>
                  </div>
                </div>

                <div
                  *ngIf="
                    card.type == 'line-chart' &&
                    (deviceType == 'MobilePortrait' ||
                      deviceType == 'MobileLandscape')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>

                        <!-- <img
                          class="expand-histchart"
                          src="../../../assets/images/ic-expand.svg"
                          alt="expand"
                          (click)="
                            openDialog(
                              deviceId,
                              card,
                              '2' + i + '_' + id,
                              card.type
                            )
                          "
                        /> -->
                      </div>

                      <div
                        fxLayout="row"
                        fxLayoutGap="10px"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                      </div>
                      <app-hist-chart-toolbar
                        [id]="'2' + i + '_' + id"
                      ></app-hist-chart-toolbar>
                      <app-historical-chart
                        [deviceId]="deviceId"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-historical-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
                <!--MOBILE MULTISERIES CHART-->
                <div
                  *ngIf="
                    card.type == 'multiseries-chart' &&
                    (deviceType == 'MobilePortrait' ||
                      deviceType == 'MobileLandscape')
                  "
                  class="widget full-width"
                >
                  <div fxFlex="100%" class="pad-15 border">
                    <div fxLayout="column">
                      <div
                        class="histchart-header"
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                      >
                        <div class="charttitle">{{ card.title }}</div>

                        <!-- <img
                          class="expand-histchart"
                          src="../../../assets/images/ic-expand.svg"
                          alt="expand"
                          (click)="
                            openDialog(
                              deviceId,
                              card,
                              '2' + i + '_' + id,
                              card.type
                            )
                          "
                        /> -->
                      </div>

                      <div
                        fxLayout="row"
                        fxLayoutGap="10px"
                        [class]="'temporal ' + deviceType?.toLowerCase()"
                      >
                        <app-datetime-picker
                          [startRangeDate]="get_minDateTime('2' + i + '_' + id)"
                          [endRangeDate]="get_maxDateTime('2' + i + '_' + id)"
                          (selectedRange)="updateChartDate($event)"
                        ></app-datetime-picker>
                        <app-date-controller
                          [device]="deviceType"
                          [type]="card.type"
                          [isDialog]="false"
                          [id]="'2' + i + '_' + id"
                        ></app-date-controller>
                      </div>
                      <app-hist-chart-toolbar
                        [id]="'2' + i + '_' + id"
                      ></app-hist-chart-toolbar>
                      <app-multiseries-chart
                        [deviceId]="deviceId"
                        [device]="deviceType"
                        [chartType]="card.type"
                        [widget]="card"
                        [id]="'2' + i + '_' + id"
                        (statusEvent)="widgetEventHandler($event)"
                        [isDialogView]="false"
                      ></app-multiseries-chart>
                      <mat-progress-bar
                        [class]="
                          deviceType.toLocaleLowerCase() + ' progress-bar below'
                        "
                        mode="indeterminate"
                        [id]="'2' + i + '_' + id"
                        style="display: none"
                      ></mat-progress-bar>
                      <span id="{{ card.title }}"></span>
                    </div>
                  </div>
                </div>
                <!--END MOBILE MULTISERIES CHART-->
              </mat-card>
            </cdk-drop-list>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <!--MOBILE DASHBOARD ENDS-->

      <ng-template #loading>
        <div class="loading-text">
          {{ "message.loading" | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</section>
