<div fxLayout="row" class="datadesign-preview-line">
  <div fxFlex="38%" class="data-design-line">
    <form [formGroup]="linechartForm">
      <mat-tab-group
        disablePagination="true"
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabClickedChanged($event)"
        [ngClass]="{
          'error-data-tab': dataTabError
        }"
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <div>
              <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center">
                <div class="data-align">DATA</div>
                <div class="tab-icon-align" *ngIf="dataTabError">
                  <mat-icon
                    [ngStyle]="{ color: '#e60000' }"
                    class="example-tab-icon"
                    >cancel</mat-icon
                  >
                </div>
              </div>
            </div>
          </ng-template>
          <div formGroupName="data" class="datatab">
            <!-- =============================== Data point ====================== -->

            <div class="left-margin">
              <div class="dropdown-field">
                <div>
                  <mat-label class="linechartLabel">{{
                    "common.data-point" | translate
                  }}</mat-label>
                </div>

                <div>
                  <mat-select
                    [compareWith]="comparedatapointObjects"
                    [ngClass]="{
                      'datapoint-error':
                        !linechartForm.valid &&
                        submitted &&
                        linechartForm.controls['data']['controls']['dataPath']
                          ?.errors?.required
                    }"
                    class="datapointinpt"
                    formControlName="dataPath"
                    placeholder="{{
                      'placeholder_messages.datapoint' | translate
                    }}"
                  >
                    <mat-option
                      *ngFor="let data of dataset"
                      [value]="data.path"
                    >
                      {{ data.path }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="
                  !linechartForm.valid &&
                  submitted &&
                  linechartForm.controls['data']['controls']['dataPath']?.errors
                    ?.required
                "
              >
                <span>*</span>{{ "message.valid-data-point" | translate }}
              </div>
            </div>

            <!-- =============================== Units ====================== -->

            <div class="top-margin">
              <div>
                <label class="linechartLabel">{{
                  "common.unit" | translate
                }}</label>
              </div>

              <div class="example-full-width-line top-margin auto-main">
                <div fxFlex="100%" fxLayout="row">
                  <input
                    class="auto-input auto-inp-pos"
                    matInput
                    placeholder="{{ 'placeholder_messages.unit' | translate }}"
                    aria-label="State"
                    [matAutocomplete]="auto"
                    formControlName="units"
                  />
                  <mat-icon
                    class="status-dropdown auto-icon-pos"
                    [ngStyle]="{ color: '#0000008a' }"
                    >arrow_drop_down</mat-icon
                  >
                </div>
                <mat-autocomplete
                  class="auto-container-pos"
                  #auto="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let unit of filteredUnits | async"
                    [value]="unit.name"
                  >
                    <span>{{ unit.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>

            <!-- =============================== Autoscale ====================== -->

            <div class="form-group autoscalemargin">
              <mat-checkbox
                id="autoScale"
                [checked]="dataObject.autoscale"
                formControlName="autoScale"
                class="autoScale"
                >{{ "common.autoscale" | translate }}
              </mat-checkbox>
            </div>
            <!--=========================== Min ======================================= -->

            <div
              *ngIf="dataObject.autoscale == false"
              class="textbox-align top-margin left-margin main-top-margin"
              class="form-group"
              [ngClass]="
                dataObject.autoscale == false ? 'showminmax' : 'hideminmax'
              "
            >
              <label class="linechartLabel">{{
                "common.minvalue" | translate
              }}</label>
              <span class="top-margin"
                ><input
                  [ngClass]="{
                    'minmax-error':
                      !linechartForm.valid &&
                      submitted &&
                      linechartForm.controls['data']['controls']['min']?.errors
                        ?.required
                  }"
                  class="inp-field"
                  matInput
                  formControlName="min"
                  placeholder="{{
                    'placeholder_messages.minvalue' | translate
                  }}"
                  type="number"
                />
                <div class="invalid-feedback" *ngIf="minmaxeror">
                  <span>*</span>{{ "message.minmaxerror" | translate }}
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    !linechartForm.valid &&
                    submitted &&
                    linechartForm.controls['data']['controls']['min']?.errors
                      ?.required
                  "
                >
                  <span>*</span>{{ "message.valid-min" | translate }}
                </div>
              </span>
            </div>
            <!-- =============================== Max ====================== -->
            <div
              *ngIf="dataObject.autoscale == false"
              class="textbox-align top-margin left-margin main-top-margin"
              class="form-group"
              [ngClass]="
                dataObject.autoscale == false ? 'showminmax' : 'hideminmax'
              "
            >
              <label class="linechartLabel">{{
                "common.maxvalue" | translate
              }}</label>
              <span class="top-margin"
                ><input
                  [ngClass]="{
                    'minmax-error':
                      !linechartForm.valid &&
                      submitted &&
                      linechartForm.controls['data']['controls']['max']?.errors
                        ?.required
                  }"
                  class="inp-field"
                  matInput
                  formControlName="max"
                  placeholder="{{
                    'placeholder_messages.maxvalue' | translate
                  }}"
                  type="number"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    !linechartForm.valid &&
                    submitted &&
                    linechartForm.controls['data']['controls']['max']?.errors
                      ?.required
                  "
                >
                  <span>*</span>{{ "message.valid-max" | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="minmaxeror">
                  <span>*</span>{{ "message.minmaxerror" | translate }}
                </div>
              </span>
            </div>
            <!-- =============================== Default filter ====================== -->

            <div class="defaultfiltertext">
              {{ "common.defaulttime" | translate }}
            </div>
            <div class="defaultfiltertext-msg">
              {{ "message.datefilter" | translate }}
            </div>
            <div class="button-cont">
              <button
                class="today"
                type="button"
                value="today"
                [ngClass]="
                  dataObject.defaultTimeWindow !== 'Today'
                    ? 'inactive'
                    : 'active'
                "
                (click)="setDefaultTime('Today')"
                mat-button
              >
                {{ "temporal.today" | translate }}
              </button>
              <button
                type="button"
                class="week"
                value="week"
                [ngClass]="
                  dataObject.defaultTimeWindow !== 'Week'
                    ? 'inactive'
                    : 'active'
                "
                (click)="setDefaultTime('Week')"
                mat-button
              >
                {{ "temporal.week" | translate }}
              </button>
              <button
                type="button"
                class="month"
                value="Month"
                [ngClass]="
                  dataObject.defaultTimeWindow !== 'Month'
                    ? 'inactive'
                    : 'active'
                "
                (click)="setDefaultTime('Month')"
                mat-button
              >
                {{ "temporal.month" | translate }}
              </button>
            </div>
            <!-- ======================================End of buttons============================ -->
          </div>
        </mat-tab>
        <mat-tab label="DESIGN">
          <div formGroupName="design" class="designtab">
            <!-- ============================graph line color=========================== -->
            <div fxLayout="row" class="graphlinecolor">
              <div fxFlex="60%">
                <mat-label>{{ "common.graphlinecolor" | translate }}</mat-label>
              </div>
              <div fxFlex="40%" class="form-group">
                <input
                  type="color"
                  class="colorline"
                  value="{{ dataObject.color }}"
                  formControlName="color"
                />
              </div>
            </div>
            <!-- ============================Custom grid messages=========================== -->
            <div class="customgridline-container">
              <mat-label class="customgridline">{{
                "message.custromgrid_title" | translate
              }}</mat-label>
              <mat-label class="customgridmsg">{{
                "message.customgrid" | translate
              }}</mat-label>
            </div>
            <!-- ===================custom grid lines===================================== -->
            <div *ngIf="gridLines == true">
              <div *ngFor="let gridline of colorgridOptions; let i = index">
                <div fxFlex="100%" fxLayout="row" class="gridline">
                  <div fxFlex="60%">
                    <mat-checkbox
                      class="example-margin"
                      [checked]="gridline?.checked"
                      (change)="checkboxGridline($event, i)"
                      >{{ gridline.message | titlecase }}</mat-checkbox
                    >
                  </div>
                  <div fxFlex="40%">
                    <input
                      type="color"
                      class="colorline"
                      value="{{ gridline.color }}"
                      (change)="setgridLineColor($event, i)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <mat-label class="customgridmsg" *ngIf="gridLines == false">
              {{ "message.noGrid" | translate }}
            </mat-label>
            <!-- =========================END================================== -->
          </div>
        </mat-tab>
      </mat-tab-group>
      <!-- ======================Cancel --delete -- save ======================= -->
      <div class="button-container-line">
        <button
          class="btn secondary-btn cancel"
          mat-flat-button
          data-test="action_cancel"
          (click)="cancel()"
          type="button"
        >
          {{ "action.cancel" | translate }}
        </button>
        <button
          *ngIf="type == 'edit'"
          class="btn secondary-btn delete"
          mat-flat-button
          data-test="action_delete"
          (click)="delete()"
          type="button"
        >
          {{ "action.delete" | translate }}
        </button>
        <button
          type="submit"
          class="btn primary-btn save"
          mat-flat-button
          data-test="action_save_widget"
          (click)="saveWidget()"
        >
          {{ "action.save-widget" | translate }}
        </button>
      </div>
      <!-- ======================Cancel --delete -- save ======================= -->
    </form>
  </div>
  <div fxFlex="62%" class="preview-line">
    <div
      [ngClass]="titleUpdated == true ? 'newTtle' : 'titlePlaceholder'"
      matTooltipClass="tooltip-hover"
    >
      {{ dataObject.title }}
    </div>
    <div fxLayout="row wrap" class="datecontrol-linechart">
      <div class="datecontrol-linechart">
        <button class="today" type="button" value="today" mat-button>
          {{ "temporal.today" | translate }}
        </button>
        <button type="button" class="week" value="week" mat-button>
          {{ "temporal.week" | translate }}
        </button>
        <button type="button" class="month" value="month" mat-button>
          {{ "temporal.month" | translate }}
        </button>
      </div>

      <div class="customdateimg">
        <button type="button" class="" value="week" mat-button>
          mm/dd/yy hh:mm - mm/dd/yy hh:mm <mat-icon> event </mat-icon>
        </button>
      </div>
    </div>
    <div class="zoompanmarker-control">
      <mat-checkbox class="showMarkerCheck"
        >{{ "action.show-markers" | translate }}
      </mat-checkbox>

      <img
        class="histchart-toolbar"
        src="../../../assets/images/ic-keyboard-arrow-left.svg"
      />
      <img
        class="histchart-toolbar"
        src="../../../assets/images/ic-keyboard-arrow-right.svg"
      />

      <img
        class="histchart-toolbar"
        src="../../../assets/images/ic-zoom-out.svg"
      />

      <img
        class="histchart-toolbar"
        src="../../../assets/images/ic-zoom-in.svg"
      />
    </div>
    <div *ngIf="chart != undefined">
      <div id="chartcontainerapexpreview">
        <apx-chart
          #charttt
          [series]="series"
          [chart]="chart"
          [xaxis]="xaxis"
          [tooltip]="tooltip"
          [stroke]="stroke"
          [markers]="markers"
          [annotations]="annotations"
          [yaxis]="yaxis"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
