<div class="filterSection" fxLayout="row" fxLayoutAlign="space-between">
  <div class="searchInput" fxLayout="row" fxFlex="80">
    <input
      #mobileDeviceName
      placeholder="Search by device name..."
      type="text"
      name="deviceName"
      fxFlex="90"
      id="deviceName"
      [value]="value"
      (keyup)="searchText($event)"
      autocomplete="off"
      data-test="input_devicename"
    />
    <span fxFlex="10"
      ><img
        *ngIf="!mobileDeviceName.value"
        src="../../../assets/images/ic-search.svg"
        data-test="media_search"
      />
      <img
        (click)="_updateFilter(mobileDeviceName.id, '')"
        *ngIf="mobileDeviceName.value"
        src="../../../assets/images/ic-close.svg"
        data-test="media_close"
      />
    </span>
  </div>
  <span
    fxFlexAlign="center"
    fxFlex="20"
    data-test="text_filter"
    (click)="_openFilter()"
  >
    <img
      class="slider"
      data-test="media_filter"
      src="../../../assets/images/solid-sliders-h.svg"
  /></span>
</div>
