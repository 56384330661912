<!--TEMPLATES DUMMY PAGE-->
<div
  [ngClass]="{
    griddesktop: deviceType === 'WebLandscape' || deviceType === 'WebPortrait'
  }"
>
  <app-ce-subheader
    (savePublishTemplateData)="savePublishAllDetails($event)"
    [templateDetails]="templateDetails"
    [editStatus]="templateDetails?.id ? editStatus : false"
    [templateStatus]="templateDetails?.id ? templateStatus : false"
    (deleteTemplate)="deleteTemplate()"
    [unSavedChanges]="unSavechanges"
    [saveClicked]="saveClicked"
    [previewBtnName]="
      (templateDetails?.id ? 'action.edit' : 'action.use-template') | translate | uppercase
    "
  ></app-ce-subheader>
  <div class="banner" [ngClass]="removedWidget == true ? 'banner' : 'hideBanner'">
    <img class="failedicon" src="../../../assets/images/failed.svg" />
    <span class="banner-text">{{ 'message.missingWidgetBanner' | translate }} </span>

    <img class="banner-close" (click)="closebanner()" src="../../../assets/images/ic-close.svg" />
  </div>

  <div
    class="add-edit-widget"
    [ngClass]="{
      gridcontainer:
        deviceType === 'TabletPortrait' ||
        deviceType === 'TabletLandscape' ||
        deviceType === 'MobileLandscape'
    }"
    *ngIf="cards"
  >
    <mat-grid-list [cols]="3" rowHeight="260">
      <mat-grid-tile
        *ngFor="let card of cards; let i = index"
        [colspan]="cards[i]['width']"
        [rowspan]="cards[i]['height']"
      >
        <cdk-drop-list
          [cdkDropListConnectedTo]="drops"
          [cdkDropListData]="i"
          [ngClass]="{
            cdkDetails: card.type == 'device-info' && deviceType.includes('MobileLandscape')
          }"
        >
          <mat-card
            cdkDrag
            (cdkDragEntered)="entered($event)"
            [cdkDragData]="i"
            [ngClass]="{
              colorcard: card.type == 'device-status',
              nostatus:
                card.type !== 'device-status' &&
                card.type !== 'space' &&
                card.type !== 'device-info',
              noborder:
                (card.type == 'space' &&
                  (deviceType == 'WebLandscape' ||
                    deviceType == 'WebPortrait' ||
                    deviceType == 'TabletLandscape' ||
                    deviceType == 'TabletPortrait')) ||
                card.type == 'gauge' ||
                card.type === 'line-chart' ||
                card.type == 'multiseries-chart',
              nocolorcard: deviceType == 'MobileLandscape' || deviceType == 'MobilePortrait',
              spacecard: card.type == 'space',
              deviceinfocard: card.type == 'device-info'
            }"
          >
            <app-device-details
              style="width: 100%; border: none; padding: 15px"
              *ngIf="card.type == 'device-info'"
              [widget]="card"
              [type]="type"
              [templateDetails]="templateDetails"
              [device]="deviceType"
            >
            </app-device-details>

            <app-device-status
              style="width: 100%"
              [type]="type"
              [statusCardData]="statusCardData"
              [widget]="card"
              id="{{ card.title }}"
              *ngIf="card.type == 'device-status'"
              [device]="deviceType"
              (scrolling)="scrollto($event)"
              (color)="getColor($event)"
            ></app-device-status>

            <div *ngIf="card.type === 'space'" class="spacewidget">
              <div class="editicon-space">
                <span
                  class="edit-posn cursor"
                  *ngIf="type == 'template'"
                  (click)="edit(card.type, card)"
                >
                  <img src="../../../../assets/images/ic-edit.svg" class="edit-img"
                /></span>
              </div>
              <div class="blank_space_text">
                <p>{{ 'common.blank_space' | translate }}</p>
              </div>
            </div>

            <app-device-power
              style="width: 100%"
              [widget]="card"
              [type]="type"
              *ngIf="card.type == 'binary'"
              id="{{ card.title }}"
              [powerData]="powerData"
            ></app-device-power>

            <app-device-cyclecount
              style="width: 100%"
              [type]="type"
              *ngIf="card.type == 'text-kpi'"
              [cycleCountData]="cycleCountData"
              [widget]="card"
              id="{{ card.title }}"
            ></app-device-cyclecount>

            <app-device-totalhours
              style="width: 100%"
              [type]="type"
              *ngIf="card.type == 'numerical-kpi'"
              [widget]="card"
              [hoursData]="hoursData"
              id="{{ card.title }}"
            ></app-device-totalhours>

            <div
              class="gauge-container mat-elevation-z8 border"
              *ngIf="card.type == 'gauge'"
              fxLayout="column"
            >
              <div class="item{{ i }}" fxLayout="column" class="gauge-content">
                <app-gauge-chart
                  style="height: 100%"
                  (timeStamp)="getTime($event)"
                  [device]="deviceType"
                  [widget]="card"
                  [id]="i"
                  [type]="type"
                ></app-gauge-chart>
                <span id="{{ card.title }}"></span>
              </div>
            </div>

            <div class="full-width" *ngIf="card.type == 'combination-chart'">
              <div
                *ngIf="deviceType == 'WebLandscape' || deviceType == 'WebPortrait'"
                class="border gauge-card"
                fxFlex="32%"
              >
                <div class="gauge-container">
                  <div fxLayout="column" class="gauge-content">
                    <app-gauge-chart
                      (timeStamp)="getTime($event)"
                      [device]="deviceType"
                      [widget]="card"
                      [type]="type"
                      style="height: 100%"
                      [id]="i"
                      [type]="type"
                    ></app-gauge-chart>
                    <span id="{{ card.title }}"></span>
                  </div>
                </div>
              </div>

              <div
                *ngIf="deviceType.includes('Tablet') || deviceType.includes('MobileLandscape')"
                class="border gauge-card"
                fxFlex="50%"
              >
                <div class="gauge-container">
                  <div fxLayout="column" class="gauge-content">
                    <app-gauge-chart
                      (timeStamp)="getTime($event)"
                      [device]="deviceType"
                      [type]="type"
                      [widget]="card"
                      style="height: 100%"
                      [id]="i"
                      [type]="type"
                    ></app-gauge-chart>
                    <span id="{{ card.title }}"></span>
                  </div>
                </div>
              </div>

              <div
                fxFlex="67%"
                class="border historical-container"
                *ngIf="deviceType == 'WebLandscape' || deviceType == 'WebPortrait'"
              >
                <div class="widget" fxLayout="column">
                  <div class="histchart-header" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="charttitle">{{ card.title }}</div>
                    <div class="linecharttoolbar">
                      <div class="zoompanmarker-control">
                        <mat-checkbox class="showMarkerCheck"
                          >{{ 'action.show-markers' | translate }}
                        </mat-checkbox>

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-left.svg"
                        />
                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-right.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-out.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-in.svg"
                        />
                      </div>
                      <span
                        class="edit-posn cursor"
                        *ngIf="type == 'template'"
                        (click)="edit(card.type, card)"
                      >
                        <img src="../../../../assets/images/ic-edit.svg" class="edit-img"
                      /></span>
                    </div>
                  </div>

                  <div fxLayout="row wrap" class="datecontrol-linechart">
                    <div class="datecontrol-linechart">
                      <button class="today" type="button" value="today" mat-button>
                        {{ 'temporal.today' | translate }}
                      </button>
                      <button type="button" class="week" value="week" mat-button>
                        {{ 'temporal.week' | translate }}
                      </button>
                      <button type="button" class="month" value="month" mat-button>
                        {{ 'temporal.month' | translate }}
                      </button>
                    </div>

                    <div class="customdateimg">
                      <button type="button" class="" value="week" mat-button>
                        mm/dd/yy hh:mm - mm/dd/yy hh:mm
                        <mat-icon> event </mat-icon>
                      </button>
                    </div>
                  </div>

                  <app-historical-chart
                    style="width: 100%"
                    [device]="deviceType"
                    [chartType]="card.type"
                    [type]="type"
                    [deviceId]="deviceId"
                    [widget]="card"
                    [id]="'1' + i + '_' + id"
                    (statusEvent)="widgetEventHandler($event)"
                    [isDialogView]="false"
                  ></app-historical-chart>
                  <mat-progress-bar
                    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
                    mode="indeterminate"
                    [id]="'1' + i + '_' + id"
                    style="display: none"
                  ></mat-progress-bar>
                </div>
              </div>

              <mat-card
                fxFlex="50%"
                class="mat-elevation-z8 border historical-container"
                *ngIf="
                  deviceType == 'TabletPortrait' ||
                  deviceType == 'MobilePortrait' ||
                  deviceType == 'MobileLandscape' ||
                  deviceType == 'TabletLandscape'
                "
                style="overflow: scroll"
              >
                <div class="widget" fxLayout="column">
                  <div class="histchart-header" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="charttitle">{{ card.title }}</div>
                    <div class="linecharttoolbar">
                      <div class="zoompanmarker-control">
                        <mat-checkbox class="showMarkerCheck"
                          >{{ 'action.show-markers' | translate }}
                        </mat-checkbox>

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-left.svg"
                        />
                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-right.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-out.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-in.svg"
                        />
                      </div>
                      <span
                        class="edit-posn cursor"
                        *ngIf="type == 'template'"
                        (click)="edit(card.type, card)"
                      >
                        <img src="../../../../assets/images/ic-edit.svg" class="edit-img"
                      /></span>
                    </div>
                  </div>

                  <div fxLayout="row wrap" class="datecontrol-linechart">
                    <div class="datecontrol-linechart">
                      <button class="today" type="button" value="today" mat-button>
                        {{ 'temporal.today' | translate }}
                      </button>
                      <button type="button" class="week" value="week" mat-button>
                        {{ 'temporal.week' | translate }}
                      </button>
                      <button type="button" class="month" value="month" mat-button>
                        {{ 'temporal.month' | translate }}
                      </button>
                    </div>

                    <div class="customdateimg">
                      <button type="button" class="" value="week" mat-button>
                        mm/dd/yy hh:mm - mm/dd/yy hh:mm
                        <mat-icon> event </mat-icon>
                      </button>
                    </div>
                  </div>

                  <app-historical-chart
                    style="width: 100%"
                    [device]="deviceType"
                    [chartType]="card.type"
                    [type]="type"
                    [deviceId]="deviceId"
                    [widget]="card"
                    [id]="'1' + i + '_' + id"
                    (statusEvent)="widgetEventHandler($event)"
                    [isDialogView]="false"
                  ></app-historical-chart>
                  <mat-progress-bar
                    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
                    mode="indeterminate"
                    [id]="'1' + i + '_' + id"
                    style="display: none"
                  ></mat-progress-bar>
                </div>
              </mat-card>
            </div>

            <div
              *ngIf="
                card.type == 'line-chart' &&
                (deviceType == 'TabletPortrait' ||
                  deviceType == 'MobilePortrait' ||
                  deviceType == 'MobileLandscape' ||
                  deviceType == 'TabletLandscape')
              "
              class="widget full-width"
            >
              <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                <div fxLayout="column">
                  <div class="histchart-header" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="charttitle">{{ card.title }}</div>

                    <img
                      *ngIf="deviceType.includes('Tablet') || deviceType.includes('Web')"
                      class="expand-histchart"
                      src="../../../assets/images/ic-expand.svg"
                      alt="expand"
                      (click)="openDialog(deviceId, card, '2' + i + '_' + id, card.type)"
                      [matTooltip]="'Expand'"
                      matTooltipClass="customtooltip"
                    />
                  </div>

                  <div fxLayout="row wrap" class="datecontrol-linechart">
                    <div class="datecontrol-linechart">
                      <button class="today" type="button" value="today" mat-button>
                        {{ 'temporal.today' | translate }}
                      </button>
                      <button type="button" class="week" value="week" mat-button>
                        {{ 'temporal.week' | translate }}
                      </button>
                      <button type="button" class="month" value="month" mat-button>
                        {{ 'temporal.month' | translate }}
                      </button>
                    </div>

                    <div class="customdateimg">
                      <button type="button" class="" value="week" mat-button>
                        mm/dd/yy hh:mm - mm/dd/yy hh:mm
                        <mat-icon> event </mat-icon>
                      </button>
                    </div>
                  </div>
                  <app-historical-chart
                    [deviceId]="deviceId"
                    [chartType]="card.type"
                    [type]="type"
                    [device]="deviceType"
                    [widget]="card"
                    [id]="'2' + i + '_' + id"
                    (statusEvent)="widgetEventHandler($event)"
                    [isDialogView]="false"
                  ></app-historical-chart>
                  <mat-progress-bar
                    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
                    mode="indeterminate"
                    [id]="'2' + i + '_' + id"
                    style="display: none"
                  ></mat-progress-bar>
                  <span id="{{ card.title }}"></span>
                </div>
              </div>
            </div>
            <!--DESKTOP MULTI SERIES CHART-->
            <div
              *ngIf="
                card.type == 'multiseries-chart' &&
                (deviceType == 'WebLandscape' || deviceType == 'WebPortrait')
              "
              class="widget full-width"
            >
              <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                <div fxLayout="column">
                  <div class="histchart-header" fxLayout="row" fxLayoutAlign="space-between">
                    <div
                      #title
                      class="charttitle truncate"
                      appTooltip
                      [toolTipTitle]="handleChange && title.offsetWidth < title.scrollWidth ? card.title : ''"
                      [positionTip]="{top: 25, left: 0}"
                      customClass="nowrap"
                    >
                      {{ card.title }}
                    </div>
                    <div class="linecharttoolbar">
                      <div class="zoompanmarker-control">
                        <mat-checkbox class="showMarkerCheck"
                          >{{ 'action.show-markers' | translate }}
                        </mat-checkbox>

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-left.svg"
                        />
                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-right.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-out.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-in.svg"
                        />
                      </div>
                      <span
                        class="edit-posn cursor"
                        *ngIf="type == 'template'"
                        (click)="edit(card.type, card)"
                      >
                        <img src="../../../../assets/images/ic-edit.svg" class="edit-img"
                      /></span>
                    </div>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayoutGap="20px"
                    fxLayout.lt-md="column"
                    [class]="'temporal ' + deviceType?.toLowerCase()"
                  >
                    <div fxLayout="row wrap" class="datecontrol-linechart">
                      <div class="datecontrol-linechart">
                        <button class="today" type="button" value="today" mat-button>
                          {{ 'temporal.today' | translate }}
                        </button>
                        <button type="button" class="week" value="week" mat-button>
                          {{ 'temporal.week' | translate }}
                        </button>
                        <button type="button" class="month" value="month" mat-button>
                          {{ 'temporal.month' | translate }}
                        </button>
                      </div>

                      <div class="customdateimg">
                        <button type="button" class="" value="week" mat-button>
                          mm/dd/yy hh:mm - mm/dd/yy hh:mm
                          <mat-icon> event </mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <app-multiseries-chart
                    [deviceId]="deviceId"
                    [device]="deviceType"
                    [chartType]="card.type"
                    [type]="type"
                    [widget]="card"
                    [id]="'2' + i + '_' + id"
                    (statusEvent)="widgetEventHandler($event)"
                    [isDialogView]="false"
                  ></app-multiseries-chart>
                  <mat-progress-bar
                    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
                    mode="indeterminate"
                    [id]="'2' + i + '_' + id"
                    style="display: none"
                  ></mat-progress-bar>
                  <span id="{{ card.title }}"></span>
                </div>
              </div>
            </div>
            <!-- END MULTI SERIES CHART-->

            <div
              *ngIf="
                card.type == 'line-chart' &&
                (deviceType == 'WebLandscape' || deviceType == 'WebPortrait')
              "
              class="widget full-width"
            >
              <div fxFlex="100%" class="pad-15 mat-elevation-z8 border">
                <div fxLayout="column">
                  <div class="histchart-header" fxLayout="row" fxLayoutAlign="space-between">
                    <div class="charttitle truncate">
                      {{ card.title }}
                    </div>
                    <div class="linecharttoolbar">
                      <div class="zoompanmarker-control">
                        <mat-checkbox class="showMarkerCheck"
                          >{{ 'action.show-markers' | translate }}
                        </mat-checkbox>

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-left.svg"
                        />
                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-keyboard-arrow-right.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-out.svg"
                        />

                        <img
                          class="histchart-toolbar"
                          src="../../../assets/images/ic-zoom-in.svg"
                        />
                      </div>
                      <span
                        class="edit-posn cursor"
                        *ngIf="type == 'template'"
                        (click)="edit(card.type, card)"
                      >
                        <img src="../../../../assets/images/ic-edit.svg" class="edit-img"
                      /></span>
                    </div>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayoutGap="20px"
                    fxLayout.lt-md="column"
                    [class]="'temporal ' + deviceType?.toLowerCase()"
                  >
                    <div fxLayout="row wrap" class="datecontrol-linechart">
                      <div class="datecontrol-linechart">
                        <button class="today" type="button" value="today" mat-button>
                          {{ 'temporal.today' | translate }}
                        </button>
                        <button type="button" class="week" value="week" mat-button>
                          {{ 'temporal.week' | translate }}
                        </button>
                        <button type="button" class="month" value="month" mat-button>
                          {{ 'temporal.month' | translate }}
                        </button>
                      </div>

                      <div class="customdateimg">
                        <button type="button" class="" value="week" mat-button>
                          mm/dd/yy hh:mm - mm/dd/yy hh:mm
                          <mat-icon> event </mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <app-historical-chart
                    [deviceId]="deviceId"
                    [device]="deviceType"
                    [chartType]="card.type"
                    [type]="type"
                    [widget]="card"
                    [id]="'2' + i + '_' + id"
                    (statusEvent)="widgetEventHandler($event)"
                    [isDialogView]="false"
                  ></app-historical-chart>
                  <mat-progress-bar
                    [class]="deviceType.toLocaleLowerCase() + ' progress-bar below'"
                    mode="indeterminate"
                    [id]="'2' + i + '_' + id"
                    style="display: none"
                  ></mat-progress-bar>
                  <span id="{{ card.title }}"></span>
                </div>
              </div>
            </div>
          </mat-card>
        </cdk-drop-list>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
