import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { ISelectedRange } from 'src/app/shared/components/datetime-picker/datetime-picker.component';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { ISelectedDate, IWidgetEvent } from '../historical-chart/historical-chart.component';
import { DashEventService } from '../services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-histchart-dialog',
  templateUrl: './histchart-dialog.component.html',
  styleUrls: ['./histchart-dialog.component.scss'],
})
export class HistchartDialogComponent implements OnInit, OnDestroy {
  showLoader = false;
  selectedDatesRange: ISelectedDate = {};
  id: string;
  startRange: number;
  endRange: number;
  subscription: Subscription[] = [];
  deviceType: string;
  cardType: any;
  constructor(
    public dialogRef: MatDialogRef<HistchartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _dashEventService: DashEventService,
    private _resolutionResolver: ResolutionResolverService
  ) {
    this.id = this.data.id;
    this.cardType = this.data.type;
    const sub = this._dashEventService
      .get_historicalGraphState(this.id)
      .subscribe((responseData) => {
        this.startRange = responseData.minDateTime;
        this.endRange = responseData.maxDateTime;
      });
    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
    this.subscription.push(sub0);

    this.subscription.push(sub);
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  toggleLoader(event: IWidgetEvent): void {
    const id = event.id;
    this.showLoader = !(event.status === 'completed');
  }

  updateChartDate(event: ISelectedRange) {
    const minDate = moment(event.start).valueOf();
    const maxDate = moment(event.end).valueOf();
    const temp = this._dashEventService.createHistoricalGraphState(minDate, maxDate, 'custom');
    this._dashEventService.setAll_historicalGraphState(temp);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
