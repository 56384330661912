import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() fileUpdated = new EventEmitter<any>();
  @Output() fileValidation = new EventEmitter<any>();
  readonly allowedExtension = ['image/jpeg', 'image/jpg', 'image/svg+xml', 'image/png'];
  constructor() {}

  ngOnInit(): void {}
  fileBrowseHandler(files) {
    if (!this.isFileAccepted(files, 1)) {
      return;
    }
    this.fileUpdated.emit(files);
  }

  onFileDropped($event) {
    if (!this.isFileAccepted($event, 1)) {
      return;
    }
    this.fileUpdated.emit($event);
  }

  isFileAccepted(files, allowedFile) {
    if (files.length <= allowedFile && this.allowedtype(files) && this.fileSizeValidation(files)) {
      return true;
    } else {
      return false;
    }
  }

  fileSizeValidation(files) {
    const fileSize = files[0].size / 1024 / 1024;
    if (parseFloat(fileSize.toFixed(2)) > 1) {
      this.fileValidation.emit({ type: 'size', value: false });
      return false;
    } else {
      this.fileValidation.emit({ type: 'size', value: true });
      return true;
    }
  }

  allowedtype(files) {
    if (this.allowedExtension.includes(files[0].type)) {
      this.fileValidation.emit({ type: 'fileType', value: true });
      return true;
    } else {
      this.fileValidation.emit({ type: 'fileType', value: false });
    }
  }
}
