<section class="line-chart-selection" fxLayout="row" fxLayoutGap="15px">
  <div class="single-y-axis" fxFlex="50%">
    <div class="selection-header" fxLayout="row">
      <div class="left-content" fxFlex="auto">
        <h3 class="header">{{ "common.single-yaxis" | translate }}</h3>
        <p class="description">{{ "common.single-yaxis-text" | translate }}</p>
      </div>
      <div class="right-action">
        <button
          class="btn primary-btn"
          (click)="onGraphTypeSelected('singleYAxis')"
        >
          Select
        </button>
      </div>
    </div>
    <div class="selection-content">
      <img
        src="../../../../../assets/images/widgets/single-y-axis-example.svg"
        alt="single-y-axis-example"
        width="100%"
      />
    </div>
  </div>

  <div class="dual-y-axis" fxFlex="50%">
    <div class="selection-header" fxLayout="row">
      <div class="left-content" fxFlex="auto">
        <h3 class="header">{{ "common.dual-yaxis" | translate }}</h3>
        <p class="description">{{ "common.dual-yaxis-text" | translate }}</p>
      </div>
      <div class="right-action">
        <button
          class="btn primary-btn"
          (click)="onGraphTypeSelected('dualYAxis')"
        >
          Select
        </button>
      </div>
    </div>
    <div class="selection-content">
      <img
        src="../../../../../assets/images/widgets/dual-y-axis-example.svg"
        alt="dual-y-axis-example"
        width="100%"
      />
    </div>
  </div>
</section>
