<div class="user-listing-container">
  <div class="header">
    <h1 data-test="text_devices_title">
      {{ "common.userlisting-title" | translate }}
    </h1>
    <div>
      <button
        data-test="action_reset_filter"
        class="btn secondary-btn"
        mat-flat-button
        (click)="resetTable()"
      >
        <mat-icon> refresh </mat-icon>
        {{ "action.reset-filter" | translate | uppercase }}
      </button>
      <button
        (click)="addUser()"
        data-test="action_add_user"
        class="btn primary-btn"
        mat-flat-button
      >
        <mat-icon> add </mat-icon>
        {{ "action.add-newuser" | translate | uppercase }}
      </button>
    </div>
  </div>

  <app-devices [renderedFrom]="'users'"></app-devices>
</div>
