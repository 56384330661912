<div
  class="histtoolbar"
  [ngClass]="
    deviceType == 'MobilePortrait' || deviceType == 'MobileLandscape'
      ? 'histtoolbar-mobile'
      : 'histtoolbar'
  "
>
  <mat-checkbox
    *ngIf="deviceType == 'WebPortrait' || deviceType == 'WebLandscape'"
    [checked]="showMarkerVal"
    name="show-marker"
    class="showMarkerCheck"
    (change)="showMarkers($event)"
    data-test="action_markers"
    >{{ "action.show-markers" | translate }}
  </mat-checkbox>
  <mat-checkbox
    *ngIf="
      deviceType == 'MobilePortrait' ||
      deviceType == 'MobileLandscape' ||
      deviceType == 'TabletPortrait' ||
      deviceType == 'TabletLandscape'
    "
    [checked]="showMarkerVal"
    name="show-marker"
    class="showMarkerCheck"
    (change)="showMarkers($event)"
    data-test="action_markers"
    >{{ "action.markers" | translate }}
  </mat-checkbox>

  <img
    id="panLeft"
    class="histchart-toolbar"
    [ngClass]="allowPanLeft == true ? 'enable' : 'disable'"
    src="../../../assets/images/ic-keyboard-arrow-left.svg"
    alt="pan left"
    (click)="panChart('left')"
    [matTooltip]="'Pan left'"
    matTooltipClass="customtooltip"
    data-test="action_panleft"
  />
  <img
    id="panRight"
    [ngClass]="allowPanRight == true ? 'enable' : 'disable'"
    class="histchart-toolbar"
    src="../../../assets/images/ic-keyboard-arrow-right.svg"
    alt="pan right"
    (click)="panChart('right')"
    [matTooltip]="'Pan right'"
    matTooltipClass="customtooltip"
    data-test="action_panright"
  />

  <img
    id="zoomOut"
    [ngClass]="allowZoomOut == true ? 'enable' : 'disable'"
    class="histchart-toolbar"
    src="../../../assets/images/ic-zoom-out.svg"
    alt="zoom out"
    (click)="zoomChart('zoomOut')"
    [matTooltip]="'Zoom out'"
    matTooltipClass="customtooltip"
    data-test="action_zoomout"
  />

  <img
    id="zoomIn"
    [ngClass]="allowZoomIn == true ? 'enable' : 'disable'"
    class="histchart-toolbar"
    src="../../../assets/images/ic-zoom-in.svg"
    alt="zoom in"
    (click)="zoomChart('zoomIn')"
    [matTooltip]="'Zoom in'"
    matTooltipClass="customtooltip"
    data-test="action_zoomin"
  />
</div>
