import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { IHistoricalGraphState, ITemporal } from 'src/app/shared/interface/_base';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { DashEventService } from '../services/dashboard-event/dash-event.service';

@Component({
  selector: 'app-hist-chart-toolbar',
  templateUrl: './hist-chart-toolbar.component.html',
  styleUrls: ['./hist-chart-toolbar.component.scss'],
})
export class HistChartToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string;
  minDate: any;
  maxDate: any;
  allowZoomIn: boolean;
  allowZoomOut: boolean;
  allowPanLeft: boolean;
  upperLimit: number;
  lowerLimit: number;
  allowPanRight: boolean;
  dateObj: IHistoricalGraphState = undefined;

  showMarkerVal = false;
  type: ITemporal;
  markervalue: any;
  deviceType: any;
  private subscription: Subscription[] = [];
  constructor(
    private _dashEventService: DashEventService,
    private cdk: ChangeDetectorRef,
    private _resolutionResolver: ResolutionResolverService
  ) {}

  ngOnInit(): void {
    this.lowerLimit = moment(moment().subtract(30, 'days')).startOf('day').valueOf();

    this.upperLimit = moment().valueOf();
    const sub = this._dashEventService.get_historicalGraphState(this.id).subscribe((p) => {
      if (!!p) {
        this.type = p.type;
        this.dateObj = p;
        this.minDate = this.dateObj ? this.dateObj.minDateTime : 0;
        this.maxDate = this.dateObj ? this.dateObj.maxDateTime : 0;

        const lowerLimit = moment(moment().subtract(30, 'days')).startOf('day').valueOf();

        const upperLimit = moment().valueOf();

        this.disableEnableButtons(this.minDate, this.maxDate);
      }
    });

    this._dashEventService.getMarker(this.id).subscribe((data) => {
      this.markervalue = data;
      if (this.markervalue !== this.showMarkerVal) {
        this.showMarkerVal = data;
      }
    });

    const markerInit = this._dashEventService.isMarkerInit(this.id);

    if (markerInit) {
      this._dashEventService.setMarker(this.id, this.showMarkerVal);
    }

    const sub0 = this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });

    this.subscription.push(sub);
  }

  disableEnableButtons(min, max) {
    const daysDiff = (max - min) / (1000 * 60 * 60 * 24);
    const secDiff = moment(max).diff(moment(min), 'seconds');
    if (secDiff <= 10 && min !== max) {
      this.allowZoomIn = false;
      this.allowZoomOut = true;
      this.allowPanLeft = true;
      this.allowPanRight = true;
    } else if (daysDiff >= 30) {
      this.allowZoomIn = true;
      this.allowZoomOut = false;
      this.allowPanLeft = false;
      this.allowPanRight = true;
    } else if (min <= this.lowerLimit) {
      this.allowPanLeft = false;
      this.allowZoomIn = true;
      this.allowZoomOut = true;
      this.allowPanRight = true;
    } else if (max === this.upperLimit) {
      this.allowPanLeft = true;
      this.allowZoomIn = true;
      this.allowZoomOut = true;
      this.allowPanRight = true;
    } else if (
      secDiff > 10 &&
      daysDiff < 30 &&
      min !== max &&
      min !== this.lowerLimit &&
      max !== this.upperLimit
    ) {
      this.allowPanLeft = true;
      this.allowZoomIn = true;
      this.allowZoomOut = true;
      this.allowPanRight = true;
    }
  }

  panChart(type) {
    if (type === 'left') {
      let newMin = this.minDate - (this.maxDate - this.minDate);
      let newMax = this.minDate;
      const diff = newMax - newMin;
      if (newMin < this.lowerLimit) {
        newMin = this.lowerLimit;

        newMax = newMin + diff;
      }
      if (newMin !== newMax) {
        this.minDate = newMin;
        this.maxDate = newMax;

        this.disableEnableButtons(this.minDate, this.maxDate);
        this.updateChartDate(moment(newMin).toDate(), moment(newMax).toDate());
      } else {
        this.allowPanLeft = false;
      }
    }

    if (type === 'right') {
      let newMin = this.maxDate;
      let newMax = this.maxDate + (this.maxDate - this.minDate);
      const diff = newMax - newMin;
      if (newMax > this.upperLimit) {
        newMax = moment().valueOf();
        newMin = newMax - diff;
      }
      if (
        newMin !== newMax &&
        moment(newMax).diff(moment(newMin), 'seconds') > 5 &&
        newMax <= moment().valueOf()
      ) {
        this.minDate = newMin;
        this.maxDate = newMax;

        this.disableEnableButtons(this.minDate, this.maxDate);
        this.updateChartDate(moment(newMin).toDate(), moment(newMax).toDate());
      } else {
      }
    }
  }

  zoomChart(type) {
    if (type === 'zoomOut') {
      const centerX = (this.minDate + this.maxDate) / 2;

      let newMin = this.minDate - (centerX - this.minDate);
      let newMax = this.maxDate - (centerX - this.maxDate);

      //if both are out of bounds
      if (newMax > this.upperLimit && newMin < this.lowerLimit) {
        const diff = newMax - newMin;

        newMax = this.upperLimit;
        newMin = this.lowerLimit;
      } else if (newMin < this.lowerLimit && newMax < this.upperLimit) {
        // if newMin is lower than lowerlimit

        const diff = newMax - newMin;
        const diffDays = moment(newMax).diff(moment(newMin), 'days');

        if (diffDays >= 30) {
          newMax = this.upperLimit;
          newMin = this.lowerLimit;
        } else {
          newMin = this.lowerLimit;
          newMax = newMin + diff;
        }
      } else if (newMax > this.upperLimit && newMin > this.lowerLimit) {
        // if newMax is upper than upperlimit
        const diff = newMax - newMin;
        const diffDays = moment(newMax).diff(moment(newMin), 'days');

        if (diffDays >= 30) {
          newMax = this.upperLimit;
          newMin = this.lowerLimit;
        } else {
          newMax = this.upperLimit;
          newMin = newMax - diff;
        }
      } else {
      }

      if (newMin !== newMax) {
        this.minDate = newMin;
        this.maxDate = newMax;
        this.disableEnableButtons(this.minDate, this.maxDate);
        this.updateChartDate(moment(newMin).toDate(), moment(newMax).toDate(), 'custom');
      } else {
        this.allowZoomOut = false;
      }
    }

    if (type === 'zoomIn') {
      const centerX = (this.minDate + this.maxDate) / 2;

      const newMin = (this.minDate + centerX) / 2;
      const newMax = (this.maxDate + centerX) / 2;

      if (newMin !== newMax) {
        this.minDate = newMin;
        this.maxDate = newMax;
        this.disableEnableButtons(this.minDate, this.maxDate);
        this.updateChartDate(moment(newMin).toDate(), moment(newMax).toDate(), 'custom');
      } else {
        this.allowZoomOut = false;
      }
    }
  }

  showMarkers(event) {
    this.showMarkerVal = event.checked;
    this._dashEventService.setMarker(this.id, this.showMarkerVal);
  }

  updateChartDate(mindate: Date, maxDate: Date, type?: ITemporal) {
    const _type = type || this.type;
    const _minDate = moment(mindate).valueOf();
    const _maxDate = moment(maxDate).valueOf();

    const temp = this._dashEventService.createHistoricalGraphState(_minDate, _maxDate, _type);
    this._dashEventService.setAll_historicalGraphState(temp);
  }

  ngAfterViewInit() {
    this.showMarkerVal = this.markervalue;

    this.cdk.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
