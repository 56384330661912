import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import '../../../../../node_modules/intl-tel-input/build/js/utils.js';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { ToasterData } from 'src/app/shared/constants/constants.js';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewChecked, OnDestroy {
  register: boolean;
  userDetails: any;
  inputTel: any;
  initialViewCheck: any;
  iti: any;
  profileForm = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    timezone: ['', [Validators.required]],
    phone: [''],
    notification_email: [],
    notification_sms: [],
  });
  email: string;
  validPhone = false;
  timezone = [];
  selectedTimezone: string;
  user_name: any;
  submitted = false;
  numberValue: any;
  userCreated: boolean;
  hidePassword = true;
  disableClick = false;
  phoneNumber: string;
  sentrequest = false;
  emailcheck: boolean;
  dataLoaded = false;
  sub: any;
  private subscription: Subscription[] = [];
  constructor(
    private service: MeshServiceProvidor,
    public fb: FormBuilder,
    public location: Location,
    private cs: CommonService
  ) {
    this.service.getUserDetails();
  }

  ngOnInit(): void {
    this.getTimeZoneInfo();

    const that = this;

    const sub1 = this.service.userDetails$.subscribe((userDetail) => {
      setTimeout(() => {
        if (!!userDetail && Object.keys(userDetail).length !== 0) {
          that.userDetails = userDetail;
          that.setFormData(that.userDetails);
          this.dataLoaded = true;
        }
      }, 1000);
    });

    const sub2 = this.profileForm.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        this.phonenumberValididtycheck(value.phone);
      });

    this.subscription.push(sub1);
    this.subscription.push(sub2);
  }

  checkforemail() {
    this.emailcheck = true;
  }

  setFormData(data: any): void {
    this.phoneNumber = this.getFormattedPhoneNumber(data.phoneNumber);
    this.email = data.username;
    this.profileForm.patchValue({
      firstname: data.firstName,
      lastname: data.lastName,
      timezone: data.userTimeZone,
      notification_email: data.notificationMethods.email,
      notification_sms: data.notificationMethods.sms,
    });

    this.selectedTimezone = data.userTimeZone;
    if (!!this.iti && this.phoneNumber !== '') {
      this.iti.setNumber(this.phoneNumber);
    }
  }

  getFormattedPhoneNumber(phoneNumber: string): string {
    if (!!phoneNumber && phoneNumber.length > 0 && phoneNumber[0] !== '+') {
      return `+${phoneNumber}`;
    } else {
      return phoneNumber;
    }
  }

  checkifValid(ev): any {
    if (!!ev && !!ev.target.value) {
      const num = ev.target.value;
      this.phonenumberValididtycheck(num);
    }
  }

  getTimeZoneInfo(): any {
    const countries = moment.tz.names();
    countries.map((country) => {
      const obj = {
        name: country,
        format: moment().tz(country).format('Z'),
        displayText: `${country} (UTC ${moment().tz(country).format('Z')})`,
      };
      this.timezone.push(obj);
    });

    this.timezone.push({
      name: 'UTC',
      format: moment().tz('UTC').format('Z'),
      displayText: `"UTC" (UTC ${moment().tz('UTC').format('Z')})`,
    });
  }

  comparedatapointObjects(object1: any, object2: any): any {
    return object1 && object2 && object1.name.trim() === object2.trim();
  }

  ngAfterViewChecked(): any {
    if (!this.initialViewCheck) {
      this.initIntTelInput();
      this.initialViewCheck = true;
    }
  }

  initIntTelInput(): any {
    const that = this;
    this.inputTel = document.querySelector('#phone');
    this.iti = intlTelInput(this.inputTel, {
      utilsScript: '../../../../../node_modules/intl-tel-input/build/js/utils.js',
      autoPlaceholder: 'off',
      formatOnDisplay: false,
    });
    this.inputTel.addEventListener('countrychange', function () {
      const num = that.iti.getNumber().trim();
      that.phonenumberValididtycheck(num);
    });
  }

  phonenumberValididtycheck(num) {
    const notification_sms = this.profileForm.get('notification_sms').value;
    this.numberValue = this.iti.getNumber().trim();
    if (this.iti.getNumber().trim()) {
      if (
        this.iti.isValidNumber() &&
        (this.iti.getNumberType() === (window as any).intlTelInputUtils.numberType.MOBILE ||
          this.iti.getNumberType() === (window as any).intlTelInputUtils.numberType.FIXED_LINE ||
          this.iti.getNumberType() ===
            (window as any).intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE)
      ) {
        const reg = /[ `!@#$%^&*_\=\[\]{};':"\\|,.<>\/?~a-zA-Z]/;
        if (!reg.test(num) || (this.numberValue === '' && notification_sms === false)) {
          this.validPhone = true;
        } else {
          this.validPhone = false;
        }
      } else {
        this.validPhone = false;
      }
    } else if (notification_sms === true && (this.phoneNumber === '' || this.numberValue === '')) {
      this.validPhone = false;
    } else {
      this.validPhone = true;
    }
  }

  save(): any {
    this.submitted = true;
    if (this.profileForm.valid) {
      if (this.checkPhoneValidity()) {
        this.sentrequest = true;
        this.disableClick = true;
        // this.profileForm.value.notification_sms;
        let timezone;
        if (typeof this.profileForm.value.timezone === 'string') {
          timezone = this.profileForm.value.timezone;
        } else {
          timezone = this.profileForm.value.timezone.name;
        }

        const phone = !this.profileForm.controls.phone.touched
          ? this.phoneNumber
          : this.validPhone && this.profileForm.value.phone === ''
          ? ''
          : this.validPhone && !!this.numberValue
          ? this.numberValue
          : '';
        const data = {
          firstName: this.profileForm.value.firstname,
          lastName: this.profileForm.value.lastname,
          phoneNumber: phone,
          userTimeZone: timezone,
          notificationMethods: {
            email: this.profileForm.value.notification_email,
            sms: this.profileForm.value.notification_sms,
          },
        };
        this.service
          .setUserDetails(data)
          .then((res) => {
            const data1: ToasterData = {
              type: 'success',
              message: 'profileUpdated',
            };
            this.sentrequest = false;
            this.cs.openToaster(data1);
            this.location.back();
          })
          .catch((err) => {
            this.userCreated = false;
            const data2: ToasterData = {
              type: 'error-custom',
              message: err.toString(),
            };
            this.sentrequest = false;
            this.cs.openToaster(data2);
          });
      }
    } else {
      this.disableClick = false;
    }
  }

  checkPhoneValidity() {
    if (
      (this.validPhone &&
        this.profileForm.value.phone !== '' &&
        this.profileForm.value.notification_sms === true &&
        this.phoneNumber !== '' &&
        this.numberValue !== '') ||
      (!this.profileForm.controls.phone.touched &&
        this.profileForm.value.notification_sms === false) ||
      (this.validPhone &&
        this.profileForm.value.notification_sms === true &&
        this.numberValue !== '' &&
        (this.profileForm.value.phone !== '' || !this.profileForm.controls.phone.touched)) ||
      (this.validPhone && this.profileForm.value.notification_sms === false)
    ) {
      return true;
    } else {
      this.validPhone = false;
      return false;
    }
  }

  cancel(): any {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
