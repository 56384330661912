import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material.module';
import { StatusStyleDirective } from './directive/status-style/status-style.directive';
import { ExternalModule } from './modules/external.module';
import {
  DatetimePickerComponent,
  HeaderDatetimePickerComponent,
} from './components/datetime-picker/datetime-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateformatPipe } from './pipe/dateformat/dateformat.pipe';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { DeviceCardComponent } from './components/device-card/device-card.component';
import { TooltipDirective } from './directive/tooltip/tooltip.directive';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DndDirective } from './directive/dragndrop/dnd.directive';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ShareDashboardComponent } from './components/share-dashboard/share-dashboard.component';
import { DashboardPermissionComponent } from './components/dashboard-permission/dashboard-permission.component';
import { CommonFilterPipe } from './pipe/common-filter/common-filter.pipe';
import { ArrayIncludesPipe } from './pipe/array-includes/array-includes.pipe';

@NgModule({
  declarations: [
    StatusStyleDirective,
    DatetimePickerComponent,
    DateformatPipe,
    HeaderDatetimePickerComponent,
    MultiSelectDropdownComponent,
    DeviceCardComponent,
    TooltipDirective,
    TemplateCardComponent,
    FileUploadComponent,
    DndDirective,
    GenericDialogComponent,
    ToasterComponent,
    ShareDashboardComponent,
    DashboardPermissionComponent,
    CommonFilterPipe,
    ArrayIncludesPipe,
  ],
  imports: [CommonModule, MaterialModule, ExternalModule, FormsModule, ReactiveFormsModule],
  exports: [
    MaterialModule,
    StatusStyleDirective,
    ExternalModule,
    DatetimePickerComponent,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectDropdownComponent,
    DeviceCardComponent,
    TooltipDirective,
    TemplateCardComponent,
    MatFormFieldModule,
    MatAutocompleteModule,
    FileUploadComponent,
    DateformatPipe,
    CommonFilterPipe,
    ArrayIncludesPipe,
  ],
})
export class SharedModule {}
