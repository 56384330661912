import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/service/template/template.service';
import { WidgetsService } from '../../../service/widgets/widgets.service';
import * as _ from 'lodash';
import { CommonService } from '../../../shared/services/common/common.service';
import { ToasterData } from '../../../shared/constants/constants';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MeshServiceProvidor } from 'src/app/service/mesh/mesh.service';
import { fakeTempData } from 'src/app/shared/constants/ce-templateData';

@Component({
  selector: 'app-ce-subheader',
  templateUrl: './ce-subheader.component.html',
  styleUrls: ['./ce-subheader.component.scss'],
})
export class CeSubheaderComponent implements OnInit, OnChanges {
  @Input() templateDetails;
  // @Output("saveTemplateData") saveTemplateData = new EventEmitter();
  @Output() savePublishTemplateData: EventEmitter<any> = new EventEmitter();
  @Output() deleteTemplate: EventEmitter<any> = new EventEmitter();
  @Input() editStatus;
  @Input() templateStatus;
  @Input() unSavedChanges;
  @Input() previewBtnName: string;
  @Input() saveClicked: boolean;
  @Output() editActionBtn: EventEmitter<any> = new EventEmitter();
  templatePage: string;
  selectedWidget: string;
  maxWidget = false;
  noAction: boolean;
  disableClick = true;
  constructor(
    public router: Router,
    private ws: WidgetsService,
    private _templateService: TemplateService,
    private dialog: MatDialog,
    private meshServiceProvidor: MeshServiceProvidor,
    private cs: CommonService
  ) {}

  ngOnInit(): void {
    //display Subheader content based on route
    if (this.router.url === '/main/template') {
      this.templatePage = 'template';
    }
    if (this.router.url.includes('/main/template/blanktemplate')) {
      this.templatePage = 'blanktemplate';
    }
    if (this.router.url.includes('create-dashboard-template')) {
      this.templatePage = 'create-dashboard';
    }
    if (this.router.url === '/main/template/addwidget') {
      this.templatePage = 'addwidget';
      this.ws.selectWidgetChange.subscribe((widget) => {
        this.selectedWidget = widget;
      });
    }
    if (this.router.url.includes('editwidget')) {
      this.templatePage = 'editwidget';
    }
    if (this.router.url === '/main/template/updateInfo') {
      this.templatePage = 'updateInfo';
    }
    if (this.router.url.includes('preview?')) {
      this.templatePage = 'preview';
      if (this.router.url.includes('noaction=true')) {
        this.noAction = true;
      } else {
        this.noAction = false;
      }
    }

    this._templateService.templdataUpdated.subscribe((p) => {
      this.widgetCount();
    });
    this.widgetCount();
  }

  ngOnChanges() {
    this.disableClick =
      !!this.templateDetails && 'dashboardTemplateId' in this.templateDetails ? false : true;
  }

  widgetCount() {
    const allWidgets = this._templateService.tempData;

    const count = _.countBy(allWidgets, (obj) => obj.type !== 'space');

    if (count.true >= 15) {
      this.maxWidget = true;
    } else {
      this.maxWidget = false;
    }
  }

  savePublish(e) {
    this.savePublishTemplateData.emit(e);
  }
  unPublish(e) {
    this.savePublishTemplateData.emit(e);
  }
  delete() {
    this.deleteTemplate.emit();
  }

  redirect() {
    if (!this.unSavedChanges) {
      this.router.navigate(['/', 'main', 'template']);
      sessionStorage.removeItem('initTempData');
      sessionStorage.removeItem('initTempName');
    } else {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        height: 'auto',
        width: '500px',
        data: {
          modalType: 'unsaved-changes',
        },
      });
    }
  }

  previewInNewWindow() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/main/template/preview`], {
        queryParamsHandling: 'merge',
        queryParams: { noaction: true },
      })
    );

    window.open(url, '_blank');
  }

  async manageRedirect() {
    const cloneData = JSON.parse(sessionStorage.getItem('cloneData'));
    if (cloneData) {
      try {
        const cloneDataRes = await this.meshServiceProvidor.cloneDashboardTemplate(cloneData);
        const id = (cloneDataRes as any).id;
        if (
          !!cloneDataRes &&
          (cloneDataRes as any).removedCount &&
          (cloneDataRes as any).removedCount > 0
        ) {
          sessionStorage.setItem('removedWidget', JSON.stringify(true));
        } else {
          sessionStorage.removeItem('removedWidget');
        }
        const temp = { ...this.templateDetails, ...{ id } };
        temp.dashboardTemplateId = id;
        sessionStorage.removeItem('cloneData');
        sessionStorage.removeItem('tempData');
        sessionStorage.removeItem('tempInfo');
        sessionStorage.removeItem('dataPoints');
        sessionStorage.removeItem('defaultTimeFilter');
        this._templateService.tempData = fakeTempData;
        this.router.navigate(['/', 'main', 'template', 'blanktemplate'], {
          queryParams: { ...temp },
        });
      } catch (error) {
        const data: ToasterData = {
          type: 'error-custom',
          message: error.toString(),
        };
        this.cs.openToaster(data);
      }
    } else {
      this.router.navigate(['/', 'main', 'template', 'blanktemplate'], {
        queryParamsHandling: 'merge',
      });
    }
  }

  get hasScroll() {
    const div = document.querySelector(
      'body > app-root > app-main > app-sidebar > mat-sidenav-container > mat-sidenav-content'
    );
    const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
    return hasVerticalScrollbar;
  }
}
