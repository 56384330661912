export class MutliSeriesChartModel {
  readonly name = 'multiseries-chart';
  public readonly type = this.name;
  public readonly height = 2;
  public readonly width = 3;
  private readonly _widgetID = `${this.name}${Math.floor(Math.random() * 1000000)}`;

  constructor(
    public autoscale: boolean,
    public seriesCount: number,
    public colors: Array<string>,
    public annotationData: Array<{ y: number; color: string }>,
    public dataPath: string[],
    public title: string,
    public units: Array<string>,
    public primaryDataPoint: string,
    public dualYAxis: boolean,
    public min: Array<number>,
    public max: Array<number>,
    public widgetID?: string
  ) {
    const _widgetID = `${this.name}${Math.floor(Math.random() * 1000000)}`;
    this.widgetID = this.widgetID || _widgetID;
  }
}
